import { Padding } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  InputAdornment,
  FormLabel,
  Checkbox,
  FormGroup,
  OutlinedInput,
} from "@mui/material";
import { React, useState, useRef, useEffect } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAddComplianceQMutation } from "state/api";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#ccc", // Inactive step color
        },
        active: {
          color: "#4caf50", // Active step color
        },
        completed: {
          color: "#4caf50", // Completed step color
        },
      },
    },
  },
});

const ComplianceQ = ({ open, onClose, id, data }) => {
  const [addComplianceQ] = useAddComplianceQMutation();
  console.log("id", id, data);
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const modalRef = useRef();
  const [activeStep, setActiveStep] = useState(0);

  const handleTab = (event) => {
    if (!modalRef.current) return; // Guard clause

    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );
    const firstElement = focusableModalElements[0];
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1];

    if (!focusableModalElements.length) {
      event.preventDefault();
      return;
    }

    if (event.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      event.preventDefault();
    } else if (!event.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (open && modalRef.current) {
      // Now you have access to the modal element
      // You can use modalRef.current to manipulate the modal
      console.log("Modal is open", modalRef.current);
    }
  }, [open]);

  var i = data?.complianceQuestionaire;

  const initialValues = {
    // For Supply Chain Due Diligence form
    corporatePercentage: i?.corporatePercentage,
    AssayReport: i?.AssayReport,
    individualPercentage: i?.individualPercentage,
    supplierDocumentTypes: {
      companyIdentification: false,
      registrationDocuments: false,
      nameAddress: false,
      beneficialOwnership: false,
      businessActivity: false,
      license: false,
      amlCftPolicy: false,
      others: false,
      otherDetails: "",
    },
    invoice: i?.invoice,
    customsDocument: i?.customsDocument,
    certificateOfOrigin: i?.certificateOfOrigin,
    assayReport: i?.assayReport,
    otherDocuments: i?.otherDocuments,
    otherDocumentDetails: i?.otherDocumentDetails,
    monitorSuppliers: i?.monitorSuppliers,
    assessSupplyChain: i?.assessSupplyChain,

    // For Responsible Precious Metal Supply Chain Policy form Name- Nationality- Residential status-risk-profile-  real estate activity report- periodic sanction screening
    supplyChainPolicy: i?.supplyChainPolicy,
    supplyChainPolicyCopy: i?.supplyChainPolicyCopy,
    oecdCompliance: i?.oecdCompliance,
    humanRightsPolicy: i?.humanRightsPolicy,
    antiBriberyPolicy: i?.antiBriberyPolicy,
    antiBriberyPolicyCopy: i?.antiBriberyPolicyCopy,
    whistleBlowerPolicy: i?.whistleBlowerPolicy,
    whistleBlowerPolicyCopy: i?.whistleBlowerPolicyCopy,
    dataProtectionPolicy: i?.dataProtectionPolicy,
    dataProtectionPolicyCopy: i?.dataProtectionPolicyCopy,

    // For AML / CFT Policy and Procedures form
    documentedPolicies: i?.documentedPolicies,
    internalRiskAssessment: i?.internalRiskAssessment,
    certificateOfOrigin: i?.certificateOfOrigin,
    customerIdentification: i?.customerIdentification,
    customerRecord: i?.customerRecord,
    riskBasedAssessment: i?.riskBasedAssessment,
    enhancedDueDiligence: i?.enhancedDueDiligence,
    checkPEPs: i?.checkPEPs,
    dueDiligencePEPs: i?.dueDiligencePEPs,
    sourceOfWealth: i?.sourceOfWealth,
    sourceOfFunds: i?.sourceOfFunds,
    complianceOfficer: i?.complianceOfficer,
    customsdocument: i?.customsdocument,
    complianceOfficerDetails: {
      name: "",
      designation: "",
      contactNo: "",
      email: "",
    },
    monitoringProgram: i?.monitoringProgram,
    customerScreening: i?.customerScreening,
    screeningList: i?.screeningList || [],
    goAML: i?.goAML,
    redFlags: i?.redFlags,
    transactionAssessment: i?.transactionAssessment,
    trainingFrequency: i?.trainingFrequency,
    keepRecords: i?.keepRecords,
    recordYears: i?.recordYears,
    staffTraining: i?.staffTraining,
    maxCashPayment: i?.maxCashPayment,
    maxCashAmount: i?.maxCashAmount,

    // For Financial Information form
    records: i?.records,
    auditors: i?.auditors,
    auditorName: i?.auditorName,
    operatingFunds: {
      ownCapital: "",
      bankLoan: "",
      governmentEntity: "",
      thirdPartyLoan: "",
      others: "",
    },
  };
  console.log(initialValues);

  const [responses, setResponses] = useState(initialValues);
  const [screeningList, setScreeningList] = useState([]);

  const handleInputChange = (event) => {
    console.log(event.target);
    const { name, value, type, checked } = event.target;

    // Handling checkboxes
    if (type === "checkbox") {
      setResponses({
        ...responses,
        [name]: checked,
      });
      return;
    }

    // Handling multi-select
    if (name === "screeningList") {
      const {
        target: { value },
      } = event;
      setScreeningList(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }

    // Handling nested objects like operatingFunds and complianceOfficerDetails
    if (
      name &&
      (name.startsWith("operatingFunds.") ||
        name.startsWith("complianceOfficerDetails."))
    ) {
      const [parent, child] = name.split(".");
      setResponses({
        ...responses,
        [parent]: {
          ...responses[parent],
          [child]: value,
        },
      });
      return;
    }

    // Handling all other inputs
    setResponses({
      ...responses,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    var a = responses;
    var i = id;
    console.log("Responses:", { ...a, id: id });
    console.log(id);
    addComplianceQ({ ...a, id: id }, i);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onclose}
        // Prevents dialog from closing when clicking outside
        fullWidth
        maxWidth="md"
        scroll={"paper"}
      >
        <DialogTitle>
          <Typography variant="h4" paddingBottom={"12px"}>
            Compliance Checks
          </Typography>
          {/* {id} */}
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel
                TypographyProps={{
                  style: {
                    fontWeight: activeStep === 0 ? "bold" : "normal",
                  },
                }}
              >
                Step 1: Financial Information
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                TypographyProps={{
                  style: {
                    fontWeight: activeStep === 1 ? "bold" : "normal",
                  },
                }}
              >
                Step 2: Responsible Precious Metal Supply Chain Policy
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                TypographyProps={{
                  style: {
                    fontWeight: activeStep === 2 ? "bold" : "normal",
                  },
                }}
              >
                Step 3: AML / CFT POLICY AND PROCEDURES
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                TypographyProps={{
                  style: {
                    fontWeight: activeStep === 3 ? "bold" : "normal",
                  },
                }}
              >
                Step 4: Supply Chain Due Diligence
              </StepLabel>
            </Step>
          </Stepper>
        </DialogTitle>
        <DialogContent>
          {activeStep === 0 && (
            // <>Step 1</>
            // Step 1: Basic Information
            // Your code here
            <Box p={3}>
              {" "}
              {/* Box with 1px border */}
              <form onSubmit={handleSubmit}>
                {/* <Typography variant="h4" gutterBottom>
                  Financial Information
                </Typography> */}
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={6}>
                    Do you keep records and accounts of your transactions? (No
                    of years)
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="records"
                      value={responses?.records}
                      autoFocus
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Do you have external independent financial auditors?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="auditors"
                        row
                        value={responses?.auditors ?? ""}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    {responses?.auditors === "Yes" && (
                      <TextField
                        label="Name of Auditors"
                        name="auditorName"
                        value={responses?.auditorName}
                        onChange={handleInputChange}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    Mention the source of operating funds. (Own Capital ?)
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="sourceOfFunds"
                        row
                        value={responses?.sourceOfFunds ?? ""}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {responses?.sourceOfFunds === "No" && (
                    <Grid item xs={12}>
                      <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: "50%",
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              Bank Loan:
                            </td>
                            <td
                              style={{
                                width: "50%",
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              <TextField
                                label="Bank Name"
                                name="operatingFunds.bankLoan"
                                value={responses?.operatingFunds?.bankLoan}
                                onChange={handleInputChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              Government Entity:
                            </td>
                            <td
                              style={{
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              <TextField
                                label="Name"
                                name="operatingFunds.governmentEntity"
                                value={
                                  responses?.operatingFunds?.governmentEntity
                                }
                                onChange={handleInputChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              Third Party Loan:
                            </td>
                            <td
                              style={{
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              <TextField
                                label="Name of Third Party"
                                name="operatingFunds.thirdPartyLoan"
                                value={
                                  responses?.operatingFunds?.thirdPartyLoan
                                }
                                onChange={handleInputChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              Others:
                            </td>
                            <td
                              style={{
                                verticalAlign: "middle",
                                padding: "12px",
                              }}
                            >
                              <TextField
                                label="Please specify"
                                name="operatingFunds.others"
                                value={responses?.operatingFunds?.others}
                                onChange={handleInputChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  )}
                </Grid>
                {/* <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button> */}
              </form>
            </Box>
          )}
          {activeStep === 1 && (
            // <>Step 2</>
            // Step 2: Compliance Questions
            // Your code here
            <Box p={3}>
              <form>
                {/* <Typography variant="h4" gutterBottom>
                  Responsible Precious Metal Supply Chain Policy
                </Typography> */}
                <Grid container spacing={3} alignItems="center">
                  {/* Question 1 */}
                  <Grid item xs={6}>
                    Did your institution establish a responsible supply chain of
                    gold from conflict-affected and high-risk areas policy?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="supplyChainPolicy"
                        row
                        value={responses?.supplyChainPolicy}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {responses.supplyChainPolicy === "Yes" && (
                        <TextField
                          label="Please provide a copy"
                          name="supplyChainPolicyCopy"
                          value={responses?.supplyChainPolicyCopy}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormControl>
                  </Grid>

                  {/* Question 2 */}
                  <Grid item xs={6}>
                    Does your company comply or plan to comply with the OECD Due
                    Diligence Guidance for Responsible Supply Chains of Minerals
                    from Conflict-Affected and High-Risk Areas?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="oecdCompliance"
                        row
                        value={responses?.oecdCompliance}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Complies"
                          control={<Radio />}
                          label="Complies"
                        />
                        <FormControlLabel
                          value="Plans to Comply"
                          control={<Radio />}
                          label="Plans to Comply"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Question 3 */}
                  <Grid item xs={6}>
                    Does your company have policies and procedures that cover
                    human rights?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="humanRightsPolicy"
                        row
                        value={responses?.humanRightsPolicy}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Question 4 */}
                  <Grid item xs={6}>
                    Does your company have an anti-bribery and corruption
                    policy?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="antiBriberyPolicy"
                        row
                        value={responses?.antiBriberyPolicy}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {responses.antiBriberyPolicy === "Yes" && (
                        <TextField
                          label="Please provide a copy"
                          name="antiBriberyPolicyCopy"
                          value={responses?.antiBriberyPolicyCopy}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormControl>
                  </Grid>

                  {/* Question 5 */}
                  <Grid item xs={6}>
                    Does your company have a whistle-blower policy in place?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="whistleBlowerPolicy"
                        row
                        value={responses?.whistleBlowerPolicy}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {responses.whistleBlowerPolicy === "Yes" && (
                        <TextField
                          label="Please provide a copy"
                          name="whistleBlowerPolicyCopy"
                          value={responses?.whistleBlowerPolicyCopy}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormControl>
                  </Grid>

                  {/* Question 6 */}
                  <Grid item xs={6}>
                    Does your company have Data Protection Policy?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="dataProtectionPolicy"
                        row
                        value={responses?.dataProtectionPolicy}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {responses.dataProtectionPolicy === "Yes" && (
                        <TextField
                          label="Please provide a copy"
                          name="dataProtectionPolicyCopy"
                          value={responses?.dataProtectionPolicyCopy}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </Box>
          )}
          {activeStep === 2 && (
            // <>Step 3</>

            // Step 3: Additional Details
            // Your code here
            <Box p={3}>
              <form>
                {/* <Typography variant="h4" gutterBottom>
                  AML / CFT POLICY AND PROCEDURES
                </Typography> */}
                <Grid container spacing={3} alignItems="center">
                  {/* Previous and additional questions */}

                  {/* Question: Customer Screening with Multi-Select */}
                  <Grid item xs={6}>
                    Does your institution screen customers and transactions
                    against lists of persons, entities, or countries issued by
                    government/international bodies?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="customerScreening"
                        row
                        value={responses?.customerScreening}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {/* {responses.customerScreening === "Yes" && (
                        // <FormControl>
                        //   <InputLabel id="demo-mutiple-chip-label">
                        //     Mention the list
                        //   </InputLabel>
                        //   <Select
                        //     labelId="demo-mutiple-chip-label"
                        //     id="demo-mutiple-chip"
                        //     name="screeningList"
                        //     multiple
                        //     MenuProps={MenuProps}
                        //     value={screeningList}
                        //     onChange={handleInputChange}
                        //     renderValue={(selected) => (
                        //       <div>
                        //         {selected.map((value) => (
                        //           <Chip key={value} label={value} />
                        //         ))}
                        //       </div>
                        //     )}
                        //   >
                        //     <MenuItem value="UN Sanctions" name="1">
                        //       UN Sanctions
                        //     </MenuItem>
                        //     <MenuItem value="UAE (Local Terrorist List)">
                        //       UAE (Local Terrorist List)
                        //     </MenuItem>
                        //     <MenuItem value="Other">
                        //       Other local regulations
                        //     </MenuItem>
                        //   </Select>
                        // </FormControl>
                      )} */}
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-chip-label">
                        Chip
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        name="screeningList"
                        multiple
                        value={responses?.screeningList}
                        onChange={handleInputChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="UN Sanctions" name="UN Sanctions">
                          UN Sanctions
                        </MenuItem>
                        <MenuItem
                          value="UAE (Local Terrorist List)"
                          name="UAE Local List"
                        >
                          UAE (Local Terrorist List)
                        </MenuItem>
                        <MenuItem value="Other" name="Other">
                          Other local regulations
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Question: goAML system */}
                  <Grid item xs={6}>
                    Did your establishment sign up to the goAML system of the
                    FIU?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="goAML"
                        row
                        value={responses?.goAML}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Question: Red Flags */}
                  <Grid item xs={6}>
                    Has your establishment created and documented red flags to
                    provide reasonable grounds for reporting of any suspicious
                    transaction?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="redFlags"
                        value={responses?.redFlags}
                        row
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Add more questions here following the same pattern */}
                  {/* Question: Transaction Assessment */}
                  <Grid item xs={6}>
                    Does the Company perform a risk-based assessment to
                    understand the normal and expected transactions of its
                    clients?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="transactionAssessment"
                        row
                        value={responses?.transactionAssessment}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Question: Keep Records */}
                  <Grid item xs={6}>
                    Does the establishment keep records of customers and
                    transactions?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="keepRecords"
                        row
                        value={responses?.keepRecords}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {responses.keepRecords === "Yes" && (
                        <TextField
                          name="recordYears"
                          value={responses?.recordYears}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormControl>
                  </Grid>

                  {/* Question: Staff Training */}
                  <Grid item xs={6}>
                    Does your establishment conduct regular ongoing AML/CFT
                    training for staff?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="staffTraining"
                        row
                        value={responses?.staffTraining}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Question: Max Cash Payment */}
                  <Grid item xs={6}>
                    Does the Company have a maximum amount allowed for cash
                    payment as per internal policy or regulatory framework?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="maxCashPayment"
                        row
                        value={responses?.maxCashPayment}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {responses.maxCashPayment === "Yes" && (
                        <TextField
                          label="Mention the amount"
                          name="maxCashAmount"
                          value={responses?.maxCashAmount}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormControl>
                  </Grid>
                  {/* Question: Customer Verification */}
                  <Grid item xs={6}>
                    Has your organization implemented processes for the
                    identification and verification of your customers and
                    beneficial owners?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="customerIdentification"
                        row
                        value={responses?.customerIdentification}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Question: Risk-Based Assessment of Clients */}
                  <Grid item xs={6}>
                    Does the Company have a risk-based assessment of its clients
                    (e.g., low, medium, or high risks)?
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="riskBasedAssessment"
                        value={responses?.riskBasedAssessment}
                        row
                        onChange={(e) => handleInputChange(e)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Question: Enhanced Due Diligence */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Does the Company conduct enhanced due diligence for
                      high-risk clients?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="enhancedDueDiligence"
                          row
                          value={responses?.enhancedDueDiligence}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {/* Question: PEPs Checks */}
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        Does your establishment have checks in place to identify
                        if its customers/clients and their beneficial owners are
                        'Politically Exposed Persons' (PEPs)?
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="checkPEPs"
                            row
                            value={responses?.checkPEPs}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Question: Due Diligence for PEPs */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Does your organization have policies to conduct enhanced
                      due diligence which includes obtaining senior management
                      approval for establishing a business relationship or
                      one-off transaction with PEPs, their family, and close
                      associates?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="dueDiligencePEPs"
                          row
                          value={responses?.dueDiligencePEPs}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Question: Source of Wealth and Funds */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Does your institution collect information relating to
                      customer’s and beneficial owner(s)’ source of wealth and
                      source of funds?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="sourceOfWealth"
                          value={responses?.sourceOfWealth}
                          row
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Question: Designated Compliance Officer */}
                  {/* Question: Compliance Officer */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Has your organization appointed a designated compliance
                      officer with sufficient experience/expertise?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                          name="complianceOfficer"
                          row
                          value={responses?.complianceOfficer}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        {responses.complianceOfficer === "Yes" && (
                          <div>
                            <Box mb={2}>
                              <TextField
                                label="Name"
                                name="complianceOfficerDetails.name"
                                value={
                                  responses?.complianceOfficerDetails?.name
                                }
                                fullWidth
                                onChange={handleInputChange}
                              />
                            </Box>
                            <Box mb={2}>
                              <TextField
                                label="Designation"
                                name="complianceOfficerDetails.designation"
                                value={
                                  responses?.complianceOfficerDetails
                                    ?.designation
                                }
                                fullWidth
                                onChange={handleInputChange}
                              />
                            </Box>
                            <Box mb={2}>
                              <TextField
                                label="Contact No."
                                name="complianceOfficerDetails.contactNo"
                                fullWidth
                                value={
                                  responses?.complianceOfficerDetails?.contactNo
                                }
                                onChange={handleInputChange}
                              />
                            </Box>
                            <Box mb={2}>
                              <TextField
                                label="Email"
                                name="complianceOfficerDetails.email"
                                value={
                                  responses?.complianceOfficerDetails?.email
                                }
                                fullWidth
                                onChange={handleInputChange}
                              />
                            </Box>
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Question: Suspicious Activity Reporting */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Does your institution have a monitoring program for the
                      identification and reporting of suspicious or unusual
                      activity/transaction?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="monitoringProgram"
                          row
                          value={responses?.monitoringProgram}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Question: AML/CFT Training Frequency */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      How often does your establishment conduct AML/CFT training
                      for staff?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="trainingFrequency"
                          row
                          value={responses?.trainingFrequency}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Monthly"
                            control={<Radio />}
                            label="Monthly"
                          />
                          <FormControlLabel
                            value="Quarterly"
                            control={<Radio />}
                            label="Quarterly"
                          />
                          <FormControlLabel
                            value="Annually"
                            control={<Radio />}
                            label="Annually"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>{" "}
                  </Grid>

                  {/* Question: Internal Audits */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Does your organization conduct internal audits to assess
                      AML/CFT measures?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="internalRiskAssessment"
                          row
                          value={responses?.internalRiskAssessment}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Question: Data Protection Policy */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Does your company have a Data Protection Policy?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="dataProtectionPolicy"
                          row
                          value={responses?.dataProtectionPolicy}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Question: Whistleblower Policy */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Does your company have a whistleblower policy in place?
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="whistleBlowerPolicy"
                          row
                          value={responses?.whistleBlowerPolicy}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </form>{" "}
            </Box>
          )}
          {activeStep === 3 && (
            // <>Final</>

            // Step 4: Review & Submit
            // Your code here
            <Box>
              {/* <Typography variant="h4" gutterBottom>
                SUPPLY CHAIN DUE DILIGENCE
              </Typography> */}
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6}>
                  What is the typical profile of your precious metal's
                  suppliers? Corporate (%) Individual (%)
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Corporate (%)"
                    name="corporatePercentage"
                    onChange={handleInputChange}
                    value={responses?.corporatePercentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Individual (%)"
                    name="individualPercentage"
                    onChange={handleInputChange}
                    value={responses?.individualPercentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* ... (Similar grid items for other questions) */}
                <Grid item xs={6}>
                  What type of information does the Company request from its
                  precious metal’s suppliers?
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Select all that apply
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleInputChange}
                            checked={responses?.companyIdentification || false}
                            name="companyIdentification"
                          />
                        }
                        label="Company identification"
                      />
                      {/* ... (other checkboxes) */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleInputChange}
                            checked={responses?.others || false}
                            name="others"
                          />
                        }
                        label="Others"
                      />
                      {responses.others && (
                        <TextField
                          label="Specify Others"
                          name="otherDetails"
                          value={responses?.otherDetails}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  What type of documents does the Company request from its
                  precious metal’s suppliers?
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Select all that apply
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleInputChange}
                            name="invoice"
                            checked={responses?.invoice || false}
                          />
                        }
                        label="Invoice"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleInputChange}
                            name="customsDocument"
                            checked={responses?.customsDocument || false}
                          />
                        }
                        label="Customs document"
                      />{" "}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleInputChange}
                            name="certificateOfOrigin"
                            checked={responses?.certificateOfOrigin || false}
                          />
                        }
                        label="Certificate of Origin"
                      />{" "}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleInputChange}
                            name="AssayReport"
                            checked={responses?.AssayReport || false}
                          />
                        }
                        label="Assay Report"
                      />
                      {/* ... (other checkboxes) */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleInputChange}
                            name="otherDocuments"
                            checked={responses?.otherDocuments || false}
                          />
                        }
                        label="Other Documents"
                      />
                      {responses.otherDocuments && (
                        <TextField
                          label="Specify Other Documents"
                          name="otherDocumentDetails"
                          value={responses?.otherDocumentDetails}
                          onChange={handleInputChange}
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  Do you monitor your suppliers for inconsistent transactions?
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="monitorSuppliers"
                      row
                      onChange={handleInputChange}
                      value={responses?.monitorSuppliers}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  Does the Company assess its corporate precious metals
                  suppliers' Supply Chain Policy and purchase procedures and
                  practices?
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="assessSupplyChain"
                      row
                      value={responses?.assessSupplyChain}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}></Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={onClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            color="success"
            variant="contained"
          >
            Save
          </Button>
          <DialogActions>
            <Button
              disabled={activeStep === 0}
              color={"secondary"}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button variant="contained" color="success" onClick={handleNext}>
              {activeStep === 3 ? "Finish" : "Next"}
            </Button>
          </DialogActions> */}
          <Button onClick={onClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            disabled={activeStep === 0}
            color={"secondary"}
            onClick={handleBack}
          >
            Back
          </Button>
          {activeStep === 4 - 1 ? (
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Submit
            </Button>
          ) : (
            <Button variant="contained" color="success" onClick={handleNext}>
              Next
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ComplianceQ;
