import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography, useTheme, List, ListItem, Link } from "@mui/material";
import { useGetSalesQuery } from "state/api";

const BreakdownChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  function openInNewWindow(event, url) {
    event.preventDefault();
    window.open(url, "newwindow", "width=600,height=400"); // Adjust width and height as needed
    return false;
  }

  return (
    <Box
      height={isDashboard ? "400px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      <List>
        <ListItem>
          <Link
            href="#"
            style={{
              color: theme.palette.secondary[300],
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
            onClick={(e) =>
              openInNewWindow(
                e,
                "https://www.un.org/securitycouncil/sanctions/information"
              )
            }
          >
            United Nations Security Council (UNSC){" "}
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href="#"
            style={{ color: theme.palette.secondary[300] }}
            onClick={(e) =>
              openInNewWindow(e, "https://www.uaeiec.gov.ae/en-us/un-page")
            }
          >
            Executive Office For Control & Non-Proliferation{" "}
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href="#"
            style={{ color: theme.palette.secondary[300] }}
            onClick={(e) =>
              openInNewWindow(
                e,
                "https://ofac.treasury.gov/ofac-sanctions-lists"
              )
            }
          >
            Office of Foreign Asset Control (OFAC){" "}
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href="#"
            style={{ color: theme.palette.secondary[300] }}
            onClick={(e) =>
              openInNewWindow(
                e,
                "https://www.gov.uk/government/publications/the-uk-sanctions-list"
              )
            }
          >
            United Kingdom HM Treasury (UKHMT){" "}
          </Link>
        </ListItem>
      </List>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        color={theme.palette.secondary[400]}
        textAlign="center"
        pointerEvents="none"
        sx={{
          transform: isDashboard
            ? "translate(-75%, -170%)"
            : "translate(-50%, -100%)",
        }}
      >
        <Typography variant="h6"></Typography>
      </Box>
    </Box>
  );
};

export default BreakdownChart;
