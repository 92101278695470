import { Facebook, Twitter, LinkedIn, Instagram } from "@mui/icons-material";
import { Typography, Box, Container, Link, Grid } from "@mui/material";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#f5f5f5", color: "black", pt: 6, pb: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Contact Us
            </Typography>

            {/* <Typography>sales@AMLdiligence.com</Typography> */}
            <Typography>
              <b> ZAHADS Chartered Accountants </b> <br /> Office no. 751,
              Tamani Art offices, Marasi Drive Business Bay, Dubai, UAE | Tel:
              +971 (4) 451 9930
            </Typography>
            <Typography>Mob: +971 56 751 7147 | +971 58 945 4385</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Quick Links
            </Typography>
            <Link
              href="#"
              underline="none"
              style={{ color: "rgb(43, 174, 238)" }}
            >
              About
            </Link>{" "}
            *{" "}
            <Link
              href="#"
              underline="none"
              style={{ color: "rgb(43, 174, 238)" }}
            >
              Solutions
            </Link>{" "}
            <br />
            <Link
              href="#"
              underline="none"
              style={{ color: "rgb(43, 174, 238)" }}
            >
              Industries
            </Link>{" "}
            *
            <Link
              href="#"
              underline="none"
              style={{ color: "rgb(43, 174, 238)" }}
            >
              Privacy Policy
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Follow Us
            </Typography>
            <Facebook sx={{ mr: 1 }} />
            <Twitter sx={{ mr: 1 }} />
            <LinkedIn sx={{ mr: 1 }} />
            <Instagram />
          </Grid>
        </Grid>
        <Box textAlign="center" mt={5}>
          <Typography variant="body2">
            © 2023 AMLdiligence.com. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
