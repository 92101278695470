import { Typography, Box, Container } from "@mui/material";

const Process = () => {
  return (
    <Container backgroundColor={"#F5F5F5"} id="process">
      <Box my={4} textAlign="center">
        <Typography
          variant="h4"
          gutterBottom
          marginBottom="30px"
          fontWeight={"bold"}
        >
          AML CFT Process
        </Typography>
        <img
          src={
            "https://mermaid.ink/img/pako:eNqdlU9zmzAQxb_KDpnpyTnE3HzojA34T9JOPHbaQ3AOqliwxkJiJOHWjfPduwiS4tywT5Z4v129JwleA64zDCZBYVi1h6d4p4B-1p0kwhRyIeXk5i6ZJkkyolmjDzi5CcNwBFxLbSY3eZ73kdkVyN0VzPgKJhzORFcgV9iJrrATXWEnHo7MhyOL4chyOLIajtwPRx6GINM0qq3TJRp4VL80M5lQxQvc3n6FWbrKUDnhTvATjcgFZ05o9dKCs0ZzjjWvSxJdKM50P9Joj_wAC31Eo7xiZW2NGaziiwIzQb2dEfxzhXE6Z1wwCRvkulCimYYtZ5f9Z4wfCqNrlYFvSGCYbvAo8DdEBjPhYCnInzm9Y3feW_Tf9kbYA0ytRWubdb7rxq2uG4X9UeRbe27LtaHAznSFUqohCtXW-4ZHlC99-ZNhyjLubayZcxSLJWycThWTp7_YrZMCkNDT2osi62RNLQ2iapuG6VpLioYgeYLkT6UtRbxGY4lsE3nnW99xOpVoHCxQoelvZ9T67d6lUdgfxX4w79Au3S-wUke0ThT9MnO_zG1tK8GFrm3fCkRa5cKUmJ1hQZtUaarmNExrtyfjTqC9qDJn0iKstaVHRzzDMv1RZcwhfNd0HHzusKnlB7X0y1ylj6rQzbOPHY5reo0IKQpUHDvxyovvU8pK6IyOn9-2DbLPB-G-n8TCDx7SSJeVFIzKQeujuTTBKKBuJRMZfZteG2AXuD2WuAsm9DfDnNXS7YKdeiMpq53enhQPJs7UOApq7y0WjL5q5eVkkjV2g0neBDIKKqaetS678ds_TBA69w?type=png"
          }
          alt="AML CFT Process"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Box>
    </Container>
  );
};

export default Process;
