// import React, { useState } from "react";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Button,
//   Box,
//   Alert,
// } from "@mui/material";

// const PasswordChangeDialog = ({ open, onClose, onChangePassword }) => {
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [error, setError] = useState("");

//   const handlePasswordChange = () => {
//     if (password === confirmPassword) {
//       setError("");
//       onChangePassword(password);
//     } else {
//       setError("Passwords do not match");
//     }
//   };

//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>Change Password</DialogTitle>
//       <DialogContent>
//         <Box display="flex" flexDirection="column" gap="1rem">
//           <TextField
//             label="New Password"
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             fullWidth
//           />
//           <TextField
//             label="Confirm Password"
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             fullWidth
//           />
//           {error && <Alert severity="error">{error}</Alert>}
//         </Box>
//       </DialogContent>
//       <DialogActions>
//         <Button color="error" onClick={onClose}>
//           Cancel
//         </Button>
//         <Button color="success" onClick={handlePasswordChange}>
//           Change Password
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default PasswordChangeDialog;

import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Alert,
} from "@mui/material";

const PasswordChangeDialog = ({ open, onClose, onChangePassword }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handlePasswordChange = () => {
    if (!password || !confirmPassword) {
      setError("Password fields cannot be empty");
      return;
    }
    if (password === confirmPassword) {
      setError("");
      onChangePassword(password);
    } else {
      setError("Passwords do not match");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="1rem">
          <TextField
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          <TextField
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
          />
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="warning" onClick={onClose}>
          Cancel
        </Button>
        <Button color="success" onClick={handlePasswordChange}>
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChangeDialog;
