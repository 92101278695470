import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGetLogsQuery } from "state/api";
import { useTheme } from "@mui/material";
import moment from "moment";

const GridDash = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetLogsQuery();
  console.log(data);

  return (
    <DataGrid
      autoPageSize
      loading={isLoading || !data}
      getRowId={(row) => row._id}
      rows={data ?? []}
      columns={[
        {
          field: moment("createdAt").format("DD-MM-YYYY"),
          sortable: false,
          filterable: false,
          headerName: "Search Date",
          width: 190,
          renderCell: (params) =>
            moment(params.row.createdAt).format("DD-MM-YYYY"),
        },
        {
          field: "name",
          sortable: false,
          filterable: false,
          headerName: "Name Searched",
          width: 190,
        },
        {
          field: "status",
          sortable: false,
          filterable: false,
          headerName: "Status",
          width: 190,
        },
        {
          fiels: "type",
          headerName: "Type",
          sortable: false,
          width: 200,
          filterable: false,
          valueGetter: (params) => params.row.criteria?.typ,
          renderCell: (params) => params.value,
        },
      ]}
    />
  );
};

export default GridDash;
