import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Products from "scenes/products";
import Customers from "scenes/customers";
import ExpiredCustomer from "scenes/expiredcustomers";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Performance from "scenes/performance";
import Login from "scenes/login";
import ExpiredEntities from "scenes/expiredentities";
import Entity from "scenes/entity";
import Entities from "./scenes/entities";
import RequireAuth from "./middleware/RequireAuth";
import RedirectIfAuthenticated from "./middleware/RedirectIfAuthenticated";
import ExpiredCustoomer from "scenes/expiredcustomers";
import ScreenLog from "scenes/ScreenLog";
import NaturalPerson from "scenes/NaturalPersonCRP";
import LegalPerson from "scenes/LegalPersonCRP";
import SearchPage from "scenes/Search";
import Landing from "scenes/landing";
import CountryRisk from "scenes/CountriesRisk";
import IdTypeRisk from "scenes/idTypeRisk";
import LicTypeRisk from "scenes/licTypeRisk";
import ResidencyTypeRisk from "scenes/ResidentRisk";
import SourceOfFundsRisk from "scenes/SourceOfFundsRisk";
import BusinessActivityRisk from "scenes/BusinessActivityRisk";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/landing" element={<Landing />}></Route>
            {/* <Route element={<Layout />}> */}
            <Route
              path="/"
              element={<RedirectIfAuthenticated Component={Landing} />}
            />
            <Route element={<RequireAuth Component={Layout} />}>
              //{" "}
              {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
              <Route path="*" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/entities" element={<Entities />} />
              <Route path="/expiredentities" element={<ExpiredEntities />} />
              <Route path="/entity" element={<Entity />} />
              <Route path="/products" element={<Products />} />
              <Route path="/product" element={<LegalPerson />} />
              <Route path="/naturalPerson" element={<NaturalPerson />} />
              <Route path="/legalPerson" element={<LegalPerson />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/expiredcustomers" element={<ExpiredCustoomer />} />
              <Route path="/screeninglog" element={<ScreenLog />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/monthly" element={<Monthly />} />
              <Route path="/breakdown" element={<Breakdown />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/countriesRisk" element={<CountryRisk />} />
              <Route path="/idType" element={<IdTypeRisk />} />
              <Route path="/licType" element={<LicTypeRisk />} />
              <Route path="/resType" element={<ResidencyTypeRisk />} />
              <Route path="/fundSource" element={<SourceOfFundsRisk />} />
              <Route
                path="/businessActivity"
                element={<BusinessActivityRisk />}
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
