import { Typography, Box, Container, Grid } from "@mui/material";
const IndustriesSection = () => {
  const industries = [
    {
      title: "Banking and Finance",
      description:
        "Secure transactions and protect your institution with our robust compliance framework.",
    },
    {
      title: "Precious Metals Trade",
      description:
        "Stay agile and compliant in the fast-paced sector with our adaptive AML/CFT tools.",
    },
    {
      title: "Real Estate",
      description:
        "Mitigate risks in high-value transactions and maintain integrity with our property-focused compliance solutions.",
    },
    {
      title: "Legal & Professional",
      description:
        "Ensure your client dealings are transparent and compliant with our specialized tools for legal professionals.",
    },
  ];

  return (
    <Box
      sx={{ backgroundColor: "#2BAEEE", paddingBottom: "30px" }}
      id="industries"
    >
      <Container>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          padding="30px"
          textAlign="center"
          color={"white"}
          fontWeight={"bold"} // Change made here
        >
          Industries We Serve
        </Typography>
        <Grid container spacing={2}>
          {industries.map((industry, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  height: "150px",
                }}
              >
                <Typography
                  variant="h6"
                  component="h3"
                  color={"white"}
                  fontWeight={"bold"}
                >
                  {industry.title}
                </Typography>
                <Typography variant="body1" color={"white"}>
                  {industry.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default IndustriesSection;
