// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   FormControl,
//   Grid,
//   FormHelperText,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
//   CircularProgress,
//   Alert,
//   AlertTitle,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Switch,
//   Dialog,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { APIURL } from "../../globals";

// // import { useSearchIndividualsQuery } from "state/api";
// import { useMakePersonClearMutation } from "state/api";

// import { countryNames } from "data/data";
// import { countryListAlpha2 } from "data/data";
// import { values } from "data/data";

// const countryMenuItems = countryNames.map((country) => (
//   <MenuItem value={`${country}`} key={country}>
//     {country}
//   </MenuItem>
// ));

// const SearchPage = (name) => {
//   const [fullName, setFullName] = useState("");
//   const [reason, setReason] = useState("");

//   const [type, setType] = useState("Natural Person");
//   const [dob, setDOB] = useState("");
//   const [nationality, setNationality] = useState("");
//   const [incorporationDate, setIncorporationDate] = useState("");
//   const [incorporationCountry, setIncorporationCountry] = useState("");
//   const [searchResults, setSearchResults] = useState({ records: [], meta: {} });
//   const [showStartKYC, setShowStartKYC] = useState(false);
//   const [searched, setSearched] = useState(false);
//   const [fullNameError, setFullNameError] = useState("");
//   const [reasonError, setReasonError] = useState("");
//   const [makePersonClear, { isClearLoading, isError }] =
//     useMakePersonClearMutation();
//   // const search = useSearchIndividualsQuery();
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   const [isClearedDialog, setClearedDialog] = useState(false);
//   const [isCleared, setCleared] = useState(false);
//   const [ffrRequired, SetffrRequired] = useState(false);
//   const [nmrRequired, SetnmrRequired] = useState(false);

//   const handleSearch = async (event) => {
//     event.preventDefault();
//     if (fullName.trim() === "") {
//       setFullNameError("Full Name is required");
//       return;
//     }

//     setFullNameError("");
//     setIsLoading(true);

//     try {
//       const response = await fetch(
//         // "https://amldiligence.com/api/client/search",
//         APIURL + "/client/search",

//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           credentials: "include",
//           body: JSON.stringify({
//             fullName: fullName.trim(),
//             dob: dob || incorporationDate,
//             nationality: nationality || incorporationCountry,
//             typ: type,
//           }),
//         }
//       );

//       if (response.ok) {
//         const results = await response.json();
//         setSearchResults(results);
//         console.log(results);
//         console.log(JSON.stringify(results));
//         setShowStartKYC(true);
//         setSearched(true);
//       } else {
//         // Handle the error case
//         console.error("Failed to fetch search results");
//       }
//     } catch (error) {
//       // Handle the error case
//       console.error("Error occurred while fetching search results", error);
//     } finally {
//       setIsLoading(false); // Set loading state to false after fetching
//     }
//   };

//   const handleStartKYC = () => {
//     setClearedDialog(true);
//   };

//   const makePersonCLear = async () => {
//     if (isCleared) {
//       if (nmrRequired || ffrRequired) {
//         alert(
//           "Both PNMR Required and FFR Required must be false if person is cleared"
//         );
//         return;
//       }

//       if (reason.trim() === "") {
//         setReasonError("Reason is required if cleared");
//         return;
//       }
//     } else if (!isCleared && !nmrRequired && !ffrRequired) {
//       alert("Check the correct options below");
//       return;
//     } else if (nmrRequired && ffrRequired) {
//       alert("Only one of FFR and NMR can be selected");
//       return;
//     }

// sole.error("Error occurred while clearing person", error);
//     // }
//     try {
//       await makePersonClear({
//         id: searchResults?.meta._id, // The ID value
//         cleared: isCleared, // Boolean indicating if cleared
//         notes: reason, // Reason string
//         ffr: ffrRequired, // Boolean for FFR required
//         nmr: nmrRequired,
//       }).unwind();
//       // Handle any post-clear success actions here
//     } catch (error) {
//       // Handle errors here
//       console.error("Error in making person clear:", error);
//     }
//     navigate(`/screeninglog`);
//   };

//   return (
//     <Box p={3}>
//       <Typography variant="h5" gutterBottom>
//         Screening Criteria
//       </Typography>
//       <form onSubmit={handleSearch} autoComplete="on">
//         <Grid container spacing={2} alignItems="center">
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Full Name"
//               variant="outlined"
//               fullWidth
//               value={fullName}
//               onChange={(e) => setFullName(e.target.value)}
//               required
//               error={fullNameError !== ""}
//               autoComplete="name"
//               name="fullName"
//             />
//             {fullNameError && (
//               <FormHelperText error>{fullNameError}</FormHelperText>
//             )}
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <FormControl variant="outlined" fullWidth>
//               <InputLabel>Type</InputLabel>
//               <Select
//                 value={type}
//                 onChange={(e) => setType(e.target.value)}
//                 label="Type"
//                 autoComplete="off"
//               >
//                 <MenuItem value="Natural Person">Natural Person</MenuItem>
//                 <MenuItem value="Legal Person">
//                   Org/ Company/ Entity etc.
//                 </MenuItem>
//                 <MenuItem value="Natural Person Belonging to company">
//                   Natural Person Belonging to Company
//                 </MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           {type === "Natural Person" ||
//           type === "Natural Person Belonging to company" ? (
//             <>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   label="Date Of Birth"
//                   variant="outlined"
//                   type="date"
//                   fullWidth
//                   value={dob}
//                   autoComplete="off"
//                   onChange={(e) => setDOB(e.target.value)}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   label="Nationality"
//                   variant="outlined"
//                   fullWidth
//                   value={nationality}
//                   onChange={(e) => setNationality(e.target.value)}
//                   select
//                   autoComplete="off"
//                   defaultValue={""}
//                   placeholder="Country of Incorporation"
//                 >
//                   {countryMenuItems}
//                 </TextField>
//               </Grid>
//             </>
//           ) : type === "Legal Person" ? (
//             <>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   label="Date of Incorporation"
//                   variant="outlined"
//                   type="date"
//                   fullWidth
//                   autoComplete="off"
//                   value={incorporationDate}
//                   onChange={(e) => setIncorporationDate(e.target.value)}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={6} md={3}>
//                 <TextField
//                   label="Country of Incorporation"
//                   variant="outlined"
//                   fullWidth
//                   value={incorporationCountry}
//                   onChange={(e) => setIncorporationCountry(e.target.value)}
//                   autoComplete="off"
//                   select
//                   defaultValue={""}
//                   placeholder="Country of Incorporation"
//                 >
//                   {countryMenuItems}
//                 </TextField>
//               </Grid>
//             </>
//           ) : null}
//           <Grid item xs={4} md={3}>
//             <Button variant="contained" onClick={handleSearch}>
//               Search
//             </Button>
//           </Grid>

//           {/* {showStartKYC && (
//           <Grid item xs={6} md={6}>
//             <Box mt={1}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={handleStartKYC}
//               >
//                 Start Customer Identification and KYC Process
//               </Button>
//             </Box>
//           </Grid>
//         )} */}
//         </Grid>
//       </form>
//       {!isLoading && searched && searchResults?.records?.length == 0 && (
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           style={{ marginTop: 20 }}
//         >
//           <Alert
//             variant="outlined"
//             // action={
//             //   <Button
//             //     size="small"
//             //     variant="outlined"
//             //     color="success"
//             //     onClick={null}
//             //   >
//             //     Start Identification and KYC
//             //   </Button>
//             // }
//           >
//             <AlertTitle>System cleared!</AlertTitle>
//           </Alert>
//         </Grid>
//       )}
//       {!isLoading && searched && searchResults?.records?.length > 0 && (
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           style={{ marginTop: 20 }}
//         >
//           <Alert
//             severity="warning"
//             variant="outlined"
//             action={
//               <div>
//                 {" "}
//                 <Button
//                   size="small"
//                   variant="outlined"
//                   color="warning"
//                   onClick={handleStartKYC}
//                 >
//                   Proceed
//                 </Button>
//               </div>
//             }
//           >
//             <AlertTitle>Found possible match(es)</AlertTitle>{" "}
//             <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>
//               {" "}
//               {searchResults.meta.status}
//             </Typography>{" "}
//             <br />
//             Please narrow your search by including Nationality and Date of birth
//             etc.
//             <br /> Or, if you are sure it is not the same person listed below.
//             <br /> <br />
//             <Typography
//               sx={{ fontWeight: "italic", color: "#3f51b5", fontSize: "10px" }}
//             >
//               Note: For confirmed/partial name matches, Funds Freeze Report/
//               Partial Name Match Report is required to be submitted to concerned
//               authorities within specified time.
//             </Typography>
//           </Alert>
//         </Grid>
//       )}
//       {searchResults?.records.length > 0 && !isLoading && (
//         <TableContainer style={{ marginTop: 20 }}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 {/* <TableCell>ID</TableCell> */}
//                 <TableCell sx={{ fontWeight: "bold" }}>Full Name</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Date of Birth</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Nationality</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Listing</TableCell>
//                 {type === "Legal Person" && (
//                   <>
//                     <TableCell sx={{ fontWeight: "bold" }}>
//                       Date of Incorporation
//                     </TableCell>
//                     <TableCell sx={{ fontWeight: "bold" }}>
//                       Country of Incorporation
//                     </TableCell>
//                   </>
//                 )}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {searchResults?.records.map((result) => (
//                 <TableRow key={result?.id}>
//                   {/* <TableCell>{result?.id}</TableCell> */}
//                   <TableCell>{result?.caption}</TableCell>
//                   <TableCell>{result?.schema}</TableCell>
//                   <TableCell>
//                     {result?.properties.birthDate?.map((birthDate, i) => (
//                       <div key={i}>{birthDate}</div>
//                     ))}
//                   </TableCell>
//                   <TableCell>
//                     {result?.properties.nationality?.map((nationality, i) => (
//                       <div key={i}>
//                         {getCountryNameFromISO2(nationality.toUpperCase())}
//                       </div>
//                     ))}
//                   </TableCell>
//                   <TableCell>
//                     {result?.datasets?.map((dataset, i) => (
//                       <div key={i}>
//                         {values.find((v) => v.abbreviation === dataset)
//                           ?.fullForm === "EveryPolitician Open Data Project"
//                           ? "Politically Exposed Person"
//                           : values.find((v) => v.abbreviation === dataset)
//                               ?.fullForm || dataset}
//                       </div>
//                     ))}
//                   </TableCell>
//                   {type === "Legal Person" && (
//                     <>
//                       <TableCell>Date of Incorporation</TableCell>
//                       <TableCell>Country of Incorporation</TableCell>
//                     </>
//                   )}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       )}
//       {isLoading && (
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           style={{ marginTop: 20 }}
//         >
//           <CircularProgress color="secondary" />
//         </Grid>
//       )}{" "}
//       <Dialog
//         open={isClearedDialog}
//         onClose={() => {
//           setClearedDialog(false);
//         }}
//         fullWidth
//         maxWidth="md"
//         scroll={"paper"}
//       >
//         <DialogTitle>Not the same person</DialogTitle>
//         <DialogContent style={{ paddingTop: "10px" }}>
//           <TextField
//             label="Reason"
//             variant="outlined"
//             fullWidth
//             value={reason}
//             onChange={(e) => setReason(e.target.value)}
//             required
//             error={reasonError !== ""}
//           />
//           {reasonError && <FormHelperText error>{reasonError}</FormHelperText>}

//           <p>
//             Cleared by reason :{" "}
//             <Switch
//               onChange={() => setCleared(!isCleared)}
//               label="Clear"
//               color="secondary"
//             />
//           </p>
//           <p>
//             Funds Freeze report required? :{" "}
//             <Switch
//               onChange={() => SetffrRequired(!ffrRequired)}
//               label="Clear"
//               color="secondary"
//             />
//           </p>
//           <p>
//             Partial Name Match report required? :{" "}
//             <Switch
//               onChange={() => SetnmrRequired(!nmrRequired)}
//               label="Clear"
//               color="secondary"
//             />
//           </p>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               alert("Are you sure to close the dialogue without action?");
//             }}
//             color="secondary"
//             variant="contained"
//           >
//             Cancel
//           </Button>
//           <Button onClick={makePersonCLear} color="success" variant="contained">
//             Continue
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// function getCountryNameFromISO2(iso2) {
//   const countries = countryListAlpha2;

//   return countries[iso2] || iso2; // returns iso2 if the country name isn't found in the map
// }

// export default SearchPage;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   FormControl,
//   Grid,
//   FormHelperText,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
//   CircularProgress,
//   Alert,
//   AlertTitle,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Switch,
//   Dialog,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { APIURL } from "../../globals";
// import { useMakePersonClearMutation } from "state/api";
// import { countryNames } from "data/data";
// import { countryListAlpha2 } from "data/data";
// import { values } from "data/data";

// const countryMenuItems = countryNames.map((country) => (
//   <MenuItem value={`${country}`} key={country}>
//     {country}
//   </MenuItem>
// ));

// const SearchPage = (name) => {
//   const [fullName, setFullName] = useState("");
//   const [reason, setReason] = useState("");
//   const [type, setType] = useState("Natural Person");
//   const [dob, setDOB] = useState("");
//   const [nationality, setNationality] = useState("");
//   const [incorporationDate, setIncorporationDate] = useState("");
//   const [incorporationCountry, setIncorporationCountry] = useState("");
//   const [searchResults, setSearchResults] = useState({ records: [], meta: {} });
//   const [showStartKYC, setShowStartKYC] = useState(false);
//   const [searched, setSearched] = useState(false);
//   const [fullNameError, setFullNameError] = useState("");
//   const [reasonError, setReasonError] = useState("");
//   const [makePersonClear, { isClearLoading, isError }] =
//     useMakePersonClearMutation();
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();
//   const [isClearedDialog, setClearedDialog] = useState(false);
//   const [isCleared, setCleared] = useState(false);
//   const [ffrRequired, SetffrRequired] = useState(false);
//   const [nmrRequired, SetnmrRequired] = useState(false);

//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);

//   const handleSearch = async (event) => {
//     event.preventDefault();
//     if (fullName.trim() === "") {
//       setFullNameError("Full Name is required");
//       return;
//     }

//     setFullNameError("");
//     setIsLoading(true);

//     try {
//       const response = await fetch(
//         // "https://amldiligence.com/api/client/search",
//         APIURL + "/client/search",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           credentials: "include",
//           body: JSON.stringify({
//             fullName: fullName.trim(),
//             dob: dob || incorporationDate,
//             nationality: nationality || incorporationCountry,
//             typ: type,
//           }),
//         }
//       );

//       if (response.ok) {
//         const results = await response.json();
//         setSearchResults(results);
//         console.log(results);
//         console.log(JSON.stringify(results));
//         setShowStartKYC(true);
//         setSearched(true);
//       } else {
//         // Handle the error case
//         console.error("Failed to fetch search results");
//       }
//     } catch (error) {
//       // Handle the error case
//       console.error("Error occurred while fetching search results", error);
//     } finally {
//       setIsLoading(false); // Set loading state to false after fetching
//     }
//   };

//   const handleStartKYC = () => {
//     setClearedDialog(true);
//   };

//   const makePersonCLear = async () => {
//     if (isCleared) {
//       if (nmrRequired || ffrRequired) {
//         alert(
//           "Both PNMR Required and FFR Required must be false if person is cleared"
//         );
//         return;
//       }

//       if (reason.trim() === "") {
//         setReasonError("Reason is required if cleared");
//         return;
//       }
//     } else if (!isCleared && !nmrRequired && !ffrRequired) {
//       alert("Check the correct options below");
//       return;
//     } else if (nmrRequired && ffrRequired) {
//       alert("Only one of FFR and NMR can be selected");
//       return;
//     }

//     try {
//       await makePersonClear({
//         id: searchResults?.meta._id, // The ID value
//         cleared: isCleared, // Boolean indicating if cleared
//         notes: reason, // Reason string
//         ffr: ffrRequired, // Boolean for FFR required
//         nmr: nmrRequired,
//       }).unwind();
//       // Handle any post-clear success actions here
//     } catch (error) {
//       // Handle errors here
//       console.error("Error in making person clear:", error);
//     }
//     navigate(`/screeninglog`);
//   };

//   const fetchSuggestions = async (query) => {
//     if (!query) {
//       setSuggestions([]);
//       setShowSuggestions(false);
//       return;
//     }

//     try {
//       const response = await fetch(
//         `${APIURL}/client/suggestions?query=${query}`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.ok) {
//         const results = await response.json();
//         setSuggestions(results);
//         setShowSuggestions(true);
//       } else {
//         console.error("Failed to fetch suggestions");
//       }
//     } catch (error) {
//       console.error("Error occurred while fetching suggestions", error);
//     }
//   };

//   const handleFullNameChange = (e) => {
//     const value = e.target.value;
//     setFullName(value);
//     fetchSuggestions(value);
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setFullName(suggestion);
//     setSuggestions([]);
//     setShowSuggestions(false);
//   };

//   return (
//     <Box p={3}>
//       <Typography variant="h5" gutterBottom>
//         Screening Criteria
//       </Typography>
//       <form onSubmit={handleSearch} autoComplete="on">
//         <Grid container spacing={2} alignItems="center">
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Full Name"
//               variant="outlined"
//               fullWidth
//               value={fullName}
//               onChange={handleFullNameChange}
//               required
//               error={fullNameError !== ""}
//               autoComplete="name"
//               name="fullName"
//             />
//             {fullNameError && (
//               <FormHelperText error>{fullNameError}</FormHelperText>
//             )}
//             {showSuggestions && (
//               <Paper>
//                 {suggestions.map((suggestion, index) => (
//                   <MenuItem
//                     key={index}
//                     onClick={() => handleSuggestionClick(suggestion)}
//                   >
//                     {suggestion}
//                   </MenuItem>
//                 ))}
//               </Paper>
//             )}
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <FormControl variant="outlined" fullWidth>
//               <InputLabel>Type</InputLabel>
//               <Select
//                 value={type}
//                 onChange={(e) => setType(e.target.value)}
//                 label="Type"
//                 autoComplete="off"
//               >
//                 <MenuItem value="Natural Person">Natural Person</MenuItem>
//                 <MenuItem value="Legal Person">
//                   Org/ Company/ Entity etc.
//                 </MenuItem>
//                 <MenuItem value="Natural Person Belonging to company">
//                   Natural Person Belonging to Company
//                 </MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           {type === "Natural Person" ||
//           type === "Natural Person Belonging to company" ? (
//             <>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   label="Date Of Birth"
//                   variant="outlined"
//                   type="date"
//                   fullWidth
//                   value={dob}
//                   autoComplete="off"
//                   onChange={(e) => setDOB(e.target.value)}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   label="Nationality"
//                   variant="outlined"
//                   fullWidth
//                   value={nationality}
//                   onChange={(e) => setNationality(e.target.value)}
//                   select
//                   autoComplete="off"
//                   defaultValue={""}
//                   placeholder="Country of Incorporation"
//                 >
//                   {countryMenuItems}
//                 </TextField>
//               </Grid>
//             </>
//           ) : type === "Legal Person" ? (
//             <>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   label="Date of Incorporation"
//                   variant="outlined"
//                   type="date"
//                   fullWidth
//                   autoComplete="off"
//                   value={incorporationDate}
//                   onChange={(e) => setIncorporationDate(e.target.value)}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={6} md={3}>
//                 <TextField
//                   label="Country of Incorporation"
//                   variant="outlined"
//                   fullWidth
//                   value={incorporationCountry}
//                   onChange={(e) => setIncorporationCountry(e.target.value)}
//                   autoComplete="off"
//                   select
//                   defaultValue={""}
//                   placeholder="Country of Incorporation"
//                 >
//                   {countryMenuItems}
//                 </TextField>
//               </Grid>
//             </>
//           ) : null}
//           <Grid item xs={4} md={3}>
//             <Button variant="contained" onClick={handleSearch}>
//               Search
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//       {!isLoading && searched && searchResults?.records?.length == 0 && (
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           style={{ marginTop: 20 }}
//         >
//           <Alert variant="outlined">
//             <AlertTitle>System cleared!</AlertTitle>
//           </Alert>
//         </Grid>
//       )}
//       {!isLoading && searched && searchResults?.records?.length > 0 && (
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           style={{ marginTop: 20 }}
//         >
//           <Alert
//             severity="warning"
//             variant="outlined"
//             action={
//               <div>
//                 {" "}
//                 <Button
//                   size="small"
//                   variant="outlined"
//                   color="warning"
//                   onClick={handleStartKYC}
//                 >
//                   Proceed
//                 </Button>
//               </div>
//             }
//           >
//             <AlertTitle>Found possible match(es)</AlertTitle>{" "}
//             <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>
//               {" "}
//               {searchResults.meta.status}
//             </Typography>{" "}
//             <br />
//             Please narrow your search by including Nationality and Date of birth
//             etc.
//             <br /> Or, if you are sure it is not the same person listed below.
//             <br /> <br />
//             <Typography
//               sx={{ fontWeight: "italic", color: "#3f51b5", fontSize: "10px" }}
//             >
//               Note: For confirmed/partial name matches, Funds Freeze Report/
//               Partial Name Match Report is required to be submitted to concerned
//               authorities within specified time.
//             </Typography>
//           </Alert>
//         </Grid>
//       )}
//       {searchResults?.records.length > 0 && !isLoading && (
//         <TableContainer style={{ marginTop: 20 }}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell sx={{ fontWeight: "bold" }}>Full Name</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Date of Birth</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Nationality</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Listing</TableCell>
//                 {type === "Legal Person" && (
//                   <>
//                     <TableCell sx={{ fontWeight: "bold" }}>
//                       Date of Incorporation
//                     </TableCell>
//                     <TableCell sx={{ fontWeight: "bold" }}>
//                       Country of Incorporation
//                     </TableCell>
//                   </>
//                 )}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {searchResults?.records.map((result) => (
//                 <TableRow key={result?.id}>
//                   <TableCell>{result?.caption}</TableCell>
//                   <TableCell>{result?.schema}</TableCell>
//                   <TableCell>
//                     {result?.properties.birthDate?.map((birthDate, i) => (
//                       <div key={i}>{birthDate}</div>
//                     ))}
//                   </TableCell>
//                   <TableCell>
//                     {result?.properties.nationality?.map((nationality, i) => (
//                       <div key={i}>
//                         {getCountryNameFromISO2(nationality.toUpperCase())}
//                       </div>
//                     ))}
//                   </TableCell>
//                   <TableCell>
//                     {result?.datasets?.map((dataset, i) => (
//                       <div key={i}>
//                         {values.find((v) => v.abbreviation === dataset)
//                           ?.fullForm === "EveryPolitician Open Data Project"
//                           ? "Politically Exposed Person"
//                           : values.find((v) => v.abbreviation === dataset)
//                               ?.fullForm || dataset}
//                       </div>
//                     ))}
//                   </TableCell>
//                   {type === "Legal Person" && (
//                     <>
//                       <TableCell>Date of Incorporation</TableCell>
//                       <TableCell>Country of Incorporation</TableCell>
//                     </>
//                   )}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       )}
//       {isLoading && (
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           style={{ marginTop: 20 }}
//         >
//           <CircularProgress color="secondary" />
//         </Grid>
//       )}
//       <Dialog
//         open={isClearedDialog}
//         onClose={() => {
//           setClearedDialog(false);
//         }}
//         fullWidth
//         maxWidth="md"
//         scroll={"paper"}
//       >
//         <DialogTitle>Not the same person</DialogTitle>
//         <DialogContent style={{ paddingTop: "10px" }}>
//           <TextField
//             label="Reason"
//             variant="outlined"
//             fullWidth
//             value={reason}
//             onChange={(e) => setReason(e.target.value)}
//             required
//             error={reasonError !== ""}
//           />
//           {reasonError && <FormHelperText error>{reasonError}</FormHelperText>}

//           <p>
//             Cleared by reason :{" "}
//             <Switch
//               onChange={() => setCleared(!isCleared)}
//               label="Clear"
//               color="secondary"
//             />
//           </p>
//           <p>
//             Funds Freeze report required? :{" "}
//             <Switch
//               onChange={() => SetffrRequired(!ffrRequired)}
//               label="Clear"
//               color="secondary"
//             />
//           </p>
//           <p>
//             Partial Name Match report required? :{" "}
//             <Switch
//               onChange={() => SetnmrRequired(!nmrRequired)}
//               label="Clear"
//               color="secondary"
//             />
//           </p>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               alert("Are you sure to close the dialogue without action?");
//             }}
//             color="secondary"
//             variant="contained"
//           >
//             Cancel
//           </Button>
//           <Button onClick={makePersonCLear} color="success" variant="contained">
//             Continue
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// function getCountryNameFromISO2(iso2) {
//   const countries = countryListAlpha2;
//   return countries[iso2] || iso2; // returns iso2 if the country name isn't found in the map
// }

// export default SearchPage;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  AlertTitle,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../globals";
import { useMakePersonClearMutation } from "state/api";
import { countryNames } from "data/data";
import { countryListAlpha2 } from "data/data";
import { values } from "data/data";

const countryMenuItems = countryNames.map((country) => (
  <MenuItem value={`${country}`} key={country}>
    {country}
  </MenuItem>
));

const SearchPage = () => {
  const [fullName, setFullName] = useState("");
  const [reason, setReason] = useState("");
  const [type, setType] = useState("Natural Person");
  const [dob, setDOB] = useState("");
  const [nationality, setNationality] = useState("");
  const [incorporationDate, setIncorporationDate] = useState("");
  const [incorporationCountry, setIncorporationCountry] = useState("");
  const [searchResults, setSearchResults] = useState({ records: [], meta: {} });
  const [showStartKYC, setShowStartKYC] = useState(false);
  const [searched, setSearched] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [makePersonClear, { isClearLoading, isError }] =
    useMakePersonClearMutation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isClearedDialog, setClearedDialog] = useState(false);
  const [isCleared, setCleared] = useState(false);
  const [ffrRequired, SetffrRequired] = useState(false);
  const [nmrRequired, SetnmrRequired] = useState(false);

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const storedSuggestions =
      JSON.parse(localStorage.getItem("historicalSearches")) || [];
    setSuggestions(storedSuggestions);
  }, []);

  const handleSearch = async (event) => {
    event.preventDefault();
    if (fullName.trim() === "") {
      setFullNameError("Full Name is required");
      return;
    }

    setFullNameError("");
    setIsLoading(true);

    try {
      const response = await fetch(APIURL + "/client/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          fullName: fullName.trim(),
          dob: dob || incorporationDate,
          nationality: nationality || incorporationCountry,
          typ: type,
        }),
      });

      if (response.ok) {
        const results = await response.json();
        setSearchResults(results);
        console.log(results);
        console.log(JSON.stringify(results));
        setShowStartKYC(true);
        setSearched(true);
        // Save the search term to localStorage
        saveToLocalStorage(fullName.trim());
      } else {
        console.error("Failed to fetch search results");
      }
    } catch (error) {
      console.error("Error occurred while fetching search results", error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching
    }
  };

  const saveToLocalStorage = (searchTerm) => {
    let storedSuggestions =
      JSON.parse(localStorage.getItem("historicalSearches")) || [];
    if (!storedSuggestions.includes(searchTerm)) {
      storedSuggestions.push(searchTerm);
      localStorage.setItem(
        "historicalSearches",
        JSON.stringify(storedSuggestions)
      );
    }
  };

  const fetchSuggestions = (query) => {
    if (!query) {
      setShowSuggestions(false);
      return;
    }

    const storedSuggestions =
      JSON.parse(localStorage.getItem("historicalSearches")) || [];
    const filteredSuggestions = storedSuggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(query.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    setShowSuggestions(true);
  };

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    fetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setFullName(suggestion);
    setShowSuggestions(false);
  };

  const handleStartKYC = () => {
    setClearedDialog(true);
  };

  const makePersonCLear = async () => {
    if (isCleared) {
      if (nmrRequired || ffrRequired) {
        alert(
          "Both PNMR Required and FFR Required must be false if person is cleared"
        );
        return;
      }

      if (reason.trim() === "") {
        setReasonError("Reason is required if cleared");
        return;
      }
    } else if (!isCleared && !nmrRequired && !ffrRequired) {
      alert("Check the correct options below");
      return;
    } else if (nmrRequired && ffrRequired) {
      alert("Only one of FFR and NMR can be selected");
      return;
    }

    try {
      await makePersonClear({
        id: searchResults?.meta._id, // The ID value
        cleared: isCleared, // Boolean indicating if cleared
        notes: reason, // Reason string
        ffr: ffrRequired, // Boolean for FFR required
        nmr: nmrRequired,
      }).unwind();
      // Handle any post-clear success actions here
    } catch (error) {
      // Handle errors here
      console.error("Error in making person clear:", error);
    }
    navigate(`/screeninglog`);
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Screening Criteria
      </Typography>
      <form onSubmit={handleSearch} autoComplete="on">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              value={fullName}
              onChange={handleFullNameChange}
              required
              error={fullNameError !== ""}
              autoComplete="name"
              name="fullName"
            />
            {fullNameError && (
              <FormHelperText error>{fullNameError}</FormHelperText>
            )}
            {showSuggestions && (
              <Paper>
                {suggestions.map((suggestion, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </MenuItem>
                ))}
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                label="Type"
                autoComplete="off"
              >
                <MenuItem value="Natural Person">Natural Person</MenuItem>
                <MenuItem value="Legal Person">
                  Org/ Company/ Entity etc.
                </MenuItem>
                <MenuItem value="Natural Person Belonging to company">
                  Natural Person Belonging to Company
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {type === "Natural Person" ||
          type === "Natural Person Belonging to company" ? (
            <>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Date Of Birth"
                  variant="outlined"
                  type="date"
                  fullWidth
                  value={dob}
                  autoComplete="off"
                  onChange={(e) => setDOB(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Nationality"
                  variant="outlined"
                  fullWidth
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                  select
                  autoComplete="off"
                  defaultValue={""}
                  placeholder="Country of Incorporation"
                >
                  {countryMenuItems}
                </TextField>
              </Grid>
            </>
          ) : type === "Legal Person" ? (
            <>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Date of Incorporation"
                  variant="outlined"
                  type="date"
                  fullWidth
                  autoComplete="off"
                  value={incorporationDate}
                  onChange={(e) => setIncorporationDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  label="Country of Incorporation"
                  variant="outlined"
                  fullWidth
                  value={incorporationCountry}
                  onChange={(e) => setIncorporationCountry(e.target.value)}
                  autoComplete="off"
                  select
                  defaultValue={""}
                  placeholder="Country of Incorporation"
                >
                  {countryMenuItems}
                </TextField>
              </Grid>
            </>
          ) : null}
          <Grid item xs={4} md={3}>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
      {!isLoading && searched && searchResults?.records?.length == 0 && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Alert variant="outlined">
            <AlertTitle>System cleared!</AlertTitle>
          </Alert>
        </Grid>
      )}
      {!isLoading && searched && searchResults?.records?.length > 0 && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Alert
            severity="warning"
            variant="outlined"
            action={
              <div>
                {" "}
                <Button
                  size="small"
                  variant="outlined"
                  color="warning"
                  onClick={handleStartKYC}
                >
                  Proceed
                </Button>
              </div>
            }
          >
            <AlertTitle>Found possible match(es)</AlertTitle>{" "}
            <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>
              {" "}
              {searchResults.meta.status}
            </Typography>{" "}
            <br />
            Please narrow your search by including Nationality and Date of birth
            etc.
            <br /> Or, if you are sure it is not the same person listed below.
            <br /> <br />
            <Typography
              sx={{ fontWeight: "italic", color: "#3f51b5", fontSize: "10px" }}
            >
              Note: For confirmed/partial name matches, Funds Freeze Report/
              Partial Name Match Report is required to be submitted to concerned
              authorities within specified time.
            </Typography>
          </Alert>
        </Grid>
      )}
      {searchResults?.records.length > 0 && !isLoading && (
        <TableContainer style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Full Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Date of Birth</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Nationality</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Listing</TableCell>
                {type === "Legal Person" && (
                  <>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Date of Incorporation
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Country of Incorporation
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults?.records.map((result) => (
                <TableRow key={result?.id}>
                  <TableCell>{result?.caption}</TableCell>
                  <TableCell>{result?.schema}</TableCell>
                  <TableCell>
                    {result?.properties.birthDate?.map((birthDate, i) => (
                      <div key={i}>{birthDate}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {result?.properties.nationality?.map((nationality, i) => (
                      <div key={i}>
                        {getCountryNameFromISO2(nationality.toUpperCase())}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {result?.datasets?.map((dataset, i) => (
                      <div key={i}>
                        {values.find((v) => v.abbreviation === dataset)
                          ?.fullForm === "EveryPolitician Open Data Project"
                          ? "Politically Exposed Person"
                          : values.find((v) => v.abbreviation === dataset)
                              ?.fullForm || dataset}
                      </div>
                    ))}
                  </TableCell>
                  {type === "Legal Person" && (
                    <>
                      <TableCell>Date of Incorporation</TableCell>
                      <TableCell>Country of Incorporation</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isLoading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      <Dialog
        open={isClearedDialog}
        onClose={() => {
          setClearedDialog(false);
        }}
        fullWidth
        maxWidth="md"
        scroll={"paper"}
      >
        <DialogTitle>Not the same person</DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <TextField
            label="Reason"
            variant="outlined"
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
            error={reasonError !== ""}
          />
          {reasonError && <FormHelperText error>{reasonError}</FormHelperText>}

          <p>
            Cleared by reason :{" "}
            <Switch
              onChange={() => setCleared(!isCleared)}
              label="Clear"
              color="secondary"
            />
          </p>
          <p>
            Funds Freeze report required? :{" "}
            <Switch
              onChange={() => SetffrRequired(!ffrRequired)}
              label="Clear"
              color="secondary"
            />
          </p>
          <p>
            Partial Name Match report required? :{" "}
            <Switch
              onChange={() => SetnmrRequired(!nmrRequired)}
              label="Clear"
              color="secondary"
            />
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              alert("Are you sure to close the dialogue without action?");
            }}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={makePersonCLear} color="success" variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function getCountryNameFromISO2(iso2) {
  const countries = countryListAlpha2;
  return countries[iso2] || iso2; // returns iso2 if the country name isn't found in the map
}

export default SearchPage;
