import { React, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  useTheme,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormHelperText,
  TextField,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import Header from "components/Header";
import { MaterialReactTable } from "material-react-table";
import { useGetLogsQuery } from "state/api";
import { useMakePersonClearMutation } from "state/api";
import moment from "moment";
import ReactToPrint from "react-to-print";
import PrintTable from "components/searchReport";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../globals";

const data = [];
const udata = [];

const formatDateToMinute = (dateStr) => {
  return moment(dateStr).format("YYYY-MM-DD HH:mm");
};

// const udata = data.filter(
//   (data, index, self) =>
//     index === self.findIndex((u) => u.searchDate === data.searchDate)
// );

const ScreenLog = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const { data, isLoading } = useGetLogsQuery();
  const user = JSON.parse(localStorage.getItem("DashBoardUser"));
  console.log(data);
  const componentRefn = useRef();
  const handlePrint = () => {
    return Promise.resolve();
  };

  // console.log(udata);

  const columns =
    //column definitions...
    useMemo(
      () => [
        {
          accessorFn: (originalRow) => {
            const dateString = originalRow.searchDate;

            return moment(originalRow.searchDate).format("DD-MM-YY HH:mm A");
          },
          header: "Search Date",
          type: "date",
          muiTableBodyCellEditTextFieldProps: {
            type: "date",
          },
          muiTableHeadCellFilterTextFieldProps: {
            type: "datetime",
          },
          sortingFn: "datetime",
          filterFn: "lessThanOrEqualTo",

          //dateSetting: { locale: "en-CA" },
        },
        {
          accessorKey: "name", //simple recommended way to define a column
          header: "Name",
        },
        {
          accessorKey: "criteria.doB", //simple recommended way to define a column
          header: "DoB",
        },
        {
          accessorKey: "criteria.nationality", //simple recommended way to define a column
          header: "Nationality/ Country of Incorporation",
        },
        {
          accessorKey: "status", //simple recommended way to define a column
          header: "Status",
        },
        {
          // accessorKey: "dataSet",
          accessorFn: (originalRow) => {
            var val = originalRow.ffr || originalRow.nmr;
            return val ? "Required" : "NA";
          },
          header: "FFR/PNMR",
        },
        {
          accessorKey: "pep",
          // accessorFn: (originalRow) => {
          //   var val = originalRow.nmr;
          //   return val ? "Required" : "NA";
          // },
          accessorFn: (originalRow) => {
            const checkStrings = [
              "wd_peps",
              "everypolitician",
              "us_cia_world_leaders",
            ];

            try {
              // Ensure resultset exists and is an array
              if (
                originalRow.resultset &&
                Array.isArray(originalRow.resultset)
              ) {
                // Iterate over each object in resultset
                for (let result of originalRow.resultset) {
                  // Ensure datasets exist and is an array
                  if (result.datasets && Array.isArray(result.datasets)) {
                    // Check if any specified strings are present in datasets
                    for (let dataset of result.datasets) {
                      if (
                        checkStrings.includes(dataset) ||
                        dataset.properties.topics.includes("role.pep")
                      ) {
                        return "Potential PEP";
                      }
                    }
                  }
                }
              }
            } catch (error) {
              console.error(
                "An error occurred while processing the data:",
                error
              );
            }

            return "No";
          },
          header: "PEP",
        },
        {
          // accessorKey: "dataSet",
          accessorFn: (originalRow) => {
            var reason = originalRow.criteria.typ;
            return reason;
          },
          header: "Type",
        },
      ],
      []
    );
  console.log("data on screeninglog", data);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Screening Log" subtitle="" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <MaterialReactTable
          data={data || []}
          enableColumnDragging={false}
          enableColumnOrdering
          enableGlobalFilter={true}
          enableColumnFilterModes
          enableColumnActions={false}
          enableRowActions
          key={"key"}
          columns={columns.map((c) => ({ ...c, size: 10, minSize: 10 }))}
          renderRowActions={({ row }) => {
            return <Wrapper row={row.original} user={user}></Wrapper>;
          }}
        />
      </Box>
    </Box>
  );
};

export default ScreenLog;

const Wrapper = ({ row, user }) => {
  const componentRef = useRef();
  const [open, setOpen] = useState(false); // State to manage dialog open/close

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Box sx={{}}>
        <ReactToPrint
          trigger={() => (
            <IconButton
              color="success"
              variant="contained"
              style={{ margin: "0 auto" }}
            >
              <PrintIcon />
            </IconButton>
          )}
          content={() => componentRef.current}
        />
        <div style={{ display: "none" }}>
          <PrintTable ref={componentRef} data={row} user={user} pep={row.pep} />
        </div>
        {row.status != "System cleared" && (
          <IconButton
            style={{ color: "blue" }}
            title="Edit"
            onClick={() => {
              handleOpen();
            }}
          >
            {" "}
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Dlg open={open} handleClose={handleClose} data={row} />
    </div>
  );
};

const Dlg = ({ open, handleClose, data }) => {
  console.log("data is :", data, "id is :", data._id);
  const [makePersonClear, { isLoading, isError }] =
    useMakePersonClearMutation();
  const [isCleared, SetCleared] = useState(data.status == "Cleared" ?? false);
  const [ffrRequired, SetffrRequired] = useState(data.ffr ?? false);
  const [id, SetId] = useState(data._id);
  const [nmrRequired, SetnmrRequired] = useState(data.nmr ?? false);

  const [reason, setReason] = useState(data.notes ?? "");
  const [reasonError, setReasonError] = useState("");
  const searchResults = {};

  useEffect(() => {
    SetCleared(data.status == "Cleared (reason)" || false);
    setReason(data.notes ?? "");
    SetffrRequired(data.ffr || false);
    SetnmrRequired(data.nmr || false);
    SetId(data._id);
  }, [data]);

  const handleClearedChange = () => {
    SetCleared(!isCleared);
    if (!isCleared) {
      SetffrRequired(false);
      SetnmrRequired(false);
    } else {
      setReason(""); // Clear reason when switch is turned off
    }
  };

  const handleFfrRequiredChange = () => {
    SetffrRequired(!ffrRequired);
    if (!ffrRequired) {
      SetCleared(false);
      SetnmrRequired(false);
    } else {
      setReason(""); // Clear reason when switch is turned off
    }
  };

  const handleNmrRequiredChange = () => {
    SetnmrRequired(!nmrRequired);
    if (!nmrRequired) {
      SetCleared(false);
      SetffrRequired(false);
    } else {
      setReason(""); // Clear reason when switch is turned off
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
      scroll={"paper"}
    >
      <DialogTitle>Not the same person</DialogTitle>
      <DialogContent style={{ paddingTop: "10px" }}>
        <TextField
          label="Reason"
          variant="outlined"
          fullWidth
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          required
          error={reasonError !== ""}
          inputProps={{
            maxLength: 150,
          }}
        />
        {reasonError && <FormHelperText error>{reasonError}</FormHelperText>}

        <p>
          Cleared by reason :{" "}
          <Switch
            onChange={handleClearedChange}
            checked={isCleared}
            label="Clear"
            color="secondary"
          />
        </p>
        <p>
          Funds Freeze report required? :{" "}
          <Switch
            onChange={handleFfrRequiredChange}
            checked={ffrRequired}
            label="Clear"
            color="secondary"
          />
        </p>
        <p>
          Partial Name Match report required? :{" "}
          <Switch
            onChange={handleNmrRequiredChange}
            checked={nmrRequired}
            label="Clear"
            color="secondary"
          />
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="secondary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (isCleared) {
              if (nmrRequired || ffrRequired) {
                alert(
                  "Both PNMR Required and FFR Required must be false if person is cleared"
                );
                return;
              }

              if (reason?.trim() === "") {
                setReasonError("Reason is required if cleared");
                return;
              }
            } else if (!isCleared && !nmrRequired && !ffrRequired) {
              alert("Check the correct options below");
              return;
            } else if (nmrRequired && ffrRequired) {
              alert("Only one of FFR and NMR can be selected");
              return;
            }
            console.log(
              id, // The ID value
              isCleared, // Boolean indicating if cleared
              reason, // Reason string
              ffrRequired, // Boolean for FFR required
              nmrRequired
            );

            try {
              await makePersonClear({
                id, // The ID value
                cleared: isCleared, // Boolean indicating if cleared
                notes: reason, // Reason string
                ffr: ffrRequired, // Boolean for FFR required
                nmr: nmrRequired,
              });
              handleClose();
              if (!isLoading) {
              }
              // Handle any post-clear success actions here
            } catch (error) {
              // Handle errors here
              console.error("Error in making person clear:", error);
            }
          }}
          color="success"
          variant="contained"
        >
          Continue
        </Button>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || isError}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogActions>
    </Dialog>
  );
};

// const makePersonCLear = async ({
//   setReasonError,
//   isCleared,
//   nmrRequired,
//   ffrRequired,
//   APIURL,
//   searchResults,
//   reason,
// }) => {
//   // if (isCleared) {
//   //   if (nmrRequired || ffrRequired) {
//   //     alert(
//   //       "Both PNMR Required and FFR Required must be false if person is cleared"
//   //     );
//   //     return;
//   //   }

//   //   if (reason.trim() === "") {
//   //     setReasonError("Reason is required if cleared");
//   //     return;
//   //   }
//   // } else if (isCleared && nmrRequired && ffrRequired) {
//   //   alert("Check the correct options below");
//   //   return;
//   // } else if (nmrRequired && ffrRequired) {
//   //   alert("Only one of FFR and NMR can be selected");
//   //   return;

//   // }

//   // Check if any switch is on and reason is empty
//   if ((isCleared || nmrRequired || ffrRequired) && reason.trim() === "") {
//     setReasonError("Reason is required when any switch is on");
//     return;
//   }

//   try {
//     const response = await fetch(
//       // "https://amldiligence.com/api/client/clear",
//       APIURL + "/client/clear",

//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         credentials: "include",
//         body: JSON.stringify({
//           id: searchResults?.meta._id,
//           cleared: isCleared,
//           notes: reason,
//           ffr: ffrRequired,
//           nmr: nmrRequired,
//         }),
//       }
//     );

//     if (response.ok) {
//       console.log("Person cleared successfully");
//     } else {
//       console.error("Failed to clear person");
//     }
//   } catch (error) {
//     console.error("Error occurred while clearing person", error);
//   }
// };
