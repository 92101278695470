import Header from "./Header";
import Slogan from "./Slogan";
import Hero from "./Hero";
import SolutionsSection from "./SolutionsSection";
import AdvantagesSection from "./AdvantagesSection";
import Process from "./Process";
import IndustriesSection from "./industries";
import Footer from "./Footer";

export default function Landing() {
  return (
    <div className="App">
      <div>
        <Header />
        <Hero />
        <Slogan />
        <SolutionsSection />
        <AdvantagesSection />
        <Process />
        <IndustriesSection />
        <Footer />
      </div>
    </div>
  );
}
