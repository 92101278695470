import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  AlertTitle,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  Dialog,
} from "@mui/material";

// import { useSearchIndividualsQuery } from "state/api";
import { APIURL } from "../globals";
import { countryNames } from "data/data";
import { countryListAlpha2 } from "data/data";
import { values } from "data/data";

const countryMenuItems = countryNames.map((country) => (
  <MenuItem value={`${country}`} key={country}>
    {country}
  </MenuItem>
));

const SearchSimple = ({ name, setShowSearch, type, setStatus, id }) => {
  const [fullName, setFullName] = useState(name);
  const [reason, setReason] = useState("");

  // const [type, setType] = useState("Natural Person");
  const [dob, setDOB] = useState("");
  const [nationality, setNationality] = useState("");
  const [incorporationDate, setIncorporationDate] = useState("");
  const [incorporationCountry, setIncorporationCountry] = useState("");
  const [searchResults, setSearchResults] = useState({ records: [], meta: {} });
  const [showStartKYC, setShowStartKYC] = useState(false);
  const [searched, setSearched] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [reasonError, setReasonError] = useState("");

  // const search = useSearchIndividualsQuery();
  const [isLoading, setIsLoading] = useState(false);

  const [isClearedDialog, setClearedDialog] = useState(false);
  const [isCleared, setCleared] = useState(false);
  const [ffrRequired, SetffrRequired] = useState(false);
  const [nmrRequired, SetnmrRequired] = useState(false);
  useEffect(() => {
    // Example condition: Only search if the name prop is non-empty.
    // Replace this with your desired condition.
    if (name && !isLoading) {
      handleSearch();
    }
    console.log(name, type, id);
    console.log(`Child Component ${id} Mounted/Updated`);

    return () => {
      console.log(`Child Component ${id} Unmounted`);
    };
  }, [name, type, id, searched]);
  //   useEffect(() => {
  //     // Example condition: Only search if the name prop is non-empty.
  //     // Replace this with your desired condition.
  //     if (name && !isLoading) {
  //       handleSearch();
  //     }

  //     console.log(`Child Component ${id} Mounted/Updated`);

  //     return () => {
  //       console.log(`Child Component ${id} Unmounted`);
  //     };
  //   }, [id]); // Ensure this runs only once on mount

  //   useEffect(() => {
  //     handleSearch();
  //   }, []);

  const handleSearch = async (event) => {
    if (isLoading) {
      // Check if a request is already in progress
      console.log("A request is already in progress");
      return;
    }
    // event.preventDefault();
    if (fullName.trim() === "") {
      setFullNameError("Full Name is required");
      return;
    }

    setFullNameError("");
    setIsLoading(true);

    try {
      console.log("searching: " + fullName);
      const response = await fetch(
        // "https://amldiligence.com/api/client/search",
        APIURL + "/client/search",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            fullName: fullName.trim(),
            dob: dob || incorporationDate,
            nationality: nationality || incorporationCountry,
            typ: type,
          }),
        }
      );

      if (response.ok) {
        const results = await response.json();
        setSearchResults(results);
        console.log(results);
        console.log(JSON.stringify(results));
        setShowStartKYC(true);
        setSearched(true);
        setStatus(results.meta.status);

        if (results.meta.status === "System cleared") {
          setShowSearch(false);
          setIsLoading(false);
        }
      } else {
        // Handle the error case
        console.error("Failed to fetch search results");
      }
    } catch (error) {
      // Handle the error case
      console.error("Error occurred while fetching search results", error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching
    }
  };

  const handleStartKYC = () => {
    setClearedDialog(true);
  };

  const makePersonCLear = async () => {
    if (isCleared) {
      setStatus("Cleared by notes");
      if (nmrRequired || ffrRequired) {
        alert(
          "Both PNMR Required and FFR Required must be false if person is cleared"
        );
        return;
      }

      if (reason.trim() === "") {
        setReasonError("Reason is required if cleared");
        return;
      }
    } else if (!isCleared && !nmrRequired && !ffrRequired) {
      alert("Check the correct options below");
      return;
    } else if (nmrRequired && ffrRequired) {
      alert("Only one of FFR and NMR can be selected");
      return;
    }

    try {
      const response = await fetch(
        // "https://amldiligence.com/api/client/clear"
        APIURL + "/client/clear",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id: searchResults?.meta._id,
            cleared: isCleared,
            notes: reason,
            ffr: ffrRequired,
            nmr: nmrRequired,
          }),
        }
      );

      if (response.ok) {
        console.log("Person cleared successfully");
        setClearedDialog(false);
        setShowSearch(false);
      } else {
        console.error("Failed to clear person");
      }
    } catch (error) {
      console.error("Error occurred while clearing person", error);
    }
  };

  return (
    <Box p={3}>
      {!isLoading && searched && searchResults?.records?.length == 0 && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Alert variant="outlined">
            <AlertTitle>System cleared!</AlertTitle>
          </Alert>
        </Grid>
      )}
      {!isLoading && searched && searchResults?.records?.length > 0 && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Alert
            severity="warning"
            variant="outlined"
            action={
              <div>
                {" "}
                <Button
                  size="small"
                  variant="outlined"
                  color="warning"
                  onClick={handleStartKYC}
                >
                  Proceed
                </Button>
              </div>
            }
          >
            <AlertTitle>Found possible match(es)</AlertTitle>{" "}
            <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>
              {" "}
              {searchResults.meta.status}
            </Typography>{" "}
            <br />
            Please narrow your search by including Nationality and Date of birth
            etc.
            <br /> Or, if you are sure it is not the same person listed below.
            <br /> <br />
            <Typography
              sx={{ fontWeight: "italic", color: "#3f51b5", fontSize: "10px" }}
            >
              Note: For confirmed/partial name matches, Funds Freeze Report/
              Partial Name Match Report is required to be submitted to concerned
              authorities within specified time.
            </Typography>
          </Alert>
        </Grid>
      )}
      {searchResults?.records.length > 0 && !isLoading && (
        <TableContainer style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell sx={{ fontWeight: "bold" }}>Full Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Date of Birth</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Nationality</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Listing</TableCell>
                {type === "Legal Person" && (
                  <>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Date of Incorporation
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Country of Incorporation
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults?.records.map((result) => (
                <TableRow key={result?.id}>
                  {/* <TableCell>{result?.id}</TableCell> */}
                  <TableCell>{result?.caption}</TableCell>
                  <TableCell>{result?.schema}</TableCell>
                  <TableCell>
                    {result?.properties.birthDate?.map((birthDate, i) => (
                      <div key={i}>{birthDate}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {result?.properties.nationality?.map((nationality, i) => (
                      <div key={i}>
                        {getCountryNameFromISO2(nationality.toUpperCase())}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {result?.datasets?.map((dataset, i) => (
                      <div key={i}>
                        {values.find((v) => v.abbreviation === dataset)
                          ?.fullForm === "EveryPolitician Open Data Project"
                          ? "Politically Exposed Person"
                          : values.find((v) => v.abbreviation === dataset)
                              ?.fullForm || dataset}
                      </div>
                    ))}
                  </TableCell>
                  {type === "Legal Person" && (
                    <>
                      <TableCell>Date of Incorporation</TableCell>
                      <TableCell>Country of Incorporation</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isLoading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          {" "}
          <Typography>Screening</Typography>
          <CircularProgress color="secondary" />
        </Grid>
      )}{" "}
      <Dialog
        open={isClearedDialog}
        onClose={() => {
          //   setClearedDialog(false);
        }}
        fullWidth
        maxWidth="md"
        scroll={"paper"}
      >
        <DialogTitle>Not the same person</DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <TextField
            label="Reason"
            variant="outlined"
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
            error={reasonError !== ""}
          />
          {reasonError && <FormHelperText error>{reasonError}</FormHelperText>}

          <p>
            Cleared by reason :{" "}
            <Switch
              onChange={() => setCleared(!isCleared)}
              label="Clear"
              color="secondary"
            />
          </p>
          <p>
            Funds Freeze report required? :{" "}
            <Switch
              onChange={() => SetffrRequired(!ffrRequired)}
              label="Clear"
              color="secondary"
            />
          </p>
          <p>
            Partial Name Match report required? :{" "}
            <Switch
              onChange={() => SetnmrRequired(!nmrRequired)}
              label="Clear"
              color="secondary"
            />
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              alert("Are you sure to close the dialogue without action?");
            }}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={makePersonCLear} color="success" variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function getCountryNameFromISO2(iso2) {
  const countries = countryListAlpha2;

  return countries[iso2] || iso2; // returns iso2 if the country name isn't found in the map
}

// export default SearchSimple;

// Wrap the component with React.memo
const MemoizedSearchSimple = React.memo(SearchSimple);

// Export the memoized version
export default MemoizedSearchSimple;
