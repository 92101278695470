import { React, useMemo, useState } from "react";
import { Box, Button, MenuItem, useTheme, Typography } from "@mui/material";
import {
  useGetExpiredIndividualsQuery,
  useDeleteIndividualsMutation,
} from "state/api";
import { MaterialReactTable } from "material-react-table";
import Header from "components/Header";
import EditCustomer from "components/editCustomer";

import moment from "moment";

const ExpiredCustoomer = () => {
  const { data, isLoading } = useGetExpiredIndividualsQuery();
  const [deleteIndividual] = useDeleteIndividualsMutation();
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const columns =
    //column definitions...
    useMemo(
      () => [
        {
          accessorKey: "fullLegalName", //simple recommended way to define a column
          header: "Full Name",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "idDoc.type", //simple recommended way to define a column
          header: "DocType",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "idDoc.number", //simple recommended way to define a column
          header: "Doc Number",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "idDoc.issueCountry", //simple recommended way to define a column
          header: "Issue Country",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorFn: (originalRow) => {
            const dateString = originalRow.idDoc.issueDate;
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            function parseDateTime(s) {
              var b = s.split(/\D/);
              return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
            }

            return moment(dateString).utc().format("YYYY-MM-DD");
          },
          header: "Issue Date",
          type: "date",
          muiTableBodyCellEditTextFieldProps: {
            type: "date",
          },
          muiTableHeadCellFilterTextFieldProps: {
            type: "date",
          },
          sortingFn: "datetime",
          dateSetting: { locale: "en-CA" },
        },
        {
          accessorFn: (originalRow) => {
            const dateString = originalRow.idDoc.expiryDate;
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            function parseDateTime(s) {
              var b = s.split(/\D/);
              return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
            }

            return moment(dateString).utc().format("YYYY-MM-DD");
          },
          // accessorKey: `idDoc.issueDate`, //simple recommended way to define a column
          header: "Expiry Date",
          type: "date",
          muiTableBodyCellEditTextFieldProps: {
            type: "date",
            // variant: 'outlined',
          },
          muiTableHeadCellFilterTextFieldProps: {
            type: "date",
          },
          sortingFn: "datetime",
          dateSetting: { locale: "en-gb" },
        },
        {
          accessorFn: (originalRow) => {
            const dateString = originalRow.doB;
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            function parseDateTime(s) {
              var b = s.split(/\D/);
              return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
            }

            return moment(dateString).utc().format("YYYY-MM-DD");
          },
          header: "Date of Birth",
          type: "date",
          muiTableBodyCellEditTextFieldProps: {
            type: "date",
          },
          muiTableHeadCellFilterTextFieldProps: {
            type: "date",
          },
          sortingFn: "datetime",
          dateSetting: { locale: "en-CA" },
        },
        {
          accessorKey: "nationality", //simple recommended way to define a column
          header: "Nationality",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "residentialStatus", //simple recommended way to define a column
          header: "Residential Status",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "contactInfo.type", //simple recommended way to define a column
          header: "Contact Tyoe",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "contactInfo.country", //simple recommended way to define a column
          header: "Contact Country",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "contactInfo.number", //simple recommended way to define a column
          header: "Number",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "pepStatus", //simple recommended way to define a column
          header: "PEP Status",
          Cell: ({ cell }) =>
            cell.getValue() === true ? (
              <Typography>Found</Typography>
            ) : (
              <Typography>Not Found</Typography>
            ),
          enableEditing: false,

          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "tfsScreening", //simple recommended way to define a column
          header: "TF Screening",
          enableEditing: false,

          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
      ],
      []
    );
  console.log(data);
  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Expired CUSTOMERS"
        subtitle="List of Customers with ID Doc expired"
      />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {" "}
        <MaterialReactTable
          //columns={columns}
          data={data || []}
          enableColumnDragging={false}
          enableColumnOrdering
          enableGlobalFilter={true}
          enableRowActions
          layoutMode="grid"
          enableColumnActions={false}
          columns={columns.map((c) => ({ ...c, size: 110, minSize: 10 }))}
          state={{
            isLoading: isLoading,
          }}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key={row.id + 1}
              onClick={() => {
                handleOpenDialog();
                console.log(row.original);
                // closeMenu();
              }}
            >
              Edit
            </MenuItem>,
            <EditCustomer
              key={row.original._id}
              open={dialogOpen}
              onClose={handleCloseDialog}
              data={row.original}
            />,

            <MenuItem
              key={row.id + 2}
              onClick={() => {
                deleteIndividual(row.original._id);
                closeMenu();
              }}
            >
              Delete
            </MenuItem>,
          ]}
          muiTablePaperProps={{
            sx: {
              maxWidth: "82vw",
              m: "auto",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ExpiredCustoomer;
