import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
  Radio,
  FormControl,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  InputAdornment,
  FormLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import { useLocation } from "react-router-dom";
import PrintTable from "components/printTable";

const ViewCQ = ({ open, onClose, id, data }) => {
  console.log(data?.complianceQuestionaire);
  var responses = { ...data?.complianceQuestionaire };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onclose}
        // Prevents dialog from closing when clicking outside
        fullWidth
        maxWidth="lg"
        scroll={"paper"}
      >
        <DialogTitle>
          {" "}
          <Typography
            variant="h4"
            paddingBottom={"12px"}
            color={"#ffc76b"}
            fontWeight={"bold"}
          >
            Compliance Questionaire
          </Typography>
        </DialogTitle>
        <DialogContent>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              <tr>
                <td colSpan={2} height={50}>
                  <b>Compliance Checks</b>
                </td>
              </tr>
              <tr>
                <td>Do you keep customer records?</td>
                <td>{responses["records"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have external independent financial auditors?</td>
                <td>{responses["auditors"] || "No response"}</td>
              </tr>
              <tr>
                <td>What is the name of the auditor?</td>
                <td>{responses["auditorName"] || "No response"}</td>
              </tr>
              <tr>
                <td>Mention the source of operating funds. (Own Capital ?)</td>
                <td>
                  {" "}
                  {responses.operatingFunds?.ownCapital === true
                    ? "true"
                    : "false"}
                  {responses["{operatingFunds.ownCapital}"] || "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you have operating funds from a bank loan?</td>
                <td>{responses.operatingFunds?.bankLoan || "No response"} </td>
              </tr>
              <tr>
                <td>Do you have operating funds from a government entity?</td>
                <td>
                  {responses.operatingFunds?.governmentEntity || "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you have operating funds from a third-party loan?</td>
                <td>
                  {responses.operatingFunds?.thirdPartyLoan || "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you have operating funds from other sources?</td>
                <td>{responses.operatingFunds?.others || "No response"}</td>
              </tr>

              <tr>
                <td colSpan={2} height={50}>
                  <b>Responsible Precious Metal Supply Chain Policy</b>
                </td>
              </tr>
              <tr>
                <td>
                  Did your institution establish a responsible supply chain of
                  gold from conflict-affected and high-risk areas policy?
                </td>
                <td>{responses["supplyChainPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the supply chain policy?</td>
                <td>{responses["supplyChainPolicyCopy"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does your company comply or plan to comply with the OECD Due
                  Diligence Guidance for Responsible Supply Chains of Minerals
                  from Conflict-Affected and High-Risk Areas?
                </td>
                <td>{responses["oecdCompliance"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a human rights policy?</td>
                <td>{responses["humanRightsPolicy"] || "No response"}</td>
              </tr>

              <tr>
                <td>Do you have an anti-bribery policy?</td>
                <td>{responses["antiBriberyPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the anti-bribery policy?</td>
                <td>{responses["antiBriberyPolicyCopy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a whistle blower policy?</td>
                <td>{responses["whistleBlowerPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the whistle blower policy?</td>
                <td>{responses["whistleBlowerPolicyCopy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a data protection policy?</td>
                <td>{responses["dataProtectionPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the data protection policy?</td>
                <td>
                  {responses["dataProtectionPolicyCopy"] || "No response"}
                </td>
              </tr>
              <tr>
                <td colSpan={2} height={50}>
                  <b>AML CFT Policy and Procedures</b>
                </td>
              </tr>
              <tr>
                <td>
                  Does Your instutution screen customers and transactions
                  aginsta list of persons, entities, or countries issued by
                  government /international bodies?
                </td>
                <td>{responses["screeningList"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Did your establishment sign up to the goAML system of the FIU?
                </td>
                <td>{responses["goAML"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Has your establishment created and documented red flags to
                  provide reasonable ground reporting of any suspicious
                  transaction?
                </td>
                <td>{responses["redFlags"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does the company perform risb-based assessment to understand
                  the normal and expected transactions of its clients?
                </td>
                <td>{responses["transactionAssessment"] || "No response"}</td>
              </tr>
              <tr>
                <td>Does the establishment keep record of its transactions?</td>
                <td>{responses["keepRecords"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does your establishment conduct regular AML/CFT training for
                  staff?
                </td>
                <td>{responses["staffTraining"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does the company have a maximum amount allowed for cash
                  payments as per internal policy and regulatory frameworks?
                </td>
                <td>{responses["maxCashPayment"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  What is the maximum cash amount allowed for transactions?
                </td>
                <td>{responses["maxCashAmount"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Has ypur organization implemented processes for the
                  identification and verification of your customers and
                  benificial owners?
                </td>
                <td>{responses["customerIdentification"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does the Company have a risk-based assessment of its clients
                  (e.g., low, medium, or high risks)?
                </td>
                <td>{responses["riskBasedAssessment"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does the Company conduct enhanced due diligence for high-risk
                  clients?
                </td>
                <td>{responses["enhancedDueDiligence"] || "No response"}</td>
              </tr>

              <tr>
                <td>
                  Does your establishment have checks in place to identify if
                  its customers/clients and their beneficial owners are
                  'Politically Exposed Persons' (PEPs)??
                </td>
                <td>{responses["dueDiligencePEPs"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does your organization have policies to conduct enhanced due
                  diligence which includes obtaining senior management approval
                  for establishing a business relationship or one-off
                  transaction with PEPs, their family, and close associates?
                </td>
                <td>{responses["dueDiligencePEPs"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does your institution collect information relating to
                  customer’s and beneficial owner(s)’ source of wealth and
                  source of funds?
                </td>
                <td>{responses["sourceOfWealth"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Has your organization appointed a designated compliance
                  officer with sufficient experience/expertise?
                </td>
                <td>{responses["complianceOfficer"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  Does your institution have a monitoring program for the
                  identification and reporting of suspicious or unusual
                  activity/transaction?
                </td>
                <td>{responses["monitoringProgram"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  How often does your establishment conduct AML/CFT training for
                  staff?
                </td>
                <td>{responses["trainingFrequency"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does your organization conduct internal audits to assess
                  AML/CFT measures?
                </td>
                <td>{responses["internalAudits"] || "No response"}</td>
              </tr>
              <tr>
                <td> Does your company have a Data Protection Policy?</td>
                <td>{responses["dataProtectionPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  Does your company have a whistleblower policy in place?
                </td>
                <td>{responses["whistleblowerPolicy"] || "No response"}</td>
              </tr>

              <tr>
                <td colSpan={2} height={50}>
                  <b>Supply chain due-diligence</b>
                </td>
              </tr>

              <tr>
                <td>What is the corporate percentage?</td>
                <td>{responses["corporatePercentage"] || "No response"}</td>
              </tr>
              <tr>
                <td>What is the individual percentage?</td>
                <td>{responses["individualPercentage"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you provide an invoice?</td>
                <td>
                  {responses["invoice"] === true
                    ? "Yes"
                    : "NO" || "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you provide a customs document?</td>
                <td>
                  {responses["customsDocument"] === true
                    ? "Yes"
                    : "No" || "No response"}{" "}
                </td>
              </tr>
              <tr>
                <td>Do you provide a certificate of origin?</td>
                <td>
                  {responses["CertificateofOrigin"] === true
                    ? "Yes"
                    : "No" || "No response"}{" "}
                </td>
              </tr>
              <tr>
                <td>Do you provide an assay report?</td>
                <td>
                  {responses["AssayReport"] === true
                    ? "Yes"
                    : "No" || "No response"}
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  Do you monitor your suppliers for inconsistent transactions? ?
                </td>
                <td>{responses["monitorSuppliers"] || "No response"}</td>
              </tr>
              <tr>
                <td>How do you assess the supply chain?</td>
                <td>{responses["assessSupplyChain"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Did your institution establish a responsible supply chain of
                  gold from conflict-affected and high-risk areas policy?
                </td>
                <td>{responses["supplyChainPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the supply chain policy?</td>
                <td>{responses["supplyChainPolicyCopy"] || "No response"}</td>
              </tr>
              <tr>
                <td>
                  Does your company comply or plan to comply with the OECD Due
                  Diligence Guidance for Responsible Supply Chains of Minerals
                  from Conflict-Affected and High-Risk Areas?
                </td>
                <td>{responses["oecdCompliance"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a human rights policy?</td>
                <td>{responses["humanRightsPolicy"] || "No response"}</td>
              </tr>

              <tr>
                <td>Do you have an anti-bribery policy?</td>
                <td>{responses["antiBriberyPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the anti-bribery policy?</td>
                <td>{responses["antiBriberyPolicyCopy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a whistle blower policy?</td>
                <td>{responses["whistleBlowerPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the whistle blower policy?</td>
                <td>{responses["whistleBlowerPolicyCopy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a data protection policy?</td>
                <td>{responses["dataProtectionPolicy"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a copy of the data protection policy?</td>
                <td>
                  {responses["dataProtectionPolicyCopy"] || "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you have documented policies?</td>
                <td>{responses["documentedPolicies"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you conduct an internal risk assessment?</td>
                <td>{responses["internalRiskAssessment"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you perform customer identification?</td>
                <td>{responses["customerIdentification"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you keep customer records?</td>
                <td>{responses["customerRecord"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you conduct a risk-based assessment?</td>
                <td>{responses["riskBasedAssessment"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you conduct enhanced due diligence?</td>
                <td>{responses["enhancedDueDiligence"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you check for PEPs?</td>
                <td>{responses["checkPEPs"] || "No response"}</td>
              </tr>
              <tr>
                <td>How do you perform due diligence for PEPs?</td>
                <td>{responses["dueDiligencePEPs"] || "No response"}</td>
              </tr>
              <tr>
                <td>How do you verify the source of wealth?</td>
                <td>{responses["sourceOfWealth"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a compliance officer?</td>
                <td>{responses["complianceOfficer"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have a monitoring program?</td>
                <td>{responses["monitoringProgram"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you perform customer screening?</td>
                <td>{responses["customerScreening"] || "No response"}</td>
              </tr>
              <tr>
                <td>Are you registered with goAML?</td>
                <td>{responses["goAML"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you check for red flags?</td>
                <td>{responses["redFlags"] || "No response"}</td>
              </tr>
              <tr>
                <td>How do you assess transactions?</td>
                <td>{responses["transactionAssessment"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you keep records and accounts of your transactions?</td>
                <td>{responses["keepRecords"] || "No response"}</td>
              </tr>
              <tr>
                <td>For how many years do you keep records?</td>
                <td>{responses["recordYears"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you conduct staff training?</td>
                <td>{responses["staffTraining"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you accept cash payments?</td>
                <td>{responses["maxCashPayment"] || "No response"}</td>
              </tr>
              <tr>
                <td>What is the maximum cash amount you accept?</td>
                <td>{responses["maxCashAmount"] || "No response"}</td>
              </tr>
              {/* STEP - 1 */}
              <tr>
                <td>
                  {" "}
                  Do you keep records and accounts of your transactions? (No of
                  years)
                </td>
                <td>{responses["records"] || "No response"}</td>
              </tr>
              {/* <tr>
                <td>Do you have external independent financial auditors?</td>
                <td>{responses["auditors"] || "No response"}</td>
              </tr>
              <tr>
                <td>What is the name of the auditor?</td>
                <td>{responses["auditorName"] || "No response"}</td>
              </tr>
              <tr>
                <td>Mention the source of operating funds. (Own Capital ?)</td>
                <td>
                  {responses["operatingFunds.ownCapital"] || "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you have operating funds from a bank loan?</td>
                <td>{responses["operatingFunds.bankLoan"] || "No response"}</td>
              </tr>
              <tr>
                <td>Do you have operating funds from a government entity?</td>
                <td>
                  {responses["operatingFunds.governmentEntity"] ||
                    "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you have operating funds from a third-party loan?</td>
                <td>
                  {responses["operatingFunds.thirdPartyLoan"] || "No response"}
                </td>
              </tr>
              <tr>
                <td>Do you have operating funds from other sources?</td>
                <td>{responses["operatingFunds.others"] || "No response"}</td>
              </tr> */}
            </tbody>
            <style jsx>{`
              table tr {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
              }
              table tr:nth-child(odd) {
                background-color: #f2f2f2; /* or any other color you prefer for the alternating rows */
              }
            `}</style>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Cancel
          </Button>

          <div style={{ display: "none" }}>
            {/* <PrintTable ref={componentRef} data={data} /> */}
          </div>
          <Button
            onClick={() => {
              console.log("hello");
            }}
            color="success"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>{" "}
      </Dialog>
    </div>
  );
};

export default ViewCQ;
