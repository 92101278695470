import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIURL } from "../globals";

// const userId = JSON.parse(localStorage.getItem("DashBoardUser"))?._id;
// console.log(userId);

// console.log(await userId);

const getUserIdFromLocalStorage = () => {
  const userData = JSON.parse(localStorage.getItem("DashBoardUser"));
  return userData ? userData._id : null;
};

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: APIURL,
    // "https://amldiligence.com/api",

    credentials: "include",
    crossDomain: true,
    headers: {
      // "Content-Type": "application/json",
      Accept: "application/json",
      // "Access-Control-Allow-Origin": APIURL,
      // `https://amldiligence.com/api`,
    },
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Entities",
    "Individuals",
    "ExpiredIndividuals",
    "Logs",
    "IndividualsSearch",
    "RBA",
  ],

  endpoints: (build) => {
    const userId = getUserIdFromLocalStorage();

    return {
      uploadFile: build.mutation({
        query: (file) => {
          const formData = new FormData();
          formData.append("uploadedFile", file);
          return {
            url: "/upload",
            method: "POST",
            body: formData,
          };
        },
      }),

      getCountries: build.query({
        query: (id) => `general/user/${id}`,
        providesTags: ["RBA"],
      }),

      editCountries: build.mutation({
        query: (country) => {
          console.log("Sending request with body:", country); // Logging the request body
          return {
            url: `general/rbaCountries`,
            method: "PUT",
            body: country,
          };
        },
        invalidatesTags: ["RBA"],
      }),

      editSourceOfFunds: build.mutation({
        query: (source) => {
          console.log("Sending request with body:", source); // Logging the request body
          return {
            url: `general/sourceOfFunds`,
            method: "PUT",
            body: source,
          };
        },
        invalidatesTags: ["RBA"],
      }),

      editIdType: build.mutation({
        query: (idType) => {
          console.log("Sending request with body:", idType); // Logging the request body
          return {
            url: `general/idType`,
            method: "PUT",
            body: idType,
          };
        },
        invalidatesTags: ["RBA"],
      }),

      editLicType: build.mutation({
        query: (licType) => {
          console.log("Sending request with body:", licType); // Logging the request body
          return {
            url: `general/licType`,
            method: "PUT",
            body: licType,
          };
        },
        invalidatesTags: ["RBA"],
      }),

      editResidentialStatus: build.mutation({
        query: (residentialStatus) => {
          console.log("Sending request with body:", residentialStatus); // Logging the request body
          return {
            url: `general/residentialStatus`,
            method: "PUT",
            body: residentialStatus,
          };
        },
        invalidatesTags: ["RBA"],
      }),

      editBusinessActivity: build.mutation({
        query: (businessActivity) => {
          console.log("Sending request with body:", businessActivity); // Logging the request body
          return {
            url: `general/businessActivity`,
            method: "PUT",
            body: businessActivity,
          };
        },
        invalidatesTags: ["RBA"],
      }),

      getUser: build.query({
        query: (id) => `general/user/${id}`,
        providesTags: ["User"],
      }),
      getProducts: build.query({
        query: () => "client/products",
        providesTags: ["Products"],
      }),

      getLogs: build.query({
        query: () => "/logs/screenlog",
        providesTags: ["Logs"],
      }),

      makePersonClear: build.mutation({
        query: ({ id, cleared, notes, ffr, nmr }) => ({
          url: "/client/clear",
          method: "POST",
          body: {
            id,
            cleared,
            notes,
            ffr,
            nmr,
          },
        }),
        invalidatesTags: ["Logs"],
      }),
      // ... other endpoints

      //Entities Queries
      getCustomers: build.query({
        query: () => `client/entities/${userId}`,
        providesTags: ["Entities"],
      }),
      getExpiredCustomers: build.query({
        query: () => `client/expiredEntities`,
        providesTags: ["ExpiredCustomers"],
      }),
      postCustomers: build.mutation({
        query: (customer) => ({
          url: "client/entities",
          method: "POST",
          body: { ...customer, ...{ company: userId } },
        }),
        invalidatesTags: ["Entities"],
      }),
      editCustomers: build.mutation({
        query: (customer) => ({
          url: `client/entities`,
          method: "PUT",
          body: { ...customer, ...{ company: userId } },
        }),
        invalidatesTags: ["Entities", "Dashboard"],
      }),
      addComplianceQ: build.mutation({
        query: (id, responses) => ({
          url: `client/complianceQ`,
          method: "POST",
          body: { ...responses, ...id },
        }),

        invalidatesTags: ["Entities", "Dashboard"],
      }),
      addRiskProfile: build.mutation({
        query: (data) => ({
          url: `client/riskProfile`,
          method: "POST",
          body: { ...data },
        }),

        invalidatesTags: ["Entities", "Dashboard"],
      }),
      deleteCustomers: build.mutation({
        query: (id) => ({
          url: "client/entities",
          method: "DELETE",
          body: { id: id },
        }),
        invalidatesTags: ["Entities"],
      }),
      //End Entities Queries

      searchIndividuals: build.query({
        query: (searchCriteria) => ({
          url: "client/search",
          method: "POST",
          body: searchCriteria,
        }),
      }),
      getIndividuals: build.query({
        query: () => `client/individuals`,
        providesTags: ["Individuals"],
      }),
      getExpiredIndividuals: build.query({
        query: () => `client/expiredindividuals`,
        providesTags: ["ExpiredIndividuals"],
      }),
      postIndividuals: build.mutation({
        query: (customer) => ({
          url: "client/individuals",
          method: "POST",
          body: { ...customer, ...{ company: userId } },
        }),
        invalidatesTags: ["Individuals", "Logs"],
      }),
      editIndividuals: build.mutation({
        query: (customer) => ({
          url: "client/individuals",
          method: "PUT",
          body: { ...customer, ...{ company: userId } },
        }),
        invalidatesTags: ["Individuals"],
      }),
      deleteIndividuals: build.mutation({
        query: (id) => ({
          url: "client/individuals",
          method: "DELETE",
          body: { id: id },
        }),
        invalidatesTags: ["Individuals"],
      }),

      //End Individuals Queries

      getCrp: build.query({
        query: (entity) => ({
          url: "client/crp",
          method: "POST",
          body: { entity: entity },
        }),
        invalidatesTags: ["Individuals"],
      }),
      getTransactions: build.query({
        query: ({ page, pageSize, sort, search }) => ({
          url: "client/transactions",
          method: "GET",
          params: { page, pageSize, sort, search },
        }),
        providesTags: ["Transactions"],
      }),
      getGeography: build.query({
        query: () => "client/geography",
        providesTags: ["Geography"],
      }),
      getSales: build.query({
        query: () => "sales/sales",
        providesTags: ["Sales"],
      }),
      getAdmins: build.query({
        query: () => "management/admins",
        providesTags: ["Admins"],
      }),
      getUserPerformance: build.query({
        query: (id) => `management/performance/${id}`,
        providesTags: ["Performance"],
      }),
      getDashboard: build.query({
        query: () => "general/dashboard",
        providesTags: ["Dashboard"],
      }),
    };
  },
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetCustomersQuery,
  usePostCustomersMutation,
  useDeleteCustomersMutation,
  useEditCustomersMutation,
  useGetIndividualsQuery,
  usePostIndividualsMutation,
  useSearchIndividualsQuery,
  useDeleteIndividualsMutation,
  useEditIndividualsMutation,
  useGetExpiredIndividualsQuery,
  useGetExpiredCustomersQuery,
  useGetLogsQuery,
  useAddComplianceQMutation,
  useAddRiskProfileMutation,
  useUploadFileMutation,
  useMakePersonClearMutation,
  useGetCountriesQuery,
  useEditCountriesMutation,
  useEditSourceOfFundsMutation,
  useEditBusinessActivityMutation,
  useEditIdTypeMutation,
  useEditLicTypeMutation,
  useEditResidentialStatusMutation,
  useGetCrpQuery,
} = api;
