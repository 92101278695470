export const countryNames = [
  "",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const countryListAlpha2 = {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas (the)",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia (Plurinational State of)",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory (the)",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands (the)",
  CF: "Central African Republic (the)",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands (the)",
  CO: "Colombia",
  KM: "Comoros (the)",
  CD: "Congo (the Democratic Republic of the)",
  CG: "Congo (the)",
  CK: "Cook Islands (the)",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  CI: "Côte d'Ivoire",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic (the)",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands (the) [Malvinas]",
  FO: "Faroe Islands (the)",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories (the)",
  GA: "Gabon",
  GM: "Gambia (the)",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  VA: "Holy See (the)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran (Islamic Republic of)",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea (the Democratic People's Republic of)",
  KR: "Korea (the Republic of)",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic (the)",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands (the)",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia (Federated States of)",
  MD: "Moldova (the Republic of)",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands (the)",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger (the)",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands (the)",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine, State of",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines (the)",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  MK: "Republic of North Macedonia",
  RO: "Romania",
  RU: "Russian Federation (the)",
  RW: "Rwanda",
  RE: "Réunion",
  BL: "Saint Barthélemy",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten (Dutch part)",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan (the)",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands (the)",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates (the)",
  GB: "United Kingdom of Great Britain and Northern Ireland (the)",
  UM: "United States Minor Outlying Islands (the)",
  US: "United States of America (the)",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela (Bolivarian Republic of)",
  VN: "Viet Nam",
  VG: "Virgin Islands (British)",
  VI: "Virgin Islands (U.S.)",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  AX: "Åland Islands",
};

export const values = [
  {
    abbreviation: "wd_oligarchs",
    fullForm: "World's Oligarchs",
  },
  {
    abbreviation: "us_fbi_most_wanted",
    fullForm: "U.S. Federal Bureau of Investigation (FBI) Most Wanted",
  },
  {
    abbreviation: "md_rise_profiles",
    fullForm:
      "Moldova Risk and Insecurity Situations Evaluation (RISE) Profiles",
  },
  {
    abbreviation: "ua_nabc_sanctions",
    fullForm:
      "Ukraine National Agency for the Prevention of Corruption Sanctions",
  },
  {
    abbreviation: "un_sc_sanctions",
    fullForm: "United Nations Security Council Sanctions",
  },
  {
    abbreviation: "gb_coh_psc",
    fullForm:
      "United Kingdom Companies House People with Significant Control (PSC) Register",
  },
  {
    abbreviation: "kz_afmrk_sanctions",
    fullForm: "Kazakhstan Agency for Financial Monitoring Sanctions List",
  },
  {
    abbreviation: "sy_obsalytics_opensyr",
    fullForm: "Syria Obsalytics OpenSYR Database",
  },
  {
    abbreviation: "us_cuba_sanctions",
    fullForm: "U.S. Cuba Sanctions",
  },
  {
    abbreviation: "us_sam_exclusions",
    fullForm: "U.S. System for Award Management (SAM) Exclusions",
  },
  {
    abbreviation: "ca_dfatd_sema_sanctions",
    fullForm:
      "Canada Department of Foreign Affairs, Trade, and Development (DFATD) SEMA Sanctions",
  },
  {
    abbreviation: "ae_local_terrorists",
    fullForm: "United Arab Emirates Local Terrorists List",
  },
  {
    abbreviation: "ca_facfoa",
    fullForm:
      "Canada Financial Transactions and Reports Analysis Centre of Canada (FINTRAC) Form",
  },
  {
    abbreviation: "ch_seco_sanctions",
    fullForm:
      "Switzerland State Secretariat for Economic Affairs (SECO) Sanctions",
  },
  {
    abbreviation: "gb_hmt_sanctions",
    fullForm: "United Kingdom HM Treasury Sanctions",
  },
  {
    abbreviation: "eu_sanctions_map",
    fullForm: "European Union Sanctions Map",
  },
  {
    abbreviation: "us_occ_enfact",
    fullForm:
      "U.S. Office of the Comptroller of the Currency (OCC) Enforcement Actions",
  },
  {
    abbreviation: "ru_nsd_isin",
    fullForm: "Russia National Settlement Depository (NSD) ISIN Database",
  },
  {
    abbreviation: "adb_sanctions",
    fullForm: "Asian Development Bank Sanctions List",
  },
  {
    abbreviation: "iadb_sanctions",
    fullForm: "Inter-American Development Bank Sanctions List",
  },
  {
    abbreviation: "eu_meps",
    fullForm: "European Union Members of the European Parliament (MEPs)",
  },
  {
    abbreviation: "ua_nsdc_sanctions",
    fullForm: "Ukraine National Security and Defense Council Sanctions",
  },
  {
    abbreviation: "ru_acf_bribetakers",
    fullForm: "Russia Anti-Corruption Foundation Bribetakers List",
  },
  {
    abbreviation: "ebrd_ineligible",
    fullForm:
      "European Bank for Reconstruction and Development Ineligible Firms List",
  },
  {
    abbreviation: "us_bis_denied",
    fullForm: "U.S. Bureau of Industry and Security Denied Persons List",
  },
  {
    abbreviation: "jp_mof_sanctions",
    fullForm: "Japan Ministry of Finance Sanctions",
  },
  {
    abbreviation: "gleif",
    fullForm: "Global Legal Entity Identifier Foundation (GLEIF)",
  },
  {
    abbreviation: "wikidata",
    fullForm: "WikiData",
  },
  {
    abbreviation: "az_fiu_sanctions",
    fullForm: "Azerbaijan Financial Intelligence Unit Sanctions",
  },
  {
    abbreviation: "cz_business_register",
    fullForm: "Czech Republic Business Register",
  },
  {
    abbreviation: "ca_listed_terrorists",
    fullForm: "Canada Listed Terrorist Entities",
  },
  {
    abbreviation: "opencorporates",
    fullForm: "OpenCorporates Global Company Data",
  },
  {
    abbreviation: "eu_europol_wanted",
    fullForm: "European Union Europol Wanted List",
  },
  {
    abbreviation: "be_fod_sanctions",
    fullForm: "Belgium Federal Public Service Foreign Affairs Sanctions",
  },
  {
    abbreviation: "pl_mswia_sanctions",
    fullForm: "Poland Ministry of National Defense (MSWIA) Sanctions",
  },
  {
    abbreviation: "cy_companies",
    fullForm: "Cyprus Companies",
  },
  {
    abbreviation: "in_mha_banned",
    fullForm: "India Ministry of Home Affairs Banned List",
  },
  {
    abbreviation: "au_dfat_sanctions",
    fullForm:
      "Australia Department of Foreign Affairs and Trade (DFAT) Sanctions",
  },
  {
    abbreviation: "kg_fiu_national",
    fullForm: "Kyrgyzstan Financial Intelligence Unit National Sanctions List",
  },
  {
    abbreviation: "nominatim",
    fullForm: "Nominatim Geocoding Service",
  },
  {
    abbreviation: "gb_nca_most_wanted",
    fullForm: "United Kingdom National Crime Agency (NCA) Most Wanted",
  },
  {
    abbreviation: "lv_business_register",
    fullForm: "Latvia Business Register",
  },
  {
    abbreviation: "gb_coh_disqualified",
    fullForm: "United Kingdom Companies House Disqualified Directors",
  },
  {
    abbreviation: "md_companies",
    fullForm: "Moldova Companies Register",
  },
  {
    abbreviation: "wd_curated",
    fullForm: "World's Curated Data",
  },
  {
    abbreviation: "us_trade_csl",
    fullForm:
      "U.S. Bureau of Industry and Security (BIS) Entity List (Trade CSL)",
  },
  {
    abbreviation: "eu_travel_bans",
    fullForm: "European Union Travel Bans List",
  },
  {
    abbreviation: "afdb_sanctions",
    fullForm: "African Development Bank Sanctions List",
  },
  {
    abbreviation: "ua_sfms_blacklist",
    fullForm: "Ukraine State Financial Monitoring Service (SFMS) Blacklist",
  },
  {
    abbreviation: "ua_edr",
    fullForm:
      "Ukraine State Register of Legal Entities, Individual Entrepreneurs, and Public Organizations (EDR)",
  },
  {
    abbreviation: "us_ofac_cons",
    fullForm:
      "U.S. Office of Foreign Assets Control (OFAC) Consolidated Sanctions List",
  },
  {
    abbreviation: "il_mod_terrorists",
    fullForm: "Israel Ministry of Defense (MOD) Terrorists List",
  },
  {
    abbreviation: "nl_most_wanted",
    fullForm: "Netherlands Most Wanted List",
  },
  {
    abbreviation: "qa_nctc_sanctions",
    fullForm: "Qatar National Counter-Terrorism Committee (NCTC) Sanctions",
  },
  {
    abbreviation: "worldbank_debarred",
    fullForm: "World Bank Debarred Firms List",
  },
  {
    abbreviation: "wd_peps",
    fullForm: "World's Politically Exposed Persons (PEPs)",
  },
  {
    abbreviation: "md_interdictie",
    fullForm: "Moldova Interdiction List",
  },
  {
    abbreviation: "ransomwhere",
    fullForm: "RansomWhere Project",
  },
  {
    abbreviation: "icij_offshoreleaks",
    fullForm:
      "International Consortium of Investigative Journalists (ICIJ) Offshore Leaks Database",
  },
  {
    abbreviation: "nz_russia_sanctions",
    fullForm: "New Zealand Russia Sanctions",
  },
  {
    abbreviation: "everypolitician",
    fullForm: "EveryPolitician Open Data Project",
  },
  {
    abbreviation: "us_cia_world_leaders",
    fullForm: "U.S. Central Intelligence Agency (CIA) World Leaders",
  },
  {
    abbreviation: "ru_egrul",
    fullForm: "Russia Unified State Register of Legal Entities (EGRUL)",
  },
  {
    abbreviation: "eu_cor_members",
    fullForm: "European Union European Parliament Members",
  },
  {
    abbreviation: "bg_omnio_poi",
    fullForm: "Bulgaria OMNIO Persons of Interest (POI) Database",
  },
  {
    abbreviation: "nl_terrorism_list",
    fullForm: "Netherlands Terrorism List",
  },
  {
    abbreviation: "ar_repet",
    fullForm:
      "Argentina Register of Public Officials with Conflict of Interest",
  },
  {
    abbreviation: "ru_rupep",
    fullForm: "Russia Register of PEPs (Politically Exposed Persons)",
  },
  {
    abbreviation: "za_fic_sanctions",
    fullForm: "South Africa Financial Intelligence Centre Sanctions",
  },
  {
    abbreviation: "ru_fedsfm_wmd",
    fullForm:
      "Russia Federal Service of the National Guard Troops Weapons of Mass Destruction (WMD) List",
  },
  {
    abbreviation: "lt_fiu_freezes",
    fullForm: "Lithuania Financial Intelligence Unit Freezes List",
  },
  {
    abbreviation: "ee_ariregister",
    fullForm: "Estonia Annual Reports and Interests Register (ARIREGISTER)",
  },
  {
    abbreviation: "sg_terrorists",
    fullForm: "Singapore Terrorists List",
  },
  {
    abbreviation: "fr_tresor_gels_avoir",
    fullForm:
      "France Ministry of Economy and Finance Freezing of Assets (GEL/AVOIR) List",
  },
  {
    abbreviation: "eu_fsf",
    fullForm: "European Union Financial Sanctions Framework (FSF)",
  },
  {
    abbreviation: "us_ofac_sdn",
    fullForm:
      "U.S. Office of Foreign Assets Control (OFAC) Specially Designated Nationals (SDN) List",
  },
  {
    abbreviation: "interpol_red_notices",
    fullForm: "Interpol Red Notices",
  },
];

export const businessActivityScores = [
  {
    Name: "Accounting",
    RiskScore: 2,
  },
  {
    Name: "Accounting and Tax Preparation",
    RiskScore: 2,
  },
  {
    Name: "Adult Entertainment",
    RiskScore: 2,
  },
  {
    Name: "Advertising",
    RiskScore: 2,
  },
  {
    Name: "Advertising, Marketing and PR",
    RiskScore: 2,
  },
  {
    Name: "Advocacy Organizations",
    RiskScore: 1,
  },
  {
    Name: "Aerospace",
    RiskScore: 2,
  },
  {
    Name: "Aerospace and Defense",
    RiskScore: 1,
  },
  {
    Name: "Agriculture",
    RiskScore: 1,
  },
  {
    Name: "Agriculture & Mining Other",
    RiskScore: 2,
  },
  {
    Name: "Air Couriers and Caro Services",
    RiskScore: 2,
  },
  {
    Name: "Aircraft",
    RiskScore: 2,
  },
  {
    Name: "Airline",
    RiskScore: 1,
  },
  {
    Name: "Airport, Harbor, and Terminal Operations",
    RiskScore: 2,
  },
  {
    Name: "Alcoholic Beverages",
    RiskScore: 3,
  },
  {
    Name: "Alternative Energy Sources",
    RiskScore: 1,
  },
  {
    Name: "Amusement Parks and Attractions",
    RiskScore: 1,
  },
  {
    Name: "Apparel Wholesalers",
    RiskScore: 1,
  },
  {
    Name: "Apparel&Accessories",
    RiskScore: 2,
  },
  {
    Name: "Architecture, Engineering and Design",
    RiskScore: 1,
  },
  {
    Name: "Audio, Video and Photography",
    RiskScore: 2,
  },
  {
    Name: "Automobile Dealers",
    RiskScore: 1,
  },
  {
    Name: "Automobile Parts and Supplies",
    RiskScore: 2,
  },
  {
    Name: "Automobile Parts Wholesalers",
    RiskScore: 1,
  },
  {
    Name: "Automobiles, Boats and Motor Vehicles",
    RiskScore: 1,
  },
  {
    Name: "Automotive",
    RiskScore: 1,
  },
  {
    Name: "Automotive Repair and Maintenance",
    RiskScore: 1,
  },
  {
    Name: "BANK",
    RiskScore: 2,
  },
  {
    Name: "Beer, Wine and Liquor Stores",
    RiskScore: 2,
  },
  {
    Name: "Beer, Wine and Liquor Wholesalers",
    RiskScore: 1,
  },
  {
    Name: "Biotechnology",
    RiskScore: 1,
  },
  {
    Name: "Broadcasting",
    RiskScore: 3,
  },
  {
    Name: "Brokerage",
    RiskScore: 2,
  },
  {
    Name: "Business Services Other",
    RiskScore: 1,
  },
  {
    Name: "Cable and Television Providers",
    RiskScore: 1,
  },
  {
    Name: "CallCenters",
    RiskScore: 1,
  },
  {
    Name: "CargoHandling",
    RiskScore: 1,
  },
  {
    Name: "Charitable Organizations and Foundations",
    RiskScore: 3,
  },
  {
    Name: "Chemical",
    RiskScore: 3,
  },
  {
    Name: "Chemicals and Petrochemicals",
    RiskScore: 2,
  },
  {
    Name: "Chemicals and Plastics Wholesalers",
    RiskScore: 2,
  },
  {
    Name: "Clothing and Shoe Stores",
    RiskScore: 1,
  },
  {
    Name: "Colleges and Universities",
    RiskScore: 2,
  },
  {
    Name: "Computer",
    RiskScore: 2,
  },
  {
    Name: "Computer and Electronics Other",
    RiskScore: 1,
  },
  {
    Name: "Computers, Parts and Repair",
    RiskScore: 1,
  },
  {
    Name: "Concrete, Glass and Building Materials",
    RiskScore: 1,
  },
  {
    Name: "Construction and Remodeling",
    RiskScore: 1,
  },
  {
    Name: "Construction Equipment and Supplies",
    RiskScore: 1,
  },
  {
    Name: "Consulting",
    RiskScore: 1,
  },
  {
    Name: "Consumer Electronics, Parts and Repair",
    RiskScore: 2,
  },
  {
    Name: "Consumer Services Other",
    RiskScore: 2,
  },
  {
    Name: "ConsumerProducts",
    RiskScore: 1,
  },
  {
    Name: "Cosmetics",
    RiskScore: 1,
  },
  {
    Name: "Credit Cards and Related Services",
    RiskScore: 1,
  },
  {
    Name: "Cruise Ship Operations",
    RiskScore: 1,
  },
  {
    Name: "CRYSTAL PRODUCTS TRADING",
    RiskScore: 2,
  },
  {
    Name: "Data Analytics, Management, and Internet",
    RiskScore: 2,
  },
  {
    Name: "Data and Records Management",
    RiskScore: 3,
  },
  {
    Name: "Defense",
    RiskScore: 1,
  },
  {
    Name: "Department Stores",
    RiskScore: 1,
  },
  {
    Name: "Diagnostic Laboratories",
    RiskScore: 1,
  },
  {
    Name: "Doctors and Health Care Practitioners",
    RiskScore: 1,
  },
  {
    Name: "E-Commerce and Internet Business",
    RiskScore: 1,
  },
  {
    Name: "Education",
    RiskScore: 3,
  },
  {
    Name: "Elementary and Secondary Schools",
    RiskScore: 3,
  },
  {
    Name: "Energy",
    RiskScore: 2,
  },
  {
    Name: "Entertainment&Leisure",
    RiskScore: 1,
  },
  {
    Name: "Facilities Management and Maintenance",
    RiskScore: 1,
  },
  {
    Name: "Farming and Mining Machinery and Equipment",
    RiskScore: 2,
  },
  {
    Name: "Farming and Ranching",
    RiskScore: 2,
  },
  {
    Name: "FINANCIAL INSTITUTION",
    RiskScore: 2,
  },
  {
    Name: "Fishing, Hunting and Forestry and Logging",
    RiskScore: 2,
  },
  {
    Name: "Food and Dairy Product Manufacturing and Packaging",
    RiskScore: 2,
  },
  {
    Name: "Food,Beverage&Tobacco",
    RiskScore: 1,
  },
  {
    Name: "Freight Hauling (Rail and Truck)",
    RiskScore: 1,
  },
  {
    Name: "Funeral Homes and Services",
    RiskScore: 1,
  },
  {
    Name: "Furniture Manufacturing",
    RiskScore: 2,
  },
  {
    Name: "Furniture Stores",
    RiskScore: 1,
  },
  {
    Name: "Gambling and Gaming",
    RiskScore: 2,
  },
  {
    Name: "Garments & Textites",
    RiskScore: 2,
  },
  {
    Name: "Gasoline and Oil Refineries",
    RiskScore: 3,
  },
  {
    Name: "General Trading",
    RiskScore: 3,
  },
  {
    Name: "GIFTS TRADING",
    RiskScore: 2,
  },
  {
    Name: "GOLD AND PRECIOUS METAL",
    RiskScore: 1,
  },
  {
    Name: "GOLD AND PRECIOUS METAL CASTING",
    RiskScore: 3,
  },
  {
    Name: "GOLD REFINERY",
    RiskScore: 2,
  },
  {
    Name: "Government Other",
    RiskScore: 1,
  },
  {
    Name: "Grocery",
    RiskScore: 1,
  },
  {
    Name: "Hardware and Building Material Dealers",
    RiskScore: 2,
  },
  {
    Name: "Health, Pharmaceuticals, and Biotech Other",
    RiskScore: 1,
  },
  {
    Name: "HealthCare",
    RiskScore: 1,
  },
  {
    Name: "Hospitals",
    RiskScore: 2,
  },
  {
    Name: "Hotels",
    RiskScore: 1,
  },
  {
    Name: "HR and Recruiting Services",
    RiskScore: 3,
  },
  {
    Name: "INSIDE UAE",
    RiskScore: 3,
  },
  {
    Name: "Instruments and Controls",
    RiskScore: 3,
  },
  {
    Name: "Insurance and Risk Management",
    RiskScore: 1,
  },
  {
    Name: "International Bodies and Organizations",
    RiskScore: 1,
  },
  {
    Name: "InternetPublishing",
    RiskScore: 2,
  },
  {
    Name: "Investment Banking and Venture Capital",
    RiskScore: 3,
  },
  {
    Name: "IT and Network Services and Support",
    RiskScore: 1,
  },
  {
    Name: "JEWELLERY TRADING",
    RiskScore: 3,
  },
  {
    Name: "Jewelry Precious Metal",
    RiskScore: 1,
  },
  {
    Name: "Laundry and Dry Cleaning",
    RiskScore: 1,
  },
  {
    Name: "Legal",
    RiskScore: 1,
  },
  {
    Name: "Lending and Mortgage",
    RiskScore: 1,
  },
  {
    Name: "Libraries, Archives and Museums",
    RiskScore: 1,
  },
  {
    Name: "Management Consulting",
    RiskScore: 1,
  },
  {
    Name: "Manufacturing",
    RiskScore: 1,
  },
  {
    Name: "Marine and Inland Shipping",
    RiskScore: 1,
  },
  {
    Name: "Media and Entertainment Other",
    RiskScore: 1,
  },
  {
    Name: "Medical Devices",
    RiskScore: 2,
  },
  {
    Name: "Medical Supplies and Equipment",
    RiskScore: 1,
  },
  {
    Name: "Metal and Mineral Wholesalers",
    RiskScore: 1,
  },
  {
    Name: "Mining and Quarrying",
    RiskScore: 1,
  },
  {
    Name: "Motion Picture and Recording Producers",
    RiskScore: 1,
  },
  {
    Name: "Motion Picture Exhibitors",
    RiskScore: 1,
  },
  {
    Name: "Moving Companies and Services",
    RiskScore: 1,
  },
  {
    Name: "Music",
    RiskScore: 2,
  },
  {
    Name: "National Government",
    RiskScore: 2,
  },
  {
    Name: "Network Security Products",
    RiskScore: 1,
  },
  {
    Name: "Networking equipment and Systems",
    RiskScore: 1,
  },
  {
    Name: "NewspaperPublishers",
    RiskScore: 3,
  },
  {
    Name: "Newspapers, Books, and Periodicals",
    RiskScore: 1,
  },
  {
    Name: "NON-MANUFACTURED PRECIOUS METAL TRADING",
    RiskScore: 3,
  },
  {
    Name: "NOVELITIES TRADING",
    RiskScore: 2,
  },
  {
    Name: "Office Equipment and Suppliers Wholesalers",
    RiskScore: 1,
  },
  {
    Name: "Office Machinery and Equipment",
    RiskScore: 1,
  },
  {
    Name: "Office Supplies Stores",
    RiskScore: 1,
  },
  {
    Name: "OnlineAuctions",
    RiskScore: 1,
  },
  {
    Name: "Paper and Paper Products",
    RiskScore: 1,
  },
  {
    Name: "Parking Lots and Garage Management",
    RiskScore: 3,
  },
  {
    Name: "Participatory Sports and Recreation",
    RiskScore: 1,
  },
  {
    Name: "Passenger Airlines",
    RiskScore: 2,
  },
  {
    Name: "Payroll Services",
    RiskScore: 1,
  },
  {
    Name: "PEARLS AND PRECIOUS STONES TRADING",
    RiskScore: 3,
  },
  {
    Name: "PensionFunds",
    RiskScore: 3,
  },
  {
    Name: "Performing Arts",
    RiskScore: 1,
  },
  {
    Name: "Peripherals Manufacturing",
    RiskScore: 2,
  },
  {
    Name: "Personal Care",
    RiskScore: 3,
  },
  {
    Name: "Personal Financial Planning and Private Banking",
    RiskScore: 2,
  },
  {
    Name: "Personal Health Care Products",
    RiskScore: 3,
  },
  {
    Name: "Petroleum Products Wholesalers",
    RiskScore: 3,
  },
  {
    Name: "Pharmaceuticals",
    RiskScore: 1,
  },
  {
    Name: "Photofinishing Services",
    RiskScore: 2,
  },
  {
    Name: "Postal, Express Delivery and Couriers",
    RiskScore: 1,
  },
  {
    Name: "PrivateEquity",
    RiskScore: 1,
  },
  {
    Name: "Property Leasing and Management",
    RiskScore: 2,
  },
  {
    Name: "Publishing",
    RiskScore: 1,
  },
  {
    Name: "Radio, Television Broadcasting",
    RiskScore: 1,
  },
  {
    Name: "Real Estate Agents and Appraisers",
    RiskScore: 1,
  },
  {
    Name: "Real Estate and Construction Other",
    RiskScore: 1,
  },
  {
    Name: "Real Estate Investment and Development",
    RiskScore: 3,
  },
  {
    Name: "RealEstate",
    RiskScore: 3,
  },
  {
    Name: "Religious Organizations",
    RiskScore: 2,
  },
  {
    Name: "Rental Cars",
    RiskScore: 1,
  },
  {
    Name: "Residential and Long-term Care Facilities",
    RiskScore: 1,
  },
  {
    Name: "Resorts and Casinos",
    RiskScore: 2,
  },
  {
    Name: "Restaurants and Bars",
    RiskScore: 1,
  },
  {
    Name: "Retail Others",
    RiskScore: 1,
  },
  {
    Name: "Retail&Wholesale",
    RiskScore: 1,
  },
  {
    Name: "Securities Agents and Brokers",
    RiskScore: 2,
  },
  {
    Name: "Securities&CommodityExchanges",
    RiskScore: 2,
  },
  {
    Name: "Security Services",
    RiskScore: 1,
  },
  {
    Name: "Semiconductor and Microchip Manufacturing",
    RiskScore: 1,
  },
  {
    Name: "SERVICE",
    RiskScore: 2,
  },
  {
    Name: "Service Based Professional Client",
    RiskScore: 1,
  },
  {
    Name: "Sewage Treatment Facilities",
    RiskScore: 1,
  },
  {
    Name: "Soap&Detergent",
    RiskScore: 3,
  },
  {
    Name: "Social and Membership Organizations",
    RiskScore: 3,
  },
  {
    Name: "Software",
    RiskScore: 1,
  },
  {
    Name: "Spectator Sports and Teams",
    RiskScore: 2,
  },
  {
    Name: "Sports, Arts, and Recreation Instruction",
    RiskScore: 2,
  },
  {
    Name: "Steel Industries and Steel contracting",
    RiskScore: 2,
  },
  {
    Name: "Taxi, Buses and Transit Systems",
    RiskScore: 1,
  },
  {
    Name: "Technical and Trade Schools",
    RiskScore: 2,
  },
  {
    Name: "Telecommunications",
    RiskScore: 2,
  },
  {
    Name: "Telecommunications Equipment and Accessories",
    RiskScore: 1,
  },
  {
    Name: "Telephone Service Providers and Carriers",
    RiskScore: 2,
  },
  {
    Name: "Television",
    RiskScore: 1,
  },
  {
    Name: "Test Preparation",
    RiskScore: 1,
  },
  {
    Name: "Textiles, Apparel and Accessories",
    RiskScore: 2,
  },
  {
    Name: "Timber products ans Services",
    RiskScore: 3,
  },
  {
    Name: "Tools, Hardware and Light Machinery",
    RiskScore: 3,
  },
  {
    Name: "Trade Groups and Labor Unions",
    RiskScore: 2,
  },
  {
    Name: "TRADING",
    RiskScore: 1,
  },
  {
    Name: "Trading in Computer Equiments and Others",
    RiskScore: 1,
  },
  {
    Name: "Transportation",
    RiskScore: 1,
  },
  {
    Name: "Travel Agents and Services",
    RiskScore: 2,
  },
  {
    Name: "Travel, Recreations and Leisure Other",
    RiskScore: 2,
  },
  {
    Name: "Trucking",
    RiskScore: 2,
  },
  {
    Name: "Trust, Fiduciary, and Custody Activities",
    RiskScore: 1,
  },
  {
    Name: "Used Cars",
    RiskScore: 1,
  },
  {
    Name: "USED JEWELLERY TRADIN",
    RiskScore: 3,
  },
  {
    Name: "Veterinary Clinics and Services",
    RiskScore: 1,
  },
  {
    Name: "Video and Teleconferencing",
    RiskScore: 2,
  },
  {
    Name: "Warehousing and Storage",
    RiskScore: 1,
  },
  {
    Name: "Waste Management and Recycling",
    RiskScore: 1,
  },
  {
    Name: "WATCH AND CLOCK AND SPARE PARTS TRADING",
    RiskScore: 2,
  },
  {
    Name: "Water Treatment and Utilities",
    RiskScore: 2,
  },
  {
    Name: "Wholesale and Distribution Other",
    RiskScore: 2,
  },
  {
    Name: "Wireless and Mobile",
    RiskScore: 2,
  },
];
