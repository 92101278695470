import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  FormControlLabel,
  Box,
  Paper,
  Grid,
} from "@mui/material";
import moment from "moment";
import { Padding } from "@mui/icons-material";
import { countryListAlpha2 } from "data/data";
import { values } from "data/data";

const PrintTable = React.forwardRef(
  (
    {
      data,
      user,
      pep,
      clientName,
      responses,
      riskProfile,
      dueDiligence,
      statusResult,
      type,
    },
    ref
  ) => {
    console.log(data);
    return (
      <Box
        ref={ref}
        className="a"
        sx={{
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          transform: "scale(1.0)",
          paddingTop: "20px",
        }}
      >
        <Box
          className="div"
          sx={{
            backgroundColor: "#ffffff",
            border: "1px none",
            height: "842px",
            position: "relative",
            width: "595px",
          }}
        >
          <Box
            className="group"
            sx={{
              height: "55px",
              left: "0px",
              position: "absolute",
              top: "108px",
              width: "550px",
            }}
          >
            <Box
              className="text-wrapper"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#000000",
                fontFamily: '"Inter-ExtraBold", Helvetica',
                fontSize: "20px",
                fontWeight: "800",
                left: "145px",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "15px",
                width: "300px",
                height: "auto",
                // you need to define a height for the flex container to work as expected
              }}
            >
              Search Report
            </Box>
          </Box>
          <Box
            sx={{
              height: "auto",
              left: "0px",
              objectFit: "cover",
              position: "absolute",
              top: "154px",
              width: "auto",
            }}
          >
            {<h4> Query and Status</h4>}
            <TableContainer component={Paper}>
              {/* width={"990px"} left={"290px"}> */}
              <Table sx={{}} aria-label="simple table">
                <TableHead style={{ whiteSpace: "nowrap" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        //fontSize: "6px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Search Date
                    </TableCell>
                    <TableCell
                      sx={{
                        //fontSize: "6px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Full Name
                    </TableCell>
                    <TableCell
                      sx={{
                        //fontSize: "6px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      DoB/Inc Date
                    </TableCell>
                    <TableCell
                      sx={{
                        //fontSize: "6px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Nationality/Country of Inc.
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontSize: "6px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Listing
                    </TableCell> */}
                    <TableCell
                      sx={{
                        //fontSize: "6px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={data.name}>
                    <TableCell
                      style={{ whiteSpace: "wrap" }}
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "10px",
                        alignItems: "center",
                        minWidth: "130px",
                      }}
                    >
                      {moment(data.createdAt).format("DD-MM-YY HH:mm A")}
                    </TableCell>
                    <TableCell
                      sx={{
                        //fontSize: "9px",
                        textAlign: "center",
                        whiteSpace: "wrap",
                        minWidth: "130px",
                      }}
                    >
                      {data.criteria?.name || ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        // fontSize: "9px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        minWidth: "50px",
                      }}
                    >
                      {data.criteria?.doB || "NA"}
                    </TableCell>
                    <TableCell
                      sx={{
                        // fontSize: "9px",
                        textAlign: "center",
                        whiteSpace: "Wrap",
                        minWidth: "130px",
                      }}
                    >
                      {data.criteria?.nationality || "NA"}
                    </TableCell>

                    <TableCell
                      sx={{
                        //fontSize: "9px",
                        textAlign: "center",
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: (theme) => {
                          if (data.status === "System cleared") {
                            return theme.palette.success.main; // Green color for success
                          } else if (data.status === "Not cleared") {
                            return theme.palette.error.main; // Red color for error
                          } else if (data.status.includes("Cleared (reason)")) {
                            return "blue"; // Blue color for specific "Cleared (reason)" status
                          }
                          return theme.palette.grey[500]; // Default color if none of the conditions are met
                        },
                        // backgroundColor: (theme) =>
                        //   data.status === "Cleared" ||
                        //   data.status === "System cleared"
                        //     ? theme.palette.success.main
                        //     : theme.palette.error.main,
                      }}
                    >
                      {data.status}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>{" "}
            {/* {JSON.stringify(data)} */}
            <Box margin={"5px"}>
              {" "}
              <Typography color="#D70000" fontWeight={"bold"}>
                Actions
              </Typography>
              {data.status === "Cleared (reason)" && (
                <span>
                  {" "}
                  <Typography component="span" fontWeight="bold">
                    Clearance Notes:{" "}
                  </Typography>
                  <Typography
                    component="span"
                    fontSize="9px"
                    fontWeight="normal"
                  >
                    {data.notes}
                  </Typography>
                  <br />
                </span>
              )}{" "}
              {/* <b>PEP Status: </b>
              <Typography
                display="inline"
                color={
                  checkForPEP(data) === "Potential PEP" ? "#D70000" : "black"
                }
              >
                {checkForPEP(data)}
              </Typography>
              <br></br>
              <b>Funds Freeze Report </b>{" "}
              {data.ffr ? (
                <Typography display="inline" color={"#D70000"}>
                  Required{" "}
                </Typography>
              ) : (
                <Typography display="inline" color="#19A309">
                  {" "}
                  Not required
                </Typography>
              )}
              <span>
                <br></br>
                <Typography display="inline" fontWeight={"bold"}>
                  {" "}
                  Partial name Match Report{" "}
                </Typography>
                {data.nmr ? (
                  <Typography display="inline" color={"#D70000"}>
                    Required{" "}
                  </Typography>
                ) : (
                  <Typography display="inline" color="#19A309">
                    {" "}
                    Not required
                  </Typography>
                )}
              </span> */}
              <Grid item xs={6}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sx={{ borderBottom: "1px dotted lightGrey" }}
                  >
                    <b>PEP Status:</b>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ borderBottom: "1px dotted lightGrey" }}
                  >
                    <Typography
                      display="inline"
                      color={
                        checkForPEP(data) === "Potential PEP"
                          ? "#D70000"
                          : "black"
                      }
                    >
                      {checkForPEP(data)}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ borderBottom: "1px dotted lightGrey" }}
                  >
                    <b>Funds Freeze Report</b>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ borderBottom: "1px dotted lightGrey" }}
                  >
                    {data.ffr ? (
                      <Typography display="inline" color={"#D70000"}>
                        Required
                      </Typography>
                    ) : (
                      <Typography display="inline" color="#19A309">
                        Not required
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ borderBottom: "1px dotted lightGrey" }}
                  >
                    <b>Partial Name Match Report</b>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ borderBottom: "1px dotted lightGrey" }}
                  >
                    {data.nmr ? (
                      <Typography display="inline" color={"#D70000"}>
                        Required
                      </Typography>
                    ) : (
                      <Typography display="inline" color="#19A309">
                        Not required
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            className="overlap"
            sx={{
              height: "21px",
              left: "205px",
              position: "absolute",
              top: "153px",
              width: "auto",
              color: "blue",
            }}
          ></Box>
          {data.status != "System cleared" && (
            <Box
              className="rectangle-2"
              sx={{
                height: "auto",
                left: "0px",
                position: "absolute",
                top: "428px",
                width: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
              }}
            >
              {" "}
              <TableContainer>
                <br />
                {<h4> Result Set</h4>}
                <Table sx={{}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Full Name
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        DoB/Inc. Date
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Nationality
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Listing
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.resultset?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontSize: "8px" }}>
                          {row?.caption}
                        </TableCell>
                        <TableCell
                          sx={{ whiteSpace: "nowrap", fontSize: "8px" }}
                        >
                          {row?.schema === "Organization" ||
                          row?.schema === "Company"
                            ? row?.properties.incorporationDate
                            : row?.properties.birthDate?.map((bd, subIndex) => (
                                <div key={subIndex}>{bd}</div>
                              ))}
                        </TableCell>
                        <TableCell
                          sx={{ whiteSpace: "nowrap", fontSize: "8px" }}
                        >
                          {row?.properties.nationality?.map(
                            (code, subIndex) => (
                              <div key={subIndex}>
                                {getCountryNameFromISO2(code.toUpperCase())}
                              </div>
                            )
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            minWidth: "50px",
                            fontSize: "8px",
                          }}
                        >
                          {" "}
                          {row?.datasets?.map((dataset, datasetIndex) => (
                            <div key={datasetIndex}>
                              {/* {values.find((v) => v.abbreviation === dataset)
                                ?.fullForm || dataset} */}
                              {values.find((v) => v.abbreviation === dataset)
                                ?.fullForm ===
                              "EveryPolitician Open Data Project"
                                ? "Politically Exposed Person"
                                : values.find((v) => v.abbreviation === dataset)
                                    ?.fullForm || dataset}
                            </div>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <br />
                <br />
                <br />
                <br />
                <Box
                  className="overlap-3"
                  sx={{
                    //backgroundImage: "url(./rectangle-5.svg)",
                    backgroundSize: "100% 100%",
                    //backgroundColor: "grey",
                    height: "196px",
                    width: "100vw",
                    left: "20px",
                    position: "relative",
                    // top: "900px",
                    width: "497px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <Box sx={{ width: "20%", textAlign: "center" }}>
                      <Box
                        sx={{
                          borderBottom: "1px solid #000",
                          marginBottom: "10px",
                        }}
                      />
                      <Typography variant="body1">Name</Typography>
                    </Box>
                    <Box sx={{ width: "20%", textAlign: "center" }}>
                      <Box
                        sx={{
                          borderBottom: "1px solid #000",
                          marginBottom: "10px",
                        }}
                      />
                      <Typography variant="body1">Designation</Typography>
                    </Box>
                    <Box sx={{ width: "20%", textAlign: "center" }}>
                      <Box
                        sx={{
                          borderBottom: "1px solid #000",
                          marginBottom: "10px",
                        }}
                      />
                      <Typography variant="body1">Signature</Typography>
                    </Box>
                    <Box sx={{ width: "20%", textAlign: "center" }}>
                      <Box
                        sx={{
                          borderBottom: "1px solid #000",
                          marginBottom: "10px",
                        }}
                      />
                      <Typography variant="body1">Date</Typography>
                    </Box>
                  </Box>
                </Box>
                {data.resultset?.length >= 5 && (
                  <Box
                    className="text-wrapper-8"
                    sx={{
                      color: "#000000",
                      fontFamily: '"Inter-Regular", Helvetica',
                      fontSize: "12px",
                      fontWeight: "400",
                      left: "40vw",
                      letterSpacing: "0",
                      lineHeight: "normal",
                      position: "relative", // Change this line
                      bottom: "20px",
                      // justifyContent: "center",
                      // top: "96vh",
                      width: "341px",
                    }}
                  >
                    Printed on: {new Date().toLocaleString()}
                  </Box>
                )}
              </TableContainer>{" "}
            </Box>
          )}

          <Box
            className="rectangle-3"
            sx={{
              height: "63px",
              left: "380px",
              position: "absolute",
              top: "11px",
              width: "280px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              padding: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                Company Name:
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontSize: "9px" }}
              >
                {/* {"ARA JEWELLEY LLC"} */}
                {user.name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "", gap: "6px" }}>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                Address:
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontSize: "9px" }}
              >
                {/* {"Office # 402, Dubai"}
                {"Diamond Plaza, Gold Souk, Deira, Dubai "} */}
                {user.address}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                FIU Org ID:
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontSize: "12px" }}
              >
                {user.fiuOrgID}
              </Typography>
            </Box>
          </Box>

          <Box
            className="rectangle-4"
            sx={{
              //backgroundColor: "#ff1809",
              height: "55px",
              left: "232px",
              position: "absolute",
              top: "0px",
              width: "152px",
            }}
          >
            <img
              className="logo"
              alt="Logo"
              src={`https://amldiligence.com/${user.logo || "logo.png"}`}
              style={{
                height: "100px",
                left: "0",
                objectFit: "contain",
                position: "absolute",
                top: "0px",
                width: "100px",
              }}
            />
          </Box>
          <Box
            className="overlap-2"
            sx={{
              // backgroundColor: "#f7f7f7",
              height: "63px",
              left: "0px",
              position: "absolute",
              top: "11px",
              width: "182px",
            }}
          >
            <Box
              className="text-wrapper-3"
              sx={{
                color: "#000000",
                fontFamily: "lucida-console",
                fontSize: "12px",
                fontWeight: "100",
                left: "0px",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "32px",
              }}
            >
              www.amldiligence.com
            </Box>
            <p
              className="p"
              style={{
                color: "#000000",
                fontFamily: '"Inter-Regular", Helvetica',
                fontSize: "9px",
                fontWeight: "400",
                left: "0",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "35px",
              }}
            >
              {/* Consulted by MHR Chartered Acoountants */}
            </p>
            <img
              className="logo"
              alt="Logo"
              src="logo.png"
              style={{
                height: "24px",
                left: "0",
                objectFit: "cover",
                position: "absolute",
                top: "8px",
                width: "116px",
              }}
            />
          </Box>
          {data.status == "System cleared" && (
            <Box
              className="overlap-3"
              sx={{
                //backgroundImage: "url(./rectangle-5.svg)",
                backgroundSize: "100% 100%",
                //backgroundColor: "grey",
                height: "196px",
                width: "100vw",
                left: "20px",
                position: "relative",
                top: "900px",
                width: "497px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ width: "20%", textAlign: "center" }}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography variant="body1">Name</Typography>
                </Box>
                <Box sx={{ width: "20%", textAlign: "center" }}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography variant="body1">Designation</Typography>
                </Box>
                <Box sx={{ width: "20%", textAlign: "center" }}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography variant="body1">Signature</Typography>
                </Box>
                <Box sx={{ width: "20%", textAlign: "center" }}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography variant="body1">Date</Typography>
                </Box>
              </Box>
            </Box>
          )}

          {data.resultset?.length <= 5 && (
            <Box
              className="text-wrapper-8"
              sx={{
                color: "#000000",
                fontFamily: '"Inter-Regular", Helvetica',
                fontSize: "12px",
                fontWeight: "400",
                left: "40vw",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "fixed", // Change this line
                bottom: "20px",
                top: "96vh",
                width: "341px",
              }}
            >
              Printed on: {new Date().toLocaleString()}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export default PrintTable;

function getCountryNameFromISO2(iso2) {
  const countries = countryListAlpha2;

  return countries[iso2] || iso2; // returns iso2 if the country name isn't found in the map
}

function checkForPEP(originalRow) {
  const checkStrings = ["wd_peps", "everypolitician", "us_cia_world_leaders"];

  try {
    if (originalRow?.resultset && Array.isArray(originalRow?.resultset)) {
      for (let result of originalRow.resultset) {
        if (result.datasets && Array.isArray(result.datasets)) {
          for (let dataset of result.datasets) {
            if (
              checkStrings.includes(dataset) ||
              dataset.properties.topics.includes("role.pep")
            ) {
              return "Potential PEP";
            }
          }
        }
      }
    }
  } catch (error) {
    console.error("An error occurred while processing the data:", error);
  }

  return "No";
}
