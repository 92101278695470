import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Icon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  BusinessOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
  RestorePageOutlined,
  SearchIcon,
  Search,
} from "@mui/icons-material";
import StorageIcon from "@mui/icons-material/Storage";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CodeIcon from "@mui/icons-material/Code";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import PasswordChangeDialog from "./PasswordChangeDialog"; // Ensure the correct path
import FlexBetween from "./FlexBetween";

const SideBar = ({ user, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleClick = (text) => {
    let lcText = text.toLowerCase();
    if (lcText === "password change") {
      setIsPasswordDialogOpen(true); // Open the dialog
      return;
    }
    if (lcText === "instant screen") {
      lcText = "search";
      //navigate(/search); // Uncomment this line if it's supposed to navigate immediately
    }
    navigate(`/${lcText.replace(/\s+/g, "")}`);
    setActive(lcText);
  };

  const handlePasswordChange = (newPassword) => {
    const email = user.email; // Ensure `user` object has `email` property
    fetch("https://amldiligence.com/api/general/cp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, newPassword }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Password changed successfully:", data);
        setIsPasswordDialogOpen(false);
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        // setError("Failed to change password");
      });
  };

  const renderMenuItem = (item) => {
    if (item.subItems && item.subItems.length > 0) {
      return (
        <SubMenu key={item.text} label={item.text} icon={item.icon}>
          {item.subItems.map((subItem) => (
            <MenuItem key={subItem.text} icon={subItem.icon}>
              {subItem.text}
            </MenuItem>
          ))}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem
          key={item.text}
          icon={item.icon}
          onClick={() => handleClick(item.text)}
        >
          {item.text}
        </MenuItem>
      );
    }
  };

  return (
    <>
      {isSidebarOpen && isNonMobile && (
        <Sidebar height={"100vh"} backgroundColor="#F0F0F0">
          <Box m="1.5rem 2rem 2rem 3rem">
            <FlexBetween color={theme.palette.secondary.main}>
              <Box display="flex" alignItems="center" gap="0.5rem">
                <img
                  className="logo"
                  alt="Logo"
                  src="logo.png"
                  style={{
                    height: "28px",
                    objectFit: "contain",
                    position: "relative",
                    width: "180px",
                  }}
                />
              </Box>

              {!isNonMobile && (
                <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                  <ChevronLeft />
                </IconButton>
              )}
            </FlexBetween>
          </Box>
          <br /> <br />
          <Menu iconShape="square" height={"100vh"}>
            <MenuItem
              fontStyle={"Bold"}
              icon={<HomeOutlined />}
              onClick={() => handleClick("Dashboard")}
            >
              Dashboard
            </MenuItem>
            <SubMenu label="Records" icon={<StorageIcon />}>
              <MenuItem
                onClick={() => handleClick("Entities")}
                icon={<BusinessOutlined />}
              >
                Entities
              </MenuItem>
              <MenuItem
                onClick={() => handleClick("Expired Entities")}
                icon={<Icon />}
              >
                Expired Entities
              </MenuItem>
              <MenuItem
                onClick={() => handleClick("Customers")}
                icon={<Groups2Outlined />}
              >
                Customers
              </MenuItem>
              <MenuItem
                onClick={() => handleClick("Expired Customers")}
                icon={<Icon />}
              >
                Expired Customers
              </MenuItem>
            </SubMenu>
            <SubMenu
              label="Screening and Logs"
              icon={<YoutubeSearchedForIcon />}
            >
              <MenuItem
                onClick={() => handleClick("Instant Screen")}
                icon={<Search />}
              >
                Instant Screen
              </MenuItem>
              <MenuItem
                onClick={() => handleClick("Screening Log")}
                icon={<RestorePageOutlined />}
              >
                Screening Log
              </MenuItem>
            </SubMenu>
            <SubMenu label="Transaction Monitoring" icon={<ReceiptLongIcon />}>
              {" "}
            </SubMenu>
            <SubMenu label="Reportings (goAML)" icon={<CodeIcon />}>
              <MenuItem
                onClick={() =>
                  handleClick(
                    "Dealers In Precious Metals and Stones Reports (DPMSR)"
                  )
                }
              >
                Dealers In Precious Metals
                <br /> and Stones Reports (DPMSR)
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleClick("Suspicious Transactions Reports (STR)")
                }
              >
                Suspicious Transactions <br />
                Reports (STR){" "}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleClick("Partial Name Match Reports (PNMR)            ")
                }
              >
                Partial Name Match
                <br /> Reports (PNMR)
              </MenuItem>
              <MenuItem
                onClick={() => handleClick("Funds Freeze Reports (FFR)")}
              >
                Funds Freeze Reports (FFR)
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleClick(" High Risk Country Reports (HRCR)           ")
                }
              >
                High Risk Country
                <br /> Reports (HRCR)
              </MenuItem>
            </SubMenu>
            <SubMenu label="RBA Definitions" icon={<AssessmentIcon />}>
              <MenuItem onClick={() => handleClick("countriesRisk")}>
                Countries Risk
              </MenuItem>{" "}
              <MenuItem onClick={() => handleClick("fundSource")}>
                Source Of Funds Risk
              </MenuItem>{" "}
              <MenuItem onClick={() => handleClick("idType")}>
                ID Type Risk
              </MenuItem>{" "}
              <MenuItem onClick={() => handleClick("licType")}>
                Lic Type Risk
              </MenuItem>
              <MenuItem onClick={() => handleClick("resType")}>
                Residential Status Risk
              </MenuItem>
              <MenuItem onClick={() => handleClick("businessActivity")}>
                Business Activity Risk
              </MenuItem>
            </SubMenu>
            <SubMenu label="Management" icon={<ManageAccountsIcon />}>
              {" "}
              {/* <MenuItem
                onClick={() => handleClick("Periodic sanction Screening")}
              >
                Periodic sanction Screening{" "}
              </MenuItem> */}
              <MenuItem onClick={() => handleClick("Password Change")}>
                Password Change{" "}
              </MenuItem>
            </SubMenu>
          </Menu>
        </Sidebar>
      )}
      <PasswordChangeDialog
        open={isPasswordDialogOpen}
        onClose={() => setIsPasswordDialogOpen(false)}
        onChangePassword={handlePasswordChange}
      />
    </>
  );
};

export default SideBar;
