import { React, useMemo, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  useTheme,
  Typography,
  Grid,
} from "@mui/material";
import {
  useGetIndividualsQuery,
  usePostIndividualsMutation,
  useDeleteIndividualsMutation,
} from "state/api";
import * as XLSX from "xlsx";
import CrpI from "components/CrpI";
import Header from "components/Header";
import { MaterialReactTable } from "material-react-table";
import AddCustomer from "components/addCustomer";
import moment from "moment";
import EditCustomer from "components/editCustomer";

const Customers = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetIndividualsQuery();
  const [deleteIndividual] = useDeleteIndividualsMutation();
  const [cRpdialogOpen, setcRpDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setaddDialogOpen] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [currentRowData, setCurrentRowData] = useState(null);
  // const exportToExcel = () => {
  //   const wb = XLSX.utils.book_new();

  //   const capitalizeInitials = (str) => {
  //     return str
  //       .split(" ")
  //       .map(
  //         (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  //       )
  //       .join(" ");
  //   };

  //   const flattenObject = (obj, parent = "", res = {}) => {
  //     for (let key in obj) {
  //       if (obj.hasOwnProperty(key)) {
  //         const propName = parent ? `${parent}.${key}` : key;
  //         if (typeof obj[key] === "object" && obj[key] !== null) {
  //           flattenObject(obj[key], propName, res);
  //         } else {
  //           res[propName] = obj[key];
  //         }
  //       }
  //     }
  //     return res;
  //   };

  //   const flattenedData = data.map((item) => flattenObject(item));
  //   const ws1 = XLSX.utils.json_to_sheet(flattenedData);

  //   const formatFirstRow = (ws) => {
  //     const range = XLSX.utils.decode_range(ws["!ref"]);
  //     for (let col = range.s.c; col <= range.e.c; ++col) {
  //       const address = XLSX.utils.encode_cell({ r: 0, c: col });
  //       if (!ws[address]) continue;
  //       ws[address].v = capitalizeInitials(ws[address].v);
  //       ws[address].s = {
  //         font: {
  //           bold: true,
  //         },
  //         fill: {
  //           patternType: "solid",
  //           fgColor: { rgb: "FFFF00" },
  //           bgColor: { rgb: "FFFF00" },
  //         },
  //       };
  //     }
  //   };

  //   formatFirstRow(ws1);

  //   XLSX.utils.book_append_sheet(wb, ws1, "LegalPersons");

  //   XLSX.writeFile(wb, "customers.xlsx");
  // };

  // const exportToExcel = () => {
  //   const wb = XLSX.utils.book_new();

  //   const capitalizeInitials = (str) => {
  //     return str
  //       .split(" ")
  //       .map(
  //         (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  //       )
  //       .join(" ");
  //   };

  //   const flattenObject = (obj, parent = "", res = {}) => {
  //     for (let key in obj) {
  //       if (obj.hasOwnProperty(key)) {
  //         const propName = parent ? `${parent}.${key}` : key;
  //         if (
  //           typeof obj[key] === "object" &&
  //           obj[key] !== null &&
  //           !Array.isArray(obj[key])
  //         ) {
  //           // Recursively flatten the nested object
  //           flattenObject(obj[key], propName, res);
  //         } else {
  //           // Assign the value to the result object
  //           res[propName] = obj[key];
  //         }
  //       }
  //     }
  //     return res;
  //   };

  //   // Map over the data to flatten each object
  //   const flattenedData = data.map((item) => flattenObject(item));

  //   const ws1 = XLSX.utils.json_to_sheet(flattenedData);

  //   const formatFirstRow = (ws) => {
  //     const range = XLSX.utils.decode_range(ws["!ref"]);
  //     for (let col = range.s.c; col <= range.e.c; ++col) {
  //       const address = XLSX.utils.encode_cell({ r: 0, c: col });
  //       if (!ws[address]) continue;
  //       ws[address].v = capitalizeInitials(ws[address].v);
  //       ws[address].s = {
  //         font: {
  //           bold: true,
  //         },
  //         fill: {
  //           patternType: "solid",
  //           fgColor: { rgb: "FFFF00" },
  //           bgColor: { rgb: "FFFF00" },
  //         },
  //       };
  //     }
  //   };

  //   formatFirstRow(ws1);

  //   XLSX.utils.book_append_sheet(wb, ws1, "LegalPersons");

  //   XLSX.writeFile(wb, "customers.xlsx");
  // };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    const capitalizeInitials = (str) => {
      return str
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    const flattenObject = (obj, parent = "", res = {}) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const propName = parent ? `${parent}.${key}` : key;
          if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
          ) {
            flattenObject(obj[key], propName, res);
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };

    const excludeFields = ["_id", "__v", "tfsScreening", "company"];

    const flattenedData = data.map((item) => {
      const flatItem = flattenObject(item);
      // Exclude specified fields
      excludeFields.forEach((field) => delete flatItem[field]);
      return flatItem;
    });

    // Arrange columns in the desired order
    const orderedKeys = [
      "fullLegalName",
      "nationality",
      "idDoc.type",
      "idDoc.issueDate",
      "idDoc.number",
    ];
    const orderedData = flattenedData.map((item) => {
      const orderedItem = {};
      orderedKeys.forEach((key) => {
        if (item.hasOwnProperty(key)) {
          orderedItem[key] = item[key];
        }
      });
      // Include any other remaining keys that weren't explicitly ordered
      Object.keys(item).forEach((key) => {
        if (!orderedItem.hasOwnProperty(key)) {
          orderedItem[key] = item[key];
        }
      });
      return orderedItem;
    });

    const ws1 = XLSX.utils.json_to_sheet(orderedData);

    const formatFirstRow = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let col = range.s.c; col <= range.e.c; ++col) {
        const address = XLSX.utils.encode_cell({ r: 0, c: col });
        if (!ws[address]) continue;
        ws[address].v = capitalizeInitials(ws[address].v);
        ws[address].s = {
          font: {
            bold: true,
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFF00" },
            bgColor: { rgb: "FFFF00" },
          },
        };
      }
    };

    formatFirstRow(ws1);

    XLSX.utils.book_append_sheet(wb, ws1, "LegalPersons");

    XLSX.writeFile(wb, "customers.xlsx");
  };

  const handleCrpClick = (row) => {
    setCurrentRowId(row._id);
    setCurrentRowData(row);
    setcRpDialogOpen(true);
  };
  const handleCloseCrp = () => {
    setcRpDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenAddDialog = () => {
    setaddDialogOpen(true);
  };
  const handleCloseAddDialog = () => {
    setaddDialogOpen(false);
  };
  console.log("Individuals data", data);

  const columns =
    //column definitions...
    useMemo(
      () => [
        {
          accessorKey: "fullLegalName", //simple recommended way to define a column
          header: "Full Name",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "riskLevel",
          header: "Risk Profile",
          Cell: ({ cell, row }) =>
            cell.getValue() === "High Risk" ? (
              <Typography color={"red"} fontWeight={"bold"}>
                High Risk
              </Typography>
            ) : cell.getValue() === "Medium Risk" ? (
              <Typography color={"olive"} fontWeight={"bold"}>
                Medium Risk
              </Typography>
            ) : cell.getValue() === "Medium High Risk" ? (
              <Typography color={"darkviolet"} fontWeight={"bold"}>
                Medium High Risk
              </Typography>
            ) : cell.getValue() === "Low Risk" ? (
              <Typography color={"darlgreen"} fontWeight={"bold"}>
                Low Risk{" "}
              </Typography>
            ) : (
              <></>
            ),
        },
        {
          accessorKey: "idDoc.type", //simple recommended way to define a column
          header: "DocType",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "idDoc.number", //simple recommended way to define a column
          header: "Doc No.",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "idDoc.issueCountry", //simple recommended way to define a column
          header: "Issue Country",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },

        // {
        //   accessorFn: (originalRow) => {
        //     const dateString = originalRow.idDoc.issueDate;
        //     const date = new Date();
        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, "0");
        //     const day = String(date.getDate()).padStart(2, "0");
        //     const formattedDate = `${year}-${month}-${day}`;
        //     function parseDateTime(s) {
        //       var b = s.split(/\D/);
        //       return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        //     }

        //     return moment(dateString).utc().format("DD-MM-YYYY");
        //   },
        //   header: "Issue Date",
        //   type: "date",
        //   muiTableBodyCellEditTextFieldProps: {
        //     type: "date",
        //   },
        //   muiTableHeadCellFilterTextFieldProps: {
        //     type: "date",
        //   },
        //   sortingFn: "datetime",
        //   dateSetting: { locale: "en-CA" },
        // },
        // {
        //   accessorFn: (originalRow) => {
        //     const dateString = originalRow.idDoc.expiryDate;
        //     const date = new Date();
        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, "0");
        //     const day = String(date.getDate()).padStart(2, "0");
        //     const formattedDate = `${year}-${month}-${day}`;
        //     function parseDateTime(s) {
        //       var b = s.split(/\D/);
        //       return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        //     }

        //     return moment(dateString).utc().format("DD-MM-YYYY");
        //   },
        //   // accessorKey: `idDoc.issueDate`, //simple recommended way to define a column
        //   header: "Expiry Date",
        //   type: "date",
        //   muiTableBodyCellEditTextFieldProps: {
        //     type: "date",
        //     // variant: 'outlined',
        //   },
        //   muiTableHeadCellFilterTextFieldProps: {
        //     type: "date",
        //   },
        //   sortingFn: "datetime",
        //   dateSetting: { locale: "en-gb" },
        // },
        // {
        //   accessorFn: (originalRow) => {
        //     const dateString = originalRow.doB;
        //     const date = new Date();
        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, "0");
        //     const day = String(date.getDate()).padStart(2, "0");
        //     const formattedDate = `${year}-${month}-${day}`;
        //     function parseDateTime(s) {
        //       var b = s.split(/\D/);
        //       return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        //     }

        //     return moment(dateString).utc().format("DD-MM-YYYY");
        //   },
        //   header: "Date of Birth",
        //   type: "date",
        //   muiTableBodyCellEditTextFieldProps: {
        //     type: "date",
        //   },
        //   muiTableHeadCellFilterTextFieldProps: {
        //     type: "date",
        //   },
        //   sortingFn: "datetime",
        //   dateSetting: { locale: "en-CA" },
        // },
        {
          accessorKey: "nationality", //simple recommended way to define a column
          header: "Nationality",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        {
          accessorKey: "residentialStatus", //simple recommended way to define a column
          header: "Residential Status",
          //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        },
        // {
        //   accessorKey: "contactInfo.type", //simple recommended way to define a column
        //   header: "Contact Tyoe",
        //   //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        // },
        // {
        //   accessorKey: "contactInfo.country", //simple recommended way to define a column
        //   header: "Contact Country",
        //   //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        // },
        // {
        //   accessorKey: "contactInfo.number", //simple recommended way to define a column
        //   header: "Number",
        //   //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        // },
        // {
        //   accessorKey: "pepStatus", //simple recommended way to define a column
        //   header: "PEP Status",
        //   Cell: ({ cell }) =>
        //     cell.getValue() === true ? (
        //       <Typography>Found</Typography>
        //     ) : (
        //       <Typography>Not Found</Typography>
        //     ),
        //   enableEditing: false,

        //   //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        // },
        // {
        //   accessorKey: "tfsScreening", //simple recommended way to define a column
        //   header: "TF Screening",
        //   enableEditing: false,

        //   //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        // },
      ],
      []
    );
  //if (data) {
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CUSTOMERS" subtitle="List of Customers" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {" "}
        <MaterialReactTable
          //columns={columns}
          data={data || []}
          enableColumnDragging={false}
          enableColumnOrdering
          enableGlobalFilter={true}
          enableRowActions
          layoutMode="grid"
          enableColumnActions={false}
          columns={columns.map((c) => ({ ...c, size: 110, minSize: 10 }))}
          state={{
            isLoading: isLoading,
          }}
          renderTopToolbarCustomActions={() => (
            // <div>
            //   {" "}
            //   <Button
            //     variant="outlined"
            //     color="secondary"
            //     onClick={handleOpenAddDialog}
            //     //onClick={handleOpenAddDialog}
            //   >
            //     Add New
            //   </Button>
            //   <AddCustomer
            //     open={addDialogOpen}
            //     onClose={handleCloseAddDialog}
            //   />
            //   <Button> Export</Button>
            // </div>
            <Box style={{ width: "900px" }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleOpenAddDialog}
                  >
                    Add New
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={exportToExcel}
                  >
                    Export
                  </Button>
                </Grid>
              </Grid>
              <AddCustomer
                open={addDialogOpen}
                onClose={handleCloseAddDialog}
              />
            </Box>
          )}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key={row.id + 1}
              onClick={() => {
                handleOpenDialog();
                console.log(row.original);
                // closeMenu();
              }}
            >
              Edit
            </MenuItem>,
            <MenuItem
              key={row.id + 23}
              onClick={() => {
                // handleOpenDialog();
                handleCrpClick(row.original);
                // console.log(row.original);
                // closeMenu();
              }}
            >
              CRP
            </MenuItem>,
            <EditCustomer
              key={row.original._id}
              open={dialogOpen}
              onClose={handleCloseDialog}
              data={row.original}
            />,

            <MenuItem
              key={row.id + 2}
              onClick={() => {
                deleteIndividual(row.original._id);
                closeMenu();
              }}
            >
              Delete
            </MenuItem>,
          ]}
          muiTablePaperProps={{
            sx: {
              maxWidth: "auto",
              m: "auto",
            },
          }}
        />
      </Box>
      <CrpI
        open={cRpdialogOpen}
        onClose={handleCloseCrp}
        data={currentRowData}
        id={currentRowId}
      />
    </Box>
  );
  // } else
  //   return (
  //     <div>
  //       <Typography>Loading</Typography>
  //     </div>
  //   );
};

export default Customers;

const data1 = [
  {
    _id: "64887b6515453eca7d8b0e0d",
    fullLegalName: "Joann Hamilton",
    idDoc: {
      type: "Passport",
      issueCountry: "Bangladesh",
      number: 7695666185,
      issueDate: "2022-07-06T03:51:02 -05:00",
      expiryDate: "2023-04-19T05:23:32 -05:00",
    },
    doB: "2000-11-21T05:22:48 -05:00",
    nationality: "Switzerland",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Congo",
      number: "(822) 449-3681",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Consectetur nostrud duis aliqua nostrud dolor amet sint occaecat qui qui.",
    addedDate: "2022-05-08T09:31:09 -05:00",
    lastModified: "2023-03-07T01:40:36 -05:00",
  },
  {
    _id: "64887b65456c5a8381c1bdb1",
    fullLegalName: "Barker Blankenship",
    idDoc: {
      type: "ID",
      issueCountry: "Algeria",
      number: 2999221886,
      issueDate: "2021-09-08T10:00:42 -05:00",
      expiryDate: "2023-01-04T08:54:12 -05:00",
    },
    doB: "2012-04-17T11:30:00 -05:00",
    nationality: "Honduras",
    residentialStatus: "National",
    contactInfo: {
      type: "telephone",
      country: "Cook Islands",
      number: "(934) 471-3652",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Tempor duis eiusmod fugiat elit id.",
    addedDate: "2022-09-18T05:31:27 -05:00",
    lastModified: "2023-04-11T06:24:32 -05:00",
  },
  {
    _id: "64887b659c1c4d31ec32308b",
    fullLegalName: "Joyner Rosario",
    idDoc: {
      type: "ID",
      issueCountry: "Morocco",
      number: 1895533578,
      issueDate: "2022-01-08T04:36:55 -05:00",
      expiryDate: "2023-05-27T01:42:12 -05:00",
    },
    doB: "2000-10-12T02:23:37 -05:00",
    nationality: "Comoros",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Ireland",
      number: "(885) 420-2705",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes:
      "Exercitation officia fugiat dolor magna mollit esse pariatur consectetur elit laborum.",
    addedDate: "2022-07-19T05:40:28 -05:00",
    lastModified: "2023-04-22T10:06:25 -05:00",
  },
  {
    _id: "64887b653f0b9d8fc3793bc4",
    fullLegalName: "Mollie Rodgers",
    idDoc: {
      type: "ID",
      issueCountry: "Slovenia",
      number: 2840548237,
      issueDate: "2021-03-13T04:59:19 -05:00",
      expiryDate: "2023-05-07T08:43:41 -05:00",
    },
    doB: "1953-01-26T12:14:17 -05:00",
    nationality: "Equatorial Guinea",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Poland",
      number: "(895) 431-3749",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Proident veniam esse deserunt aliquip amet dolore velit labore.",
    addedDate: "2021-04-13T09:47:09 -05:00",
    lastModified: "2023-04-13T09:23:36 -05:00",
  },
  {
    _id: "64887b65861b5ac86e33ecac",
    fullLegalName: "Crane Mccoy",
    idDoc: {
      type: "ID",
      issueCountry: "Monaco",
      number: 8330605898,
      issueDate: "2021-05-24T01:32:02 -05:00",
      expiryDate: "2023-03-07T06:24:49 -05:00",
    },
    doB: "2004-12-03T12:12:57 -05:00",
    nationality: "Uruguay",
    residentialStatus: "National",
    contactInfo: {
      type: "telephone",
      country: "Jordan",
      number: "(811) 435-3948",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes: "Culpa consequat est exercitation elit.",
    addedDate: "2021-06-22T08:19:57 -05:00",
    lastModified: "2023-03-29T07:17:00 -05:00",
  },
  {
    _id: "64887b65531c7e4cbebf78ab",
    fullLegalName: "Jeannine Atkins",
    idDoc: {
      type: "Passport",
      issueCountry: "United Kingdom",
      number: 9094249890,
      issueDate: "2021-06-10T03:46:14 -05:00",
      expiryDate: "2023-01-18T07:38:51 -05:00",
    },
    doB: "2017-05-30T10:49:45 -05:00",
    nationality: "Malta",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "telephone",
      country: "France",
      number: "(815) 505-3122",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Officia non nisi incididunt adipisicing fugiat tempor cupidatat in sit consectetur eu culpa elit.",
    addedDate: "2021-09-08T12:31:16 -05:00",
    lastModified: "2023-05-15T06:18:49 -05:00",
  },
  {
    _id: "64887b654dc3421d77a0ccc2",
    fullLegalName: "Aisha Valenzuela",
    idDoc: {
      type: "Passport",
      issueCountry: "Gambia",
      number: 8884579282,
      issueDate: "2022-02-06T05:39:29 -05:00",
      expiryDate: "2023-04-30T10:10:07 -05:00",
    },
    doB: "2009-07-27T04:29:56 -06:00",
    nationality: "Sweden",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "telephone",
      country: "Cambodia",
      number: "(811) 599-2357",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Sint exercitation nostrud anim sit consequat irure ex quis.",
    addedDate: "2023-05-31T08:18:27 -05:00",
    lastModified: "2023-04-07T05:56:26 -05:00",
  },
  {
    _id: "64887b65add7c145fa797352",
    fullLegalName: "Olson Roberson",
    idDoc: {
      type: "ID",
      issueCountry: "China",
      number: 9655651939,
      issueDate: "2022-01-28T12:27:13 -05:00",
      expiryDate: "2023-01-20T04:02:16 -05:00",
    },
    doB: "1964-07-29T06:07:29 -05:00",
    nationality: "Latvia",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "telephone",
      country: "Costa Rica",
      number: "(815) 475-2293",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Pariatur magna irure irure quis commodo veniam sit aliquip id fugiat cupidatat minim voluptate.",
    addedDate: "2021-03-31T10:49:32 -05:00",
    lastModified: "2023-04-12T12:42:33 -05:00",
  },
  {
    _id: "64887b65bb9b14e9acf7f356",
    fullLegalName: "Day Fernandez",
    idDoc: {
      type: "Passport",
      issueCountry: "Kenya",
      number: 5717110115,
      issueDate: "2022-02-18T02:41:56 -05:00",
      expiryDate: "2023-04-14T05:41:54 -05:00",
    },
    doB: "1949-07-21T12:22:40 -06:-30",
    nationality: "Greenland",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "telephone",
      country: "Zaire",
      number: "(821) 487-3630",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Ut nulla irure aute elit.",
    addedDate: "2023-01-09T02:14:22 -05:00",
    lastModified: "2023-05-12T03:38:02 -05:00",
  },
  {
    _id: "64887b65b1e8f22c02c165d8",
    fullLegalName: "Monroe Santiago",
    idDoc: {
      type: "ID",
      issueCountry: "Cayman Islands",
      number: 1859143176,
      issueDate: "2022-09-20T12:01:51 -05:00",
      expiryDate: "2023-03-25T10:06:15 -05:00",
    },
    doB: "2013-02-03T11:15:13 -05:00",
    nationality: "Bosnia and Herzegovina",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Guadeloupe",
      number: "(826) 557-2474",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes: "Aliquip ad anim ut dolor non.",
    addedDate: "2021-04-13T01:02:02 -05:00",
    lastModified: "2023-05-01T03:51:06 -05:00",
  },
  {
    _id: "64887b65f261a899cf3d22e8",
    fullLegalName: "Cabrera Gill",
    idDoc: {
      type: "ID",
      issueCountry: "Mexico",
      number: 1886955605,
      issueDate: "2023-05-16T11:43:01 -05:00",
      expiryDate: "2023-03-02T08:08:26 -05:00",
    },
    doB: "1959-02-05T11:31:41 -05:00",
    nationality: "Iran",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Belgium",
      number: "(994) 514-2462",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes:
      "Ea enim aliqua elit ea reprehenderit mollit consectetur elit aute velit eu.",
    addedDate: "2022-06-19T11:05:06 -05:00",
    lastModified: "2023-04-05T05:12:44 -05:00",
  },
  {
    _id: "64887b65d194afc3c275cfab",
    fullLegalName: "Georgette Morrison",
    idDoc: {
      type: "Passport",
      issueCountry: "Norfolk Island",
      number: 7000170438,
      issueDate: "2021-03-01T09:54:06 -05:00",
      expiryDate: "2023-05-18T09:10:24 -05:00",
    },
    doB: "1970-11-10T07:06:36 -05:00",
    nationality: "Nigeria",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Macedonia",
      number: "(874) 511-3262",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Mollit velit quis aliqua officia eiusmod cupidatat magna eiusmod eiusmod eu Lorem.",
    addedDate: "2022-06-24T12:37:40 -05:00",
    lastModified: "2023-04-06T12:10:11 -05:00",
  },
  {
    _id: "64887b651b1e2920c4e41f61",
    fullLegalName: "Sara Baird",
    idDoc: {
      type: "Passport",
      issueCountry: "Niue",
      number: 3736310439,
      issueDate: "2022-11-07T03:28:43 -05:00",
      expiryDate: "2023-05-31T09:57:06 -05:00",
    },
    doB: "1968-05-17T09:06:48 -05:00",
    nationality: "Mongolia",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "telephone",
      country: "S. Georgia and S. Sandwich Isls.",
      number: "(846) 467-3542",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Fugiat ea pariatur sit irure laboris laborum cupidatat sint magna et ut dolor officia occaecat.",
    addedDate: "2021-03-15T06:24:42 -05:00",
    lastModified: "2023-03-03T10:03:21 -05:00",
  },
  {
    _id: "64887b6532c5cc50b7f7a4c1",
    fullLegalName: "Allyson Ramirez",
    idDoc: {
      type: "Passport",
      issueCountry: "El Salvador",
      number: 6017485476,
      issueDate: "2022-05-26T01:30:44 -05:00",
      expiryDate: "2023-03-26T04:01:59 -05:00",
    },
    doB: "1971-09-14T01:56:38 -05:00",
    nationality: "Trinidad and Tobago",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Panama",
      number: "(928) 537-2653",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes:
      "Occaecat et ex ullamco duis ex incididunt non cillum mollit fugiat.",
    addedDate: "2023-04-01T06:45:34 -05:00",
    lastModified: "2023-06-01T11:24:19 -05:00",
  },
  {
    _id: "64887b652c9b86e1185b4b29",
    fullLegalName: "Elva Nunez",
    idDoc: {
      type: "ID",
      issueCountry: "Kiribati",
      number: 1688372490,
      issueDate: "2022-03-19T05:32:27 -05:00",
      expiryDate: "2023-03-20T10:32:18 -05:00",
    },
    doB: "1981-12-29T06:18:42 -05:00",
    nationality: "Iceland",
    residentialStatus: "National",
    contactInfo: {
      type: "telephone",
      country: "Nicaragua",
      number: "(891) 583-3062",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes: "Sint nisi ad mollit officia exercitation exercitation.",
    addedDate: "2022-07-09T12:53:07 -05:00",
    lastModified: "2023-04-04T01:31:52 -05:00",
  },
  {
    _id: "64887b65a2902e8d3837b3b5",
    fullLegalName: "Hillary Juarez",
    idDoc: {
      type: "Passport",
      issueCountry: "Lebanon",
      number: 9728461539,
      issueDate: "2022-05-01T05:14:43 -05:00",
      expiryDate: "2023-04-22T11:49:59 -05:00",
    },
    doB: "1952-10-02T01:52:15 -05:00",
    nationality: "Yugoslavia",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Aruba",
      number: "(990) 466-2968",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes: "Id exercitation sit sint do velit do ullamco tempor.",
    addedDate: "2022-11-18T10:51:27 -05:00",
    lastModified: "2023-03-09T04:39:34 -05:00",
  },
  {
    _id: "64887b652fdb37fd50de5c58",
    fullLegalName: "Mendez Holloway",
    idDoc: {
      type: "ID",
      issueCountry: "Sri Lanka",
      number: 9479172359,
      issueDate: "2021-06-18T05:50:53 -05:00",
      expiryDate: "2023-01-01T08:04:42 -05:00",
    },
    doB: "1953-01-01T11:08:01 -05:00",
    nationality: "New Caledonia",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Cape Verde",
      number: "(924) 587-3889",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Consequat elit irure nostrud enim velit et velit voluptate ut ea.",
    addedDate: "2021-05-09T12:50:02 -05:00",
    lastModified: "2023-02-19T12:36:31 -05:00",
  },
  {
    _id: "64887b657bf7573921985220",
    fullLegalName: "Harris Anderson",
    idDoc: {
      type: "ID",
      issueCountry: "Ecuador",
      number: 4788959412,
      issueDate: "2022-01-14T08:47:26 -05:00",
      expiryDate: "2023-02-03T04:48:23 -05:00",
    },
    doB: "2013-01-15T03:06:27 -05:00",
    nationality: "Qatar",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Tajikistan",
      number: "(811) 564-2276",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes:
      "Deserunt pariatur in reprehenderit reprehenderit exercitation incididunt voluptate incididunt adipisicing ex sint.",
    addedDate: "2021-10-09T10:51:41 -05:00",
    lastModified: "2023-04-16T09:42:44 -05:00",
  },
  {
    _id: "64887b6565a10146af0af4a3",
    fullLegalName: "Kaye Huff",
    idDoc: {
      type: "Passport",
      issueCountry: "Grenada",
      number: 1927857901,
      issueDate: "2021-04-28T11:43:59 -05:00",
      expiryDate: "2023-03-24T09:08:33 -05:00",
    },
    doB: "2015-08-24T03:19:11 -05:00",
    nationality: "Burkina Faso",
    residentialStatus: "National",
    contactInfo: {
      type: "telephone",
      country: "Slovak Republic",
      number: "(895) 565-2196",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Est ea eiusmod id officia pariatur eu laborum.",
    addedDate: "2021-04-22T06:50:00 -05:00",
    lastModified: "2023-03-15T01:01:33 -05:00",
  },
  {
    _id: "64887b65cdc9f205a4e521a1",
    fullLegalName: "Mai Moody",
    idDoc: {
      type: "ID",
      issueCountry: "East Timor",
      number: 8325335818,
      issueDate: "2022-02-16T02:32:25 -05:00",
      expiryDate: "2023-02-20T06:35:16 -05:00",
    },
    doB: "2016-09-02T08:50:43 -05:00",
    nationality: "Haiti",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Korea (South)",
      number: "(962) 424-3894",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Incididunt magna ex fugiat ipsum ex.",
    addedDate: "2022-07-20T09:25:38 -05:00",
    lastModified: "2023-05-10T10:14:16 -05:00",
  },
  {
    _id: "64887b65679dd060a4c199c3",
    fullLegalName: "Christie Bolton",
    idDoc: {
      type: "ID",
      issueCountry: "Antigua and Barbuda",
      number: 3231415630,
      issueDate: "2022-06-03T03:20:38 -05:00",
      expiryDate: "2023-05-22T03:45:00 -05:00",
    },
    doB: "2003-03-21T11:07:41 -05:00",
    nationality: "Vanuatu",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Turkey",
      number: "(860) 503-2741",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Ipsum minim nisi proident do occaecat officia aute ex occaecat labore non ut anim.",
    addedDate: "2023-02-06T12:22:28 -05:00",
    lastModified: "2023-02-21T01:07:30 -05:00",
  },
  {
    _id: "64887b652d5c5495f90f4930",
    fullLegalName: "Angelia Whitehead",
    idDoc: {
      type: "ID",
      issueCountry: "Laos",
      number: 5509295915,
      issueDate: "2021-03-06T02:31:26 -05:00",
      expiryDate: "2023-01-01T07:45:48 -05:00",
    },
    doB: "2012-04-10T06:00:34 -05:00",
    nationality: "Denmark",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Cocos (Keeling Islands)",
      number: "(827) 549-3963",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes: "Irure veniam velit dolor labore mollit.",
    addedDate: "2021-12-28T08:10:13 -05:00",
    lastModified: "2023-03-05T05:34:20 -05:00",
  },
  {
    _id: "64887b65267f027585612030",
    fullLegalName: "Sharp Craft",
    idDoc: {
      type: "Passport",
      issueCountry: "Saint Lucia",
      number: 3355522438,
      issueDate: "2022-03-14T06:02:43 -05:00",
      expiryDate: "2023-04-10T02:09:05 -05:00",
    },
    doB: "1980-06-26T11:45:21 -05:00",
    nationality: "Rwanda",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "telephone",
      country: "Samoa",
      number: "(875) 558-2559",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Reprehenderit pariatur magna irure laboris eu dolor aute mollit ad ut adipisicing.",
    addedDate: "2021-07-25T03:38:49 -05:00",
    lastModified: "2023-02-15T06:01:55 -05:00",
  },
  {
    _id: "64887b65b49b64748dc3b0a1",
    fullLegalName: "Shawn Flowers",
    idDoc: {
      type: "ID",
      issueCountry: "Mali",
      number: 3799525840,
      issueDate: "2022-06-30T12:06:46 -05:00",
      expiryDate: "2023-04-28T11:46:58 -05:00",
    },
    doB: "1986-12-11T04:40:16 -05:00",
    nationality: "Gabon",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Austria",
      number: "(905) 549-2722",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes:
      "Laborum deserunt do ut non aliquip aliquip veniam aliqua nisi velit qui reprehenderit aute anim.",
    addedDate: "2022-08-27T06:23:02 -05:00",
    lastModified: "2023-06-05T07:46:31 -05:00",
  },
  {
    _id: "64887b65d06c3f8ab23283f2",
    fullLegalName: "Horne Copeland",
    idDoc: {
      type: "Passport",
      issueCountry: "Western Sahara",
      number: 4285545937,
      issueDate: "2022-08-12T12:31:34 -05:00",
      expiryDate: "2023-04-20T09:07:21 -05:00",
    },
    doB: "1950-10-15T03:38:04 -06:-30",
    nationality: "Taiwan",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Nepal",
      number: "(981) 567-3273",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Ad est labore nisi non pariatur ut labore elit proident minim quis aute.",
    addedDate: "2022-01-07T05:33:40 -05:00",
    lastModified: "2023-04-21T08:58:57 -05:00",
  },
  {
    _id: "64887b65db5a688dae2a497b",
    fullLegalName: "Ila Lopez",
    idDoc: {
      type: "Passport",
      issueCountry: "Greece",
      number: 7465578571,
      issueDate: "2021-01-29T04:16:45 -05:00",
      expiryDate: "2023-01-05T12:15:07 -05:00",
    },
    doB: "2015-07-28T05:57:06 -05:00",
    nationality: "Cyprus",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "telephone",
      country: "San Marino",
      number: "(972) 584-2077",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes:
      "Ipsum qui deserunt sunt consequat ullamco reprehenderit duis Lorem non minim et dolore voluptate.",
    addedDate: "2022-09-16T03:29:04 -05:00",
    lastModified: "2023-05-26T07:44:38 -05:00",
  },
  {
    _id: "64887b65dc70f5f29d972911",
    fullLegalName: "Carole Chaney",
    idDoc: {
      type: "ID",
      issueCountry: "France, Metropolitan",
      number: 3137471486,
      issueDate: "2021-04-18T08:47:07 -05:00",
      expiryDate: "2023-06-05T03:31:22 -05:00",
    },
    doB: "1978-02-04T12:12:52 -05:00",
    nationality: "Puerto Rico",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "telephone",
      country: "Northern Mariana Islands",
      number: "(869) 557-2432",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes:
      "Non culpa et enim est enim minim sit nulla ut ea et elit excepteur fugiat.",
    addedDate: "2022-11-14T12:06:32 -05:00",
    lastModified: "2023-06-04T03:12:11 -05:00",
  },
  {
    _id: "64887b65806bd07f1a8a9be7",
    fullLegalName: "Sexton Carrillo",
    idDoc: {
      type: "ID",
      issueCountry: "Niger",
      number: 8741660583,
      issueDate: "2021-03-22T02:25:11 -05:00",
      expiryDate: "2023-01-26T04:28:02 -05:00",
    },
    doB: "2009-06-11T11:57:02 -06:00",
    nationality: "Falkland Islands (Malvinas)",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Somalia",
      number: "(834) 405-2303",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Quis magna et proident excepteur cillum eu excepteur consectetur mollit consequat.",
    addedDate: "2022-05-03T08:33:42 -05:00",
    lastModified: "2023-03-27T12:40:59 -05:00",
  },
  {
    _id: "64887b651230b3fd93aae923",
    fullLegalName: "Elena Russo",
    idDoc: {
      type: "Passport",
      issueCountry: "Mauritania",
      number: 1593396594,
      issueDate: "2022-04-28T09:09:02 -05:00",
      expiryDate: "2023-06-08T02:02:19 -05:00",
    },
    doB: "1955-05-18T05:40:55 -05:00",
    nationality: "Kuwait",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Georgia",
      number: "(825) 477-2983",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Do ut ex duis consequat voluptate.",
    addedDate: "2022-07-10T06:15:51 -05:00",
    lastModified: "2023-05-30T02:57:59 -05:00",
  },
  {
    _id: "64887b65ca44cc0125c9c4f7",
    fullLegalName: "Nancy Levy",
    idDoc: {
      type: "Passport",
      issueCountry: "Cote D'Ivoire (Ivory Coast)",
      number: 6989383611,
      issueDate: "2022-01-31T05:55:28 -05:00",
      expiryDate: "2023-04-22T04:49:03 -05:00",
    },
    doB: "2017-04-21T03:12:16 -05:00",
    nationality: "United Arab Emirates",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Peru",
      number: "(884) 584-2851",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes:
      "Sit laborum qui laboris do commodo dolor occaecat exercitation tempor commodo fugiat adipisicing minim magna.",
    addedDate: "2021-09-17T06:27:12 -05:00",
    lastModified: "2023-04-18T05:59:27 -05:00",
  },
  {
    _id: "64887b6515a764af28bffe19",
    fullLegalName: "Molly Frye",
    idDoc: {
      type: "Passport",
      issueCountry: "India",
      number: 4364245726,
      issueDate: "2022-07-28T04:08:33 -05:00",
      expiryDate: "2023-02-06T07:37:23 -05:00",
    },
    doB: "2020-11-14T04:27:25 -05:00",
    nationality: "Saudi Arabia",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Bermuda",
      number: "(994) 519-3417",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Nostrud fugiat sit ex ut ea culpa sint esse.",
    addedDate: "2021-04-23T01:42:45 -05:00",
    lastModified: "2023-05-31T05:54:14 -05:00",
  },
  {
    _id: "64887b65bee5dbc349782465",
    fullLegalName: "Heath May",
    idDoc: {
      type: "ID",
      issueCountry: "Bahrain",
      number: 8803558424,
      issueDate: "2021-04-15T08:54:12 -05:00",
      expiryDate: "2023-04-23T04:06:24 -05:00",
    },
    doB: "1966-11-11T10:08:29 -05:00",
    nationality: "Dominican Republic",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "telephone",
      country: "Viet Nam",
      number: "(861) 506-2742",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes:
      "Qui do esse tempor reprehenderit esse minim do do dolor adipisicing.",
    addedDate: "2022-03-29T02:06:07 -05:00",
    lastModified: "2023-06-11T07:20:44 -05:00",
  },
  {
    _id: "64887b65776428d97f235450",
    fullLegalName: "Emma Rowland",
    idDoc: {
      type: "ID",
      issueCountry: "Macau",
      number: 5107336693,
      issueDate: "2022-08-08T08:26:26 -05:00",
      expiryDate: "2023-04-13T04:16:29 -05:00",
    },
    doB: "1962-10-14T03:24:16 -05:00",
    nationality: "Azerbaijan",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Fiji",
      number: "(989) 476-2826",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Veniam sit nulla elit magna duis.",
    addedDate: "2021-10-15T03:17:16 -05:00",
    lastModified: "2023-03-29T09:28:10 -05:00",
  },
  {
    _id: "64887b6575dda6c690a04485",
    fullLegalName: "Charlene Dodson",
    idDoc: {
      type: "Passport",
      issueCountry: "New Zealand",
      number: 1589252258,
      issueDate: "2021-06-04T07:46:41 -05:00",
      expiryDate: "2023-02-27T06:48:36 -05:00",
    },
    doB: "2010-01-03T09:57:15 -05:00",
    nationality: "Oman",
    residentialStatus: "National",
    contactInfo: {
      type: "telephone",
      country: "Czech Republic",
      number: "(966) 498-3255",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Pariatur pariatur ullamco ullamco sunt consectetur veniam Lorem nisi quis pariatur.",
    addedDate: "2021-07-17T04:53:37 -05:00",
    lastModified: "2023-03-03T11:06:58 -05:00",
  },
  {
    _id: "64887b65b10032238d157ff6",
    fullLegalName: "Young Ellison",
    idDoc: {
      type: "Passport",
      issueCountry: "Tunisia",
      number: 6908993650,
      issueDate: "2023-02-04T07:19:22 -05:00",
      expiryDate: "2023-04-10T04:54:29 -05:00",
    },
    doB: "1976-03-02T11:00:30 -05:00",
    nationality: "Estonia",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Korea (North)",
      number: "(840) 467-2611",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Excepteur sunt esse veniam aliquip sit magna do voluptate excepteur sit consectetur ipsum.",
    addedDate: "2022-05-29T09:35:27 -05:00",
    lastModified: "2023-02-12T08:44:31 -05:00",
  },
  {
    _id: "64887b65100f659c18591a57",
    fullLegalName: "Harper Browning",
    idDoc: {
      type: "ID",
      issueCountry: "Guyana",
      number: 7268985921,
      issueDate: "2022-10-06T02:23:47 -05:00",
      expiryDate: "2023-03-17T07:11:49 -05:00",
    },
    doB: "1998-03-28T05:23:18 -05:00",
    nationality: "Colombia",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Bouvet Island",
      number: "(942) 508-3698",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Excepteur reprehenderit duis minim dolore do elit consequat cillum sunt consequat ipsum.",
    addedDate: "2022-03-07T07:42:12 -05:00",
    lastModified: "2023-04-05T04:57:56 -05:00",
  },
  {
    _id: "64887b6507c45a0bd086a4a1",
    fullLegalName: "Bender Robertson",
    idDoc: {
      type: "Passport",
      issueCountry: "St. Helena",
      number: 5495328611,
      issueDate: "2023-05-19T12:43:38 -05:00",
      expiryDate: "2023-04-11T02:58:34 -05:00",
    },
    doB: "1983-01-10T05:42:56 -05:00",
    nationality: "Indonesia",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Lesotho",
      number: "(971) 478-3256",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes:
      "Ex amet commodo do ullamco ad in dolor Lorem exercitation commodo consequat adipisicing.",
    addedDate: "2022-10-07T03:32:36 -05:00",
    lastModified: "2023-06-02T09:02:00 -05:00",
  },
  {
    _id: "64887b65338c1f490368e390",
    fullLegalName: "Ashlee Sosa",
    idDoc: {
      type: "Passport",
      issueCountry: "Eritrea",
      number: 3525432940,
      issueDate: "2021-12-18T09:01:18 -05:00",
      expiryDate: "2023-05-02T02:02:11 -05:00",
    },
    doB: "1986-12-03T08:13:24 -05:00",
    nationality: "South Africa",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Canada",
      number: "(959) 572-3308",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes:
      "Aute dolor laboris non ullamco incididunt adipisicing do enim ullamco.",
    addedDate: "2021-05-13T10:45:51 -05:00",
    lastModified: "2023-04-29T08:17:44 -05:00",
  },
  {
    _id: "64887b656e5632b5d362a6ba",
    fullLegalName: "Slater Matthews",
    idDoc: {
      type: "Passport",
      issueCountry: "Armenia",
      number: 9109118248,
      issueDate: "2022-12-13T07:53:30 -05:00",
      expiryDate: "2023-01-24T10:29:27 -05:00",
    },
    doB: "1981-03-27T10:12:27 -05:00",
    nationality: "Hong Kong",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Australia",
      number: "(917) 502-3972",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Magna eiusmod qui ex ut ut sint nulla cillum consectetur et laborum.",
    addedDate: "2021-08-21T07:20:07 -05:00",
    lastModified: "2023-05-29T02:27:50 -05:00",
  },
  {
    _id: "64887b65e4fc516bdac3477f",
    fullLegalName: "Dennis Delaney",
    idDoc: {
      type: "ID",
      issueCountry: "Pitcairn",
      number: 8555705985,
      issueDate: "2021-11-01T11:01:25 -05:00",
      expiryDate: "2023-03-30T02:34:03 -05:00",
    },
    doB: "1987-08-18T10:14:25 -05:00",
    nationality: "Myanmar",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Zambia",
      number: "(986) 416-3686",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Veniam consequat pariatur do occaecat proident mollit aliquip eu culpa qui ut ut.",
    addedDate: "2022-03-25T09:11:39 -05:00",
    lastModified: "2023-04-16T10:51:41 -05:00",
  },
  {
    _id: "64887b65c0f6c5150fe92a6e",
    fullLegalName: "Lane Velez",
    idDoc: {
      type: "Passport",
      issueCountry: "Tanzania",
      number: 4022899525,
      issueDate: "2021-01-25T01:06:17 -05:00",
      expiryDate: "2023-06-01T11:59:49 -05:00",
    },
    doB: "2013-09-01T08:22:52 -05:00",
    nationality: "Luxembourg",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Belize",
      number: "(828) 414-2357",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes: "Proident magna cillum Lorem exercitation incididunt quis.",
    addedDate: "2021-06-19T07:15:59 -05:00",
    lastModified: "2023-02-13T02:41:54 -05:00",
  },
  {
    _id: "64887b651909c954c8100afb",
    fullLegalName: "Vivian Walter",
    idDoc: {
      type: "ID",
      issueCountry: "Christmas Island",
      number: 8790242599,
      issueDate: "2021-06-25T04:52:12 -05:00",
      expiryDate: "2023-03-02T09:29:08 -05:00",
    },
    doB: "1990-08-25T08:35:57 -05:00",
    nationality: "Vatican City State (Holy See)",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Yemen",
      number: "(949) 534-2173",
    },
    pepStatus: true,
    tfsScreening: "MATCH FOUND",
    notes:
      "Culpa sunt laboris reprehenderit ad esse commodo cupidatat sint amet do do.",
    addedDate: "2021-04-26T02:21:50 -05:00",
    lastModified: "2023-06-10T02:23:52 -05:00",
  },
  {
    _id: "64887b653aa417aa6f26ffd1",
    fullLegalName: "Holland Arnold",
    idDoc: {
      type: "Passport",
      issueCountry: "French Polynesia",
      number: 5901265760,
      issueDate: "2021-12-07T08:35:21 -05:00",
      expiryDate: "2023-02-24T11:40:35 -05:00",
    },
    doB: "2002-05-28T01:47:05 -06:00",
    nationality: "Malawi",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "telephone",
      country: "Israel",
      number: "(831) 542-2411",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Proident tempor sit laborum culpa velit.",
    addedDate: "2022-06-23T03:18:57 -05:00",
    lastModified: "2023-04-08T09:50:18 -05:00",
  },
  {
    _id: "64887b6594c0c172ec1f832b",
    fullLegalName: "Miriam Hudson",
    idDoc: {
      type: "Passport",
      issueCountry: "Chad",
      number: 6071365131,
      issueDate: "2021-04-06T11:20:25 -05:00",
      expiryDate: "2023-03-10T08:17:15 -05:00",
    },
    doB: "1963-11-02T01:23:41 -05:00",
    nationality: "Cameroon",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Guam",
      number: "(995) 448-3283",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes:
      "Pariatur culpa fugiat commodo cillum voluptate veniam ullamco fugiat.",
    addedDate: "2022-12-24T01:02:33 -05:00",
    lastModified: "2023-03-31T07:06:27 -05:00",
  },
  {
    _id: "64887b65aec84f6ea29c2047",
    fullLegalName: "Deanne Christensen",
    idDoc: {
      type: "Passport",
      issueCountry: "Philippines",
      number: 1769338045,
      issueDate: "2023-05-05T06:06:45 -05:00",
      expiryDate: "2023-05-16T07:03:20 -05:00",
    },
    doB: "1998-03-11T01:37:20 -05:00",
    nationality: "Tuvalu",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "mobile",
      country: "Turkmenistan",
      number: "(865) 543-3067",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes: "Non eu aute nisi voluptate pariatur enim non.",
    addedDate: "2023-01-03T10:44:00 -05:00",
    lastModified: "2023-05-06T07:57:44 -05:00",
  },
  {
    _id: "64887b655420a0bc5c154d58",
    fullLegalName: "Lula Fuller",
    idDoc: {
      type: "Passport",
      issueCountry: "Chile",
      number: 4449211986,
      issueDate: "2021-01-13T07:12:33 -05:00",
      expiryDate: "2023-01-07T04:43:59 -05:00",
    },
    doB: "1997-10-28T06:53:47 -05:00",
    nationality: "Russian Federation",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Andorra",
      number: "(970) 535-3221",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes: "Consectetur est esse ea incididunt ipsum.",
    addedDate: "2021-09-13T06:46:35 -05:00",
    lastModified: "2023-05-28T05:49:38 -05:00",
  },
  {
    _id: "64887b6586572b506f709a58",
    fullLegalName: "Opal Butler",
    idDoc: {
      type: "ID",
      issueCountry: "Ethiopia",
      number: 2053244900,
      issueDate: "2021-10-13T09:50:44 -05:00",
      expiryDate: "2023-01-22T06:07:54 -05:00",
    },
    doB: "2016-03-03T09:13:04 -05:00",
    nationality: "Turks and Caicos Islands",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Singapore",
      number: "(962) 412-2987",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Et aliqua exercitation fugiat pariatur deserunt Lorem magna est adipisicing ut proident nostrud ex.",
    addedDate: "2023-06-06T11:52:04 -05:00",
    lastModified: "2023-02-19T05:12:42 -05:00",
  },
  {
    _id: "64887b65bd4ce372bb6f335a",
    fullLegalName: "Ewing Brady",
    idDoc: {
      type: "ID",
      issueCountry: "US Minor Outlying Islands",
      number: 1181396706,
      issueDate: "2022-05-22T11:25:11 -05:00",
      expiryDate: "2023-03-23T09:38:09 -05:00",
    },
    doB: "1955-03-07T12:47:29 -05:00",
    nationality: "Portugal",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Svalbard and Jan Mayen Islands",
      number: "(938) 515-3948",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Cillum eiusmod occaecat occaecat nulla laboris exercitation occaecat voluptate sunt qui deserunt.",
    addedDate: "2021-06-08T08:29:04 -05:00",
    lastModified: "2023-05-15T06:07:49 -05:00",
  },
  {
    _id: "64887b65c35ac7b229378d49",
    fullLegalName: "Vera Farmer",
    idDoc: {
      type: "Passport",
      issueCountry: "Senegal",
      number: 8387171557,
      issueDate: "2021-06-13T06:27:25 -05:00",
      expiryDate: "2023-01-19T01:01:59 -05:00",
    },
    doB: "1988-10-23T04:58:11 -05:00",
    nationality: "Egypt",
    residentialStatus: "Tourist",
    contactInfo: {
      type: "telephone",
      country: "Brunei Darussalam",
      number: "(994) 570-3689",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Quis commodo officia ullamco officia sit non laborum aliqua irure.",
    addedDate: "2022-06-16T05:30:05 -05:00",
    lastModified: "2023-03-31T04:33:04 -05:00",
  },
  {
    _id: "64887b65e44a11d7b8a8f3a2",
    fullLegalName: "Luisa Payne",
    idDoc: {
      type: "Passport",
      issueCountry: "Bhutan",
      number: 8028706315,
      issueDate: "2021-08-12T01:52:56 -05:00",
      expiryDate: "2023-01-12T09:57:38 -05:00",
    },
    doB: "1953-10-24T05:57:44 -05:00",
    nationality: "Albania",
    residentialStatus: "National",
    contactInfo: {
      type: "mobile",
      country: "Saint Vincent and The Grenadines",
      number: "(997) 504-2663",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes:
      "Pariatur in duis ex excepteur non cillum magna veniam dolor laborum dolor commodo enim deserunt.",
    addedDate: "2021-06-15T09:22:11 -05:00",
    lastModified: "2023-05-17T04:15:28 -05:00",
  },
  {
    _id: "64887b650130cf00a63250ec",
    fullLegalName: "Charles Taylor",
    idDoc: {
      type: "Passport",
      issueCountry: "Mauritius",
      number: 8829074142,
      issueDate: "2022-10-12T12:28:26 -05:00",
      expiryDate: "2023-02-27T11:03:20 -05:00",
    },
    doB: "1970-05-18T05:44:28 -05:00",
    nationality: "Sudan",
    residentialStatus: "National",
    contactInfo: {
      type: "telephone",
      country: "Suriname",
      number: "(935) 561-2122",
    },
    pepStatus: false,
    tfsScreening: "NO MATCH FOUND",
    notes:
      "Veniam occaecat aliquip deserunt dolor sunt magna excepteur in nisi ad veniam esse occaecat fugiat.",
    addedDate: "2021-08-03T12:47:33 -05:00",
    lastModified: "2023-03-17T09:22:48 -05:00",
  },
  {
    _id: "64887b65838281d9e673485b",
    fullLegalName: "Ebony Frazier",
    idDoc: {
      type: "ID",
      issueCountry: "Japan",
      number: 7296097344,
      issueDate: "2021-06-22T12:31:08 -05:00",
      expiryDate: "2023-05-13T12:32:04 -05:00",
    },
    doB: "1971-08-19T11:27:27 -05:00",
    nationality: "Tokelau",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Mozambique",
      number: "(949) 587-3122",
    },
    pepStatus: true,
    tfsScreening: "NO MATCH FOUND",
    notes: "Deserunt sunt ut nisi deserunt id sint aute ullamco.",
    addedDate: "2022-07-02T09:59:17 -05:00",
    lastModified: "2023-05-11T08:55:59 -05:00",
  },
  {
    _id: "64887b65669d27b02afd345e",
    fullLegalName: "Knight Tyler",
    idDoc: {
      type: "ID",
      issueCountry: "Romania",
      number: 6453931650,
      issueDate: "2022-03-30T03:26:39 -05:00",
      expiryDate: "2023-04-27T06:19:02 -05:00",
    },
    doB: "1997-10-26T11:46:08 -05:00",
    nationality: "Martinique",
    residentialStatus: "Permanent Resident",
    contactInfo: {
      type: "mobile",
      country: "Nauru",
      number: "(846) 477-3477",
    },
    pepStatus: false,
    tfsScreening: "MATCH FOUND",
    notes: "Esse veniam non ex amet eiusmod laborum.",
    addedDate: "2023-02-09T12:57:55 -05:00",
    lastModified: "2023-06-11T11:24:54 -05:00",
  },
];
