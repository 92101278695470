import { React, useMemo, useState } from "react";
import { Box, Button, MenuItem, useTheme, Typography } from "@mui/material";
import {
  useGetExpiredCustomersQuery,
  useDeleteCustomersMutation,
} from "state/api";
import { MaterialReactTable } from "material-react-table";
import Header from "components/Header";
import EditEntity from "components/editEntity";

import moment from "moment";

const ExpiredEntities = () => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data, isLoading } = useGetExpiredCustomersQuery();
  const [deleteEntity] = useDeleteCustomersMutation();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  console.log(data);
  const shColumns = useMemo(() => [
    {
      accessorKey: "fullLegalName", //simple recommended way to define a column
      header: "Name",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "idDoc.type", //simple recommended way to define a column
      header: "ID Doc",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "idDoc.issueCountry", //simple recommended way to define a column
      header: "Issue Country",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "idDoc.number", //simple recommended way to define a column
      header: "Number",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow.idDoc.issueDate;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Issue Date",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow.idDoc.expiryDate;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Expiry Date",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorFn: (originalRow) => {
        console.log(originalRow);
        const dateString = originalRow.doB;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Date of Birth",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorKey: "nationality", //simple recommended way to define a column
      header: "Nationality",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "residentialStatus", //simple recommended way to define a column
      header: "Residential Status",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "percentShares", //simple recommended way to define a column
      header: "% Shares",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "contactInfo.contactType", //simple recommended way to define a column
      header: "Contact Type",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "contactInfo.contactCountry", //simple recommended way to define a column
      header: "Phone Country",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "contactInfo.contactNumber", //simple recommended way to define a column
      header: "Number",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "ubo", //simple recommended way to define a column
      header: "UBO",
      Cell: ({ cell }) =>
        cell.getValue() === true ? (
          <Typography>Yes</Typography>
        ) : (
          <Typography>NO</Typography>
        ),
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "uboReason", //simple recommended way to define a column
      header: "UBO Reason",
      Cell: ({ cell }) =>
        cell.getValue() ? (
          <Typography>{cell.getValue()}</Typography>
        ) : (
          <Typography>NA</Typography>
        ),
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow?.UboDate;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s?.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return originalRow.UboDate
          ? parseDateTime(dateString).toLocaleDateString("en-CA")
          : "NA";
      },
      header: "UBO Date",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorKey: "PepStatus", //simple recommended way to define a column
      header: "PEP",
      Cell: ({ cell }) =>
        cell.getValue() === true ? (
          <Typography>Yes</Typography>
        ) : (
          <Typography>NO</Typography>
        ),
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "tfsScreening", //simple recommended way to define a column
      header: "TFS-Screening",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "isAuthorizedSignatory", //simple recommended way to define a column
      header: "Authorized Signatory",
      Cell: ({ cell }) =>
        cell.getValue() === true ? (
          <Typography>Yes</Typography>
        ) : (
          <Typography>NO</Typography>
        ),
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
  ]);

  const columns = useMemo(() => [
    {
      accessorKey: "fullLegalName", //simple recommended way to define a column
      header: "Company Name",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "registeredAddress", //simple recommended way to define a column
      header: "Registered Address",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "TradeLicenseInfo.businessActivity", //simple recommended way to define a column
      header: "Business Activity",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "TradeLicenseInfo.tradeLicenseNo", //simple recommended way to define a column
      header: "Trade Lic. No",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
    },
    {
      accessorKey: "TradeLicenseInfo.countryofIncorporation", //simple recommended way to define a column
      header: "Incorp. Country",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority
    },
    {
      accessorKey: "TradeLicenseInfo.licensingAuthority", //simple recommended way to define a column
      header: "Lic. Authority",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority incoroprationDate
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow.TradeLicenseInfo.incoroprationDate;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Incorporation Date",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow.TradeLicenseInfo.licenseExpiry;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Lic. Expiry",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorKey: "contactInfo.contactType", //simple recommended way to define a column
      header: "ContactType",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority incoroprationDate
    },
    {
      accessorKey: "contactInfo.contactCountry", //simple recommended way to define a column
      header: "ContactCountry",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority incoroprationDate
    },
    {
      accessorKey: "contactInfo.contactNumber", //simple recommended way to define a column
      header: "ContactNumber",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority incoroprationDate
    },
    {
      accessorKey: "contactInfo.emailAddress", //simple recommended way to define a column
      header: "email",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority incoroprationDate
    },
    {
      accessorKey: "contactInfo.website", //simple recommended way to define a column
      header: "website",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority incoroprationDate
    },
    {
      accessorKey: "contactInfo.POBox", //simple recommended way to define a column
      header: "POBox",
      //muiTableHeadCellProps: { sx: { color: "green" } }, //custom props licensingAuthority incoroprationDate
    },
  ]);
  return (
    <Box
      m="1.5rem 2.5rem"
      // sx={{
      //   width: "calc(100vw - 295px)", // Subtract sidebar width
      //   height: "calc(100vh - 64px)", // Subtract topbar height
      //   margin: "16px",
      // }}
    >
      {/* //m="1.5rem 2.5rem"> */}
      <Header title="Expired Entities" subtitle=" Business License Expired" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <MaterialReactTable
          // columns={columns}
          data={data || []}
          layoutMode="grid"
          enableGlobalFilter={true}
          enableColumnActions={false}
          enableColumnOrdering={false}
          columns={columns.map((c) => ({ ...c, size: 110, minSize: 10 }))}
          state={{
            isLoading: isLoading,
          }}
          key={"key"}
          enableRowActions
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key={row.id + 1}
              onClick={() => {
                handleOpenDialog();
                console.log(row.original);
                // closeMenu();
              }}
            >
              Edit
            </MenuItem>,
            <EditEntity
              key={row.original._id}
              open={dialogOpen}
              onClose={handleCloseDialog}
              data={row.original}
            />,

            <MenuItem
              key={row.id + 2}
              onClick={() => {
                deleteEntity(row.original._id);
                closeMenu();
              }}
            >
              Delete
            </MenuItem>,
          ]}
          renderDetailPanel={({ row }) => (
            <div style={{ display: "grid" }}>
              <Typography variant="h5">Share Holders </Typography>
              <MaterialReactTable
                //columns={shColumns}
                columns={shColumns.map((c) => ({
                  ...c,
                  size: 110,
                  minSize: 10,
                }))}
                data={row.original.shareHolders}
                key={row.original._id}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                layoutMode="grid"
                //muiTableBodyRowProps={{ hover: false }}
              />
            </div>
          )}
          muiTablePaperProps={{
            sx: {
              maxWidth: "82vw",
              m: "auto",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ExpiredEntities;
