// import React, { useMemo, useState, useRef } from "react";
// import {
//   Box,
//   Typography,
//   useTheme,
//   MenuItem,
//   Select,
//   Button,
//   Grid,
//   Modal,
//   Menu,
//   IconButton,
//   Link,
// } from "@mui/material";
// import PrintIcon from "@mui/icons-material/Print";
// import ReactToPrint from "react-to-print";
// import EntityPrint from "../../components/EntityPrint";
// import * as XLSX from "xlsx";
// import { useGetCustomersQuery, useDeleteCustomersMutation } from "state/api";
// import Header from "components/Header";
// import { MaterialReactTable } from "material-react-table";
// import EditEntity from "components/editEntity";
// import AddEntity from "components/addEntity";
// import moment from "moment";
// import ComplianceQ from "components/ComplianceQ";
// import Crp from "components/Crp";
// import { json } from "react-router-dom";
// import LegalPerson from "scenes/LegalPersonCRP";
// import ViewKyc from "components/viewKyc";
// import ViewCQ from "components/viewComplianceQ";
// import { isAllFieldsFilled, isComplianceQFilled } from "components/utils";

// const Entities = () => {
//   const theme = useTheme();
//   const { data, isLoading } = useGetCustomersQuery();
//   const [deleteEntity] = useDeleteCustomersMutation();
//   const [anchorEl, setAnchorEl] = useState(null);
//   //<ViewCQ open={cQDialogOpen} onClose={handleCloseCQ} />
//   console.log("data", data);
//   const [expiryState, setExpiryState] = useState(true);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [cRpdialogOpen, setcRpDialogOpen] = useState(false);
//   const [cQDialogOpen, setcQDialogOpen] = useState(false);
//   // New View Dialogues //
//   const [cQvDialogOpen, setcQvDialogOpen] = useState(false);
//   const [kYcDialogOpen, setkYcDialogOpen] = useState(false);

//   const [addDialogOpen, setaddDialogOpen] = useState(false);
//   const [currentId, setCurrentId] = useState(null); // ID of the current entity

//   //Test

//   const [currentRowId, setCurrentRowId] = useState(null); // State to hold the current row ID
//   // const [cQDialogOpen, setcQDialogOpen] = useState(false); // State for Compliance Questionnaire Dialog
//   // const [dialogOpen, setDialogOpen] = useState(false); // State for Edit Entity Dialog
//   // const [addDialogOpen, setaddDialogOpen] = useState(false); // State for Add Entity Dialog
//   const [currentRowData, setCurrentRowData] = useState(null);

//   function findObjectById() {
//     // Use the find method to locate the object with the specified _id
//     // return data.find((obj) => obj._id === currentRowId);
//     if (!isLoading && currentRowId) {
//       console.log(
//         "currentRow:",
//         data.find((obj) => obj?._id === currentRowId)
//       );
//       const currentRow = data.find((obj) => obj?._id === currentRowId);
//       return currentRow;
//     }
//     // if (Array.isArray(data)) {
//     //   return data.find((obj) => obj._id === currentRowId);
//     // } else {
//     //   console.error("The second argument must be an array.");
//     //   return null;
//     // }
//   }

//   const handleViewKyc = (rowId) => {
//     setCurrentRowId(rowId);
//     setkYcDialogOpen(true);
//   };

//   const handleCloseKyc = () => {
//     setkYcDialogOpen(false);
//   };

//   const handleViewCQ = (rowId) => {
//     setCurrentRowId(rowId);
//     setcQvDialogOpen(true);
//   };

//   const handleCloseViewCQ = () => {
//     setcQvDialogOpen(false);
//   };
//   // Function to handle menu item click for Compliance Questionnaire
//   const handleComplianceClick = (rowId) => {
//     setCurrentRowId(rowId); // Update the current row ID
//     setcQDialogOpen(true); // Open the Compliance Questionnaire Dialog
//   };

//   const handleCrpClick = (rowId) => {
//     setCurrentRowId(rowId); // Update the current row ID
//     setcRpDialogOpen(true); // Open the Compliance Questionnaire Dialog
//   };

//   // Function to handle menu item click for Edit Entity
//   const handleEditClick = (rowId) => {
//     setCurrentRowId(rowId); // Update the current row ID
//     setCurrentRowData();
//     setDialogOpen(true); // Open the Edit Entity Dialog
//   };

//   // End Test
//   // Function to transform the data
//   const transformData = () => {
//     const legalPersons = [];
//     const allShareholders = [];

//     data.forEach((entry) => {
//       const {
//         fullLegalName,
//         shareHolders,
//         registeredAddress,
//         businessAddress,

//         TradeLicenseInfo,

//         restodEntry,
//       } = entry;
//       // const {Tradelicenseinfo, contact}=entity
//       // Push the parent info to the legalPersons array
//       legalPersons.push({
//         "Full Legal Name": fullLegalName,
//         "registered Address": registeredAddress,
//         "business Address": businessAddress,
//         "trade Lic. No": TradeLicenseInfo.tradeLicenseNo,
//         "business Activity": TradeLicenseInfo.businessActivity,
//         "lic Authority": TradeLicenseInfo.licensingAuthority,
//         "country of Incorp.": TradeLicenseInfo.countryofIncorporation,
//         "incorporation Date": moment(TradeLicenseInfo.incoroprationDate).format(
//           "DD-MM-YYYY"
//         ),

//         // ...restodEntry,
//         // shareHolders: undefined, // Remove the shareHolders field
//       });

//       // Add the shareholders to the allShareholders array
//       shareHolders.forEach((shareholder) => {
//         const {
//           contactInfo,
//           idDoc,
//           _id,
//           __v,
//           createdAt,
//           updatedAt,
//           isAuthorizedSignatory,
//           restofShareholder,
//           percentShares,
//           ubo,
//           uboDate,
//           uboReason,
//         } = shareholder;
//         allShareholders.push({
//           "parent Legal Name": fullLegalName, // Include the parent's legal name
//           "contact Type": contactInfo.contactType,
//           "contact Country": contactInfo.contactCountry,
//           "contact Number": contactInfo.contactNumber,
//           "ID Type": idDoc.type,
//           "ID Number": idDoc.number,
//           "ID Issue Country": idDoc.issueCountry,
//           "ID Issue Date": moment(idDoc.issueDate).format("DD-MM-YYYY"),
//           "ID Expiry Date": moment(idDoc.expiryDate).format("DD-MM-YYYY"),
//           "percentage Shares": percentShares,
//           "Authorized Signatory": isAuthorizedSignatory ? "Yes" : "No",
//           UBO: ubo ? "Yes" : "No",

//           "ubo Reason": uboReason,
//           "UBO Date": moment(uboDate).format("MM-DD-YYYY"),

//           ...restofShareholder,
//         });
//       });
//     });

//     return { legalPersons, allShareholders };
//   };

//   // // Function to export the data to Excel
//   // const exportToExcel = () => {
//   //   const { legalPersons, allShareholders } = transformData();

//   //   // Create a workbook
//   //   const wb = XLSX.utils.book_new();

//   //   // Create worksheets
//   //   const ws1 = XLSX.utils.json_to_sheet(legalPersons);
//   //   const ws2 = XLSX.utils.json_to_sheet(allShareholders);

//   //   // Function to capitalize initials
//   //   const capitalizeInitials = (str) => {
//   //     return str
//   //       .split(" ")
//   //       .map(
//   //         (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
//   //       )
//   //       .join(" ");
//   //   };

//   //   // Format the first row of each worksheet with bold text and yellow background
//   //   const formatFirstRow = (ws) => {
//   //     const range = XLSX.utils.decode_range(ws["!ref"]);
//   //     for (let col = range.s.c; col <= range.e.c; ++col) {
//   //       const address = XLSX.utils.encode_cell({ r: 0, c: col });
//   //       if (!ws[address]) continue;
//   //       ws[address].v = capitalizeInitials(ws[address].v); // Capitalize initials
//   //       ws[address].s = {
//   //         font: {
//   //           bold: true,
//   //         },
//   //         fill: {
//   //           bgColor: { rgb: "#FFFF00" }, // Yellow background
//   //         },
//   //       };
//   //     }
//   //   };

//   //   // Apply formatting to the first row of each worksheet
//   //   formatFirstRow(ws1);
//   //   formatFirstRow(ws2);

//   //   // Append worksheets to workbook
//   //   XLSX.utils.book_append_sheet(wb, ws1, "LegalPersons");
//   //   XLSX.utils.book_append_sheet(wb, ws2, "ShareHolders");

//   //   // Write workbook to XLSX file
//   //   XLSX.writeFile(wb, "CombinedData.xlsx");
//   // };

//   // Function to export the data to Excel
//   const exportToExcel = () => {
//     const { legalPersons, allShareholders } = transformData();

//     // Create a workbook
//     const wb = XLSX.utils.book_new();

//     // Create worksheets
//     const ws1 = XLSX.utils.json_to_sheet(legalPersons);
//     const ws2 = XLSX.utils.json_to_sheet(allShareholders);

//     // Function to capitalize initials
//     const capitalizeInitials = (str) => {
//       return str
//         .split(" ")
//         .map(
//           (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
//         )
//         .join(" ");
//     };

//     // Function to remove unwanted columns from a worksheet
//     const removeColumns = (ws, columnsToRemove) => {
//       const range = XLSX.utils.decode_range(ws["!ref"]);
//       for (let col = range.s.c; col <= range.e.c; ++col) {
//         const address = XLSX.utils.encode_cell({ r: 0, c: col });
//         if (columnsToRemove.includes(ws[address].v)) {
//           for (let row = range.s.r; row <= range.e.r; ++row) {
//             delete ws[XLSX.utils.encode_cell({ r: row, c: col })];
//           }
//         }
//       }
//     };
//     const columnsToRemove = [
//       "TradeLicenseInfo",
//       "financialInfo",
//       "contactInfo",
//       "_id",
//       "__v",
//       "ComplianceQuestionaire",
//       "company",
//       "shareHolders",
//       "complianceQuestionaire",
//       "createdAt",
//       "updatedAt",
//       "idDoc",
//     ]; // Replace with your column names

//     // Remove unwanted columns from each worksheet
//     // removeColumns(ws1, columnsToRemove);
//     // removeColumns(ws2, columnsToRemove);

//     // Format the first row of each worksheet with bold text and yellow background
//     const formatFirstRow = (ws) => {
//       const range = XLSX.utils.decode_range(ws["!ref"]);
//       for (let col = range.s.c; col <= range.e.c; ++col) {
//         const address = XLSX.utils.encode_cell({ r: 0, c: col });
//         if (!ws[address]) continue;
//         ws[address].v = capitalizeInitials(ws[address].v);
//         ws[address].s = {
//           font: {
//             bold: true,
//           },
//           fill: {
//             patternType: "solid",
//             fgColor: { rgb: "FFFF00" },
//             bgColor: { rgb: "FFFF00" },
//           },
//         };
//       }
//     };

//     // Apply formatting to the first row of each worksheet
//     formatFirstRow(ws1);
//     formatFirstRow(ws2);

//     // Append worksheets to workbook
//     XLSX.utils.book_append_sheet(wb, ws1, "LegalPersons");
//     XLSX.utils.book_append_sheet(wb, ws2, "ShareHolders");

//     // Write workbook to XLSX file
//     XLSX.writeFile(wb, "CombinedData.xlsx");
//   };

//   const handleExpiryState = () => {
//     setExpiryState(false);
//   };

//   const handleOpencQDialog = () => {
//     setcQDialogOpen(true);
//   };

//   const handleClosecQDialog = () => {
//     setcQDialogOpen(false);
//   };

//   const handleCloseCrp = () => {
//     setcRpDialogOpen(false);
//   };

//   const handleOpenAddDialog = () => {
//     setaddDialogOpen(true);
//   };

//   const handleCloseAddDialog = () => {
//     setaddDialogOpen(false);
//   };

//   const handleOpenDialog = () => {
//     setDialogOpen(true);
//   };

//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };

//   const shColumns = useMemo(() => [
//     {
//       accessorKey: "fullLegalName", //simple recommended way to define a column
//       header: "Name",
//     },
//     {
//       accessorKey: "idDoc.type", //simple recommended way to define a column
//       header: "ID Doc",
//     },
//     {
//       accessorKey: "idDoc.issueCountry", //simple recommended way to define a column
//       header: "Issue Country",
//     },
//     {
//       accessorKey: "idDoc.number", //simple recommended way to define a column
//       header: "Number",
//     },
//     {
//       accessorFn: (originalRow) => {
//         const dateString = originalRow.idDoc.issueDate;
//         const date = new Date();
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, "0");
//         const day = String(date.getDate()).padStart(2, "0");
//         const formattedDate = `${year}-${month}-${day}`;
//         function parseDateTime(s) {
//           var b = s.split(/\D/);
//           return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
//         }

//         return parseDateTime(dateString).toLocaleDateString("en-CA");
//       },
//       header: "Issue Date",
//       type: "date",
//       muiTableBodyCellEditTextFieldProps: {
//         type: "date",
//       },
//       muiTableHeadCellFilterTextFieldProps: {
//         type: "date",
//       },
//       sortingFn: "datetime",
//       dateSetting: { locale: "en-CA" },
//     },
//     {
//       accessorFn: (originalRow) => {
//         const dateString = originalRow.idDoc.expiryDate;
//         const date = new Date();
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, "0");
//         const day = String(date.getDate()).padStart(2, "0");
//         const formattedDate = `${year}-${month}-${day}`;
//         function parseDateTime(s) {
//           var b = s.split(/\D/);
//           return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
//         }

//         return parseDateTime(dateString).toLocaleDateString("en-CA");
//       },
//       header: "Expiry Date",
//       type: "date",
//       muiTableBodyCellEditTextFieldProps: {
//         type: "date",
//       },
//       muiTableHeadCellFilterTextFieldProps: {
//         type: "date",
//       },
//       sortingFn: "datetime",
//       dateSetting: { locale: "en-CA" },
//     },
//     {
//       accessorFn: (originalRow) => {
//         console.log(originalRow);
//         const dateString = originalRow.doB;
//         const date = new Date();
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, "0");
//         const day = String(date.getDate()).padStart(2, "0");
//         const formattedDate = `${year}-${month}-${day}`;
//         function parseDateTime(s) {
//           var b = s.split(/\D/);
//           return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
//         }

//         return parseDateTime(dateString).toLocaleDateString("en-CA");
//       },
//       header: "Date of Birth",
//       type: "date",
//       muiTableBodyCellEditTextFieldProps: {
//         type: "date",
//       },
//       muiTableHeadCellFilterTextFieldProps: {
//         type: "date",
//       },
//       sortingFn: "datetime",
//       dateSetting: { locale: "en-CA" },
//     },
//     {
//       accessorKey: "nationality", //simple recommended way to define a column
//       header: "Nationality",
//     },
//     {
//       accessorKey: "residentialStatus", //simple recommended way to define a column
//       header: "Residential Status",
//     },
//     {
//       accessorKey: "percentShares", //simple recommended way to define a column
//       header: "% Shares",
//     },
//     {
//       accessorKey: "contactInfo.contactType", //simple recommended way to define a column
//       header: "Contact Type",
//     },
//     {
//       accessorKey: "contactInfo.contactCountry", //simple recommended way to define a column
//       header: "Phone Country",
//     },
//     {
//       accessorKey: "contactInfo.contactNumber", //simple recommended way to define a column
//       header: "Number",
//     },
//     {
//       accessorKey: "ubo", //simple recommended way to define a column
//       header: "UBO",
//       Cell: ({ cell }) =>
//         cell.getValue() === true ? (
//           <Typography>Yes</Typography>
//         ) : (
//           <Typography>NO</Typography>
//         ),
//     },
//     {
//       accessorKey: "uboReason", //simple recommended way to define a column
//       header: "UBO Reason",
//       Cell: ({ cell }) =>
//         cell.getValue() ? (
//           <Typography>{cell.getValue()}</Typography>
//         ) : (
//           <Typography>NA</Typography>
//         ),
//     },
//     {
//       accessorFn: (originalRow) => {
//         const dateString = originalRow?.UboDate;

//         return originalRow.uboDate
//           ? moment(dateString).format("DD-MM-YY")
//           : "NA";
//       },
//       header: "UBO Date",
//       type: "date",
//       muiTableBodyCellEditTextFieldProps: {
//         type: "date",
//       },
//       muiTableHeadCellFilterTextFieldProps: {
//         type: "date",
//       },
//       sortingFn: "datetime",
//       dateSetting: { locale: "en-CA" },
//     },
//     {
//       accessorKey: "PepStatus", //simple recommended way to define a column
//       header: "PEP",
//       Cell: ({ cell }) =>
//         cell.getValue() === true ? (
//           <Typography>Yes</Typography>
//         ) : (
//           <Typography>NO</Typography>
//         ),
//     },
//     {
//       accessorKey: "tfsScreening", //simple recommended way to define a column
//       header: "TFS-Screening",
//     },
//     {
//       accessorKey: "isAuthorizedSignatory", //simple recommended way to define a column
//       header: "Authorized Signatory",
//       Cell: ({ cell }) =>
//         cell.getValue() === true ? (
//           <Typography>Yes</Typography>
//         ) : (
//           <Typography>NO</Typography>
//         ),
//     },
//   ]);

//   const columns = useMemo(() => [
//     {
//       accessorKey: "fullLegalName", //simple recommended way to define a column
//       header: "Company Name",
//     },

//     {
//       accessorKey: "TradeLicenseInfo.countryofIncorporation", //simple recommended way to define a column
//       header: "Incorporation Country",
//     },
//     {
//       accessorKey: "riskProfile", //simple recommended way to define a column
//       header: "Risk Profile",
//       Cell: ({ cell }) =>
//         cell.getValue() === "CRP Pending" ? (
//           <Typography color={"blue"} fontWeight={"bold"}>
//             CRP Pending
//           </Typography>
//         ) : cell.getValue() === "Low" ? (
//           <Typography color={"green"} fontWeight={"bold"}>
//             Low
//           </Typography>
//         ) : (
//           <Typography color={"red"} fontWeight={"bold"}>
//             {" "}
//             High
//           </Typography>
//         ),
//       // <Typography>{cell.getValue()}</Typography>
//     },
//     {
//       accessorKey: "TradeLicenseInfo.countryofIncorporation", //simple recommended way to define a column
//       header: "KYC Details",
//       Cell: ({ cell, row }) => (
//         <Link
//           component="button"
//           variant="body2"
//           color={"#1F51FF	"}
//           // onClick={handleViewKyc}
//           onClick={(e) => {
//             e.stopPropagation();
//             handleViewKyc(row.original?._id);
//             console.log("CURRENT ROW:  " + JSON.stringify(row.original?._id));
//           }}
//         >
//           View
//         </Link>
//       ),
//     },
//     {
//       accessorKey: "TradeLicenseInfo.countryofIncorporation", //simple recommended way to define a column
//       header: "Compliance Questionaire",
//       Cell: ({ cell, row }) => (
//         <Link
//           component="button"
//           color={"#1F51FF	"}
//           variant="body2"
//           onClick={(e) => {
//             e.stopPropagation();
//             handleViewCQ(row.original._id);
//           }}
//         >
//           View
//         </Link>
//       ),
//     },
//     {
//       accessorKey: "TradeLicenseInfo.countryofIncorporation", //simple recommended way to define a column
//       header: "Status",
//       Cell: ({ cell, row }) =>
//         // row.ComplianceQ &&
//         // row.riskProfile != "CRP Pending" &&
//         isAllFieldsFilled(row.original.TradeLicenseInfo) == true &&
//         isAllFieldsFilled(row.original.contactInfo) == true &&
//         isAllFieldsFilled(row.original.financialInfo ?? {}) == true &&
//         isAllFieldsFilled(row.original.shareHolders) == true &&
//         isComplianceQFilled(row.original.ComplianceQ) == true &&
//         row.original.riskProfile !== "CRP Pending" ? (
//           <Typography color={"green"}>Complete</Typography>
//         ) : (
//           <Typography color={"red"}>Incomplete</Typography>
//         ),
//     },
//     // {
//     //   accessorKey: "TradeLicenseInfo.licenseExpiry", //simple recommended way to define a column
//     //   header: "expiry",
//     //   Cell: ({ cell }) => moment(cell.getValue()).format("DD-MM-YYYY"),
//     //   display: false,
//     // },
//   ]);
//   if (data) {
//     return (
//       <Box m="1.5rem 2.5rem">
//         <Header title="Client Companies" subtitle=" All Client Companies" />
//         <Box
//           mt="40px"
//           height="75vh"
//           sx={{
//             "& .MuiDataGrid-root": {
//               border: "none",
//             },
//             "& .MuiDataGrid-cell": {
//               borderBottom: "none",
//             },
//             "& .MuiDataGrid-columnHeaders": {
//               backgroundColor: theme.palette.background.alt,
//               color: theme.palette.secondary[100],
//               borderBottom: "none",
//             },
//             "& .MuiDataGrid-virtualScroller": {
//               backgroundColor: theme.palette.primary.light,
//             },
//             "& .MuiDataGrid-footerContainer": {
//               backgroundColor: theme.palette.background.alt,
//               color: theme.palette.secondary[100],
//               borderTop: "none",
//             },
//             "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//               color: `${theme.palette.secondary[200]} !important`,
//             },
//           }}
//         >
//           <MaterialReactTable
//             columns={columns.map((c) => ({ ...c, size: 10, minSize: 10 }))}
//             enableColumnDragging={false}
//             data={data}
//             //enableRowSelection
//             enableColumnOrdering
//             enableColumnActions={false}
//             //enableEditing
//             key={"key"}
//             enableGlobalFilter={true}
//             enableRowActions
//             renderRowActionMenuItems={({ row, closeMenu }) => [
//               <MenuItem
//                 key={row.original._id + "a"}
//                 onClick={(e) => {
//                   // deleteEntity(row.original._id)
//                   //handleOpencQDialog();
//                   findObjectById();
//                   handleComplianceClick(row.original._id);
//                   closeMenu();
//                   e.stopPropagation();
//                   console.log(row.original._id);
//                 }}
//               >
//                 Compliance checks
//               </MenuItem>,
//               <MenuItem
//                 key={row.original._id + "aab"}
//                 onClick={(e) => {
//                   findObjectById();
//                   handleCrpClick(row.original);
//                   closeMenu();
//                   e.stopPropagation();
//                 }}
//               >
//                 Customer Risk Profile (CRP)
//               </MenuItem>,
//               <MenuItem
//                 key={row.original._id + "b"}
//                 onClick={(e) => {
//                   closeMenu();
//                   // handleOpenDialog();
//                   // handleEditClick();
//                   handleEditClick(row.original._id);
//                   console.log(row.original._id);
//                   e.stopPropagation();
//                 }}
//               >
//                 Edit KYC
//               </MenuItem>,
//               <Wrapper row={row.original} />,
//               <MenuItem
//                 key={row.original._id + "c"}
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   deleteEntity(row.original._id);
//                   closeMenu();
//                 }}
//               >
//                 Delete Record
//               </MenuItem>,
//             ]}
//             renderDetailPanel={({ row }) => (
//               <div style={{ display: "grid" }}>
//                 <Typography variant="h5">Share Holders </Typography>
//                 <MaterialReactTable
//                   columns={shColumns}
//                   data={row.original.shareHolders}
//                   key={row.original._id}
//                   enableColumnActions={false}
//                   enableColumnFilters={false}
//                   enablePagination={false}
//                   enableSorting={false}
//                   enableBottomToolbar={false}
//                   enableTopToolbar={false}
//                   layoutMode="grid"
//                   //muiTableBodyRowProps={{ hover: false }}
//                 />
//               </div>
//             )}
//             renderTopToolbarCustomActions={({ table }) => (
//               <Box style={{ width: "900px" }}>
//                 {" "}
//                 <Grid container spacing={2}>
//                   <Grid item>
//                     <Button
//                       variant="outlined"
//                       color="secondary"
//                       onClick={handleOpenAddDialog}
//                     >
//                       Add New
//                     </Button>
//                   </Grid>
//                   {/* <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() =>
//                       table.setColumnFilters((prev) => [
//                         ...prev,
//                         {
//                           id: "TradeLicenseInfo.expiry",
//                           value: "Expired",
//                         },
//                       ])
//                     }
//                   >
//                     Expired
//                   </Button> */}
//                   {/* <Button
//                   onClick={() => table.resetColumnFilters()}
//                   variant="outlined"
//                   sx={{ color: "blue" }}
//                   //color={"danger"}
//                 >
//                   Reset Filters
//                 </Button> */}
//                   {/* <AddEntity
//                   open={addDialogOpen}
//                   onClose={handleCloseAddDialog}
//                   data={[]}
//                   //data={{ shareHolders: [] }}
//                 /> */}
//                   <Grid item>
//                     <Button
//                       variant="outlined"
//                       color="success"
//                       onClick={exportToExcel}
//                     >
//                       Export
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </Box>
//             )}
//             muiTablePaperProps={{
//               sx: {
//                 maxWidth: "82vw",
//                 m: "auto",
//               },
//             }}
//           />
//         </Box>
//         <AddEntity
//           open={addDialogOpen}
//           onClose={handleCloseAddDialog}
//           data={[]}
//           //data={{ shareHolders: [] }}
//         />
//         <ViewKyc
//           open={kYcDialogOpen}
//           onClose={handleCloseKyc}
//           data={findObjectById()}
//         />
//         <ViewCQ
//           open={cQvDialogOpen}
//           onClose={handleCloseViewCQ}
//           id={currentRowId}
//           data={findObjectById()}
//         />
//         <LegalPerson
//           open={cRpdialogOpen}
//           onClose={handleCloseCrp}
//           id={currentRowId}
//           data={findObjectById()}
//         />
//         {cQDialogOpen ? (
//           <ComplianceQ
//             open={cQDialogOpen}
//             onClose={handleClosecQDialog}
//             id={currentRowId}
//             data={findObjectById()}
//           />
//         ) : null}
//         {dialogOpen ? (
//           <EditEntity
//             open={dialogOpen}
//             onClose={handleCloseDialog}
//             data={findObjectById()}
//           />
//         ) : null}
//         {/* <EditEntity
//           open={dialogOpen}
//           onClose={handleCloseDialog}
//           data={JSON.stringify(findObjectById())}
//         /> */}
//       </Box>
//     );
//   } else return <>Loading</>;
// };

// export default Entities;

// const Wrapper = ({ row, user }) => {
//   const componentRef = useRef();

//   return (
//     <>
//       <ReactToPrint
//         trigger={() => (
//           <MenuItem
//             // color="success"
//             // variant="text"
//             style={{ margin: "0 auto", alignContent: "left" }}
//           >
//             {/* <PrintIcon /> Print */}
//             Print KYC
//           </MenuItem>
//         )}
//         content={() => componentRef.current}
//       />
//       <div style={{ display: "none" }}>
//         <EntityPrint ref={componentRef} data={row} user={user} />
//       </div>
//     </>
//   );
// };

import React, { useMemo, useState, useRef } from "react";
import {
  Box,
  Typography,
  useTheme,
  MenuItem,
  Button,
  Grid,
  Link,
} from "@mui/material";
import EntityPrint from "../../components/EntityPrint";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import * as XLSX from "xlsx";
import { useGetCustomersQuery, useDeleteCustomersMutation } from "state/api";
import Header from "components/Header";
import { MaterialReactTable } from "material-react-table";
import EditEntity from "components/editEntity";
import AddEntity from "components/addEntity";
import moment from "moment";
import ComplianceQ from "components/ComplianceQ";
import Crp from "components/Crp";
import LegalPerson from "scenes/LegalPersonCRP";
import ViewKyc from "components/viewKyc";
import ViewCQ from "components/viewComplianceQ";
import { useReactToPrint } from "react-to-print";

import { isAllFieldsFilled, isComplianceQFilled } from "components/utils";

const Entities = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetCustomersQuery();
  const [deleteEntity] = useDeleteCustomersMutation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cRpdialogOpen, setcRpDialogOpen] = useState(false);
  const [cQDialogOpen, setcQDialogOpen] = useState(false);
  const [cQvDialogOpen, setcQvDialogOpen] = useState(false);
  const [kYcDialogOpen, setkYcDialogOpen] = useState(false);
  const [addDialogOpen, setaddDialogOpen] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [currentRowData, setCurrentRowData] = useState(null);

  function findObjectById() {
    if (!isLoading && currentRowId) {
      console.log(
        "currentRow:",
        data.find((obj) => obj?._id === currentRowId)
      );
      const currentRow = data.find((obj) => obj?._id === currentRowId);
      return currentRow;
    }
  }

  const handleViewKyc = (rowId) => {
    setCurrentRowId(rowId);
    setkYcDialogOpen(true);
  };

  const handleCloseKyc = () => {
    setkYcDialogOpen(false);
  };

  const handleViewCQ = (rowId) => {
    setCurrentRowId(rowId);
    setcQvDialogOpen(true);
  };

  const handleCloseViewCQ = () => {
    setcQvDialogOpen(false);
  };

  const handleComplianceClick = (rowId) => {
    setCurrentRowId(rowId);
    setcQDialogOpen(true);
  };

  const handleCrpClick = (row) => {
    setCurrentRowId(row._id);
    setCurrentRowData(row);
    setcRpDialogOpen(true);
  };

  const handleEditClick = (rowId) => {
    setCurrentRowId(rowId);
    setCurrentRowData();
    setDialogOpen(true);
  };

  const transformData = () => {
    const legalPersons = [];
    const allShareholders = [];

    data.forEach((entry) => {
      const {
        fullLegalName,
        shareHolders,
        registeredAddress,
        businessAddress,
        TradeLicenseInfo,
        restodEntry,
      } = entry;

      legalPersons.push({
        "Full Legal Name": fullLegalName,
        "registered Address": registeredAddress,
        "business Address": businessAddress,
        "trade Lic. No": TradeLicenseInfo.tradeLicenseNo,
        "business Activity": TradeLicenseInfo.businessActivity,
        "lic Authority": TradeLicenseInfo.licensingAuthority,
        "country of Incorp.": TradeLicenseInfo.countryofIncorporation,
        "incorporation Date": moment(TradeLicenseInfo.incoroprationDate).format(
          "DD-MM-YYYY"
        ),
      });

      shareHolders.forEach((shareholder) => {
        const {
          contactInfo,
          idDoc,
          isAuthorizedSignatory,
          restofShareholder,
          percentShares,
          ubo,
          uboDate,
          uboReason,
        } = shareholder;
        allShareholders.push({
          "parent Legal Name": fullLegalName,
          "contact Type": contactInfo.contactType,
          "contact Country": contactInfo.contactCountry,
          "contact Number": contactInfo.contactNumber,
          "ID Type": idDoc.type,
          "ID Number": idDoc.number,
          "ID Issue Country": idDoc.issueCountry,
          "ID Issue Date": moment(idDoc.issueDate).format("DD-MM-YYYY"),
          "ID Expiry Date": moment(idDoc.expiryDate).format("DD-MM-YYYY"),
          "percentage Shares": percentShares,
          "Authorized Signatory": isAuthorizedSignatory ? "Yes" : "No",
          UBO: ubo ? "Yes" : "No",
          "ubo Reason": uboReason,
          "UBO Date": moment(uboDate).format("MM-DD-YYYY"),
          ...restofShareholder,
        });
      });
    });

    return { legalPersons, allShareholders };
  };

  const exportToExcel = () => {
    const { legalPersons, allShareholders } = transformData();

    const wb = XLSX.utils.book_new();

    const ws1 = XLSX.utils.json_to_sheet(legalPersons);
    const ws2 = XLSX.utils.json_to_sheet(allShareholders);

    const capitalizeInitials = (str) => {
      return str
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    const formatFirstRow = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let col = range.s.c; col <= range.e.c; ++col) {
        const address = XLSX.utils.encode_cell({ r: 0, c: col });
        if (!ws[address]) continue;
        ws[address].v = capitalizeInitials(ws[address].v);
        ws[address].s = {
          font: {
            bold: true,
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFF00" },
            bgColor: { rgb: "FFFF00" },
          },
        };
      }
    };

    formatFirstRow(ws1);
    formatFirstRow(ws2);

    XLSX.utils.book_append_sheet(wb, ws1, "LegalPersons");
    XLSX.utils.book_append_sheet(wb, ws2, "ShareHolders");

    XLSX.writeFile(wb, "CombinedData.xlsx");
  };

  const handleOpencQDialog = () => {
    setcQDialogOpen(true);
  };

  const handleClosecQDialog = () => {
    setcQDialogOpen(false);
  };

  const handleCloseCrp = () => {
    setcRpDialogOpen(false);
  };

  const handleOpenAddDialog = () => {
    setaddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setaddDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const shColumns = useMemo(() => [
    {
      accessorKey: "fullLegalName",
      header: "Name",
    },
    {
      accessorKey: "idDoc.type",
      header: "ID Doc",
    },
    {
      accessorKey: "idDoc.issueCountry",
      header: "Issue Country",
    },
    {
      accessorKey: "idDoc.number",
      header: "Number",
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow.idDoc.issueDate;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Issue Date",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow.idDoc.expiryDate;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Expiry Date",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow.doB;
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        function parseDateTime(s) {
          var b = s.split(/\D/);
          return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return parseDateTime(dateString).toLocaleDateString("en-CA");
      },
      header: "Date of Birth",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorKey: "nationality",
      header: "Nationality",
    },
    {
      accessorKey: "residentialStatus",
      header: "Residential Status",
    },
    {
      accessorKey: "percentShares",
      header: "% Shares",
    },
    {
      accessorKey: "contactInfo.contactType",
      header: "Contact Type",
    },
    {
      accessorKey: "contactInfo.contactCountry",
      header: "Phone Country",
    },
    {
      accessorKey: "contactInfo.contactNumber",
      header: "Number",
    },
    {
      accessorKey: "ubo",
      header: "UBO",
      Cell: ({ cell }) =>
        cell.getValue() === true ? (
          <Typography>Yes</Typography>
        ) : (
          <Typography>NO</Typography>
        ),
    },
    {
      accessorKey: "uboReason",
      header: "UBO Reason",
      Cell: ({ cell }) =>
        cell.getValue() ? (
          <Typography>{cell.getValue()}</Typography>
        ) : (
          <Typography>NA</Typography>
        ),
    },
    {
      accessorFn: (originalRow) => {
        const dateString = originalRow?.uboDate;

        return originalRow.uboDate
          ? moment(dateString).format("DD-MM-YY")
          : "NA";
      },
      header: "UBO Date",
      type: "date",
      muiTableBodyCellEditTextFieldProps: {
        type: "date",
      },
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      sortingFn: "datetime",
      dateSetting: { locale: "en-CA" },
    },
    {
      accessorKey: "PepStatus",
      header: "PEP",
      Cell: ({ cell }) =>
        cell.getValue() === true ? (
          <Typography>Yes</Typography>
        ) : (
          <Typography>NO</Typography>
        ),
    },
    {
      accessorKey: "tfsScreening",
      header: "TFS-Screening",
    },
    {
      accessorKey: "isAuthorizedSignatory",
      header: "Authorized Signatory",
      Cell: ({ cell }) =>
        cell.getValue() === true ? (
          <Typography>Yes</Typography>
        ) : (
          <Typography>NO</Typography>
        ),
    },
  ]);

  const columns = useMemo(() => [
    {
      accessorKey: "fullLegalName",
      header: "Company Name",
    },
    {
      accessorKey: "TradeLicenseInfo.countryofIncorporation",
      header: "Incorporation Country",
    },
    {
      accessorKey: "riskProfile",
      header: "Risk Profile",
      Cell: ({ cell, row }) =>
        cell.getValue() === "High Risk" ? (
          <Typography color={"red"} fontWeight={"bold"}>
            High Risk
          </Typography>
        ) : cell.getValue() === "Medium Risk" ? (
          <Typography color={"olive"} fontWeight={"bold"}>
            Medium Risk
          </Typography>
        ) : cell.getValue() === "Medium High Risk" ? (
          <Typography color={"darkviolet"} fontWeight={"bold"}>
            Medium High Risk
          </Typography>
        ) : cell.getValue() === "Low Risk" ? (
          <Typography color={"darlgreen"} fontWeight={"bold"}>
            Low Risk{" "}
          </Typography>
        ) : (
          <></>
        ),
    },
    {
      accessorKey: "TradeLicenseInfo.countryofIncorporation",
      header: "KYC Details",
      Cell: ({ cell, row }) => (
        <Link
          component="button"
          variant="body2"
          color={"#1F51FF	"}
          onClick={(e) => {
            e.stopPropagation();
            handleViewKyc(row.original?._id);
            console.log("CURRENT ROW:  " + JSON.stringify(row.original?._id));
          }}
        >
          View
        </Link>
      ),
    },
    {
      accessorKey: "TradeLicenseInfo.countryofIncorporation",
      header: "Compliance Questionaire",
      Cell: ({ cell, row }) => (
        <Link
          component="button"
          color={"#1F51FF	"}
          variant="body2"
          onClick={(e) => {
            e.stopPropagation();
            handleViewCQ(row.original._id);
          }}
        >
          View
        </Link>
      ),
    },
    {
      accessorKey: "TradeLicenseInfo.countryofIncorporation",
      header: "Status",
      Cell: ({ cell, row }) =>
        isAllFieldsFilled(row.original.TradeLicenseInfo) == true &&
        isAllFieldsFilled(row.original.contactInfo) == true &&
        isAllFieldsFilled(row.original.financialInfo ?? {}) == true &&
        isAllFieldsFilled(row.original.shareHolders) == true &&
        isComplianceQFilled(row.original.ComplianceQ) == true &&
        row.original.riskProfile !== "CRP Pending" ? (
          <Typography color={"green"}>Complete</Typography>
        ) : (
          <Typography color={"red"}>Incomplete</Typography>
        ),
    },
  ]);

  if (data) {
    return (
      <Box m="1.5rem 2.5rem">
        <Header title="Client Companies" subtitle=" All Client Companies" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <MaterialReactTable
            columns={columns.map((c) => ({ ...c, size: 10, minSize: 10 }))}
            enableColumnDragging={false}
            data={data}
            enableColumnOrdering
            enableColumnActions={false}
            key={"key"}
            enableGlobalFilter={true}
            enableRowActions
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <MenuItem
                key={row.original._id + "a"}
                onClick={(e) => {
                  findObjectById();
                  handleComplianceClick(row.original._id);
                  closeMenu();
                  e.stopPropagation();
                  console.log(row.original._id);
                }}
              >
                Compliance checks
              </MenuItem>,
              <MenuItem
                key={row.original._id + "aab"}
                onClick={(e) => {
                  findObjectById();
                  handleCrpClick(row.original);
                  closeMenu();
                  e.stopPropagation();
                }}
              >
                Customer Risk Profile (CRP)
              </MenuItem>,

              <MenuItem
                key={row.original._id + "b"}
                onClick={(e) => {
                  closeMenu();
                  handleEditClick(row.original._id);
                  console.log(row.original._id);
                  e.stopPropagation();
                }}
              >
                Edit KYC
              </MenuItem>,
              <Wrapper row={row.original} />,
              <MenuItem
                key={row.original._id + "c"}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteEntity(row.original._id);
                  closeMenu();
                }}
              >
                Delete Record
              </MenuItem>,
            ]}
            renderDetailPanel={({ row }) => (
              <div style={{ display: "grid" }}>
                <Typography variant="h5">Share Holders </Typography>
                <MaterialReactTable
                  columns={shColumns}
                  data={row.original.shareHolders}
                  key={row.original._id}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enablePagination={false}
                  enableSorting={false}
                  enableBottomToolbar={false}
                  enableTopToolbar={false}
                  layoutMode="grid"
                />
              </div>
            )}
            renderTopToolbarCustomActions={({ table }) => (
              <Box style={{ width: "900px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleOpenAddDialog}
                    >
                      Add New
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={exportToExcel}
                    >
                      Export
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            muiTablePaperProps={{
              sx: {
                maxWidth: "82vw",
                m: "auto",
              },
            }}
          />
        </Box>
        <AddEntity
          open={addDialogOpen}
          onClose={handleCloseAddDialog}
          data={[]}
        />
        <ViewKyc
          open={kYcDialogOpen}
          onClose={handleCloseKyc}
          data={findObjectById()}
        />
        <ViewCQ
          open={cQvDialogOpen}
          onClose={handleCloseViewCQ}
          id={currentRowId}
          data={findObjectById()}
        />
        <Crp
          open={cRpdialogOpen}
          onClose={handleCloseCrp}
          data={currentRowData}
          id={currentRowId}
        />
        {cQDialogOpen ? (
          <ComplianceQ
            open={cQDialogOpen}
            onClose={handleClosecQDialog}
            id={currentRowId}
            data={findObjectById()}
          />
        ) : null}
        {dialogOpen ? (
          <EditEntity
            open={dialogOpen}
            onClose={handleCloseDialog}
            data={findObjectById()}
          />
        ) : null}
      </Box>
    );
  } else return <>Loading</>;
};

export default Entities;

const Wrapper = ({ row, user }) => {
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <MenuItem style={{ margin: "0 auto", alignContent: "left" }}>
            Print KYC
          </MenuItem>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }}>
        <EntityPrint ref={componentRef} data={row} user={user} />
      </div>
    </>
  );
};
