import React, { useState, useEffect } from "react";
import {
  Box,
  Fab,
  FormControlLabel,
  Button,
  Checkbox,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  InputLabel,
  Select,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";
import { useEditCustomersMutation } from "state/api";
import Autocomplete from "@mui/material/Autocomplete";
import { usePostCustomersMutation, useGetCountriesQuery } from "state/api";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { transformShareHolders, transformData } from "./utils";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { countryNames, businessActivityScores } from "data/data";

const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
const userId = storedValue?._id;

const countryMenuItems = countryNames.map((country) => (
  <MenuItem value={`${country}`} key={country}>
    {country}
  </MenuItem>
));

const businessActivityMenuItems = businessActivityScores.map((activity) => (
  <MenuItem value={`${activity.Name}`} key={activity.Name}>
    {activity.Name}
  </MenuItem>
));

const EditEntity = ({ open, onClose, data }) => {
  console.log(data);
  const [fields, setFields] = useState([]);
  console.log("Received in editEntity.jsx" + JSON.stringify(data._id));

  const [editEntity] = useEditCustomersMutation();

  const initialValues = {
    _id: data._id,
    legalName: data.fullLegalName || "",
    screeningResult: data.screeningResult || "", // Assuming this field exists in your data
    registeredAddress: data.registeredAddress || "",
    businessActivity: data.TradeLicenseInfo.businessActivity || "",
    tradeLicenseNo: data.TradeLicenseInfo.tradeLicenseNo || "",
    taxNo: data.taxNo || "",
    countryofIncorporation: data.TradeLicenseInfo.countryofIncorporation || "",
    licensingAuthority: data.TradeLicenseInfo.licensingAuthority || "",
    businessAddress: data.businessAddress || "",
    incoroprationDate:
      moment(data.TradeLicenseInfo.incoroprationDate)
        .utc()
        .format("YYYY-MM-DD") || "",
    licenseExpiry:
      moment(data.TradeLicenseInfo.licenseExpiry).utc().format("YYYY-MM-DD") ||
      "",
    contactType: data.contactInfo.contactType || "",
    contactCountry: data.contactInfo.contactCountry || "",
    contactNumber: data.contactInfo.contactNumber || "",
    emailAddress: data.contactInfo.emailAddress || "",
    jurisdiction: data.jurisdiction || "", // Assuming this field exists in your data
    POBox: data.contactInfo.POBox || "",
    website: data.contactInfo.website || "",
    bankName: data.financialInfo.bankName || "",
    accountNo: data.financialInfo.accountNo || "",
    accountName: data.financialInfo.accountName || "",
    iban: data.financialInfo.iban || "",
    bicSwift: data.financialInfo.bicSwift || "",
    currency: data.financialInfo.currency || "",
    yearOfRelationship: data.financialInfo.yearOfRelationship || "",
    branch: data.financialInfo.branch || "",
    anualBusiness: data.financialInfo.anualBusiness || "",
    totalShareCapital: data.financialInfo.totalShareCapital || "",
    totalShareHolderEquity: data.financialInfo.totalShareHolderEquity || "",
    totalAssets: data.financialInfo.totalAssets || "",
    totalAnualSales: data.financialInfo.totalAnualSales || "",
    sourceOfFunds: data.sourceOfFunds || "", // Assuming this field exists in your data
    shareHolders: data.shareHolders || [],
  };
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [id]: value,
    }));
    console.log(id + ";" + value);
  };
  const handleDropdownChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
    console.log(name + ":" + value);
  };

  useEffect(() => {
    var newSh = [];
    data.shareHolders.map((sh, index) => {
      var _shareHolder = {
        id: index,
        fullName: sh.fullLegalName,
        legalNameSanction: sh.legalNameSanction, // Just added
        nationality: sh.nationality, // Just added
        pep: sh.pep, // Just added
        dualNationality: sh.dualNationality, // Just added
        occupation: sh.occupation, // Just added
        idType: sh.idDoc.type,
        country: sh.idDoc.issueCountry,
        number: sh.idDoc.number,
        issueDate: moment(sh.idDoc.issueDate).utc().format("YYYY-MM-DD"),
        expiryDate: moment(sh.idDoc.expiryDate).utc().format("YYYY-MM-DD"),
        birthDate: moment(sh.doB).utc().format("YYYY-MM-DD"),
        residentialStatus: sh.residentialStatus,
        percentShares: sh.percentShares,
        contactType: sh.contactInfo.contactType,
        contactCountry: sh.contactInfo.contactCountry,
        contactNumber: sh.contactInfo.contactNumber,
        authorizedSignatory: sh.isAuthorizedSignatory,
        ubo: sh.ubo,
        uboReason: sh.uboReason,
        uboDate: moment(sh.UboDate).utc().format("YYYY-MM-DD"),
      };
      newSh.push(_shareHolder);
    });
    setFields(newSh);
  }, []);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      fullName: "",
      legalNameSanction: "", // Just added
      nationality: "", // Just added
      dualNationality: "", // Just added
      occupation: "", // Just added
      idType: "",
      country: "",
      number: "",
      issueDate: "",
      expiryDate: "",
      birthDate: "",
      residentialStatus: "",
      percentShares: "",
      contactType: "",
      contactCountry: "",
      contactNumber: "",
      authorizedSignatory: false,
      ubo: false,
      uboReason: "",
      uboDate: "",
      pep: false,
      pepReason: " ",
    };
    setFields([...fields, newField]);
  };

  const handleFieldChange = (id, key, value) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, [key]: value } : field
    );
    console.log(key + ";" + value);
    setFields(updatedFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const transformedValues = transformData(formValues);
    const transformedShHolders = fields.map((f) => {
      return transformShareHolders(f);
    });
    formValues.shareHolders = [...fields];
    transformedValues.shareHolders = [...transformedShHolders];
    console.log("transformedValues", transformedValues);
    console.log("formValues", formValues);
    editEntity(transformedValues);
    onClose();
  };

  const removeField = (index) => {
    const filteredFields = [...fields];
    filteredFields.splice(index, 1);
    setFields(filteredFields);
  };
  console.log("Edit KYC: ", data);
  return data ? (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <form onSubmit={handleSubmit}>
        <DialogTitle color={"#ffc76b"} fontWeight={"bold"}>
          Edit Entity
        </DialogTitle>{" "}
        <Grid
          container={true}
          columnSpacing={3}
          columns={{ xs: 4, sm: 8, md: 12 }}
          padding="45px"
          paddingTop={0}
        >
          <Grid item xs={5}>
            <InputLabel>Legal Name</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="legalName"
              value={formValues.legalName}
              type="text"
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={7}>
            <InputLabel>Registered Address</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="registeredAddress"
              // label="Registered Address"
              value={formValues.registeredAddress}
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={8}>
            <InputLabel>Business Address</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="businessAddress"
              // label="Registered Address"
              value={formValues.businessAddress}
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Tax Reg. No.</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="taxNo"
              //label="tradeLicenseNo"
              value={formValues.taxNo}
              type="text"
              fullWidth
              onChange={handleChange}
            />{" "}
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={5}>
              <InputLabel>Source of Funds</InputLabel>{" "}
              <SourceOfFunds
                sourceOfFunds={formValues.sourceOfFunds ?? ""}
                onChange={handleDropdownChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontWeight={"bold"}
              paddingTop={"5px"}
              paddingBottom={"5px"}
              color={"#ffc76b"}
            >
              Trade License Info
            </Typography>{" "}
            <Divider color={"#ffc76b"} />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Business Activity</InputLabel>{" "}
            <BusinessActivity
              value={formValues.businessActivity}
              onChange={handleDropdownChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Trade Lic. No.</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="tradeLicenseNo"
              //label="tradeLicenseNo"
              value={formValues.tradeLicenseNo}
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Country of incorporation</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              //id="countryofIncorporation"
              name="countryofIncorporation"
              select
              //label="Country of Incorporation"
              //   Value={formValues.countryofIncorporation}
              defaultValue={formValues.countryofIncorporation}
              fullWidth
              placeholder="Country of Incorporation"
              onChange={handleDropdownChange}
            >
              {countryMenuItems}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Licensing Authority</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="licensingAuthority"
              //label="Licensing Authority"
              value={formValues.licensingAuthority}
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Jurisdiction</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              name="jurisdiction"
              select
              defaultValue={formValues.jurisdiction}
              fullWidth
              placeholder="Country of Incorporation"
              onChange={handleDropdownChange}
            >
              <MenuItem value={"FREE ZONE"} key={1}>
                {"FREE ZONE"}
              </MenuItem>
              <MenuItem value={"MAIN LAND"} key={2}>
                {"MAIN LAND"}
              </MenuItem>
              <MenuItem value={"OUTSIDE UAE"} key={3}>
                {"OUTSIDE UAE"}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Incorporation Date</InputLabel>
            <TextField
              // label="Incorporation Date"
              autoFocus
              type="date"
              id="incoroprationDate"
              required
              defaultValue={formValues.incoroprationDate}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <InputLabel>Lic. Expiry Date</InputLabel>
            <TextField
              // label="Lic. Expiry Date"
              type="date"
              id="licenseExpiry"
              // value={formValues.licenseExpiry}
              required
              defaultValue={formValues.licenseExpiry}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontWeight={"bold"}
              paddingTop={"5px"}
              paddingBottom={"5px"}
              color={"#ffc76b"}
            >
              Contact Info
            </Typography>{" "}
            <Divider color={"#ffc76b"} />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Contact Type</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              name="contactType"
              select
              defaultValue={formValues.contactType}
              fullWidth
              placeholder="Contact Type"
              onChange={handleDropdownChange}
            >
              <MenuItem value="Telephone">Phone</MenuItem>
              <MenuItem value="Mobile">Mobile</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Contact Country</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              name="contactCountry"
              select
              defaultValue={formValues.contactCountry}
              fullWidth
              placeholder="Contact Country"
              onChange={handleDropdownChange}
            >
              {countryMenuItems}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Number</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="contactNumber"
              type="text"
              value={formValues.contactNumber}
              onChange={handleChange}
              fullWidth
              placeholder="Number"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Email</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="emailAddress"
              type="text"
              value={formValues.emailAddress}
              onChange={handleChange}
              fullWidth
              placeholder="Email Address"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>PO Box</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="POBox"
              type="text"
              value={formValues.POBox}
              onChange={handleChange}
              fullWidth
              placeholder="PO Box"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Website</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="website"
              type="text"
              value={formValues.website}
              onChange={handleChange}
              fullWidth
              placeholder="Website"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontWeight={"bold"}
              paddingTop={"5px"}
              paddingBottom={"5px"}
              color={"#ffc76b"}
            >
              Financial Info
            </Typography>{" "}
            <Divider color={"#ffc76b"} paddingBottom={"5px"} />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Bank Name </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="bankName"
              type="text"
              value={formValues.bankName}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Account no. </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="accountNo"
              type="text"
              value={formValues.accountNo}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Account name </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="accountName"
              type="text"
              value={formValues.accountName}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>IBAN </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="iban"
              type="text"
              value={formValues.iban}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>BIC/SWIFT</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="bicSwift"
              type="text"
              value={formValues.bicSwift}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Currency of Account </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="currency"
              type="text"
              value={formValues.currency}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Year of relationship with bank </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="yearOfRelationship"
              type="text"
              value={formValues.yearOfRelationship}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Bank Br Name, City, Town </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="branch"
              type="text"
              value={formValues.branch}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Expected Annual Business with coompany </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="anualBusiness"
              type="text"
              value={formValues.anualBusiness}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Total Share Capital </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="totalShareCapital"
              type="text"
              value={formValues.totalShareCapital}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Total Shareholder equity </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="totalShareHolderEquity"
              type="text"
              value={formValues.totalShareHolderEquity}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Total assets</InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="totalAssets"
              type="text"
              value={formValues.totalAssets}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Latest Annual Sales </InputLabel>
            <TextField
              autoFocus
              margin="dense"
              id="totalAnualSales"
              type="text"
              value={formValues.totalAnualSales}
              onChange={handleChange}
              fullWidth
              // placeholder="AED"
            />
          </Grid>
          {
            <Grid item xs={12}>
              {" "}
              {/* <Typography paddingTop={"10px"} variant="h5">
                  Share Holders
                </Typography>
                <Divider /> */}
              {fields.length > 0 && (
                <Typography
                  paddingTop={"10px"}
                  variant="h5"
                  fontWeight={"bold"}
                  color={"#ffc76b"}
                >
                  Share Holders
                  <Divider color={"#ffc76b"} />
                </Typography>
              )}
            </Grid>
          }
          {/* Third Section */}
          {fields.map((field, index) => (
            <Grid
              sx={{
                p: 2,
                backgroundColor: "#FFFDE9",
                margin: "auto",
                title: "home",
                borderRadius: "10px",
              }}
              container="true"
              columnSpacing={3}
              columns={{ xs: 4, sm: 8, md: 12 }}
              paddingTop={1}
              paddingLeft={"40px"}
              key={index}
            >
              {" "}
              <Grid item xs={3}>
                <InputLabel id={`FullName-label-${field.id}`}>
                  Full Name
                </InputLabel>
                <TextField
                  value={field.fullName}
                  onChange={(event) =>
                    handleFieldChange(field.id, "fullName", event.target.value)
                  }
                  fullWidth
                  //   margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <InputLabel id={`idType-label-${field.id}`}>ID Type</InputLabel>
                <Select
                  labelId={`idType-label-${field.id}`}
                  value={field.idType}
                  onChange={(event) =>
                    handleFieldChange(field.id, "idType", event.target.value)
                  }
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Emirates ID">Emirates ID</MenuItem>
                  <MenuItem value="Passport">Passport</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel id={`country-label-${field.id}`}>
                  Issue Country
                </InputLabel>
                <Select
                  labelId={`country-label-${field.id}`}
                  value={field.country}
                  onChange={(event) =>
                    handleFieldChange(field.id, "country", event.target.value)
                  }
                  fullWidth
                  margin="normal"
                >
                  {countryMenuItems}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel id={`nationality-label-${field.id}`}>
                  Nationality
                </InputLabel>
                <Select
                  labelId={`nationality-label-${field.id}`}
                  value={field.nationality}
                  defaultValue={""}
                  required
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "nationality",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                >
                  {countryMenuItems}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel id={`FullName-label-${field.id}`}>
                  Number{" "}
                </InputLabel>
                <TextField
                  value={field.number}
                  onChange={(event) =>
                    handleFieldChange(field.id, "number", event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  type="date"
                  required
                  value={field.issueDate}
                  onChange={(event) =>
                    handleFieldChange(field.id, "issueDate", event.target.value)
                  }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Expiry Date"
                  type="date"
                  required
                  value={field.expiryDate}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "expiryDate",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Birth Date"
                  type="date"
                  value={field.birthDate}
                  required
                  onChange={(event) =>
                    handleFieldChange(field.id, "birthDate", event.target.value)
                  }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <InputLabel id={`residentialStatus-label-${field.id}`}>
                  Residential Status
                </InputLabel>
                <Select
                  labelId={`residentialStatus-label-${field.id}`}
                  value={field?.residentialStatus}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "residentialStatus",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Resident">Resident</MenuItem>
                  <MenuItem value="Non Resident">Non Resident</MenuItem>
                  <MenuItem value="Permanent Resident">
                    Permanent Resident
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Percent Shares"
                  value={field.percentShares}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "percentShares",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <InputLabel id={`dualNationality-label-${field.id}`}>
                  Dual Nationality{" "}
                </InputLabel>
                <Select
                  labelId={`dualNationality-label-${field.id}`}
                  value={field?.dualNationality}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "dualNationality",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel id={`residentialStatus-label-${field.id}`}>
                  Occupation{" "}
                </InputLabel>
                <Select
                  labelId={`occupation-label-${field.id}`}
                  value={field.occupation}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "occupation",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                >
                  {businessActivityMenuItems}

                  <MenuItem value="Occupation1">Occupation1</MenuItem>
                  <MenuItem value="Occupation2">Occupation2</MenuItem>
                  <MenuItem value="Occupation3">Occupation3</MenuItem>
                  <MenuItem value="Occupation4">Occupation4</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  paddingBottom={"5px"}
                  paddingTop={"5px"}
                  color={"#ffc76b"}
                >
                  Contact Info
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel id={`contactType-label-${field.id}`}>
                  Contact Type
                </InputLabel>
                <Select
                  //   labelId={`contactType-label-${field.id}`}
                  value={field.contactType}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "contactType",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Telephone">Phone</MenuItem>
                  <MenuItem value="Mobile">Mobile</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <InputLabel id={`contactCountry-label-${field.id}`}>
                  Contact Country
                </InputLabel>
                <Select
                  //   labelId={`contactCountry-label-${field.id}`}
                  value={field.contactCountry}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "contactCountry",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                >
                  {/* Replace with your list of country names */}
                  {countryMenuItems}
                  {/* <MenuItem value="country1">Country 1</MenuItem>
                <MenuItem value="country2">Country 2</MenuItem> */}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Contact Number"
                  value={field.contactNumber}
                  onChange={(event) =>
                    handleFieldChange(
                      field.id,
                      "contactNumber",
                      event.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.authorizedSignatory}
                      onChange={(event) =>
                        handleFieldChange(
                          field.id,
                          "authorizedSignatory",
                          event.target.checked
                        )
                      }
                    />
                  }
                  label="Authorized Signatory"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.ubo}
                      onChange={(event) =>
                        handleFieldChange(field.id, "ubo", event.target.checked)
                      }
                    />
                  }
                  label="UBO"
                />
              </Grid>
              <Grid item xs={4}>
                {field.ubo && (
                  <>
                    <InputLabel id={`uboReason-label-${field.id}`}>
                      UBO Reason
                    </InputLabel>
                    <Select
                      labelId={`uboReason-label-${field.id}`}
                      value={field.uboReason}
                      onChange={(event) =>
                        handleFieldChange(
                          field.id,
                          "uboReason",
                          event.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                    >
                      <MenuItem value="SHARE HOLDING">Share Holding</MenuItem>
                      <MenuItem value="CONTROL">Control</MenuItem>
                    </Select>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                {field.ubo && (
                  <>
                    <TextField
                      label="UBO Date"
                      type="date"
                      value={field.uboDate}
                      required
                      onChange={(event) =>
                        handleFieldChange(
                          field.id,
                          "uboDate",
                          event.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </>
                )}
              </Grid>{" "}
              <Grid item xs={1}>
                <DeleteOutlineOutlinedIcon
                  color="error"
                  title="Delete"
                  onClick={() => {
                    removeField(index);
                  }}
                />
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              alignItems={"right"}
              color="success"
              justifyItems="right"
              onClick={addField}
            >
              Add Share Holders
            </Button>
          </Grid>
          {/* </DialogContent>
        </DialogContent> */}
        </Grid>
        <Box sx={{ "& > :not(style)": { m: 1 } }}></Box>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            // onClick={handleSubmit}
            type="submit"
            color="success"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    <></>
  );
};

export default EditEntity;

// const label_ubo = { inputProps: { "aria-label": "UBO" } };
// const countries = [
//   {
//     value: "Select Country",
//     label: "$",
//   },
//   {
//     value: "China",
//     label: "€",
//   },
//   {
//     value: "China",
//     label: "€",
//   },
//   {
//     value: "UAE",
//     label: "฿",
//   },
//   {
//     value: "Japan",
//     label: "¥",
//   },
// ];
// const contacts = [
//   {
//     value: "Phone",
//     lable: "Phone",
//   },
//   {
//     value: "Mobile",
//     lable: "Mobile",
//   },
// ];
// const id_Doc = [
//   {
//     value: "Passport",
//     lable: "Passport",
//   },
//   {
//     value: "ID",
//     lable: "ID",
//   },
// ];

const BusinessActivity = ({ value, onChange }) => {
  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  if (isLoadingCountries) {
    return <div>Loading...</div>;
  }

  const handleAutocompleteChange = (event, newValue) => {
    onChange({
      target: {
        name: "businessActivity",
        value: newValue ? newValue.Name : "",
      },
    });
  };

  // Find the option that matches the current value
  const currentValue = data.businessActivityScores.find(
    (option) => option.Name === value
  );

  return (
    <div>
      <Autocomplete
        value={currentValue}
        onChange={handleAutocompleteChange}
        options={data.businessActivityScores}
        getOptionLabel={(option) => option.Name}
        renderInput={(params) => <TextField {...params} margin="dense" />}
      />
    </div>
  );
};

const SourceOfFunds = ({ sourceOfFunds, onChange }) => {
  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  if (isLoadingCountries) {
    return <div>Loading...</div>;
  }

  const handleAutocompleteChange = (event, newValue) => {
    // Directly call the passed onChange function with the correct format
    onChange({
      target: {
        name: "sourceOfFunds",
        value: newValue ? newValue.source : "",
      },
    });
  };

  // Find the option that matches the current value
  const currentValue = data.sourceOfFundsScores.find(
    (option) => option.source === sourceOfFunds
  );

  return (
    <div>
      <Autocomplete
        value={currentValue}
        onChange={handleAutocompleteChange}
        options={data.sourceOfFundsScores}
        getOptionLabel={(option) => option.source}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};

const Jurisdiction = ({ sourceOfFunds, onChange }) => {
  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  if (isLoadingCountries) {
    return <div>Loading...</div>;
  }

  const handleAutocompleteChange = (event, newValue) => {
    // Directly call the passed onChange function with the correct format
    onChange({
      target: {
        name: "sourceOfFunds",
        value: newValue ? newValue.source : "",
      },
    });
  };

  // Find the option that matches the current value
  const currentValue = data.sourceOfFundsScores.find(
    (option) => option.source === sourceOfFunds
  );

  return (
    <div>
      <Autocomplete
        value={currentValue}
        onChange={handleAutocompleteChange}
        options={data.sourceOfFundsScores}
        getOptionLabel={(option) => option.source}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};
