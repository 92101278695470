import { Typography, Box, Container } from "@mui/material";

const Slogan = () => {
  return (
    <Box
      sx={{ textAlign: "center", padding: "50px", backgroundColor: "#f0f0f0" }}
    >
      <Typography variant="h4" fontWeight={"bold"}>
        Simplify Compliance. Secure Operations. Build Trust.
      </Typography>
      <Container maxWidth="md">
        <Typography variant="h6" marginTop="20px">
          {/* At AMLdiligence.com, we understand the complexities and challenges
            that businesses face in navigating the evolving regulatory landscape
            of AML and CFT compliance. Our cutting-edge solutions are designed to */}
          Our cutting-edge solutions are designed to empower your business to
          stay ahead of risks, safeguard your operations, and maintain
          impeccable compliance standards.
        </Typography>
      </Container>
      <Typography variant="subtitle1"></Typography>
      {/* <Button variant="contained" color="primary" sx={{ marginTop: "20px" }}>
          Learn More
        </Button> */}
    </Box>
  );
};

export default Slogan;
