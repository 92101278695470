import { React, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Card, CardContent, Button, TextField } from "@mui/material";
import background from "assets/client1.avif";
import bg1 from "assets/backdrop.jpg";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../globals.jsx";

const LoginScreen = () => {
  const navigate = useNavigate();

  return (
    console.log(APIURL),
    (
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log(APIURL);
          const response = await fetch(
            // `https://amldiligence.com/api/general/login`,
            APIURL + "/general/login",

            {
              method: "POST",
              withCredentials: true,
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin": APIURL,
                // "https://amldiligence.com",
                // "Access-Control-Allow-Origin": "http://localhost:5000",
              },
              body: JSON.stringify({
                email: values.email,
                password: values.password,
              }),
            }
          );
          const data = await response.json();
          console.log(data);
          if (data.error) {
            // setError(true);
            // setErrorMessage(data.error);
          } else {
            // setError(false);
            console.log("loggedIn");
            return Promise.resolve().then(function () {
              localStorage.setItem("DashBoardUser", JSON.stringify(data));
              localStorage.setItem("DashBoardUserLoggedIn", true);
              navigate("/dashboard");
            });
            localStorage.setItem("DashBoardUserLoggedIn", true);
            localStorage.setItem("DashBoardUser", JSON.stringify(data));
            navigate("/dashboard");
          }
        }}
        validate={(values) => {
          let errors = {};
          if (!values.email) {
            errors.email = "*Required";
          } else if (!EmailValidator.validate(values.email)) {
            errors.email = "Invalid email address.";
          }

          // const passwordRegex = /(?=.*[0-9])/;
          // if (!values.password) {
          //   errors.password = "*Required";
          // } else if (values.password.length < 8) {
          //   errors.password = "Password must be 8 characters long.";
          // } else if (!passwordRegex.test(values.password)) {
          //   errors.password = "Invalid password. Must contain one number.";
          // }

          return errors;
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column", // Add this line
                alignItems: "center",
                minHeight: "100vh",
                // backgroundImage: `url(${background})`,
                backgroundImage:
                  "url(https://amldiligence.com/login/client1.jpg)",
                backgroundSize: "cover",
                position: "relative", // Add this line
              }}
            >
              <Box
                // padding={"10px"}
                // position={"absolute"}
                // top={"80px"}
                // // right={"50px"} // Add this line
                // transform={"translateX(-50%)"} // Center horizontally
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "90px", // Add margin to create space between login card and logo/text
                  marginTop: "30px",
                }}
              >
                <img src="./logo.png"></img>
                <Typography
                  textAlign={"center"}
                  fontSize={"26px"}
                  fontFamily={"lucida-console"}
                >
                  www.AMLdiligence.com
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  // flexGrow: 1,
                }}
              >
                <Card
                  sx={{
                    width: {
                      sx: 12.0, // 100%
                      sm: 250,
                      md: 300,
                    },
                  }}
                  elevation={8}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "h4",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        Login
                      </Typography>

                      <TextField
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Enter your email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.email && touched.email && "error"}
                      />
                      {errors.email && touched.email && (
                        <div className="input-feedback">{errors.email}</div>
                      )}

                      <TextField
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Enter your password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.password && touched.password && "error"
                        }
                      />
                      {errors.password && touched.password && (
                        <div className="input-feedback">{errors.password}</div>
                      )}
                      <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Login
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              </Box>
              <Box
                sx={{
                  // textAlign: "center",
                  // position: "absolute",
                  // bottom: "120px",
                  textAlign: "center",
                  position: "relative", // Use relative positioning
                  marginTop: "auto", // Push the text to the bottom
                  padding: "20px 0",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontStyle: "bold",
                    fontFamily: "fantasy",
                    paddingTop: "50px",
                    color: "black[800]",
                  }}
                >
                  Designated Nonfinancial Businesses and Professions <br />
                </Typography>
                <Typography
                  sx={{
                    alignItems: "center",
                    color: "black[800]",
                    fontFamily: "fantasy",
                    fontSize: "20px",
                    backdropFilter: "80%",
                  }}
                >
                  {/* Dealers in precious metals and stones{" "} */}
                </Typography>
              </Box>
            </Box>
          );
        }}
      </Formik>
    )
  );
};

export default LoginScreen;
