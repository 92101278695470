import {
  AppBar,
  Toolbar,
  Box,
  Container,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const Header = () => {
  const handleNavClick = (e, anchorId) => {
    e.preventDefault();
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{ boxShadow: "rgba(20, 22, 96, 0.1) 0px 10px 30px", zIndex: 9999 }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo and other elements */}
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <Link href="https://amldiligence.com/" underline="none">
              <Box
                component="img"
                src="https://amldiligence.com/logo.png"
                alt="AMLDiligence"
                sx={{ width: 130, height: 28 }}
              />
            </Link>
            {/* <Box
              component="img"
              src="https://amldiligence.com/zahads.jpeg"
              alt="Zahads"
              sx={{ width: 150, height: 40, paddingLeft: 3 }}
            /> */}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* Menu items */}
              <MenuItem onClick={handleCloseNavMenu} style={{ color: "red" }}>
                <Link href="#home" style={{ color: "rgb(43, 174, 238)" }}>
                  Home
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link href="#about" style={{ color: "rgb(43, 174, 238)" }}>
                  About
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link href="#process" style={{ color: "rgb(43, 174, 238)" }}>
                  Process
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link href="#industries" style={{ color: "rgb(43, 174, 238)" }}>
                  Industries
                </Link>
              </MenuItem>
              <MenuItem>
                <Link href="/login" style={{ color: "rgb(43, 174, 238)" }}>
                  Login
                </Link>{" "}
              </MenuItem>
            </Menu>
          </Box>

          {/* Desktop Menu Items */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {/* Your existing buttons for desktop view */}
            <MenuItem onClick={handleCloseNavMenu}>
              <Link href="#home" style={{ color: "rgb(43, 174, 238)" }}>
                Home
              </Link>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Link href="#about" style={{ color: "rgb(43, 174, 238)" }}>
                About
              </Link>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Link href="#process" style={{ color: "rgb(43, 174, 238)" }}>
                Process
              </Link>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Link href="#industries" style={{ color: "rgb(43, 174, 238)" }}>
                Industries
              </Link>
            </MenuItem>
            <MenuItem>
              {" "}
              <Link
                href="/login"
                style={{ color: "rgb(43, 174, 238)", fontWeight: "bold" }}
              >
                Login
              </Link>
            </MenuItem>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
