import { Typography, Box, Container, Grid, Paper } from "@mui/material";

const AdvantageItem = ({ title, description, IconComponent }) => (
  <Grid item xs={6}>
    {" "}
    {/* Half width for each item on all screen sizes */}
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        textAlign: "center",
        ":hover": { boxShadow: 6 },
        height: "150px",
        overflow: "hidden", // Prevents overflow
        wordWrap: "break-word", // Breaks long words that could overflow
        textOverflow: "ellipsis",
      }}
    >
      {/* <IconComponent sx={{ fontSize: 40 }} /> */}
      <Typography
        variant="h6"
        component="h2"
        sx={{ mt: 1 }}
        fontWeight={"bold"}
      >
        {title}
      </Typography>
      <br />

      <Typography variant="body2">{description}</Typography>
    </Paper>
  </Grid>
);

const AdvantagesSection = () => {
  const advantages = [
    {
      title: "Innovation-Driven",
      description:
        "Harness the power of artificial intelligence and machine learning to streamline your AML/CFT processes.",
      IconComponent: <Box />,
    },

    {
      title: "Expertise at Your Fingertips",
      description:
        "Our team of compliance experts is here to support you every step of the way, ensuring your business is fortified against financial crimes.",
      IconComponent: <Box />,
    },
    {
      title: "Global Compliance",
      description:
        "No matter where you operate, our global compliance solutions are tailored to meet the requirements of various jurisdictions.",
      IconComponent: <Box />,
    },
    {
      title: "Scalable Solutions",
      description:
        "From startups to large enterprises, our solutions are scalable to your business size and the complexity of your operations.",
      IconComponent: <Box />,
    },

    // ... Add other advantages here
  ];

  return (
    <Box sx={{ py: 8, backgroundColor: "#f5f5f5" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%" // Ensure the Box takes full height of the parent Grid item
            >
              <Typography
                variant="h4"
                marginBottom={"40px"}
                fontWeight={"bold"}
              >
                The AML Diligence Advantage
              </Typography>
              <Typography variant="h6">
                At AMLdiligence.com, we understand the complexities and
                challenges that businesses face in navigating the evolving
                regulatory landscape of AML and CFT compliance.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {advantages.map((advantage, index) => (
                <AdvantageItem key={index} {...advantage} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AdvantagesSection;
