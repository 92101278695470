import { React, useState } from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import MaterialReactTable from "material-react-table";
import {
  useGetCountriesQuery,
  useGetUserQuery,
  useEditCountriesMutation,
} from "state/api";

const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
const userId = storedValue?._id;
const CountryRisk = () => {
  const [editCountries, { isLoading, isError }] = useEditCountriesMutation();

  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  const handleSave = async ({ values, table, row, exitEditingMode }) => {
    console.log(values);
    console.log(row.original); //exit editing mode
    await editCountries({ ...row.original, ...values });
    exitEditingMode();
  };

  const columns = [
    {
      accessorKey: "Name",
      header: "Country Name",
      enableEditing: false,
    },

    {
      accessorKey: "Risk", // Assuming 'risk' is the key for the Risk Level
      header: "Risk Level",
      Cell: ({ cell }) => cell.getValue(),
      enableEditing: false,
      editVariant: "select",
      editSelectOptions: ["Low", "Medium", "High"],
    },
    {
      accessorKey: "RiskScore",
      header: "Risk Score",
      type: "number", // Specify the type for proper input handling
      Cell: ({ cell }) => cell.getValue(),
      // Enable editing for this column
      enableEditing: true,
      editVariant: "select",
      editSelectOptions: [1, 2, 3, 4],
    },
  ];

  if (isLoading || isLoadingCountries) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Countries Risk" subtitle="RBA Definition Countries Risk" />
      <Box sx={{ height: 400, width: "100%" }}>
        <MaterialReactTable
          columns={columns}
          data={data.countryRiskScores}
          enableEditing
          editingMode="row"
          onEditingRowSave={handleSave}
        />
      </Box>
    </Box>
  );
};

export default CountryRisk;
// import React, { useState, useEffect } from "react";
// import { Box } from "@mui/material";
// import Header from "components/Header";
// import MaterialReactTable from "material-react-table";
// import { useGetCountriesQuery, useGetUserQuery } from "state/api";

// const CountryRisk = () => {
//   const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
//   const userId = storedValue?._id;

//   const { data: userData, isLoading: isUserLoading } = useGetUserQuery(userId);
//   const { data: countryData, isLoading: isCountriesLoading } =
//     useGetCountriesQuery(userId);

//   // Initialize table data state
//   const [tableData, setTableData] = useState([]);

//   // Update table data when countryData is fetched
//   useEffect(() => {
//     if (countryData && countryData.countryRiskScores) {
//       setTableData(countryData.countryRiskScores);
//     }
//   }, [countryData]);

//   // Define the updateMyData function
//   const updateMyData = (rowId, columnId, newValue) => {
//     setTableData((oldData) =>
//       oldData.map((row) => {
//         if (row._id === rowId) {
//           return { ...row, [columnId]: newValue };
//         }
//         return row;
//       })
//     );
//   };

//   const onEditingRowSave = async ({ row, exitEditingMode }) => {
//     try {
//       // Perform API call to save the updated data
//       // For now, just logging the updated row
//       console.log("Saving row:", row);

//       // Exit editing mode
//       exitEditingMode();
//     } catch (error) {
//       console.error("Error updating data:", error);
//     }
//   };

//   const columns = [
//     {
//       accessorKey: "Name",
//       header: "Country Name",
//       enableEditing: false,
//     },
//     {
//       accessorKey: "Risk",
//       header: "Risk Level",
//       enableEditing: true,
//       editVariant: "select",
//       editSelectOptions: ["Low", "Medium", "High"],

//       // Edit: ({ cell, column, row }) => (
//       //   <select
//       //     value={cell.getValue()}
//       //     onChange={(e) =>
//       //       updateMyData(row._id, column.accessorKey, e.target.value)
//       //     }
//       //     style={{ border: "none", borderBottom: "1px solid black" }}
//       //   >
//       //     {["Low", "Medium", "High"].map((level) => (
//       //       <option key={level} value={level}>
//       //         {level}
//       //       </option>
//       //     ))}
//       //   </select>
//       // ),
//     },
//     {
//       accessorKey: "RiskScore",
//       header: "Risk Score",
//       enableEditing: true,
//       Edit: ({ cell, column, row }) => (
//         <input
//           type="number"
//           value={cell.getValue()}
//           onChange={(e) =>
//             updateMyData(row._id, column.accessorKey, e.target.value)
//           }
//           style={{ border: "none", borderBottom: "1px solid black" }}
//         />
//       ),
//     },
//     // ... add other columns as needed ...
//   ];

//   if (isUserLoading || isCountriesLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Header title="Countries Risk" subtitle="RBA Definition Countries Risk" />
//       <Box sx={{ height: 400, width: "100%" }}>
//         <MaterialReactTable
//           columns={columns}
//           data={tableData}
//           enableEditing
//           editingMode="row"
//           onEditingRowSave={onEditingRowSave}
//           // options={{
//           //   exportButton: true,
//           // }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default CountryRisk;
