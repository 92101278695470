import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import { useLocation } from "react-router-dom";
import PrintTable from "components/printTable";
import { useAddRiskProfileMutation } from "state/api";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const data = [
  {
    q: "Domestic PEP (Shareholders, Partners, UBOs, Senior Managers, ORG, Entity)",
    risk: "Customer Risk",
    response: false,
    rs: 3,
  },

  {
    q: "Foreign PEP (Shareholders, Partners, UBOs, Senior Managers, ORG, Entity)",
    risk: "Customer Risk",
    response: false,
    rs: 5,
  },

  {
    q: "Company or any shareholder/partner/UBOs/Senior managers of company are on TFS sanction lists?",
    risk: "Customer Risk",
    response: false,
    rs: 5,
  },
  {
    q: "Company or any of their branch is registered in high risk country?",
    risk: "Customer Risk",
    response: false,
    rs: 5,
  },
  {
    q: "Any shareholder/partner/UBOs/Senior managers of company are nationals of high risk country?",
    risk: "Country Risk",
    response: false,
    rs: 3,
  },
  {
    q: "Company or any of their branch registered in gray list country",
    risk: "Country Risk",
    response: false,
    rs: 3,
  },
  {
    q: "Source of funds provided? Bank statement, Audited Financials statements etc (in case of companies registered in gray list countries)",
    risk: "Transaction Risk",
    response: false,
    rs: 3,
  },
  {
    q: "Is delivery direct? Or if it is through an in-direct channel / third party, channel/or third party is reliable and has proven track record and necessary KYCs documents are available for authentication.",
    risk: "Delivery Channel Risk",
    response: false,
    rs: 3,
  },
];

const LegalPerson = ({ open, onClose, id, helloFunction, idata }) => {
  const [status, setStatus] = useState([]);
  const [riskProfile, setRiskProfile] = useState("Low");
  const user = JSON.parse(localStorage.getItem("DashBoardUser"));
  const [dueDiligence, setDueDiligence] = useState("Simplified");
  const [statusResult, setStatusResult] = useState("Accept");
  const [responses, setResponses] = useState(data.map((item) => item.response));
  const [tableDialog, setTableDialog] = useState(false);
  let query = useQuery();
  const [addriskProfile] = useAddRiskProfileMutation();
  var iid = id?._id;
  const printRef = useRef();
  var totalRiskScore = 0;
  useEffect(() => {
    const isSwitchOn = (index) => responses[index] === true;

    if (isSwitchOn(0) && !isSwitchOn(6)) {
      setStatusResult("Reject");
      setRiskProfile("****");
      setDueDiligence("****");
    } else if (isSwitchOn(0) && isSwitchOn(6)) {
      setStatusResult("Accept");
      setRiskProfile("High");
      setDueDiligence("Enhanced");
    } else if (isSwitchOn(1) && isSwitchOn(6)) {
      setStatusResult("Accept");
      setRiskProfile("High");
      setDueDiligence("Enhanced");
    } else if (isSwitchOn(1) && !isSwitchOn(6)) {
      setStatusResult("Reject");
      setRiskProfile("****");
      setDueDiligence("****");
    } else if (isSwitchOn(2)) {
      setStatusResult("Reject");
      setRiskProfile("****");
      setDueDiligence("****");
    } else if (isSwitchOn(3) && isSwitchOn(6)) {
      setStatusResult("Accept");
      setRiskProfile("High");
      setDueDiligence("Enhanced");
    } else if (isSwitchOn(3) && !isSwitchOn(6)) {
      setStatusResult("Reject");
      setRiskProfile("High");
      setDueDiligence("***");
    } else if (
      isSwitchOn(7) &&
      isSwitchOn(6) &&
      !isSwitchOn(5) &&
      !isSwitchOn(4) &&
      !isSwitchOn(3) &&
      !isSwitchOn(2) &&
      !isSwitchOn(1)
    ) {
      setStatusResult("Accept");
      setRiskProfile("Low");
      setDueDiligence("Standard");
    } else {
      setStatusResult("****");
      setRiskProfile("****");
      setDueDiligence("****");
    }
  }, [responses]);

  useEffect(() => {
    // The following lines will help to log the state changes to ensure they are updating
    console.log("statusResult:", statusResult);
    console.log("riskProfile:", riskProfile);
    console.log("dueDiligence:", dueDiligence);
  }, [statusResult, riskProfile, dueDiligence]);

  const handleSwitchChange = (index) => {
    console.log(index);
    setResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[index] = !updatedResponses[index]; // Toggle the boolean value
      return updatedResponses;
    });
  };

  function submit() {
    console.log({ riskProfile, iid });
    addriskProfile({ iid, riskProfile });
    onClose();
  }

  const questions = data.map((item, index) => (
    <li key={index}>
      <span>{item.q}</span>
      <div>
        <p>{item.risk}</p>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              onChange={() => handleSwitchChange(index)}
              checked={responses[index] === "Yes" || responses[index] === true}
            />
          }
        />
      </div>
    </li>
  ));

  const handlePrintTable = () => {
    window.print();
  };
  const componentRef = useRef(); // Define the componentRef using useRef

  return (
    <div>
      <Dialog
        open={open}
        onClose={onclose}
        // Prevents dialog from closing when clicking outside
        fullWidth
        maxWidth="md"
        scroll={"paper"}
      >
        <DialogTitle>
          {" "}
          <Typography
            variant="h4"
            paddingBottom={"12px"}
            color={"#ffc76b"}
            fontWeight={"bold"}
          >
            Customer Risk Profile (CRP)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer sx={{ padding: "5px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>S#</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Question</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>No/Yes</TableCell>
                  {/* <TableCell sx={{ fontWeight: "bold" }}>Risk Score</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index} sx={{ padding: "5px" }}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.q}</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            onChange={() => handleSwitchChange(index)}
                            checked={
                              responses[index] === "Yes" ||
                              responses[index] === true
                            }
                          />
                        }
                      />
                    </TableCell>
                    {/* <TableCell>
                      {" "}
                      {responses[index] === true ? item.rs : 1}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyItems: "right",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <Button
                  color="success"
                  variant="contained"
                  style={{ margin: "0 auto" }}
                  onClick={() => setTableDialog(true)}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <PrintTable
                ref={componentRef}
                data={data}
                clientName={query.get("name")}
                type={"______________________"}
                responses={responses}
                riskProfile={riskProfile}
                dueDiligence={dueDiligence}
                statusResult={statusResult}
              />
            </div>
          </div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Cancel
          </Button>

          <ReactToPrint
            trigger={() => (
              <Button
                color="success"
                variant="text"
                style={{ margin: "0 auto" }}
                onClick={() => setTableDialog(true)}
              >
                <PrintIcon />
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <PrintTable
              ref={componentRef}
              data={data}
              user={user}
              clientName={id?.fullLegalName}
              type={"______________________"}
              responses={responses}
              riskProfile={riskProfile}
              dueDiligence={dueDiligence}
              statusResult={statusResult}
            />
          </div>
          <Button onClick={submit} color="success" variant="contained">
            Save
          </Button>
        </DialogActions>{" "}
      </Dialog>
    </div>
  );
};

export default LegalPerson;
