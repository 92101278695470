import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { values } from "data/data";

const VerticalMarquee = () => {
  const theme = useTheme();
  //   const values = [
  //     {
  //       abbreviation: "wd_oligarchs",
  //       fullForm: "World's Oligarchs",
  //     },
  //     {
  //       abbreviation: "us_fbi_most_wanted",
  //       fullForm: "U.S. Federal Bureau of Investigation (FBI) Most Wanted",
  //     },
  //     {
  //       abbreviation: "md_rise_profiles",
  //       fullForm:
  //         "Moldova Risk and Insecurity Situations Evaluation (RISE) Profiles",
  //     },
  //     {
  //       abbreviation: "ua_nabc_sanctions",
  //       fullForm:
  //         "Ukraine National Agency for the Prevention of Corruption Sanctions",
  //     },
  //   ];

  const marqueeStyle = {
    overflowY: "hidden",
    position: "relative",
    height: "280px", // Set a fixed height that works with your design
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "18px", // Gradient height
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.alt}, rgba(0,0,0,0))`,
      zIndex: 2,
    },
    "&::after": {
      top: "auto",
      bottom: 0,
      transform: "rotate(180deg)",
    },
    "& > div": {
      animation: "marquee 210s linear infinite", // Adjust timing as needed
    },
    "&:hover > div": {
      animationPlayState: "paused", // Pause the animation when mouse hovers over
    },
    "@keyframes marquee": {
      "0%": { transform: "translateY(0)" },
      "100%": { transform: "translateY(-100%)" }, // You might need to adjust this value
    },
  };

  return (
    <Box
      gridColumn="span 4"
      gridRow="span 2"
      backgroundColor={theme.palette.background.alt}
      p="1rem"
      borderRadius="0.55rem"
    >
      <Typography
        variant="h4"
        paddingBottom={2}
        style={{ color: theme.palette.secondary[300] }}
        fontWeight="bold"
      >
        Screening lists:
      </Typography>
      <Box sx={marqueeStyle}>
        <div>
          {values.map((value, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <Typography
                sx={{ flex: 1 }}
                style={{ color: theme.palette.secondary[300] }}
              >
                {value.fullForm}
              </Typography>
            </Box>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default VerticalMarquee;
