import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Container,
  Grid,
  Box,
  Typography,
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
} from "@mui/material";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const CrpI = ({ open, onClose, data }, ref) => {
  const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
  console.log("Data in CRPI", data);
  // Reference to the report content
  const reportRef = useRef();

  // Print handler
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
  });
  // Calculate the average risk score for the "Legal Person Risk Assessment"
  const legalPersonRiskScores = data?.riskScores;
  const legalPersonAverage =
    legalPersonRiskScores?.reduce((sum, score) => sum + score, 0) /
    legalPersonRiskScores?.length;

  // Calculate the average risk score for the "Shareholder Risk Assessment"
  const shareholderRiskScores = data?.shareHolderRiskScores;
  const shareholderAverage =
    shareholderRiskScores?.reduce((sum, score) => sum + score, 0) /
    shareholderRiskScores?.length;

  // Calculate the overall sum
  const totalSum =
    (legalPersonRiskScores?.reduce((sum, score) => sum + score, 0) +
      shareholderRiskScores?.reduce((sum, score) => sum + score, 0)) /
    10;

  const getRiskLevel = (score) => {
    if (score > 3.1) {
      return { level: "High Risk", color: "red" };
    } else if (score > 2.1) {
      return { level: "Medium High Risk", color: "darkviolet" };
    } else if (score > 1.1) {
      return { level: "Medium Risk", color: "olive" };
    } else {
      return { level: "Low Risk", color: "darkgreen" };
    }
  };

  if (data != null) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h5" color="#ffc76b" fontWeight="bold">
            Customer Risk Profile
          </Typography>
        </DialogTitle>
        {data?.riskScores[4] !== 20 ? (
          <DialogContent>
            <Container>
              <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      {storedValue.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {storedValue.address}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {storedValue.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2">
                      <b>Customer ID:</b> {data?._id}
                    </Typography>
                    <Typography variant="body2">
                      <b>Full Legal Name:</b> {data?.fullLegalName}
                    </Typography>
                    <Typography variant="body2">
                      <b>Date of Birth:</b>{" "}
                      {moment(data?.doB).format("DD MMM, YYYY")}
                    </Typography>
                    <Typography variant="body2">
                      <b>Nationality:</b> {data?.nationality}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              <Paper elevation={0} sx={{ p: 0, mb: 3 }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="#ffc76b"
                  gutterBottom
                >
                  Individual Customer
                </Typography>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ border: "1px solid #ccc" }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{ background: "#f5f5f5" }}>
                        <TableCell>
                          <b>Attribute</b>
                        </TableCell>
                        <TableCell>
                          <b>Value</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Risk Score</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Full Legal Name</TableCell>
                        <TableCell>{data.fullLegalName}</TableCell>
                        <TableCell align="right">
                          {data.riskScores[3]}
                          {/* {data.shareHolderRiskScores[0]} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ID Document</TableCell>
                        <TableCell>{data.idDoc.type}</TableCell>
                        <TableCell align="right">
                          {data.riskScores[0]}
                          {/* {data.shareHolderRiskScores[1]} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Nationality</TableCell>
                        <TableCell>
                          {data.nationality}
                          {/* {data.highestShareHolderRisk.nationality} */}
                        </TableCell>
                        <TableCell align="right">
                          {data.riskScores[1]}
                          {/* {data.shareHolderRiskScores[2]} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Source of Funds</TableCell>
                        <TableCell>
                          {data.sourceOfFunds}
                          {/* {data.highestShareHolderRisk.dualNationality} */}
                        </TableCell>
                        <TableCell align="right">
                          {data.riskScores[2]}
                          {/* {data.shareHolderRiskScores[3]} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Occupation</TableCell>
                        <TableCell>
                          {data.occupation}
                          {/* {data.highestShareHolderRisk.occupation} */}
                        </TableCell>
                        <TableCell align="right">
                          {data.riskScores[4]}
                          {/* {data.shareHolderRiskScores[4]} */}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow sx={{ background: "#f5f5f5" }}>
                        <TableCell colSpan={2} align="right">
                          <b> Shareholder Average</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{shareholderAverage.toFixed(2)}</b>
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Total Risk Score
                    </Typography>
                    <Typography variant="body2">
                      (Sum of attributes) / Total Number of attributes
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={getRiskLevel(data.totalRiskScore).color}
                    >
                      {data.totalRiskScore} <br />
                      {getRiskLevel(data.totalRiskScore).level}
                      {/* {totalSum.toFixed(2)} ({getRiskLevel(totalSum).level}) */}
                    </Typography>
                  </Box>
                </Box>
              </Paper>

              <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Risk Range
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Box sx={{ width: "48%" }}>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="red"
                      gutterBottom
                    >
                      High Risk (Above 3.1)
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="darkviolet"
                      gutterBottom
                    >
                      Medium High Risk (2.1 to 3.1)
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="olive"
                      gutterBottom
                    >
                      Medium Risk (1.1 to 2.1)
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="darkgreen"
                      gutterBottom
                    >
                      Low Risk (Below 1.1)
                    </Typography>
                  </Box>
                  <Box sx={{ width: "48%" }}>
                    <Typography variant="body2">
                      <i>
                        Note: For legal entities, the system evaluates the risk
                        based on the shareholder with the highest risk score. If
                        any individual risk scores are flagged as critical
                        (score of 4), the overall risk is automatically elevated
                        unless mitigated.
                      </i>
                    </Typography>
                  </Box>
                </Box>
              </Paper>

              <Divider sx={{ my: 3 }} />

              <Box textAlign="center">
                <Typography variant="body2" color="textSecondary">
                  ***** End of Report *****
                </Typography>
              </Box>
            </Container>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography variant="h6" align="center">
              No Risk Profile Available
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          {/* <Button onClick={handlePrint} color="primary" variant="contained">
            Print to PDF
          </Button> */}
          <Button onClick={onClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};

export default CrpI;
