import React, { useState, useEffect } from "react";
import {
  Box,
  Fab,
  FormControlLabel,
  Button,
  Checkbox,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  InputLabel,
  Select,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
} from "@mui/material";
import moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import ReactDOM from "react-dom/client";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import { usePostCustomersMutation, useGetCountriesQuery } from "state/api";
import { transformShareHolders, transformData } from "./utils";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MenuItem from "@mui/material/MenuItem";
import { countryNames, businessActivityScores } from "data/data";
import SearchPage from "scenes/Search";
import SearchSimple from "./SearchSimple";

const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
const userId = storedValue?._id;

const countryMenuItems = countryNames.map((country) => (
  <MenuItem value={`${country}`} key={country}>
    {country}
  </MenuItem>
));

const businessActivityMenuItems = businessActivityScores.map((activity) => (
  <MenuItem value={`${activity.Name}`} key={activity.Name}>
    {activity.Name}
  </MenuItem>
));

const AddEntity = ({ open, onClose, data }) => {
  // const { countries, isLoading: isLoadingCountries } =
  //   useGetCountriesQuery(userId);
  // console.log(userId);

  const [fields, setFields] = useState([]);
  const [showSearch, setShowSearch] = useState(false); // To control SearchSimple component
  const [status, setStatus] = useState("");
  const [addEntity] = usePostCustomersMutation();
  const [showOtherTextField, setShowOtherTextField] = useState(false);
  // New Code----------------------------------------------------------

  const [showSearchFullName, setShowSearchFullName] = useState(false);
  const [showSearchMap, setShowSearchMap] = useState({});

  const handleFullNameClick = () => {
    console.log("Handle FullName Click");
    setShowSearchFullName(true);
  };

  const updateShowSearch = (fieldId, value) => {
    setShowSearchMap((prevShowSearchMap) => ({
      ...prevShowSearchMap,
      [fieldId]: value,
    }));
  };

  const [statusMap, setStatusMap] = useState({});

  const updateStatus = (fieldId, newStatus) => {
    setStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [fieldId]: newStatus,
    }));
    console.log("fieldID and status", fieldId, newStatus, statusMap);
  };

  const deleteStatus = (fieldId) => {
    setStatusMap((prevStatusMap) => {
      const newStatusMap = { ...prevStatusMap };
      delete newStatusMap[fieldId];
      return newStatusMap;
    });
  };

  // New Code --------------------------------------------------------
  const handleNestedDropdownChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "Other") {
      setShowOtherTextField(true);
    } else {
      setShowOtherTextField(false);
    }
  };

  const initialValues = {
    legalName: "",
    screeningResult: status,
    registeredAddress: "",
    businessActivity: "",
    tradeLicenseNo: "",
    taxNo: "",
    countryofIncorporation: "",
    licensingAuthority: "",
    businessAddress: "",
    incoroprationDate: "",
    licenseExpiry: "",
    contactType: "",
    contactCountry: "",
    contactNumber: "",
    emailAddress: "",
    jurisdiction: "",
    POBox: "",
    website: "",
    bankName: "",
    accountNo: "",
    accountName: "",
    iban: "",
    bicSwift: "",
    currency: "",
    yearOfRelationship: "",
    branch: "",
    anualBusiness: "",
    totalShareCapital: "",
    totalShareHolderEquity: "",
    totalAssets: "",
    totalAnualSales: "",
    sourceOfFunds: "",
    shareHolders: [],
  };
  const [formValues, setFormValues] = useState(initialValues);
  // form values being reset upon form open state change.
  //Without the following logic the form would retain field values from last entry
  useEffect(() => {
    if (!open) {
      // Reset form values here
      setFormValues(initialValues);
      setStatus("");
      setStatusMap({});
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      // Reset form values here
      setFormValues(initialValues);
      setStatus(status);
      setStatusMap({});
    }
  }, [open]);

  const handleChange = (event) => {
    console.log(event.target);
    const { id, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [id]: value,
    }));
    console.log(id + ";" + value);
  };

  // const handleDropdownChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormValues((prevFormValues) => ({
  //     ...prevFormValues,
  //     [name]: value,
  //   }));
  //   if (name == "businessActivity" && value == "Other") {
  //     setShowOtherTextField(true);
  //   }
  //   console.log(name + ";" + value);
  //   console.log("event", event.target, "formValues", formValues);
  // };

  // const handleDropdownChange = (event, newValue) => {
  //   // For Autocomplete, the second argument is the new value
  //   const name = event.target.name || "sourceOfFunds"; // default name for Autocomplete
  //   const value = newValue ? newValue.source : event.target.value || "";
  //   console.log(newValue);
  //   setFormValues((prevFormValues) => ({
  //     ...prevFormValues,
  //     [name]: value,
  //   }));

  //   // if (name === "businessActivity" && value === "Other") {
  //   //   setShowOtherTextField(true);
  //   // }

  //   console.log(name + ";" + value);
  //   // console.log(
  //   //   "event",
  //   //   event.target,
  //   //   "newValue",
  //   //   newValue,
  //   //   "formValues",
  //   //   formValues
  //   // );
  // };

  const handleDropdownChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
    console.log(name + ";" + value);
  };

  useEffect(() => {
    var newSh = [];
    setFormValues(initialValues);
    formValues.shareHolders.map((sh, index) => {
      var _shareHolder = {
        id: index,
        fullName: sh.fullLegalName,
        screeningResult: statusMap[index] ?? "dhdg",
        legalNameSanction: sh.legalNameSanction, // Just added
        nationality: sh.nationality, // Just added
        pep: sh.pep, // Just added
        dualNationality: sh.dualNationality, // Just added
        occupation: sh.occupation, // Just added
        idType: sh.idDoc.type,
        country: sh.idDoc.issueCountry,
        number: sh.idDoc.number,
        issueDate: moment(sh.idDoc.issueDate).utc().format("YYYY-MM-DD"),
        expiryDate: moment(sh.idDoc.expiryDate).utc().format("YYYY-MM-DD"),
        birthDate: moment(sh.doB).utc().format("YYYY-MM-DD"),
        residentialStatus: sh.residentialStatus,
        percentShares: sh.percentShares,
        contactType: sh.contactInfo.type,
        contactCountry: sh.contactInfo.country,
        contactNumber: sh.contactInfo.number,
        authorizedSignatory: sh.isAuthorizedSignatory,
        ubo: sh.ubo,
        uboReason: sh.uboReason,
        uboDate: moment(sh.uboDate).utc().format("YYYY-MM-DD"),
      };
      newSh.push(_shareHolder);
    });
    setFields(newSh);
  }, []);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      fullName: "",
      legalNameSanction: "", // Just added
      nationality: "", // Just added
      dualNationality: "", // Just added
      occupation: "", //Just added
      idType: "",
      country: "",
      number: "",
      issueDate: "",
      expiryDate: "",
      birthDate: "",
      residentialStatus: "",
      screeningResult: "dhdg",
      percentShares: "",
      contactType: "",
      contactCountry: "",
      contactNumber: "",
      authorizedSignatory: false,
      ubo: false,
      uboReason: "",
      uboDate: "",
      pep: false,
      pepReason: "",
    };
    setFields([...fields, newField]);
  };

  const handleFieldChange = (id, key, value) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, [key]: value } : field
    );
    console.log(id + ":" + key + ":" + value);
    setFields(updatedFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const transformedValues = transformData(formValues);
    const transformedShHolders = fields.map((f) => {
      return transformShareHolders(f);
    });
    formValues.shareHolders = [...fields];
    transformedValues.shareHolders = [...transformedShHolders];
    if (transformedValues.shareHolders.length === 0 || status === "") {
      alert("At least one Share Holder is required");
      return; // Stop the submission process if no share holders are present
    }
    console.log(transformedValues);
    console.log(formValues);
    addEntity(transformedValues);
    onClose();
  };

  const handleSearchClick = () => {
    setShowSearch(true);
  };

  const removeField = (index) => {
    const filteredFields = [...fields];
    filteredFields.splice(index, 1);
    setFields(filteredFields);
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={onClose} //commented to make border-dismissable false
        fullWidth
        maxWidth="md"
        scroll={"paper"}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle color={"#ffc76b"} fontWeight={"bold"}>
            Add New Entity
          </DialogTitle>
          {showSearch && (
            <SearchSimple
              id={formValues.legalName}
              name={formValues.legalName}
              type={"ORG/Company"}
              setStatus={setStatus}
              setShowSearch={setShowSearch}
            />
          )}
          {!showSearch && (
            <>
              <DialogContent>
                <Grid
                  container={true}
                  columnSpacing={3}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  padding="45px"
                  paddingTop={0}
                >
                  <Grid item xs={5}>
                    <InputLabel>Legal Name</InputLabel>
                    <TextField
                      autoFocus
                      required
                      fontSize={"2px"}
                      margin="dense"
                      id="legalName"
                      name="legalName"
                      value={formValues.legalName}
                      type="text"
                      disabled={status !== ""}
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              // Checks the 'status' value here
                              status === "" ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={handleSearchClick}
                                  disabled={formValues.legalName.length <= 4}
                                >
                                  SCREEN
                                </Button>
                              ) : (
                                <span
                                  style={{
                                    color:
                                      status === "System cleared" ||
                                      status === "Cleared by notes"
                                        ? "green"
                                        : "red",
                                    fontSize: "8px", // Set the font size to 8px
                                  }}
                                >
                                  {status}
                                </span>
                              )
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <InputLabel>Registered Address</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="registeredAddress"
                      // label="Registered Address"
                      value={formValues.registeredAddress}
                      type="text"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputLabel>Business Address</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="businessAddress"
                      // label="Registered Address"
                      value={formValues.businessAddress}
                      type="text"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Tax Reg. No.</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="taxNo"
                      //label="tradeLicenseNo"
                      value={formValues.taxNo}
                      type="text"
                      fullWidth
                      onChange={handleChange}
                    />{" "}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={5}>
                      <InputLabel>Source of Funds</InputLabel>{" "}
                      <SourceOfFunds
                        sourceOfFunds={formValues.sourceOfFunds ?? ""}
                        onChange={handleDropdownChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      paddingTop={"5px"}
                      paddingBottom={"5px"}
                      color={"#ffc76b"}
                    >
                      Trade License Info
                    </Typography>{" "}
                    <Divider color={"#ffc76b"} />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Business Activity</InputLabel>{" "}
                    <BusinessActivity
                      required
                      value={formValues.businessActivity}
                      onChange={handleDropdownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Trade Lic. No.</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="tradeLicenseNo"
                      //label="tradeLicenseNo"
                      value={formValues.tradeLicenseNo}
                      type="text"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Country of incorporation</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      //id="countryofIncorporation"
                      name="countryofIncorporation"
                      select
                      required
                      //label="Country of Incorporation"
                      //defaultValue={formValues.countryofIncorporation}
                      defaultValue={""}
                      fullWidth
                      placeholder="Country of Incorporation"
                      onChange={handleDropdownChange}
                    >
                      {countryMenuItems}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Licensing Authority</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="licensingAuthority"
                      //label="Licensing Authority"
                      value={formValues.licensingAuthority}
                      type="text"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Jurisdiction</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      name="jurisdiction"
                      select
                      defaultValue={""}
                      required
                      fullWidth
                      placeholder="Country of Incorporation"
                      onChange={handleDropdownChange}
                    >
                      <MenuItem value={"FREE ZONE"} key={1}>
                        {"FREE ZONE"}
                      </MenuItem>
                      <MenuItem value={"MAIN LAND"} key={2}>
                        {"MAIN LAND"}
                      </MenuItem>
                      <MenuItem value={"OUTSIDE UAE"} key={3}>
                        {"OUTSIDE UAE"}
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Incorporation Date</InputLabel>
                    <TextField
                      // label="Incorporation Date"
                      autoFocus
                      type="date"
                      id="incoroprationDate"
                      required
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel>Lic. Expiry Date</InputLabel>
                    <TextField
                      // label="Lic. Expiry Date"
                      type="date"
                      id="licenseExpiry"
                      // value={formValues.licenseExpiry}
                      required
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      paddingTop={"5px"}
                      paddingBottom={"5px"}
                      color={"#ffc76b"}
                    >
                      Contact Info
                    </Typography>{" "}
                    <Divider color={"#ffc76b"} />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Contact Type</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      name="contactType"
                      select
                      label="Contact Type"
                      fullWidth
                      placeholder="Contact Type"
                      onChange={handleDropdownChange}
                    >
                      <MenuItem value="Telephone">Phone</MenuItem>
                      <MenuItem value="Mobile">Mobile</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Contact Country</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      name="contactCountry"
                      select
                      label="ContactCountry"
                      defaultValue={""}
                      fullWidth
                      placeholder="Contact Country"
                      onChange={handleDropdownChange}
                    >
                      {countryMenuItems}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Number</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="contactNumber"
                      label="Number"
                      type="text"
                      //value={formValues.contactNumber}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Email</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="emailAddress"
                      type="email"
                      // value={formValues.emailAddress}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Email Address"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>PO Box</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="POBox"
                      type="text"
                      value={formValues.POBox}
                      onChange={handleChange}
                      fullWidth
                      placeholder="PO Box"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Website</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="website"
                      type="text"
                      value={formValues.website}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Website"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      paddingTop={"5px"}
                      paddingBottom={"5px"}
                      color={"#ffc76b"}
                    >
                      Financial Info
                    </Typography>{" "}
                    <Divider color={"#ffc76b"} paddingBottom={"5px"} />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Bank Name </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="bankName"
                      type="text"
                      value={formValues.bankName}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Account no. </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="accountNo"
                      type="text"
                      value={formValues.accountNo}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Account name </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="accountName"
                      type="text"
                      value={formValues.accountName}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>IBAN </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="iban"
                      type="text"
                      value={formValues.iban}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>BIC/SWIFT</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="bicSwift"
                      type="text"
                      value={formValues.bicSwift}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Currency of Account </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="currency"
                      type="text"
                      value={formValues.currency}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Year of relationship with bank </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="yearOfRelationship"
                      type="text"
                      value={formValues.yearOfRelationship}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Bank Br Name, City, Town </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="branch"
                      type="text"
                      value={formValues.branch}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>
                      Expected Annual Business with coompany{" "}
                    </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="anualBusiness"
                      type="text"
                      value={formValues.anualBusiness}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Total Share Capital </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="totalShareCapital"
                      type="text"
                      value={formValues.totalShareCapital}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Total Shareholder equity </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="totalShareHolderEquity"
                      type="text"
                      value={formValues.totalShareHolderEquity}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Total assets</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="totalAssets"
                      type="text"
                      value={formValues.totalAssets}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Latest Annual Sales </InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="totalAnualSales"
                      type="text"
                      value={formValues.totalAnualSales}
                      onChange={handleChange}
                      fullWidth
                      // placeholder="AED"
                    />
                  </Grid>
                  {
                    <Grid item xs={12}>
                      {" "}
                      {fields.length > 0 && (
                        <Typography
                          paddingTop={"10px"}
                          variant="h5"
                          fontWeight={"bold"}
                          paddingBottom={"5px"}
                          color={"#ffc76b"}
                        >
                          Share Holders
                        </Typography>
                      )}
                    </Grid>
                  }
                  {/* Third Section */}

                  {fields.map((field, index) =>
                    showSearchMap[field.id] ? (
                      <div key={index}>
                        <SearchSimple
                          name={field.fullName}
                          type={"Person Belonging to Company"}
                          setStatus={(newStatus) =>
                            updateStatus(field.id, newStatus)
                          }
                          setShowSearch={(value) =>
                            updateShowSearch(field.id, value)
                          }
                        />
                      </div>
                    ) : (
                      <Grid
                        sx={{
                          // p: 1,
                          backgroundColor: "#FFFDE9",
                          // border: "1px solid grey",
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                        container="true"
                        columnSpacing={3}
                        columns={{ xs: 12, sm: 13, md: 12 }}
                        padding={1}
                        color={"grey"}
                        //paddingLeft={"190px"}
                        //margin={"10px"}
                        key={index}
                      >
                        {" "}
                        <Grid item xs={3}>
                          <InputLabel>Full Name</InputLabel>

                          <TextField
                            // ...existing TextField props
                            value={field.fullName}
                            required
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "fullName",
                                event.target.value
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {statusMap[field.id] === undefined ? (
                                    <Button
                                      // ...existing Button props
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        updateShowSearch(field.id, true)
                                      }
                                    >
                                      SCREEN
                                    </Button>
                                  ) : (
                                    <span
                                      style={{
                                        color:
                                          statusMap[field.id] ===
                                            "System cleared" ||
                                          statusMap[field.id] ===
                                            "Cleared by notes"
                                            ? "green"
                                            : "red",
                                        fontSize: "8px",
                                      }}
                                    >
                                      {statusMap[field.id]}
                                    </span>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                          {showSearchMap[field.id] && <></>}
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel id={`idType-label-${field.id}`}>
                            ID Type
                          </InputLabel>
                          <Select
                            required
                            labelId={`idType-label-${field.id}`}
                            value={field.idType}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "idType",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            <MenuItem value="Emirates ID">Emirates ID</MenuItem>
                            <MenuItem value="Passport">Passport</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel id={`country-label-${field.id}`}>
                            Issue Country
                          </InputLabel>
                          <Select
                            labelId={`country-label-${field.id}`}
                            required
                            value={field.country}
                            defaultValue={""}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "country",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            {countryMenuItems}
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel id={`nationality-label-${field.id}`}>
                            Nationality
                          </InputLabel>
                          <Select
                            labelId={`nationality-label-${field.id}`}
                            value={field.nationality}
                            defaultValue={""}
                            required
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "nationality",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            {countryMenuItems}
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Number"
                            value={field.number}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "number",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Issue Date"
                            type="date"
                            value={field.issueDate}
                            required
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "issueDate",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Expiry Date"
                            type="date"
                            value={field.expiryDate}
                            required
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "expiryDate",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Birth Date"
                            type="date"
                            value={field.birthDate}
                            required
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "birthDate",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel
                            id={`residentialStatus-label-${field.id}`}
                          >
                            Residential Status
                          </InputLabel>
                          <Select
                            labelId={`residentialStatus-label-${field.id}`}
                            value={field.residentialStatus}
                            required
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "residentialStatus",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            <MenuItem value="Non Resident">
                              NON-RESIDENT
                            </MenuItem>
                            <MenuItem value="Permanent Resident">
                              RESIDENT
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Percent Shares"
                            value={field.percentShares}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "percentShares",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel id={`dualNationality-label-${field.id}`}>
                            Dual Nationality{" "}
                          </InputLabel>
                          <Select
                            labelId={`dualNationality-label-${field.id}`}
                            value={field.dualNationality}
                            required
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "dualNationality",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel id={`occupation-label-${field.id}`}>
                            Occupation{" "}
                          </InputLabel>
                          <Select
                            labelId={`occupation-label-${field.id}`}
                            value={field.occupation}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "occupation",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            {businessActivityMenuItems}
                            {/* <MenuItem value="Occupation1">Occupation1</MenuItem>
                            <MenuItem value="Occupation2">Occupation2</MenuItem>
                            <MenuItem value="Occupation3">Occupation3</MenuItem>
                            <MenuItem value="Occupation4">Occupation4</MenuItem> */}
                          </Select>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6">Contact Info</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel id={`contactType-label-${field.id}`}>
                            Contact Type
                          </InputLabel>
                          <Select
                            labelId={`contactType-label-${field.id}`}
                            value={field.contactType}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "contactType",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            <MenuItem value="Telephone">Phone</MenuItem>
                            <MenuItem value="Mobile">Mobile</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel id={`contactCountry-label-${field.id}`}>
                            Contact Country
                          </InputLabel>
                          <Select
                            labelId={`contactCountry-label-${field.id}`}
                            value={field.contactCountry}
                            defaultValue={""}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "contactCountry",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          >
                            {countryMenuItems}
                          </Select>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Contact Number"
                            value={field.contactNumber}
                            onChange={(event) =>
                              handleFieldChange(
                                field.id,
                                "contactNumber",
                                event.target.value
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.authorizedSignatory}
                                onChange={(event) =>
                                  handleFieldChange(
                                    field.id,
                                    "authorizedSignatory",
                                    event.target.checked
                                  )
                                }
                              />
                            }
                            label="Authorized Signatory"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.ubo}
                                onChange={(event) =>
                                  handleFieldChange(
                                    field.id,
                                    "ubo",
                                    event.target.checked
                                  )
                                }
                              />
                            }
                            label="UBO"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.pep}
                                onChange={(event) =>
                                  handleFieldChange(
                                    field.id,
                                    "pep",
                                    event.target.checked
                                  )
                                }
                              />
                            }
                            label="PEP"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {field.pep && (
                            <>
                              <InputLabel id={`pep-label-${field.id}`}>
                                PEP Type
                              </InputLabel>
                              <Select
                                required
                                labelId={`pep-label-${field.id}`}
                                value={field.pepReason}
                                onChange={(event) =>
                                  handleFieldChange(
                                    field.id,
                                    "pepReason",
                                    event.target.value
                                  )
                                }
                                fullWidth
                                margin="normal"
                              >
                                <MenuItem value="Foreign PEP">
                                  Foreign PEP
                                </MenuItem>
                                <MenuItem value="Domestic PEP">
                                  Domestic PEP
                                </MenuItem>
                              </Select>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {field.ubo && (
                            <>
                              <InputLabel id={`uboReason-label-${field.id}`}>
                                UBO Reason
                              </InputLabel>
                              <Select
                                required
                                labelId={`uboReason-label-${field.id}`}
                                value={field.uboReason}
                                onChange={(event) =>
                                  handleFieldChange(
                                    field.id,
                                    "uboReason",
                                    event.target.value
                                  )
                                }
                                fullWidth
                                margin="normal"
                              >
                                <MenuItem value="SHARE HOLDING">
                                  Share Holding
                                </MenuItem>
                                <MenuItem value="CONTROL">Control</MenuItem>
                              </Select>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {field.ubo && (
                            <>
                              <TextField
                                label="UBO Date"
                                required
                                type="date"
                                value={field.uboDate}
                                onChange={(event) =>
                                  handleFieldChange(
                                    field.id,
                                    "uboDate",
                                    event.target.value
                                  )
                                }
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </>
                          )}
                        </Grid>{" "}
                        <Grid item xs={1}>
                          <DeleteOutlineOutlinedIcon
                            color="error"
                            title="Delete"
                            onClick={() => {
                              removeField(index);
                            }}
                          />
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="success"
                      //justifyItems="right"
                      onClick={addField}
                    >
                      Add Share Holders
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                  Cancel
                </Button>
                <Button type="submit" color="success" variant="contained">
                  Save
                </Button>
              </DialogActions>{" "}
            </>
          )}
        </form>
      </Dialog>
    </div>
  );
};

export default AddEntity;

// const Test = ({ value, onChange }) => {
//   const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

//   if (isLoadingCountries) {
//     return <div>Loading...</div>;
//   }
//   return (
//     <div>
//       {/* {JSON.stringify(data.businessActivityScores)} */}

//       <Autocomplete
//         value={value}
//         onChange={(event, newValue) => {
//           onChange({
//             target: {
//               name: "businessActivity",
//               value: newValue ? newValue.Name : "",
//             },
//           });
//         }}
//         options={data.businessActivityScores}
//         getOptionLabel={(option) => option.Name}
//         renderInput={(params) => <TextField {...params} margin="dense" />}
//       />
//     </div>
//   );
// };

const BusinessActivity = ({ value, onChange }) => {
  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  if (isLoadingCountries) {
    return <div>Loading...</div>;
  }

  const handleAutocompleteChange = (event, newValue) => {
    onChange({
      target: {
        name: "businessActivity",
        value: newValue ? newValue.Name : "",
      },
    });
  };

  // Find the option that matches the current value
  const currentValue = data?.businessActivityScores?.find(
    (option) => option.Name === value
  );

  return (
    <div>
      <Autocomplete
        value={currentValue}
        onChange={handleAutocompleteChange}
        options={data?.businessActivityScores}
        getOptionLabel={(option) => option.Name}
        renderInput={(params) => (
          <TextField {...params} required margin="dense" />
        )}
      />
    </div>
  );
};

const SourceOfFunds = ({ sourceOfFunds, onChange }) => {
  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  if (isLoadingCountries) {
    return <div>Loading...</div>;
  }

  const handleAutocompleteChange = (event, newValue) => {
    // Directly call the passed onChange function with the correct format
    onChange({
      target: {
        name: "sourceOfFunds",
        value: newValue ? newValue.source : "",
      },
    });
  };

  // Find the option that matches the current value
  const currentValue = data?.sourceOfFundsScores.find(
    (option) => option.source === sourceOfFunds
  );

  return (
    <div>
      <Autocomplete
        value={currentValue}
        required
        onChange={handleAutocompleteChange}
        options={data?.sourceOfFundsScores}
        getOptionLabel={(option) => option.source}
        renderInput={(params) => <TextField required {...params} />}
      />
    </div>
  );
};

const Jurisdiction = ({ sourceOfFunds, onChange }) => {
  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  if (isLoadingCountries) {
    return <div>Loading...</div>;
  }

  const handleAutocompleteChange = (event, newValue) => {
    // Directly call the passed onChange function with the correct format
    onChange({
      target: {
        name: "sourceOfFunds",
        value: newValue ? newValue.source : "",
      },
    });
  };

  // Find the option that matches the current value
  const currentValue = data.sourceOfFundsScores.find(
    (option) => option.source === sourceOfFunds
  );

  return (
    <div>
      <Autocomplete
        value={currentValue}
        required
        onChange={handleAutocompleteChange}
        options={data.sourceOfFundsScores}
        getOptionLabel={(option) => option.source}
        renderInput={(params) => <TextField required {...params} />}
      />
    </div>
  );
};
