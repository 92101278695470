import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { useLocation } from "react-router-dom";
import PrintTable from "components/printTable";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const data = [
  { q: "Foreign PEP", risk: "Customer Risk", response: "" },
  { q: "Domestic PEP", risk: "Customer Risk", response: "" },
  {
    q: "Is individual on TFS sanction list? (Yes/No)",
    risk: "Customer Risk",
    response: false,
  },
  {
    q: "Is individual national of high-risk country? (Yes/No)",
    risk: "Customer Risk",
    response: false,
  },
  {
    q: "Is individual recently traveled from high-risk country? (Yes/No)",
    risk: "Country Risk",
    response: false,
  },
  {
    q: "Is Cash Transaction amount equal to or above AED 55,000?",
    risk: "Country Risk",
    response: false,
  },
  {
    q: "Is source of funds provided? (Bank statement, cash withdrawal slip, custom declaration, etc.)",
    risk: "Transaction Risk",
    response: false,
  },
  {
    q: "Is deliver is direct? Or if it is through in-direct channel or third party, is the channel/or third party is reliable and has proven track record and necessary KYCs documents are available for authentication",
    risk: "Delivery Channel Risk",
    response: false,
  },
];

const NaturalPerson = () => {
  const [status, setStatus] = useState([]);
  const [riskProfile, setRiskProfile] = useState("Low");
  const [dueDiligence, setDueDiligence] = useState("Simplified");
  const [statusResult, setStatusResult] = useState("Accept");
  const [responses, setResponses] = useState(data.map((item) => item.response));
  const [tableDialog, setTableDialog] = useState(false);
  let query = useQuery();
  const printRef = useRef();
  const [fullName, setFullName] = useState("");
  const [dob, setDob] = useState("");
  const [nationality, setNationality] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [nationalityError, setNationalityError] = useState(false);

  useEffect(() => {
    const isSwitchOn = (index) => responses[index] === "Yes";

    if (isSwitchOn(5) || isSwitchOn(3)) {
      setRiskProfile("High");
      setDueDiligence("Enhanced");
      setStatusResult("Reject");
    } else if (
      isSwitchOn(4) &&
      isSwitchOn(5) &&
      !isSwitchOn(6) &&
      !isSwitchOn(7)
    ) {
      setRiskProfile("Medium");
      setDueDiligence("Simplified");
      setStatusResult("Accept");
    } else if (
      isSwitchOn(4) &&
      isSwitchOn(5) &&
      isSwitchOn(6) &&
      isSwitchOn(7)
    ) {
      setRiskProfile("Medium");
      setDueDiligence("Enhanced");
      setStatusResult("Accept");
    } else if (
      isSwitchOn(3) &&
      isSwitchOn(4) &&
      isSwitchOn(5) &&
      isSwitchOn(6) &&
      !isSwitchOn(7)
    ) {
      setRiskProfile("High");
      setDueDiligence("Enhanced");
      setStatusResult("Reject");
    } else if (isSwitchOn(1)) {
      setRiskProfile("High");
      setDueDiligence("Simplified");
      setStatusResult("Accept");
    } else if (isSwitchOn(2)) {
      setRiskProfile("High");
      setDueDiligence("Enhanced");
      setStatusResult("Accept");
    } else if (isSwitchOn(2) && isSwitchOn(6)) {
      setRiskProfile("High");
      setDueDiligence("Enhanced");
      setStatusResult("Reject");
    } else {
      setRiskProfile("Low");
      setDueDiligence("Simplified");
      setStatusResult("Accept");
    }
  }, [responses]);

  const handleSwitchChange = (index) => {
    setResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[index] =
        updatedResponses[index] === "Yes" ? "No" : "Yes";
      return updatedResponses;
    });
  };

  const validateFields = () => {
    let isValid = true;

    if (fullName === "") {
      setFullNameError(true);
      isValid = false;
    } else {
      setFullNameError(false);
    }

    if (dob === "") {
      setDobError(true);
      isValid = false;
    } else {
      setDobError(false);
    }

    if (nationality === "") {
      setNationalityError(true);
      isValid = false;
    } else {
      setNationalityError(false);
    }

    return isValid;
  };

  const handlePrint = () => {
    if (validateFields()) {
      setTableDialog(true);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  };

  const componentRef = useRef();

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>
        Customer Risk Profile (Natural Person)
      </h1>
      <Box sx={{ width: "50vw", margin: "0 auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              error={fullNameError}
              helperText={fullNameError ? "Full name is required" : ""}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Date of Birth"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              error={dobError}
              helperText={dobError ? "Date of birth is required" : ""}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth error={nationalityError}>
              <InputLabel>Nationality</InputLabel>
              <Select
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
              >
                <MenuItem value={"USA"}>USA</MenuItem>
                <MenuItem value={"UK"}>UK</MenuItem>
              </Select>
              {nationalityError && (
                <FormHelperText>Nationality is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <TableContainer sx={{ padding: "10px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>S#</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Question</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Yes/No</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.q}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        onChange={() => handleSwitchChange(index)}
                        checked={
                          responses[index] === "Yes" ||
                          responses[index] === true
                        }
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        {/* <ReactToPrint
          trigger={() => (
            <Button
              color="success"
              variant="contained"
              style={{ margin: "0 auto" }}
              onBeforeGetContent={handlePrint}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
        /> */}

        <ReactToPrint
          trigger={() => (
            <Button
              color="success"
              variant="contained"
              style={{ margin: "0 auto" }}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
          onBeforeGetContent={handlePrint}
        />
        <div style={{ display: "none" }}>
          <PrintTable
            ref={componentRef}
            data={data}
            clientName={"___________________"}
            type={"______________________"}
            responses={responses}
            riskProfile={riskProfile}
            dueDiligence={dueDiligence}
            statusResult={statusResult}
          />
        </div>
      </div>
    </div>
  );
};

export default NaturalPerson;
