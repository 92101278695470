import { React, useState } from "react";
import {
  Box,
  Fab,
  FormControlLabel,
  Button,
  Checkbox,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  InputLabel,
  Select,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const EntityForm = () => {
  const [open, setOpen] = useState(false);

  //New//

  const [fields, setFields] = useState([
    // {
    //   id: 1,
    //   fullName: "",
    //   idType: "",
    //   country: "",
    //   number: "",
    //   issueDate: "",
    //   expiryDate: "",
    //   birthDate: "",
    //   residentialStatus: "",
    //   percentShares: "",
    //   contactType: "",
    //   contactCountry: "",
    //   contactNumber: "",
    //   authorizedSignatory: false,
    //   ubo: false,
    //   uboReason: "",
    //   uboDate: "",
    // },
  ]);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      fullName: "",
      idType: "",
      country: "",
      number: "",
      issueDate: "",
      expiryDate: "",
      birthDate: "",
      residentialStatus: "",
      percentShares: "",
      contactType: "",
      contactCountry: "",
      contactNumber: "",
      authorizedSignatory: false,
      ubo: false,
      uboReason: "",
      uboDate: "",
    };
    setFields([...fields, newField]);
  };

  const handleFieldChange = (id, key, value) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, [key]: value } : field
    );

    // Check if the issue date is a future date
    if (key === "issueDate" && value !== "") {
      const currentDate = new Date();

      if (new Date(value) > currentDate) {
        alert("Issue date cannot be a future date");
        return;
      }
    }
    // Check if the expiry date is before the issue date
    if (key === "expiryDate" && value !== "") {
      const issueDate = updatedFields.find(
        (field) => field.id === id
      ).issueDate;

      if (issueDate !== "" && new Date(value) < new Date(issueDate)) {
        alert("Expiry date cannot be before the issue date");
        return;
      }
    }

    setFields(updatedFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission with the field values
    console.log(fields);
  };

  const removeField = (index) => {
    const filteredFields = [...fields];
    filteredFields.splice(index, 1);
    setFields(filteredFields);
  };

  // Old version Start//=============================================================================
  const shareholders = { name: "", email: "", phone: "", address: "" };
  const [users, setUsers] = useState([]);
  const addUser = () => {
    setUsers([...users, shareholders]);
  };
  const onChange = (e, index) => {
    const updatedUsers = users.map((user, i) =>
      index == i
        ? Object.assign(user, { [e.target.name]: e.target.value })
        : user
    );
    setUsers(updatedUsers);
  };

  const removeUser = (index) => {
    const filteredUsers = [...users];
    filteredUsers.splice(index, 1);
    setUsers(filteredUsers);
  };
  // Old version End//=======================================================================================
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form>
      <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open form dialog
        </Button>
        <Dialog
          open={open}
          aria-labelledby="edit-Entity"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Entity</DialogTitle>
          <Divider />
          <Grid
            container="true"
            columnSpacing={3}
            columns={{ xs: 4, sm: 8, md: 12 }}
            padding="45px"
            paddingTop={0}
          >
            {/* <DialogContentText>
            Please, edit the flat and the floor of your apartment.
          </DialogContentText> */}
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="flat"
                label="Legal Name"
                type="text"
                fullWidth
                placeholder="Legal Name"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                autoFocus
                margin="dense"
                id="flat"
                label="Registered Address"
                type="text"
                fullWidth
                placeholder="Registered Address"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Trade License Info</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="businessActivity"
                label="businessActivity"
                type="text"
                fullWidth
                placeholder="Business Activity"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="tradeLicenseNo"
                label="tradeLicenseNo"
                type="text"
                fullWidth
                placeholder="Trade License No"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="countryofIncorporation"
                select
                label="Select"
                defaultValue="Select Country"
                helperText="Please select Country"
                fullWidth
                placeholder="Country of Incorporation"
              >
                {countries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="licensingAuthority"
                label="Licensing Authority"
                type="text"
                fullWidth
                placeholder="Licensing Authority"
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  fullWidth
                  sx={{ paddingTop: "8px" }}
                  label="Incoropration Date"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  fullWidth
                  sx={{ paddingTop: "8px" }}
                  label="Lisence Expiry"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Contact Info</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="flat"
                select
                label="Select"
                type="text"
                fullWidth
                placeholder="Legal Name"
              >
                {contacts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="countryofIncorporation"
                select
                label="Select"
                defaultValue="EUR"
                helperText="Please select your currency"
                fullWidth
                placeholder="countryofIncorporation"
              >
                {countries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="number"
                label="Number"
                type="text"
                fullWidth
                placeholder="Number"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                placeholder="Email Address"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="POBox"
                label="POBox"
                type="text"
                fullWidth
                placeholder="POBox"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="website"
                label="Website"
                type="text"
                fullWidth
                placeholder="Website"
              />
            </Grid>
            {
              <Grid item xs={12}>
                {" "}
                {/* <Typography paddingTop={"10px"} variant="h5">
                  Share Holders
                </Typography>
                <Divider /> */}
                {fields.length > 0 && (
                  <Typography paddingTop={"10px"} variant="h5">
                    Share Holders
                  </Typography>
                )}
              </Grid>
            }

            {/* Third Section */}
            {fields.map((field, index) => (
              <Grid
                sx={{
                  p: 2,
                  border: "1px solid grey",
                  margin: "auto",
                  title: "home",
                  borderRadius: "10px",
                }}
                container="true"
                columnSpacing={3}
                columns={{ xs: 4, sm: 8, md: 12 }}
                paddingTop={1}
                paddingLeft={"40px"}
                key={index}
              >
                {" "}
                <Grid item xs={3}>
                  <TextField
                    label="Full Name"
                    value={field.fullName}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "fullName",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`idType-label-${field.id}`}>
                    ID Type
                  </InputLabel>
                  <Select
                    labelId={`idType-label-${field.id}`}
                    value={field.idType}
                    onChange={(event) =>
                      handleFieldChange(field.id, "idType", event.target.value)
                    }
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="ID">ID</MenuItem>
                    <MenuItem value="Passport">Passport</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`country-label-${field.id}`}>
                    Country
                  </InputLabel>
                  <Select
                    labelId={`country-label-${field.id}`}
                    value={field.country}
                    onChange={(event) =>
                      handleFieldChange(field.id, "country", event.target.value)
                    }
                    fullWidth
                    margin="normal"
                  >
                    {/* Replace with your list of country names */}
                    <MenuItem value="country1">Country 1</MenuItem>
                    <MenuItem value="country2">Country 2</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Number"
                    value={field.number}
                    onChange={(event) =>
                      handleFieldChange(field.id, "number", event.target.value)
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Issue Date"
                    type="date"
                    value={field.issueDate}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "issueDate",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Expiry Date"
                    type="date"
                    value={field.expiryDate}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "expiryDate",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Birth Date"
                    type="date"
                    value={field.birthDate}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "birthDate",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`residentialStatus-label-${field.id}`}>
                    Residential Status
                  </InputLabel>
                  <Select
                    labelId={`residentialStatus-label-${field.id}`}
                    value={field.residentialStatus}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "residentialStatus",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="Tourist">Tourist</MenuItem>
                    <MenuItem value="Resident">Resident</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Percent Shares"
                    value={field.percentShares}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "percentShares",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Contact Info</Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id={`contactType-label-${field.id}`}>
                    Contact Type
                  </InputLabel>
                  <Select
                    labelId={`contactType-label-${field.id}`}
                    value={field.contactType}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "contactType",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="Phone">Phone</MenuItem>
                    <MenuItem value="Mobile">Mobile</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id={`contactCountry-label-${field.id}`}>
                    Contact Country
                  </InputLabel>
                  <Select
                    labelId={`contactCountry-label-${field.id}`}
                    value={field.contactCountry}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "contactCountry",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  >
                    {/* Replace with your list of country names */}
                    <MenuItem value="country1">Country 1</MenuItem>
                    <MenuItem value="country2">Country 2</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Contact Number"
                    value={field.contactNumber}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "contactNumber",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.authorizedSignatory}
                        onChange={(event) =>
                          handleFieldChange(
                            field.id,
                            "authorizedSignatory",
                            event.target.checked
                          )
                        }
                      />
                    }
                    label="Authorized Signatory"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.ubo}
                        onChange={(event) =>
                          handleFieldChange(
                            field.id,
                            "ubo",
                            event.target.checked
                          )
                        }
                      />
                    }
                    label="UBO"
                  />
                </Grid>
                <Grid item xs={4}>
                  {field.ubo && (
                    <>
                      <InputLabel id={`uboReason-label-${field.id}`}>
                        UBO Reason
                      </InputLabel>
                      <Select
                        labelId={`uboReason-label-${field.id}`}
                        value={field.uboReason}
                        onChange={(event) =>
                          handleFieldChange(
                            field.id,
                            "uboReason",
                            event.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      >
                        <MenuItem value="Share Holding">Share Holding</MenuItem>
                        <MenuItem value="Control">Control</MenuItem>
                      </Select>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {field.ubo && (
                    <>
                      <TextField
                        label="UBO Date"
                        type="date"
                        value={field.uboDate}
                        onChange={(event) =>
                          handleFieldChange(
                            field.id,
                            "uboDate",
                            event.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  )}
                </Grid>{" "}
                <Grid item xs={1}>
                  <DeleteOutlineOutlinedIcon
                    color="error"
                    title="Delete"
                    onClick={() => {
                      removeField(index);
                    }}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                alignItems={"right"}
                color="success"
                justifyItems="right"
                onClick={addField}
              >
                Add Share Holders
              </Button>
            </Grid>
            {/* </DialogContent>
        </DialogContent> */}
          </Grid>
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              sx={{
                margin: 0,
                top: "auto",
                left: 20,
                bottom: 20,
                right: "auto",
                position: "fixed",
              }}
            >
              {/* <AddIcon /> */}
              <Button
                variant="outlined"
                alignItems={"right"}
                color="success"
                justifyItems="right"
              >
                Add Share Holders
              </Button>
            </Fab>
          </Box>
          <DialogActions>
            <Button
              variant="outlined"
              alignItems={"right"}
              color="success"
              justifyItems="right"
            >
              Add Share Holders
            </Button>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleClose} color="success" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
};

export default EntityForm;

const label_ubo = { inputProps: { "aria-label": "UBO" } };
const countries = [
  {
    value: "Select Country",
    label: "$",
  },
  {
    value: "China",
    label: "€",
  },
  {
    value: "China",
    label: "€",
  },
  {
    value: "UAE",
    label: "฿",
  },
  {
    value: "Japan",
    label: "¥",
  },
];
const contacts = [
  {
    value: "Phone",
    lable: "Phone",
  },
  {
    value: "Mobile",
    lable: "Mobile",
  },
];
const id_Doc = [
  {
    value: "Passport",
    lable: "Passport",
  },
  {
    value: "ID",
    lable: "ID",
  },
];
