import { React, useState } from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import MaterialReactTable from "material-react-table";
import {
  useGetCountriesQuery,
  useGetUserQuery,
  useEditIdTypeMutation,
} from "state/api";

const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
const userId = storedValue?._id;
const IdTypeRisk = () => {
  const [editIdType, { isLoading, isError }] = useEditIdTypeMutation();

  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);
  console.log(data);
  const handleSave = async ({ values, table, row, exitEditingMode }) => {
    console.log(values);
    console.log(row.original); //exit editing mode
    await editIdType({ ...row.original, ...values });

    exitEditingMode();
  };

  const columns = [
    {
      accessorKey: "document",
      header: "ID Type",
      enableEditing: false,
    },

    {
      accessorKey: "logic", // Assuming 'risk' is the key for the Risk Level
      header: "Risk Level",
      Cell: ({ cell }) => cell.getValue(),
      enableEditing: false,
      editVariant: "select",
      editSelectOptions: ["Low", "Medium", "High"],
    },
    {
      accessorKey: "riskScore",
      header: "Risk Score",
      type: "number", // Specify the type for proper input handling
      Cell: ({ cell }) => cell.getValue(),
      // Enable editing for this column
      enableEditing: true,
      editVariant: "select",
      editSelectOptions: [1, 2, 3, 4],
    },
  ];

  if (isLoading || isLoadingCountries) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ID Type Risk" subtitle="RBA Definition ID Type Risk" />
      <Box sx={{ height: 400, width: "100%" }}>
        <MaterialReactTable
          columns={columns}
          data={data.idTypeScores}
          enableEditing
          editingMode="row"
          onEditingRowSave={handleSave}
        />
      </Box>
    </Box>
  );
};

export default IdTypeRisk;
