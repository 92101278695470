import {
  Typography,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";

const SolutionCard = ({ title, description }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card raised sx={{ height: "150px" }}>
      <CardContent>
        <Typography variant="h6" component="h2" fontWeight={"bold"}>
          {title}
        </Typography>
        <br />
        <Typography variant="body2">{description}</Typography>
      </CardContent>
    </Card>
  </Grid>
);

const SolutionsSection = () => {
  const solutions = [
    {
      title: "Risk Assessment Tools",
      description:
        "Identify and assess risks with our AI-driven risk assessment tools that adjust to the specific needs and exposure levels of your business.",
    },
    {
      title: "Customer Due Diligence (CDD)",
      description:
        "Effortlessly conduct thorough customer due diligence with our automated screening and monitoring systems to know your customers and their financial activities.",
    },
    {
      title: "Transaction Monitoring",
      description:
        "Stay on top of suspicious activities with real-time transaction monitoring and receive instant alerts to potential risks.",
    },
    {
      title: "Regulatory Reporting",
      description:
        "Generate and submit necessary reports with ease. Our system ensures you’re always in line with the latest regulatory requirements.",
    },
    {
      title: "Compliance Training Programs",
      description:
        "Equip your team with the knowledge they need. Our in-depth training modules are designed to keep your staff adept and informed.",
    },
    {
      title: "Audit and Book Keeping",
      description:
        "Stay aware of how your business is doing in real time with our innovative audit and financial reporting solutions.",
    },

    // ... Add other solutions here
  ];

  return (
    <Box sx={{ py: 8 }} id="about">
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" fontWeight={"bold"}>
          Our Comprehensive AML/CFT Compliance Suite
        </Typography>
        <Grid container spacing={4} marginTop={"20px"}>
          {solutions.map((solution, index) => (
            <SolutionCard
              key={index}
              title={solution.title}
              description={solution.description}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default SolutionsSection;
