// // // // import {
// // // //   Dialog,
// // // //   DialogTitle,
// // // //   Typography,
// // // //   DialogContent,
// // // //   DialogActions,
// // // //   Button,
// // // // } from "@mui/material";
// // // // import LegalPersonCrp from "scenes/LegalPersonCRP";
// // // // import React from "react";

// // // // function Crp({ open, onClose, id, helloFunction }) {
// // // //   console.log("hello function:", helloFunction);
// // // //   var func = helloFunction;
// // // //   console.log("id is" + id);

// // // //   return (
// // // //     <div>
// // // //       {" "}
// // // //       <Dialog
// // // //         open={open}
// // // //         onClose={onclose}
// // // //         // Prevents dialog from closing when clicking outside
// // // //         fullWidth
// // // //         maxWidth="md"
// // // //         scroll={"paper"}
// // // //       >
// // // //         <DialogTitle>
// // // //           {" "}
// // // //           <Typography
// // // //             variant="h4"
// // // //             paddingBottom={"12px"}
// // // //             color={"#ffc76b"}
// // // //             fontWeight={"bold"}
// // // //           >
// // // //             Customer Risk Profile (CRP)
// // // //           </Typography>
// // // //         </DialogTitle>
// // // //         <DialogContent>
// // // //           <LegalPersonCrp />
// // // //         </DialogContent>
// // // //         <DialogActions>
// // // //           <Button onClick={onClose} color="secondary" variant="contained">
// // // //             Cancel
// // // //           </Button>
// // // //           <Button onClick={func} color="success" variant="contained">
// // // //             Save
// // // //           </Button>
// // // //         </DialogActions>{" "}
// // // //       </Dialog>{" "}
// // // //     </div>
// // // //   );
// // // // }

// // // // export default Crp;

// // // // import {
// // // //   Dialog,
// // // //   DialogTitle,
// // // //   Typography,
// // // //   DialogContent,
// // // //   DialogActions,
// // // //   Button,
// // // // } from "@mui/material";
// // // // import LegalPersonCrp from "scenes/LegalPersonCRP";
// // // // import React from "react";

// // // // function Crp({ open, onClose, data, helloFunction }) {
// // // //   // Log the row data when the dialog is opened
// // // //   React.useEffect(() => {
// // // //     if (open && data) {
// // // //       console.log("Row data:", data);
// // // //     }
// // // //   }, [open, data]);

// // // //   return (
// // // //     <div>
// // // //       <Dialog
// // // //         open={open}
// // // //         onClose={onClose}
// // // //         // Prevents dialog from closing when clicking outside
// // // //         fullWidth
// // // //         maxWidth="md"
// // // //         scroll={"paper"}
// // // //       >
// // // //         <DialogTitle>
// // // //           <Typography
// // // //             variant="h4"
// // // //             paddingBottom={"12px"}
// // // //             color={"#ffc76b"}
// // // //             fontWeight={"bold"}
// // // //           >
// // // //             Customer Risk Profile (CRP)
// // // //           </Typography>
// // // //         </DialogTitle>
// // // //         <DialogContent>
// // // //           <LegalPersonCrp data={data} />
// // // //         </DialogContent>
// // // //         <DialogActions>
// // // //           <Button onClick={onClose} color="secondary" variant="contained">
// // // //             Cancel
// // // //           </Button>
// // // //           <Button onClick={helloFunction} color="success" variant="contained">
// // // //             Save
// // // //           </Button>
// // // //         </DialogActions>
// // // //       </Dialog>
// // // //     </div>
// // // //   );
// // // // }

// // // // export default Crp;

// // // // import React from "react";
// // // // import {
// // // //   Dialog,
// // // //   DialogTitle,
// // // //   Typography,
// // // //   DialogContent,
// // // //   DialogActions,
// // // //   Button,
// // // //   Table,
// // // //   TableBody,
// // // //   TableCell,
// // // //   TableContainer,
// // // //   TableHead,
// // // //   TableRow,
// // // //   Paper,
// // // // } from "@mui/material";

// // // // const Crp = ({ open, onClose, data }) => {
// // // //   // Sample data based on the image provided
// // // //   const rows = [
// // // //     {
// // // //       riskNo: 1,
// // // //       rbaType: "Residential status of majority of Shareholders/UBOs",
// // // //       rbaField: data?.residentialStatus || "Resident",
// // // //       riskScore: 2,
// // // //     },
// // // //     {
// // // //       riskNo: 2,
// // // //       rbaType: "Source of Funds",
// // // //       rbaField: data?.sourceOfFunds || "Investments",
// // // //       riskScore: 2,
// // // //     },
// // // //     {
// // // //       riskNo: 3,
// // // //       rbaType: "License Type",
// // // //       rbaField: data?.licenseType || "Industrial license",
// // // //       riskScore: 2,
// // // //     },
// // // //     {
// // // //       riskNo: 7,
// // // //       rbaType: "PEP Status",
// // // //       rbaField: data?.pepStatus || "PEP",
// // // //       riskScore: 3,
// // // //     },
// // // //     {
// // // //       riskNo: 8,
// // // //       rbaType:
// // // //         "TFS sanction lists (Company, Org, Partners, Shareholders, Senior Managers)",
// // // //       rbaField: data?.tfsSanctionLists || "No",
// // // //       riskScore: 2,
// // // //     },
// // // //     {
// // // //       riskNo: 9,
// // // //       rbaType:
// // // //         "Jurisdiction of incorporation/operations (High Risk Country or Countries with increased monitoring)",
// // // //       rbaField: data?.jurisdiction || "No",
// // // //       riskScore: 2,
// // // //     },
// // // //     {
// // // //       riskNo: 10,
// // // //       rbaType:
// // // //         "Shareholders/UBOs/Senior managers are nationals of High risk countries with increased monitoring)",
// // // //       rbaField: data?.highRiskNationals || "No",
// // // //       riskScore: 2,
// // // //     },
// // // //   ];
// // // //   console.log("DATA in CRP Dialog:" + JSON.stringify(data));

// // // //   // Calculate Total Average Score
// // // //   const totalScore = rows.reduce((sum, row) => sum + row.riskScore, 0);
// // // //   const averageScore = totalScore / rows.length;

// // // //   return (
// // // //     <Dialog
// // // //       open={open}
// // // //       onClose={onClose}
// // // //       fullWidth
// // // //       maxWidth="md"
// // // //       scroll="paper"
// // // //     >
// // // //       <DialogTitle>
// // // //         <Typography
// // // //           variant="h4"
// // // //           paddingBottom="12px"
// // // //           color="#ffc76b"
// // // //           fontWeight="bold"
// // // //         >
// // // //           Customer Risk Profile (CRP)
// // // //         </Typography>
// // // //       </DialogTitle>
// // // //       <DialogContent>
// // // //         <TableContainer component={Paper}>
// // // //           <Table>
// // // //             <TableHead>
// // // //               <TableRow>
// // // //                 <TableCell>Risk#</TableCell>
// // // //                 <TableCell>RBA Type</TableCell>
// // // //                 <TableCell>RBA Field</TableCell>
// // // //                 <TableCell>Risk Score</TableCell>
// // // //               </TableRow>
// // // //             </TableHead>
// // // //             <TableBody>
// // // //               {rows.map((row) => (
// // // //                 <TableRow key={row.riskNo}>
// // // //                   <TableCell>{row.riskNo}</TableCell>
// // // //                   <TableCell>{row.rbaType}</TableCell>
// // // //                   <TableCell>{row.rbaField}</TableCell>
// // // //                   <TableCell>{row.riskScore}</TableCell>
// // // //                 </TableRow>
// // // //               ))}
// // // //               <TableRow>
// // // //                 <TableCell colSpan={3} align="right">
// // // //                   Total Average Score
// // // //                 </TableCell>
// // // //                 <TableCell>{averageScore.toFixed(2)}</TableCell>
// // // //               </TableRow>
// // // //             </TableBody>
// // // //           </Table>
// // // //         </TableContainer>
// // // //       </DialogContent>
// // // //       <DialogActions>
// // // //         <Button onClick={onClose} color="secondary" variant="contained">
// // // //           Cancel
// // // //         </Button>
// // // //       </DialogActions>
// // // //     </Dialog>
// // // //   );
// // // // };

// // // // export default Crp;

// // // import React, { useEffect, useState } from "react";
// // // import {
// // //   Dialog,
// // //   DialogTitle,
// // //   Typography,
// // //   DialogContent,
// // //   DialogActions,
// // //   Button,
// // //   Table,
// // //   TableBody,
// // //   TableCell,
// // //   TableContainer,
// // //   TableHead,
// // //   TableRow,
// // //   Paper,
// // // } from "@mui/material";
// // // import { useGetCountriesQuery } from "state/api";

// // // const Crp = ({ open, onClose, data }) => {
// // //   const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
// // //   const userId = storedValue?._id;

// // //   const {
// // //     data: countryData,
// // //     isLoading: isLoadingCountries,
// // //     error,
// // //   } = useGetCountriesQuery(userId);
// // //   const [riskData, setRiskData] = useState([]);
// // //   const [averageScore, setAverageScore] = useState(0);

// // //   useEffect(() => {
// // //     if (error) {
// // //       console.error("Error fetching data:", error);
// // //     }

// // //     if (!isLoadingCountries && countryData) {
// // //       console.log("Fetched country data:", countryData);
// // //       console.log(data);

// // //       const residentialStatusRiskScore = calculateResidentialStatusRiskScore(
// // //         data?.shareHolders
// // //       );

// // //       const riskScores = [
// // //         {
// // //           riskNo: 1,
// // //           rbaType: "Residential status of majority of Shareholders/UBOs",
// // //           rbaField: "resident",
// // //           riskScore: getRiskScore(
// // //             countryData.nationalScores,
// // //             "status",
// // //             residentialStatusRiskScore.status
// // //           ),
// // //         },
// // //         {
// // //           riskNo: 2,
// // //           rbaType: "Source of Funds",
// // //           rbaField: "Investments",
// // //           riskScore: getRiskScore(
// // //             data?.sourceOfFundsScores,
// // //             "source",
// // //             "INVESTMENTS"
// // //           ),
// // //         },
// // //         {
// // //           riskNo: 3,
// // //           rbaType: "License Type",
// // //           rbaField: "Industrial license",
// // //           riskScore: getRiskScore(
// // //             data?.licTypeScores,
// // //             "document",
// // //             "Industrial License"
// // //           ),
// // //         },
// // //         {
// // //           riskNo: 7,
// // //           rbaType: "PEP Status",
// // //           rbaField: "PEP",
// // //           riskScore: 3, // Static value as per provided data
// // //         },
// // //         {
// // //           riskNo: 8,
// // //           rbaType:
// // //             "TFS sanction lists (Company, Org, Partners, Shareholders, Senior Managers)",
// // //           rbaField: "No",
// // //           riskScore: 2, // Static value as per provided data
// // //         },
// // //         {
// // //           riskNo: 9,
// // //           rbaType:
// // //             "Jurisdiction of incorporation/operations (High Risk Country or Countries with increased monitoring)",
// // //           rbaField: "No",
// // //           riskScore: getRiskScore(data?.jurisdiction, "Name", "OUTSIDE UAE "),
// // //         },
// // //         {
// // //           riskNo: 10,
// // //           rbaType:
// // //             "Shareholders/UBOs/Senior managers are nationals of High risk countries with increased monitoring)",
// // //           rbaField: "No",
// // //           riskScore: 2, // Static value as per provided data
// // //         },
// // //       ];

// // //       console.log("Calculated risk scores:", riskScores);

// // //       const totalScore = riskScores.reduce(
// // //         (acc, item) => acc + item.riskScore,
// // //         0
// // //       );
// // //       const avgScore = totalScore / riskScores.length;

// // //       setRiskData(riskScores);
// // //       setAverageScore(avgScore);
// // //     }
// // //   }, [countryData, isLoadingCountries, error, data]);

// // //   const getRiskScore = (array, key, value) => {
// // //     if (!Array.isArray(array)) {
// // //       console.error(`Expected array but got ${typeof array}`);
// // //       return 0;
// // //     }
// // //     const foundItem = array.find(
// // //       (item) => item[key]?.toLowerCase() === value?.toLowerCase()
// // //     );
// // //     console.log(`Matching item for ${value}:`, foundItem);
// // //     return foundItem ? foundItem.riskScore : 0;
// // //   };

// // //   // const calculateResidentialStatusRiskScore = (shareHolders) => {
// // //   //   if (!Array.isArray(shareHolders)) {
// // //   //     return { status: "Unknown", riskScore: 0 };
// // //   //   }

// // //   //   const significantShareholders = shareHolders.filter(
// // //   //     (sh) => parseFloat(sh.percentShares) >= 25
// // //   //   );
// // //   //   console.log("Sign Shareholders" + JSON.stringify(significantShareholders));
// // //   //   if (significantShareholders.length === 0) {
// // //   //     return { status: "Unknown", riskScore: 0 };
// // //   //   }

// // //   //   const statusCount = significantShareholders.reduce((acc, sh) => {
// // //   //     const status = sh.status?.toLowerCase();
// // //   //     if (!acc[status]) {
// // //   //       acc[status] = 0;
// // //   //     }
// // //   //     acc[status]++;
// // //   //     return acc;
// // //   //   }, {});

// // //   //   const majorityStatus = Object.keys(statusCount).reduce((a, b) =>
// // //   //     statusCount[a] > statusCount[b] ? a : b
// // //   //   );
// // //   //   console.log(majorityStatus);
// // //   //   return { status: majorityStatus, riskScore: 0 }; // Risk score will be calculated separately
// // //   // };

// // //   const calculateResidentialStatusRiskScore = (shareHolders) => {
// // //     if (!Array.isArray(shareHolders)) {
// // //       return { status: "Unknown", riskScore: 0 };
// // //     }

// // //     const significantShareholders = shareHolders.filter(
// // //       (sh) => parseFloat(sh.percentShares) >= 25
// // //     );

// // //     console.log("Significant Shareholders:", significantShareholders);

// // //     if (significantShareholders.length === 0) {
// // //       return { status: "Unknown", riskScore: 0 };
// // //     }

// // //     const statusCount = significantShareholders.reduce((acc, sh) => {
// // //       const status = sh.status?.toLowerCase();
// // //       console.log("Shareholder status:", status);
// // //       if (!status) {
// // //         return acc;
// // //       }
// // //       if (!acc[status]) {
// // //         acc[status] = 0;
// // //       }
// // //       acc[status]++;
// // //       return acc;
// // //     }, {});

// // //     console.log("Status Count:", statusCount);

// // //     if (Object.keys(statusCount).length === 0) {
// // //       return { status: "Unknown", riskScore: 0 };
// // //     }

// // //     const majorityStatus = Object.keys(statusCount).reduce(
// // //       (a, b) => (statusCount[a] > statusCount[b] ? a : b),
// // //       Object.keys(statusCount)[0] // Add initial value to avoid empty array reduce error
// // //     );

// // //     console.log("Majority Status:", majorityStatus);
// // //     return { status: majorityStatus, riskScore: 0 }; // Risk score will be calculated separately
// // //   };

// // //   if (isLoadingCountries) {
// // //     return <div>Loading...</div>;
// // //   }

// // //   return (
// // //     <Dialog
// // //       open={open}
// // //       onClose={onClose}
// // //       fullWidth
// // //       maxWidth="md"
// // //       scroll="paper"
// // //     >
// // //       <DialogTitle>
// // //         <Typography
// // //           variant="h4"
// // //           paddingBottom="12px"
// // //           color="#ffc76b"
// // //           fontWeight="bold"
// // //         >
// // //           Customer Risk Profile (CRP)
// // //         </Typography>
// // //       </DialogTitle>
// // //       <DialogContent>
// // //         <TableContainer component={Paper}>
// // //           <Table>
// // //             <TableHead>
// // //               <TableRow>
// // //                 <TableCell>Risk#</TableCell>
// // //                 <TableCell>RBA Type</TableCell>
// // //                 <TableCell>RBA Field</TableCell>
// // //                 <TableCell>Risk Score</TableCell>
// // //               </TableRow>
// // //             </TableHead>
// // //             <TableBody>
// // //               {riskData.map((row) => (
// // //                 <TableRow key={row.riskNo}>
// // //                   <TableCell>{row.riskNo}</TableCell>
// // //                   <TableCell>{row.rbaType}</TableCell>
// // //                   <TableCell>{row.rbaField}</TableCell>
// // //                   <TableCell>{row.riskScore}</TableCell>
// // //                 </TableRow>
// // //               ))}
// // //               <TableRow>
// // //                 <TableCell colSpan={3} align="right">
// // //                   Total Average Score
// // //                 </TableCell>
// // //                 <TableCell>{averageScore.toFixed(2)}</TableCell>
// // //               </TableRow>
// // //             </TableBody>
// // //           </Table>
// // //         </TableContainer>
// // //       </DialogContent>
// // //       <DialogActions>
// // //         <Button onClick={onClose} color="secondary" variant="contained">
// // //           Cancel
// // //         </Button>
// // //       </DialogActions>
// // //     </Dialog>
// // //   );
// // // };

// // // export default Crp;

// // import React from "react";
// // import './App.css';
// // import {
// //   Container,
// //   Box,
// //   Typography,
// //   Divider,
// //   Paper,
// //   Table,
// //   TableCell,
// //   TableContainer,
// //   TableBody,
// //   TableRow,
// //   TableHead,
// //   tableCellClasses
// // } from "@mui/material";

// // const data = {
// //   TradeLicenseInfo: {
// //     businessActivity: "Accounting",
// //     tradeLicenseNo: "123456",
// //     countryOfIncorporation: "Argentina",
// //     licensingAuthority: "qwwqe",
// //     incorporationDate: "2024-07-11T00:00:00.000Z",
// //     licenseExpiry: "2024-07-12T00:00:00.000Z",
// //   },
// //   contactInfo: {
// //     contactType: "Telephone",
// //     contactCountry: "Brazil",
// //     contactNumber: "321321321321",
// //     emailAddress: "email@gmail.com",
// //     POBox: "20345",
// //     website: "http://www.website.com",
// //   },
// //   financialInfo: {
// //     bankName: "bank name",
// //     accountNo: "64665645",
// //     accountName: "Account Name",
// //     iban: "IBAN no",
// //     bicSwift: "BIC/Swift",
// //     currency: "usd",
// //     yearOfRelationship: "2019",
// //     branch: "Islamabad",
// //     annualBusiness: "200000",
// //     totalShareCapital: "100000",
// //     totalShareHolderEquity: "100000",
// //     totalAssets: "100000",
// //     totalAnnualSales: "10000",
// //   },
// //   _id: "668f95f4e8ed886eea89ada1",
// //   fullLegalName: "New Customer",
// //   riskProfile: "CRP Pending",
// //   registeredAddress: "Registered Address",
// //   businessAddress: "Business address ",
// //   taxNo: "87687686",
// //   jurisdiction: "FREE ZONE",
// //   sourceOfFunds: "LOANS",
// //   shareHolders: [
// //     {
// //       idDoc: {
// //         type: "ID",
// //         issueCountry: "Albania",
// //         number: "665+6565",
// //         issueDate: "2024-12-31T00:00:00.000Z",
// //         expiryDate: "2023-12-31T00:00:00.000Z",
// //       },
// //       contactInfo: {
// //         contactType: "Telephone",
// //         contactCountry: "Austria",
// //         contactNumber: "2013565",
// //       },
// //       fullLegalName: "kjsdkjdfhk",
// //       doB: "2024-12-31T00:00:00.000Z",
// //       residentialStatus: "Non Resident",
// //       occupation: "Occupation1",
// //       dualNationality: "No",
// //       percentShares: "22",
// //       ubo: true,
// //       uboReason: "SHARE HOLDING",
// //       uboDate: "2024-07-01T00:00:00.000Z",
// //       isAuthorizedSignatory: false,
// //       _id: "668f95f4e8ed886eea89ada2",
// //     },
// //   ],
// //   addedDate: "1901-01-01T00:00:00.000Z",
// //   lastModified: "1901-01-01T00:00:00.000Z",
// //   company: "64c07b5bb8ead8bf27b144ba",
// //   createdAt: "2024-07-11T08:21:08.714Z",
// //   updatedAt: "2024-07-11T08:21:08.714Z",
// //   __v: 0,
// // };

// // function createData(field, risk) {
// //   return { field, risk };
// // }

// // const rows = {
// //   customer: {
// //     name: data.fullLegalName,
// //     legal_Name_Sanction: createData(data.fullLegalName, 1),
// //     country_Of_Incorporation: createData(data.TradeLicenseInfo.countryOfIncorporation, 2),
// //     country_Of_Residence: createData(data.contactInfo.contactCountry, 2),
// //     business_Address: createData(data.businessAddress, 2),
// //     business_Activity: createData(data.TradeLicenseInfo.businessActivity, 3),
// //     source_Of_Funds: createData(data.sourceOfFunds, 2),
// //     licensing_Authority: createData(data.TradeLicenseInfo.licensingAuthority, 1),
// //     customer_Type: createData(data.riskProfile, 1),
// //     trade_License_No: createData(data.TradeLicenseInfo.tradeLicenseNo, 2),
// //     jurisdiction: createData(data.jurisdiction, 1),
// //   },
// //   shareholder: data.shareHolders.map((shareholder, index) => ({
// //     name: shareholder.fullLegalName,
// //     dual_Nationality: createData(shareholder.dualNationality, 1),
// //     nationality: createData(shareholder.contactInfo.contactCountry, 2),
// //     document_Type: createData(shareholder.idDoc.type, 1),
// //     source_Of_Funds: createData(shareholder.uboReason, 2),
// //     occupation: createData(shareholder.occupation, 3),
// //     country_Of_Residence: createData(shareholder.idDoc.issueCountry, 2),
// //     full_Name_Sanction: createData(shareholder.fullLegalName, 1),
// //   })),
// // };

// // function App() {
// //   const user = {
// //     customer: {
// //       uid: data._id,
// //       name: data.fullLegalName,
// //       dateOfIncorporation: data.TradeLicenseInfo.incorporationDate,
// //       countryOfIncorporation: data.TradeLicenseInfo.countryOfIncorporation,
// //     },
// //   };

// //   const info = [
// //     {
// //       rbaType: "CUSTOMER RESIDUAL RISK",
// //       rbaField: "1.706",
// //       percentage: "100%",
// //       risk: "1.706",
// //     },
// //   ];

// //   return (
// //     <Container sx={{ my: 0, p: 0, width: '100%' }}>
// //       <Box>
// //         <Typography variant="h6" align="left">
// //           <b>ARA JEWELLERY LLC</b>
// //         </Typography>
// //         <Typography variant="body2" align="left">
// //           Office # 402, Dubai Diamond Plaza, Gold Souk Deira
// //         </Typography>
// //         <Typography variant="body2" align="left">
// //           +971 4 3305259
// //         </Typography>
// //         <Typography variant="body2" align="left">
// //           arajewellltd@gmail.com
// //         </Typography>
// //         <Divider sx={{ my: 1, border: "1px solid" }} />
// //         <Paper elevation={10} sx={{ mb: 1, width: "100%", pr: 8 }}>
// //           <Typography variant="body2">
// //             <b>
// //               <i>
// //                 CUSTOMER DETAILS ={">"} UID : {user.customer.uid} | NAME :{" "}
// //                 {user.customer.name} | DATE OF INCORPORATION :{" "}
// //                 {user.customer.dateOfIncorporation} | COUNTRY OF INCORPORATION :{" "}
// //                 {user.customer.countryOfIncorporation}
// //               </i>
// //             </b>
// //           </Typography>
// //         </Paper>
// //         <Box sx={{ ml: 1 }}>
// //           <Paper
// //             elevation={10}
// //             sx={{
// //               width: "90%",
// //               pr: 8,
// //               color: "darkblue",
// //               background: "lightgrey",
// //             }}
// //           >
// //             <Typography variant="body2">
// //               <i>CUSTOMER RESIDUAL RISK</i>
// //             </Typography>
// //           </Paper>
// //           <Box sx={{ px: 2, py: 0.5, ml: 1 }}>
// //             <Paper
// //               elevation={10}
// //               sx={{
// //                 mb: 1,
// //                 width: "80%",
// //                 pr: 8,
// //                 color: "darkblue",
// //                 background: "lightgrey",
// //               }}
// //             >
// //               <Typography variant="body2">
// //                 <i>CUSTOMER --{">"} {data.fullLegalName} - (CUSTOMER)</i>
// //               </Typography>
// //             </Paper>
// //             <TableContainer>
// //               <Table size="small" sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}>
// //                 <TableHead>
// //                   <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                     <TableCell>RBA TYPE</TableCell>
// //                     <TableCell>RBA FIELD</TableCell>
// //                     <TableCell align="right">RISK</TableCell>
// //                   </TableRow>
// //                 </TableHead>
// //                 <TableBody>
// //                   {Object.entries(rows.customer).map(
// //                     ([key, value], index) =>
// //                       key !== "name" && (
// //                         <TableRow key={index}>
// //                           <TableCell>
// //                             {key.replace(/_/g, " ").toUpperCase()}
// //                           </TableCell>
// //                           <TableCell>{value.field}</TableCell>
// //                           <TableCell align="right">{value.risk}</TableCell>
// //                         </TableRow>
// //                       )
// //                   )}
// //                 </TableBody>
// //               </Table>
// //             </TableContainer>
// //             {rows.shareholder.map((shareholder, idx) => (
// //               <div>
// //                 <Paper
// //                   elevation={10}
// //                   sx={{
// //                     my: 1,
// //                     width: "80%",
// //                     pr: 8,
// //                     color: "darkblue",
// //                     background: "lightgrey",
// //                   }}
// //                 >
// //                   <Typography variant="body2">
// //                     <i>
// //                       SHAREHOLDER [NATURAL PERSONS] --{">"} {rows.shareholder[0].name}{" "} - (SHAREHOLDER [NATURAL PERSONS])
// //                     </i>
// //                   </Typography>
// //                 </Paper>
// //                 <TableContainer>
// //                   <Table size="small" sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}>
// //                     <TableHead>
// //                       <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                         <TableCell>RBA TYPE</TableCell>
// //                         <TableCell>RBA FIELD</TableCell>
// //                         <TableCell align="right">RISK</TableCell>
// //                       </TableRow>
// //                     </TableHead>
// //                     <TableBody>
// //                       <React.Fragment key={idx}>
// //                         {Object.entries(shareholder).map(
// //                           ([key, value], index) =>
// //                             key !== "name" && (
// //                               <TableRow key={index}>
// //                                 <TableCell>
// //                                   {key.replace(/_/g, " ").toUpperCase()}
// //                                 </TableCell>
// //                                 <TableCell>{value.field}</TableCell>
// //                                 <TableCell align="right">{value.risk}</TableCell>
// //                               </TableRow>
// //                             )
// //                         )}
// //                       </React.Fragment>
// //                     </TableBody>
// //                   </Table>
// //                 </TableContainer>
// //               </div>
// //             ))}
// //             <Box
// //               display="flex"
// //               flexDirection="row"
// //               justifyContent="flex-end"
// //               sx={{ width: "100%" }}
// //             >
// //               <Table size="small" sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none", p: 0 } }}>
// //                 <TableRow sx={{ border: 'none' }}>
// //                   <TableCell>
// //                     <Typography textAlign="right">
// //                       (Total Sum of Risk (Customer + Relation) / Total No. of Risk){" "}
// //                       <b>ONBOARDING RISK :&nbsp;</b>
// //                     </Typography>
// //                   </TableCell>
// //                   <TableCell>
// //                     <Divider
// //                       sx={{
// //                         mt: 1,
// //                         border: "1px solid",
// //                         borderColor: "blue",
// //                         width: "100%",
// //                       }}
// //                     />
// //                     <Typography textAlign="right">
// //                       <b>1.706</b>
// //                     </Typography>
// //                   </TableCell>
// //                 </TableRow>
// //                 <TableRow sx={{ border: 'none' }}>
// //                   <TableCell>
// //                     <Typography textAlign="right">
// //                       Mitigation Score :&nbsp;
// //                     </Typography>
// //                   </TableCell>
// //                   <TableCell>
// //                     <Typography textAlign="right">
// //                       <b>0</b>
// //                     </Typography>
// //                     <Divider
// //                       sx={{
// //                         border: "1px solid",
// //                         borderColor: "blue",
// //                         width: "100%",
// //                       }}
// //                     />
// //                   </TableCell>
// //                 </TableRow>
// //                 <TableRow sx={{ border: 'none' }}>
// //                   <TableCell>
// //                     <Typography textAlign="right">
// //                       (Onboarding Risk * Mitigation Score){" "}
// //                       <b>RESIDUAL RISK :&nbsp;</b>
// //                     </Typography>
// //                   </TableCell>
// //                   <TableCell>
// //                     <Typography textAlign="right">
// //                       <b>1.706</b>
// //                     </Typography>
// //                     <Divider
// //                       sx={{
// //                         border: "1px solid",
// //                         borderColor: "blue",
// //                         width: "100%",
// //                       }}
// //                     />
// //                     <Divider
// //                       sx={{
// //                         mt: 0.5,
// //                         border: "1px solid",
// //                         borderColor: "blue",
// //                         width: "100%",
// //                       }}
// //                     />
// //                   </TableCell>
// //                 </TableRow>
// //               </Table>
// //             </Box>
// //           </Box>
// //         </Box>
// //         <Box sx={{ mt: 1, ml: 1 }}>
// //           <Paper
// //             elevation={10}
// //             sx={{
// //               mb: 1,
// //               width: "60%",
// //               pr: 8,
// //               color: "darkblue",
// //               background: "lightgrey",
// //             }}
// //           >
// //             <Typography variant="body2">
// //               <b>
// //                 <i>RISK DISTRIBUTION</i>
// //               </b>
// //             </Typography>
// //           </Paper>
// //           <Box sx={{ px: 2, ml: 1 }}>
// //             <TableContainer>
// //               <Table size="small" sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}>
// //                 <TableHead>
// //                   <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                     <TableCell>RBA TYPE</TableCell>
// //                     <TableCell>RBA FIELD</TableCell>
// //                     <TableCell>(%)</TableCell>
// //                     <TableCell align="right">RISK</TableCell>
// //                   </TableRow>
// //                 </TableHead>
// //                 <TableBody>
// //                   {info.map((row, index) => (
// //                     <TableRow key={index}>
// //                       <TableCell>{row.rbaType}</TableCell>
// //                       <TableCell>{row.rbaField}</TableCell>
// //                       <TableCell>{row.percentage}</TableCell>
// //                       <TableCell align="right">{row.risk}</TableCell>
// //                     </TableRow>
// //                   ))}
// //                 </TableBody>
// //               </Table>
// //             </TableContainer>
// //             <Box
// //               display="flex"
// //               flexDirection="row"
// //               justifyContent="flex-end"
// //               sx={{ width: "100%" }}
// //             >
// //               <Table size="small" sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none", p: 0 } }}>
// //                 <TableRow sx={{ border: 'none' }}>
// //                   <TableCell>
// //                     <Typography textAlign="right">
// //                       (Sum of Distributed Risk) <b>OVERALL RISK :&nbsp;</b>
// //                     </Typography>
// //                   </TableCell>
// //                   <TableCell>
// //                     <Divider
// //                       sx={{
// //                         mt: 1,
// //                         border: "1px solid",
// //                         borderColor: "blue",
// //                         width: "100%",
// //                       }}
// //                     />
// //                     <Typography textAlign="right">
// //                       <b>{info[0].risk}</b>
// //                     </Typography>
// //                     <Divider
// //                       sx={{
// //                         border: "1px solid",
// //                         borderColor: "blue",
// //                         width: "100%",
// //                       }}
// //                     />
// //                     <Divider
// //                       sx={{
// //                         mt: 0.5,
// //                         border: "1px solid",
// //                         borderColor: "blue",
// //                         width: "100%",
// //                       }}
// //                     />
// //                   </TableCell>
// //                 </TableRow>
// //               </Table>
// //             </Box>
// //           </Box>
// //           <Box
// //             sx={{
// //               display: "flex",
// //               flexDirection: "row",
// //               justifyContent: "space-between",
// //             }}
// //           >
// //             <Box sx={{ mt: 1, background: "lightgrey", width: "100%", px: 0.5 }}>
// //               <Typography variant="body2">
// //                 <b>RISK METHODOLOGY</b>
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: "white", background: "red" }}
// //               >
// //                 High Risk | MORE THAN 3.1
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: "white", background: "darkviolet" }}
// //               >
// //                 Medium High Risk | 2.1 {"<"}= 3.1
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: "white", background: "olive" }}
// //               >
// //                 Medium Risk | 1.1 {"<"}= 2.1
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: "white", background: "darkgreen" }}
// //               >
// //                 Low Risk | {"<"}= 1.1
// //               </Typography>
// //             </Box>
// //             <Box
// //               sx={{
// //                 mt: 1,
// //                 ml: 4,
// //                 background: "lightgrey",
// //                 display: "flex",
// //                 flexDirection: "row",
// //               }}
// //             >
// //               <Typography variant="body2" sx={{ textAlign: "left" }}>
// //                 <i>
// //                   <span style={{ color: "maroon" }}>
// //                     <b>*Note :-&nbsp;</b>
// //                   </span>{" "}
// //                   For legal customers, the system includes a profile with the
// //                   shareholder who has the highest level of risk.
// //                   <span style={{ color: "maroon" }}><b>**</b></span>If De-Risked,
// //                   Then De-Risk Parameter will be Club Risk Rating, Else If one
// //                   or more individual risk scores is 4(Override) and Not
// //                   Mitigated, the Club Risk Rating will be overridden to High
// //                   Risk, Else Club Risk Rating is Calculated Based on Risk
// //                   Score as per the set score range.
// //                 </i>
// //               </Typography>
// //               <Box sx={{ color: "darkblue", width: "300%", mt: 2 }}>
// //                 <Typography variant="body2" sx={{ mt: 1, textAlign: "right" }}>
// //                   CUSTOMER PROFILE RISK: <b>{info[0].risk}</b>
// //                 </Typography>
// //                 <Typography variant="body2" sx={{ mt: 1, textAlign: "right" }}>
// //                   CLUB RISK RATING: <b>MEDIUM RISK</b>
// //                 </Typography>
// //               </Box>
// //             </Box>
// //           </Box>
// //         </Box>
// //         <Divider>&nbsp; ***** END OF CUSTOMER ***** &nbsp;</Divider>
// //       </Box>
// //       <div className="footer">
// //         <div className="footer-text">
// //           <span className="pageNumber"></span>
// //           <span className="totalPages"></span>
// //         </div>
// //       </div>
// //     </Container>
// //   );
// // }

// // export default App;

// // import React, { useEffect, useState } from "react";
// // import {
// //   Dialog,
// //   DialogTitle,
// //   DialogContent,
// //   DialogActions,
// //   Button,
// //   Container,
// //   Box,
// //   Typography,
// //   Divider,
// //   Paper,
// //   Table,
// //   TableCell,
// //   TableContainer,
// //   TableBody,
// //   TableRow,
// //   TableHead,
// //   tableCellClasses,
// // } from "@mui/material";
// // import { useGetCountriesQuery } from "state/api";

// // const Crp = ({ open, onClose, data }) => {
// //   const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
// //   const userId = storedValue?._id;

// //   const {
// //     data: countryData,
// //     isLoading: isLoadingCountries,
// //     error,
// //   } = useGetCountriesQuery(userId);
// //   const [riskData, setRiskData] = useState([]);
// //   const [averageScore, setAverageScore] = useState(0);

// //   useEffect(() => {
// //     if (error) {
// //       console.error("Error fetching data:", error);
// //     }

// //     if (!isLoadingCountries && countryData) {
// //       console.log("Fetched country data:", countryData);
// //       console.log(data);

// //       const residentialStatusRiskScore = calculateResidentialStatusRiskScore(
// //         data?.shareHolders
// //       );

// //       const riskScores = [
// //         {
// //           riskNo: 1,
// //           rbaType: "Residential status of majority of Shareholders/UBOs",
// //           rbaField: "resident",
// //           riskScore: getRiskScore(
// //             countryData.nationalScores,
// //             "status",
// //             residentialStatusRiskScore.status
// //           ),
// //         },
// //         {
// //           riskNo: 2,
// //           rbaType: "Source of Funds",
// //           rbaField: "Investments",
// //           riskScore: getRiskScore(
// //             data?.sourceOfFundsScores,
// //             "source",
// //             "INVESTMENTS"
// //           ),
// //         },
// //         {
// //           riskNo: 3,
// //           rbaType: "License Type",
// //           rbaField: "Industrial license",
// //           riskScore: getRiskScore(
// //             data?.licTypeScores,
// //             "document",
// //             "Industrial License"
// //           ),
// //         },
// //         {
// //           riskNo: 7,
// //           rbaType: "PEP Status",
// //           rbaField: "PEP",
// //           riskScore: 3, // Static value as per provided data
// //         },
// //         {
// //           riskNo: 8,
// //           rbaType:
// //             "TFS sanction lists (Company, Org, Partners, Shareholders, Senior Managers)",
// //           rbaField: "No",
// //           riskScore: 2, // Static value as per provided data
// //         },
// //         {
// //           riskNo: 9,
// //           rbaType:
// //             "Jurisdiction of incorporation/operations (High Risk Country or Countries with increased monitoring)",
// //           rbaField: "No",
// //           riskScore: getRiskScore(data?.jurisdiction, "Name", "OUTSIDE UAE "),
// //         },
// //         {
// //           riskNo: 10,
// //           rbaType:
// //             "Shareholders/UBOs/Senior managers are nationals of High risk countries with increased monitoring)",
// //           rbaField: "No",
// //           riskScore: 2, // Static value as per provided data
// //         },
// //       ];

// //       console.log("Calculated risk scores:", riskScores);

// //       const totalScore = riskScores.reduce(
// //         (acc, item) => acc + item.riskScore,
// //         0
// //       );
// //       const avgScore = totalScore / riskScores.length;

// //       setRiskData(riskScores);
// //       setAverageScore(avgScore);
// //     }
// //   }, [countryData, isLoadingCountries, error, data]);

// //   const getRiskScore = (array, key, value) => {
// //     if (!Array.isArray(array)) {
// //       console.error(`Expected array but got ${typeof array}`);
// //       return 0;
// //     }
// //     const foundItem = array.find(
// //       (item) => item[key]?.toLowerCase() === value?.toLowerCase()
// //     );
// //     console.log(`Matching item for ${value}:`, foundItem);
// //     return foundItem ? foundItem.riskScore : 0;
// //   };

// //   const calculateResidentialStatusRiskScore = (shareHolders) => {
// //     if (!Array.isArray(shareHolders)) {
// //       return { status: "Unknown", riskScore: 0 };
// //     }

// //     const significantShareholders = shareHolders.filter(
// //       (sh) => parseFloat(sh.percentShares) >= 25
// //     );

// //     console.log("Significant Shareholders:", significantShareholders);

// //     if (significantShareholders.length === 0) {
// //       return { status: "Unknown", riskScore: 0 };
// //     }

// //     const statusCount = significantShareholders.reduce((acc, sh) => {
// //       const status = sh.status?.toLowerCase();
// //       console.log("Shareholder status:", status);
// //       if (!status) {
// //         return acc;
// //       }
// //       if (!acc[status]) {
// //         acc[status] = 0;
// //       }
// //       acc[status]++;
// //       return acc;
// //     }, {});

// //     console.log("Status Count:", statusCount);

// //     if (Object.keys(statusCount).length === 0) {
// //       return { status: "Unknown", riskScore: 0 };
// //     }

// //     const majorityStatus = Object.keys(statusCount).reduce(
// //       (a, b) => (statusCount[a] > statusCount[b] ? a : b),
// //       Object.keys(statusCount)[0] // Add initial value to avoid empty array reduce error
// //     );

// //     console.log("Majority Status:", majorityStatus);
// //     return { status: majorityStatus, riskScore: 0 }; // Risk score will be calculated separately
// //   };

// //   if (isLoadingCountries) {
// //     return <div>Loading...</div>;
// //   }

// //   const createData = (field, risk) => {
// //     return { field, risk };
// //   };

// //   const rows = {
// //     customer: {
// //       name: data?.fullLegalName,
// //       legal_Name_Sanction: createData(data?.fullLegalName, 1),
// //       country_Of_Incorporation: createData(
// //         data?.TradeLicenseInfo.countryOfIncorporation,
// //         2
// //       ),
// //       country_Of_Residence: createData(data?.contactInfo.contactCountry, 2),
// //       business_Address: createData(data?.businessAddress, 2),
// //       business_Activity: createData(data?.TradeLicenseInfo.businessActivity, 3),
// //       source_Of_Funds: createData(data?.sourceOfFunds, 2),
// //       licensing_Authority: createData(
// //         data?.TradeLicenseInfo.licensingAuthority,
// //         1
// //       ),
// //       customer_Type: createData(data?.riskProfile, 1),
// //       trade_License_No: createData(data?.TradeLicenseInfo.tradeLicenseNo, 2),
// //       jurisdiction: createData(data?.jurisdiction, 1),
// //     },
// //     shareholder: data?.shareHolders.map((shareholder) => ({
// //       name: shareholder?.fullLegalName,
// //       dual_Nationality: createData(shareholder.dualNationality, 1),
// //       nationality: createData(shareholder.contactInfo.contactCountry, 2),
// //       document_Type: createData(shareholder.idDoc.type, 1),
// //       source_Of_Funds: createData(shareholder.uboReason, 2),
// //       occupation: createData(shareholder.occupation, 3),
// //       country_Of_Residence: createData(shareholder.idDoc.issueCountry, 2),
// //       full_Name_Sanction: createData(shareholder?.fullLegalName, 1),
// //     })),
// //   };

// //   const user = {
// //     customer: {
// //       uid: data?._id,
// //       name: data?.fullLegalName,
// //       dateOfIncorporation: data?.TradeLicenseInfo.incorporationDate,
// //       countryOfIncorporation: data?.TradeLicenseInfo.countryOfIncorporation,
// //     },
// //   };

// //   const info = [
// //     {
// //       rbaType: "CUSTOMER RESIDUAL RISK",
// //       rbaField: "1.706",
// //       percentage: "100%",
// //       risk: "1.706",
// //     },
// //   ];

// //   return (
// //     <Dialog
// //       open={open}
// //       onClose={onClose}
// //       fullWidth
// //       maxWidth="md"
// //       scroll="paper"
// //     >
// //       <DialogTitle>
// //         <Typography
// //           variant="h4"
// //           paddingBottom="12px"
// //           color="#ffc76b"
// //           fontWeight="bold"
// //         >
// //           Customer Risk Profile (CRP)
// //         </Typography>
// //       </DialogTitle>
// //       <DialogContent>
// //         <Container sx={{ my: 0, p: 0, width: "100%" }}>
// //           <Box>
// //             <Typography variant="h6" align="left">
// //               <b>ARA JEWELLERY LLC</b>
// //             </Typography>
// //             <Typography variant="body2" align="left">
// //               Office # 402, Dubai Diamond Plaza, Gold Souk Deira
// //             </Typography>
// //             <Typography variant="body2" align="left">
// //               +971 4 3305259
// //             </Typography>
// //             <Typography variant="body2" align="left">
// //               arajewellltd@gmail.com
// //             </Typography>
// //             <Divider sx={{ my: 1, border: "1px solid" }} />
// //             <Paper elevation={10} sx={{ mb: 1, width: "100%", pr: 8 }}>
// //               <Typography variant="body2">
// //                 <b>
// //                   <i>
// //                     CUSTOMER DETAILS ={">"} UID : {user.customer.uid} | NAME :{" "}
// //                     {user.customer.name} | DATE OF INCORPORATION :{" "}
// //                     {user.customer.dateOfIncorporation} | COUNTRY OF
// //                     INCORPORATION : {user.customer.countryOfIncorporation}
// //                   </i>
// //                 </b>
// //               </Typography>
// //             </Paper>
// //             <Box sx={{ ml: 1 }}>
// //               <Paper
// //                 elevation={10}
// //                 sx={{
// //                   width: "90%",
// //                   pr: 8,
// //                   color: "darkblue",
// //                   background: "lightgrey",
// //                 }}
// //               >
// //                 <Typography variant="body2">
// //                   <i>CUSTOMER RESIDUAL RISK</i>
// //                 </Typography>
// //               </Paper>
// //               <Box sx={{ px: 2, py: 0.5, ml: 1 }}>
// //                 <Paper
// //                   elevation={10}
// //                   sx={{
// //                     mb: 1,
// //                     width: "80%",
// //                     pr: 8,
// //                     color: "darkblue",
// //                     background: "lightgrey",
// //                   }}
// //                 >
// //                   <Typography variant="body2">
// //                     <i>
// //                       CUSTOMER --{">"} {data.fullLegalName} - (CUSTOMER)
// //                     </i>
// //                   </Typography>
// //                 </Paper>
// //                 <TableContainer>
// //                   <Table
// //                     size="small"
// //                     sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
// //                   >
// //                     <TableHead>
// //                       <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                         <TableCell>RBA TYPE</TableCell>
// //                         <TableCell>RBA FIELD</TableCell>
// //                         <TableCell align="right">RISK</TableCell>
// //                       </TableRow>
// //                     </TableHead>
// //                     <TableBody>
// //                       {Object.entries(rows.customer).map(
// //                         ([key, value], index) =>
// //                           key !== "name" && (
// //                             <TableRow key={index}>
// //                               <TableCell>
// //                                 {key.replace(/_/g, " ").toUpperCase()}
// //                               </TableCell>
// //                               <TableCell>{value.field}</TableCell>
// //                               <TableCell align="right">{value.risk}</TableCell>
// //                             </TableRow>
// //                           )
// //                       )}
// //                     </TableBody>
// //                   </Table>
// //                 </TableContainer>
// //                 {rows.shareholder.map((shareholder, idx) => (
// //                   <div key={idx}>
// //                     <Paper
// //                       elevation={10}
// //                       sx={{
// //                         my: 1,
// //                         width: "80%",
// //                         pr: 8,
// //                         color: "darkblue",
// //                         background: "lightgrey",
// //                       }}
// //                     >
// //                       <Typography variant="body2">
// //                         <i>
// //                           SHAREHOLDER [NATURAL PERSONS] --{">"}{" "}
// //                           {shareholder.name} - (SHAREHOLDER [NATURAL PERSONS])
// //                         </i>
// //                       </Typography>
// //                     </Paper>
// //                     <TableContainer>
// //                       <Table
// //                         size="small"
// //                         sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
// //                       >
// //                         <TableHead>
// //                           <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                             <TableCell>RBA TYPE</TableCell>
// //                             <TableCell>RBA FIELD</TableCell>
// //                             <TableCell align="right">RISK</TableCell>
// //                           </TableRow>
// //                         </TableHead>
// //                         <TableBody>
// //                           {Object.entries(shareholder).map(
// //                             ([key, value], index) =>
// //                               key !== "name" && (
// //                                 <TableRow key={index}>
// //                                   <TableCell>
// //                                     {key.replace(/_/g, " ").toUpperCase()}
// //                                   </TableCell>
// //                                   <TableCell>{value.field}</TableCell>
// //                                   <TableCell align="right">
// //                                     {value.risk}
// //                                   </TableCell>
// //                                 </TableRow>
// //                               )
// //                           )}
// //                         </TableBody>
// //                       </Table>
// //                     </TableContainer>
// //                   </div>
// //                 ))}
// //                 <Box
// //                   display="flex"
// //                   flexDirection="row"
// //                   justifyContent="flex-end"
// //                   sx={{ width: "100%" }}
// //                 >
// //                   <Table
// //                     size="small"
// //                     sx={{
// //                       [`& .${tableCellClasses.root}`]: {
// //                         borderBottom: "none",
// //                         p: 0,
// //                       },
// //                     }}
// //                   >
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           (Total Sum of Risk (Customer + Relation) / Total No.
// //                           of Risk) <b>ONBOARDING RISK :&nbsp;</b>
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Divider
// //                           sx={{
// //                             mt: 1,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Typography textAlign="right">
// //                           <b>1.706</b>
// //                         </Typography>
// //                       </TableCell>
// //                     </TableRow>
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           Mitigation Score :&nbsp;
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           <b>0</b>
// //                         </Typography>
// //                         <Divider
// //                           sx={{
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                       </TableCell>
// //                     </TableRow>
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           (Onboarding Risk * Mitigation Score){" "}
// //                           <b>RESIDUAL RISK :&nbsp;</b>
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           <b>1.706</b>
// //                         </Typography>
// //                         <Divider
// //                           sx={{
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Divider
// //                           sx={{
// //                             mt: 0.5,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                       </TableCell>
// //                     </TableRow>
// //                   </Table>
// //                 </Box>
// //               </Box>
// //             </Box>
// //             <Box sx={{ mt: 1, ml: 1 }}>
// //               <Paper
// //                 elevation={10}
// //                 sx={{
// //                   mb: 1,
// //                   width: "60%",
// //                   pr: 8,
// //                   color: "darkblue",
// //                   background: "lightgrey",
// //                 }}
// //               >
// //                 <Typography variant="body2">
// //                   <b>
// //                     <i>RISK DISTRIBUTION</i>
// //                   </b>
// //                 </Typography>
// //               </Paper>
// //               <Box sx={{ px: 2, ml: 1 }}>
// //                 <TableContainer>
// //                   <Table
// //                     size="small"
// //                     sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
// //                   >
// //                     <TableHead>
// //                       <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                         <TableCell>RBA TYPE</TableCell>
// //                         <TableCell>RBA FIELD</TableCell>
// //                         <TableCell>(%)</TableCell>
// //                         <TableCell align="right">RISK</TableCell>
// //                       </TableRow>
// //                     </TableHead>
// //                     <TableBody>
// //                       {info.map((row, index) => (
// //                         <TableRow key={index}>
// //                           <TableCell>{row.rbaType}</TableCell>
// //                           <TableCell>{row.rbaField}</TableCell>
// //                           <TableCell>{row.percentage}</TableCell>
// //                           <TableCell align="right">{row.risk}</TableCell>
// //                         </TableRow>
// //                       ))}
// //                     </TableBody>
// //                   </Table>
// //                 </TableContainer>
// //                 <Box
// //                   display="flex"
// //                   flexDirection="row"
// //                   justifyContent="flex-end"
// //                   sx={{ width: "100%" }}
// //                 >
// //                   <Table
// //                     size="small"
// //                     sx={{
// //                       [`& .${tableCellClasses.root}`]: {
// //                         borderBottom: "none",
// //                         p: 0,
// //                       },
// //                     }}
// //                   >
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           (Sum of Distributed Risk) <b>OVERALL RISK :&nbsp;</b>
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Divider
// //                           sx={{
// //                             mt: 1,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Typography textAlign="right">
// //                           <b>{info[0].risk}</b>
// //                         </Typography>
// //                         <Divider
// //                           sx={{
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Divider
// //                           sx={{
// //                             mt: 0.5,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                       </TableCell>
// //                     </TableRow>
// //                   </Table>
// //                 </Box>
// //               </Box>
// //               <Box
// //                 sx={{
// //                   display: "flex",
// //                   flexDirection: "row",
// //                   justifyContent: "space-between",
// //                 }}
// //               >
// //                 <Box
// //                   sx={{
// //                     mt: 1,
// //                     background: "lightgrey",
// //                     width: "100%",
// //                     px: 0.5,
// //                   }}
// //                 >
// //                   <Typography variant="body2">
// //                     <b>RISK METHODOLOGY</b>
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "red" }}
// //                   >
// //                     High Risk | MORE THAN 3.1
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "darkviolet" }}
// //                   >
// //                     Medium High Risk | 2.1 {"<"}= 3.1
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "olive" }}
// //                   >
// //                     Medium Risk | 1.1 {"<"}= 2.1
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "darkgreen" }}
// //                   >
// //                     Low Risk | {"<"}= 1.1
// //                   </Typography>
// //                 </Box>
// //                 <Box
// //                   sx={{
// //                     mt: 1,
// //                     ml: 4,
// //                     background: "lightgrey",
// //                     display: "flex",
// //                     flexDirection: "row",
// //                   }}
// //                 >
// //                   <Typography variant="body2" sx={{ textAlign: "left" }}>
// //                     <i>
// //                       <span style={{ color: "maroon" }}>
// //                         <b>*Note :-&nbsp;</b>
// //                       </span>{" "}
// //                       For legal customers, the system includes a profile with
// //                       the shareholder who has the highest level of risk.
// //                       <span style={{ color: "maroon" }}>
// //                         <b>**</b>
// //                       </span>
// //                       If De-Risked, Then De-Risk Parameter will be Club Risk
// //                       Rating, Else If one or more individual risk scores is
// //                       4(Override) and Not Mitigated, the Club Risk Rating will
// //                       be overridden to High Risk, Else Club Risk Rating is
// //                       Calculated Based on Risk Score as per the set score range.
// //                     </i>
// //                   </Typography>
// //                   <Box sx={{ color: "darkblue", width: "300%", mt: 2 }}>
// //                     <Typography
// //                       variant="body2"
// //                       sx={{ mt: 1, textAlign: "right" }}
// //                     >
// //                       CUSTOMER PROFILE RISK: <b>{info[0].risk}</b>
// //                     </Typography>
// //                     <Typography
// //                       variant="body2"
// //                       sx={{ mt: 1, textAlign: "right" }}
// //                     >
// //                       CLUB RISK RATING: <b>MEDIUM RISK</b>
// //                     </Typography>
// //                   </Box>
// //                 </Box>
// //               </Box>
// //             </Box>
// //             <Divider>&nbsp; ***** END OF CUSTOMER ***** &nbsp;</Divider>
// //           </Box>
// //           <div className="footer">
// //             <div className="footer-text">
// //               <span className="pageNumber"></span>
// //               <span className="totalPages"></span>
// //             </div>
// //           </div>
// //         </Container>
// //       </DialogContent>
// //       <DialogActions>
// //         <Button onClick={onClose} color="secondary" variant="contained">
// //           Cancel
// //         </Button>
// //       </DialogActions>
// //     </Dialog>
// //   );
// // };

// // export default Crp;

// // import React, { useEffect, useState } from "react";
// // import {
// //   Dialog,
// //   DialogTitle,
// //   DialogContent,
// //   DialogActions,
// //   Button,
// //   Container,
// //   Box,
// //   Typography,
// //   Divider,
// //   Paper,
// //   Table,
// //   TableCell,
// //   TableContainer,
// //   TableBody,
// //   TableRow,
// //   TableHead,
// //   tableCellClasses,
// // } from "@mui/material";
// // import { useGetCountriesQuery } from "state/api";

// // const Crp = ({ open, onClose, data }) => {
// //   const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
// //   const userId = storedValue?._id;

// //   const {
// //     data: countryData,
// //     isLoading: isLoadingCountries,
// //     error,
// //   } = useGetCountriesQuery(userId);
// //   const [riskData, setRiskData] = useState([]);
// //   const [averageScore, setAverageScore] = useState(0);

// //   useEffect(() => {
// //     if (error) {
// //       console.error("Error fetching data:", error);
// //     }

// //     if (!isLoadingCountries && countryData) {
// //       console.log("Fetched country data:", countryData);
// //       console.log(data);

// //       const residentialStatusRiskScore = calculateResidentialStatusRiskScore(
// //         data?.shareHolders
// //       );

// //       const riskScores = [
// //         {
// //           riskNo: 1,
// //           rbaType: "Residential status of majority of Shareholders/UBOs",
// //           rbaField: "resident",
// //           riskScore: getRiskScore(
// //             countryData.nationalScores,
// //             "status",
// //             residentialStatusRiskScore.status
// //           ),
// //         },
// //         {
// //           riskNo: 2,
// //           rbaType: "Source of Funds",
// //           rbaField: "Investments",
// //           riskScore: getRiskScore(
// //             data?.sourceOfFundsScores,
// //             "source",
// //             "INVESTMENTS"
// //           ),
// //         },
// //         {
// //           riskNo: 3,
// //           rbaType: "License Type",
// //           rbaField: "Industrial license",
// //           riskScore: getRiskScore(
// //             data?.licTypeScores,
// //             "document",
// //             "Industrial License"
// //           ),
// //         },
// //         {
// //           riskNo: 7,
// //           rbaType: "PEP Status",
// //           rbaField: "PEP",
// //           riskScore: 3, // Static value as per provided data
// //         },
// //         {
// //           riskNo: 8,
// //           rbaType:
// //             "TFS sanction lists (Company, Org, Partners, Shareholders, Senior Managers)",
// //           rbaField: "No",
// //           riskScore: 2, // Static value as per provided data
// //         },
// //         {
// //           riskNo: 9,
// //           rbaType:
// //             "Jurisdiction of incorporation/operations (High Risk Country or Countries with increased monitoring)",
// //           rbaField: "No",
// //           riskScore: getRiskScore(data?.jurisdiction, "Name", "OUTSIDE UAE "),
// //         },
// //         {
// //           riskNo: 10,
// //           rbaType:
// //             "Shareholders/UBOs/Senior managers are nationals of High risk countries with increased monitoring)",
// //           rbaField: "No",
// //           riskScore: 2, // Static value as per provided data
// //         },
// //       ];

// //       console.log("Calculated risk scores:", riskScores);

// //       const totalScore = riskScores.reduce(
// //         (acc, item) => acc + item.riskScore,
// //         0
// //       );
// //       const avgScore = totalScore / riskScores.length;

// //       setRiskData(riskScores);
// //       setAverageScore(avgScore);
// //     }
// //   }, [countryData, isLoadingCountries, error, data]);

// //   const getRiskScore = (array, key, value) => {
// //     if (!Array.isArray(array)) {
// //       console.error(`Expected array but got ${typeof array}`);
// //       return 0;
// //     }
// //     const foundItem = array.find(
// //       (item) => item[key]?.toLowerCase() === value?.toLowerCase()
// //     );
// //     console.log(`Matching item for ${value}:`, foundItem);
// //     return foundItem ? foundItem.riskScore : 0;
// //   };

// //   const calculateResidentialStatusRiskScore = (shareHolders) => {
// //     if (!Array.isArray(shareHolders)) {
// //       return { status: "Unknown", riskScore: 0 };
// //     }

// //     const significantShareholders = shareHolders.filter(
// //       (sh) => parseFloat(sh.percentShares) >= 25
// //     );

// //     console.log("Significant Shareholders:", significantShareholders);

// //     if (significantShareholders.length === 0) {
// //       return { status: "Unknown", riskScore: 0 };
// //     }

// //     const statusCount = significantShareholders.reduce((acc, sh) => {
// //       const status = sh.status?.toLowerCase();
// //       console.log("Shareholder status:", status);
// //       if (!status) {
// //         return acc;
// //       }
// //       if (!acc[status]) {
// //         acc[status] = 0;
// //       }
// //       acc[status]++;
// //       return acc;
// //     }, {});

// //     console.log("Status Count:", statusCount);

// //     if (Object.keys(statusCount).length === 0) {
// //       return { status: "Unknown", riskScore: 0 };
// //     }

// //     const majorityStatus = Object.keys(statusCount).reduce(
// //       (a, b) => (statusCount[a] > statusCount[b] ? a : b),
// //       Object.keys(statusCount)[0] // Add initial value to avoid empty array reduce error
// //     );

// //     console.log("Majority Status:", majorityStatus);
// //     return { status: majorityStatus, riskScore: 0 }; // Risk score will be calculated separately
// //   };

// //   if (isLoadingCountries) {
// //     return <div>Loading...</div>;
// //   }

// //   if (!data) {
// //     return <div>No data available</div>;
// //   }

// //   const createData = (field, risk) => {
// //     return { field, risk };
// //   };

// //   const rows = {
// //     customer: {
// //       name: data.fullLegalName || "N/A",
// //       legal_Name_Sanction: createData(data.fullLegalName || "N/A", 1),
// //       country_Of_Incorporation: createData(
// //         data.TradeLicenseInfo?.countryOfIncorporation || "N/A",
// //         2
// //       ),
// //       country_Of_Residence: createData(
// //         data.contactInfo?.contactCountry || "N/A",
// //         2
// //       ),
// //       business_Address: createData(data.businessAddress || "N/A", 2),
// //       business_Activity: createData(
// //         data.TradeLicenseInfo?.businessActivity || "N/A",
// //         3
// //       ),
// //       source_Of_Funds: createData(data.sourceOfFunds || "N/A", 2),
// //       licensing_Authority: createData(
// //         data.TradeLicenseInfo?.licensingAuthority || "N/A",
// //         1
// //       ),
// //       customer_Type: createData(data.riskProfile || "N/A", 1),
// //       trade_License_No: createData(
// //         data.TradeLicenseInfo?.tradeLicenseNo || "N/A",
// //         2
// //       ),
// //       jurisdiction: createData(data.jurisdiction || "N/A", 1),
// //     },
// //     shareholder:
// //       data.shareHolders?.map((shareholder) => ({
// //         name: shareholder.fullLegalName || "N/A",
// //         dual_Nationality: createData(shareholder.dualNationality || "N/A", 1),
// //         nationality: createData(
// //           shareholder.contactInfo?.contactCountry || "N/A",
// //           2
// //         ),
// //         document_Type: createData(shareholder.idDoc?.type || "N/A", 1),
// //         source_Of_Funds: createData(shareholder.uboReason || "N/A", 2),
// //         occupation: createData(shareholder.occupation || "N/A", 3),
// //         country_Of_Residence: createData(
// //           shareholder.idDoc?.issueCountry || "N/A",
// //           2
// //         ),
// //         full_Name_Sanction: createData(shareholder.fullLegalName || "N/A", 1),
// //       })) || [],
// //   };

// //   const user = {
// //     customer: {
// //       uid: data._id || "N/A",
// //       name: data.fullLegalName || "N/A",
// //       dateOfIncorporation: data.TradeLicenseInfo?.incorporationDate || "N/A",
// //       countryOfIncorporation:
// //         data.TradeLicenseInfo?.countryOfIncorporation || "N/A",
// //     },
// //   };

// //   const info = [
// //     {
// //       rbaType: "CUSTOMER RESIDUAL RISK",
// //       rbaField: "1.706",
// //       percentage: "100%",
// //       risk: "1.706",
// //     },
// //   ];

// //   return (
// //     <Dialog
// //       open={open}
// //       onClose={onClose}
// //       fullWidth
// //       maxWidth="md"
// //       scroll="paper"
// //     >
// //       <DialogTitle>
// //         <Typography
// //           variant="h4"
// //           paddingBottom="12px"
// //           color="#ffc76b"
// //           fontWeight="bold"
// //         >
// //           Customer Risk Profile (CRP)
// //         </Typography>
// //       </DialogTitle>
// //       <DialogContent>
// //         <Container sx={{ my: 0, p: 0, width: "100%" }}>
// //           <Box>
// //             <Typography variant="h6" align="left">
// //               <b>ARA JEWELLERY LLC</b>
// //             </Typography>
// //             <Typography variant="body2" align="left">
// //               Office # 402, Dubai Diamond Plaza, Gold Souk Deira
// //             </Typography>
// //             <Typography variant="body2" align="left">
// //               +971 4 3305259
// //             </Typography>
// //             <Typography variant="body2" align="left">
// //               arajewellltd@gmail.com
// //             </Typography>
// //             <Divider sx={{ my: 1, border: "1px solid" }} />
// //             <Paper elevation={10} sx={{ mb: 1, width: "100%", pr: 8 }}>
// //               <Typography variant="body2">
// //                 <b>
// //                   <i>
// //                     CUSTOMER DETAILS ={">"} UID : {user.customer.uid} | NAME :{" "}
// //                     {user.customer.name} | DATE OF INCORPORATION :{" "}
// //                     {user.customer.dateOfIncorporation} | COUNTRY OF
// //                     INCORPORATION : {user.customer.countryOfIncorporation}
// //                   </i>
// //                 </b>
// //               </Typography>
// //             </Paper>
// //             <Box sx={{ ml: 1 }}>
// //               <Paper
// //                 elevation={10}
// //                 sx={{
// //                   width: "90%",
// //                   pr: 8,
// //                   color: "darkblue",
// //                   background: "lightgrey",
// //                 }}
// //               >
// //                 <Typography variant="body2">
// //                   <i>CUSTOMER RESIDUAL RISK</i>
// //                 </Typography>
// //               </Paper>
// //               <Box sx={{ px: 2, py: 0.5, ml: 1 }}>
// //                 <Paper
// //                   elevation={10}
// //                   sx={{
// //                     mb: 1,
// //                     width: "80%",
// //                     pr: 8,
// //                     color: "darkblue",
// //                     background: "lightgrey",
// //                   }}
// //                 >
// //                   <Typography variant="body2">
// //                     <i>
// //                       CUSTOMER --{">"} {data.fullLegalName} - (CUSTOMER)
// //                     </i>
// //                   </Typography>
// //                 </Paper>
// //                 <TableContainer>
// //                   <Table
// //                     size="small"
// //                     sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
// //                   >
// //                     <TableHead>
// //                       <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                         <TableCell>RBA TYPE</TableCell>
// //                         <TableCell>RBA FIELD</TableCell>
// //                         <TableCell align="right">RISK</TableCell>
// //                       </TableRow>
// //                     </TableHead>
// //                     <TableBody>
// //                       {Object.entries(rows.customer).map(
// //                         ([key, value], index) =>
// //                           key !== "name" && (
// //                             <TableRow key={index}>
// //                               <TableCell>
// //                                 {key.replace(/_/g, " ").toUpperCase()}
// //                               </TableCell>
// //                               <TableCell>{value.field}</TableCell>
// //                               <TableCell align="right">{value.risk}</TableCell>
// //                             </TableRow>
// //                           )
// //                       )}
// //                     </TableBody>
// //                   </Table>
// //                 </TableContainer>
// //                 {rows.shareholder.map((shareholder, idx) => (
// //                   <div key={idx}>
// //                     <Paper
// //                       elevation={10}
// //                       sx={{
// //                         my: 1,
// //                         width: "80%",
// //                         pr: 8,
// //                         color: "darkblue",
// //                         background: "lightgrey",
// //                       }}
// //                     >
// //                       <Typography variant="body2">
// //                         <i>
// //                           SHAREHOLDER [NATURAL PERSONS] --{">"}{" "}
// //                           {shareholder.name} - (SHAREHOLDER [NATURAL PERSONS])
// //                         </i>
// //                       </Typography>
// //                     </Paper>
// //                     <TableContainer>
// //                       <Table
// //                         size="small"
// //                         sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
// //                       >
// //                         <TableHead>
// //                           <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                             <TableCell>RBA TYPE</TableCell>
// //                             <TableCell>RBA FIELD</TableCell>
// //                             <TableCell align="right">RISK</TableCell>
// //                           </TableRow>
// //                         </TableHead>
// //                         <TableBody>
// //                           {Object.entries(shareholder).map(
// //                             ([key, value], index) =>
// //                               key !== "name" && (
// //                                 <TableRow key={index}>
// //                                   <TableCell>
// //                                     {key.replace(/_/g, " ").toUpperCase()}
// //                                   </TableCell>
// //                                   <TableCell>{value.field}</TableCell>
// //                                   <TableCell align="right">
// //                                     {value.risk}
// //                                   </TableCell>
// //                                 </TableRow>
// //                               )
// //                           )}
// //                         </TableBody>
// //                       </Table>
// //                     </TableContainer>
// //                   </div>
// //                 ))}
// //                 <Box
// //                   display="flex"
// //                   flexDirection="row"
// //                   justifyContent="flex-end"
// //                   sx={{ width: "100%" }}
// //                 >
// //                   <Table
// //                     size="small"
// //                     sx={{
// //                       [`& .${tableCellClasses.root}`]: {
// //                         borderBottom: "none",
// //                         p: 0,
// //                       },
// //                     }}
// //                   >
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           (Total Sum of Risk (Customer + Relation) / Total No.
// //                           of Risk) <b>ONBOARDING RISK :&nbsp;</b>
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Divider
// //                           sx={{
// //                             mt: 1,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Typography textAlign="right">
// //                           <b>1.706</b>
// //                         </Typography>
// //                       </TableCell>
// //                     </TableRow>
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           Mitigation Score :&nbsp;
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           <b>0</b>
// //                         </Typography>
// //                         <Divider
// //                           sx={{
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                       </TableCell>
// //                     </TableRow>
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           (Onboarding Risk * Mitigation Score){" "}
// //                           <b>RESIDUAL RISK :&nbsp;</b>
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           <b>1.706</b>
// //                         </Typography>
// //                         <Divider
// //                           sx={{
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Divider
// //                           sx={{
// //                             mt: 0.5,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                       </TableCell>
// //                     </TableRow>
// //                   </Table>
// //                 </Box>
// //               </Box>
// //             </Box>
// //             <Box sx={{ mt: 1, ml: 1 }}>
// //               <Paper
// //                 elevation={10}
// //                 sx={{
// //                   mb: 1,
// //                   width: "60%",
// //                   pr: 8,
// //                   color: "darkblue",
// //                   background: "lightgrey",
// //                 }}
// //               >
// //                 <Typography variant="body2">
// //                   <b>
// //                     <i>RISK DISTRIBUTION</i>
// //                   </b>
// //                 </Typography>
// //               </Paper>
// //               <Box sx={{ px: 2, ml: 1 }}>
// //                 <TableContainer>
// //                   <Table
// //                     size="small"
// //                     sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
// //                   >
// //                     <TableHead>
// //                       <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
// //                         <TableCell>RBA TYPE</TableCell>
// //                         <TableCell>RBA FIELD</TableCell>
// //                         <TableCell>(%)</TableCell>
// //                         <TableCell align="right">RISK</TableCell>
// //                       </TableRow>
// //                     </TableHead>
// //                     <TableBody>
// //                       {info.map((row, index) => (
// //                         <TableRow key={index}>
// //                           <TableCell>{row.rbaType}</TableCell>
// //                           <TableCell>{row.rbaField}</TableCell>
// //                           <TableCell>{row.percentage}</TableCell>
// //                           <TableCell align="right">{row.risk}</TableCell>
// //                         </TableRow>
// //                       ))}
// //                     </TableBody>
// //                   </Table>
// //                 </TableContainer>
// //                 <Box
// //                   display="flex"
// //                   flexDirection="row"
// //                   justifyContent="flex-end"
// //                   sx={{ width: "100%" }}
// //                 >
// //                   <Table
// //                     size="small"
// //                     sx={{
// //                       [`& .${tableCellClasses.root}`]: {
// //                         borderBottom: "none",
// //                         p: 0,
// //                       },
// //                     }}
// //                   >
// //                     <TableRow sx={{ border: "none" }}>
// //                       <TableCell>
// //                         <Typography textAlign="right">
// //                           (Sum of Distributed Risk) <b>OVERALL RISK :&nbsp;</b>
// //                         </Typography>
// //                       </TableCell>
// //                       <TableCell>
// //                         <Divider
// //                           sx={{
// //                             mt: 1,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Typography textAlign="right">
// //                           <b>{info[0].risk}</b>
// //                         </Typography>
// //                         <Divider
// //                           sx={{
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                         <Divider
// //                           sx={{
// //                             mt: 0.5,
// //                             border: "1px solid",
// //                             borderColor: "blue",
// //                             width: "100%",
// //                           }}
// //                         />
// //                       </TableCell>
// //                     </TableRow>
// //                   </Table>
// //                 </Box>
// //               </Box>
// //               <Box
// //                 sx={{
// //                   display: "flex",
// //                   flexDirection: "row",
// //                   justifyContent: "space-between",
// //                 }}
// //               >
// //                 <Box
// //                   sx={{
// //                     mt: 1,
// //                     background: "lightgrey",
// //                     width: "100%",
// //                     px: 0.5,
// //                   }}
// //                 >
// //                   <Typography variant="body2">
// //                     <b>RISK METHODOLOGY</b>
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "red" }}
// //                   >
// //                     High Risk | MORE THAN 3.1
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "darkviolet" }}
// //                   >
// //                     Medium High Risk | 2.1 {"<"}= 3.1
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "olive" }}
// //                   >
// //                     Medium Risk | 1.1 {"<"}= 2.1
// //                   </Typography>
// //                   <Typography
// //                     variant="body2"
// //                     sx={{ color: "white", background: "darkgreen" }}
// //                   >
// //                     Low Risk | {"<"}= 1.1
// //                   </Typography>
// //                 </Box>
// //                 <Box
// //                   sx={{
// //                     mt: 1,
// //                     ml: 4,
// //                     background: "lightgrey",
// //                     display: "flex",
// //                     flexDirection: "row",
// //                   }}
// //                 >
// //                   <Typography variant="body2" sx={{ textAlign: "left" }}>
// //                     <i>
// //                       <span style={{ color: "maroon" }}>
// //                         <b>*Note :-&nbsp;</b>
// //                       </span>{" "}
// //                       For legal customers, the system includes a profile with
// //                       the shareholder who has the highest level of risk.
// //                       <span style={{ color: "maroon" }}>
// //                         <b>**</b>
// //                       </span>
// //                       If De-Risked, Then De-Risk Parameter will be Club Risk
// //                       Rating, Else If one or more individual risk scores is
// //                       4(Override) and Not Mitigated, the Club Risk Rating will
// //                       be overridden to High Risk, Else Club Risk Rating is
// //                       Calculated Based on Risk Score as per the set score range.
// //                     </i>
// //                   </Typography>
// //                   <Box sx={{ color: "darkblue", width: "300%", mt: 2 }}>
// //                     <Typography
// //                       variant="body2"
// //                       sx={{ mt: 1, textAlign: "right" }}
// //                     >
// //                       CUSTOMER PROFILE RISK: <b>{info[0].risk}</b>
// //                     </Typography>
// //                     <Typography
// //                       variant="body2"
// //                       sx={{ mt: 1, textAlign: "right" }}
// //                     >
// //                       CLUB RISK RATING: <b>MEDIUM RISK</b>
// //                     </Typography>
// //                   </Box>
// //                 </Box>
// //               </Box>
// //             </Box>
// //             <Divider>&nbsp; ***** END OF CUSTOMER ***** &nbsp;</Divider>
// //           </Box>
// //           <div className="footer">
// //             <div className="footer-text">
// //               <span className="pageNumber"></span>
// //               <span className="totalPages"></span>
// //             </div>
// //           </div>
// //         </Container>
// //       </DialogContent>
// //       <DialogActions>
// //         <Button onClick={onClose} color="secondary" variant="contained">
// //           Cancel
// //         </Button>
// //       </DialogActions>
// //     </Dialog>
// //   );
// // };

// // export default Crp;

// import React, { useEffect, useState } from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Container,
//   Box,
//   Typography,
//   Divider,
//   Paper,
//   Table,
//   TableCell,
//   TableContainer,
//   TableBody,
//   TableRow,
//   TableHead,
//   tableCellClasses,
// } from "@mui/material";
// import { useGetCountriesQuery, useGetCrpQuery } from "state/api";
// import moment from "moment";

// const Crp = ({ open, onClose, data, id }) => {
//   console.log("received in CRP: ", id, data);
//   const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
//   const userId = storedValue?._id;

//   function sumArray(arr) {
//     if (Array.isArray(arr)) {
//       return arr.reduce((sum, value) => sum + value, 0);
//     } else {
//       console.error("Input is not an array");
//       return 0;
//     }
//   }

//   function calcRisk(arr1, arr2) {
//     return sumArray(arr1) + sumArray(arr2);
//   }

//   const totalSum = calcRisk(data?.riskScores, data?.shareHolderRiskScores) / 10;

//   function getRiskLevel(score) {
//     if (score > 3.1) {
//       return { level: "High Risk", color: "red" };
//     } else if (score > 2.1) {
//       return { level: "Medium High Risk", color: "darkviolet" };
//     } else if (score > 1.1) {
//       return { level: "Medium Risk", color: "olive" };
//     } else {
//       return { level: "Low Risk", color: "darkgreen" };
//     }
//   }

//   if (data != null) {
//     return (
//       <Dialog
//         open={open}
//         onClose={onClose}
//         fullWidth
//         maxWidth="md"
//         scroll="paper"
//       >
//         <DialogTitle>
//           <Typography
//             variant="h4"
//             paddingBottom="12px"
//             color="#ffc76b"
//             fontWeight="bold"
//           >
//             Customer Risk Profile (CRP)
//           </Typography>
//         </DialogTitle>
//         {data.riskScores[4] != 20 ? (
//           <DialogContent>
//             <Container sx={{ my: 0, p: 0, width: "100%" }}>
//               <Box>
//                 <Typography variant="h6" align="left">
//                   <b>{storedValue.name}</b>
//                 </Typography>
//                 <Typography variant="body2" align="left">
//                   {storedValue.address}
//                 </Typography>

//                 <Typography variant="body2" align="left">
//                   {storedValue.email}
//                 </Typography>

//                 <Paper
//                   elevation={0}
//                   sx={{ mb: 1, width: "100%", pr: 8, border: "1px solid grey" }}
//                 >
//                   <Typography variant="body2">
//                     <table>
//                       <tbody>
//                         <tr>
//                           <td style={{ paddingRight: "10px" }}>
//                             <b>Record Unique ID:</b>
//                           </td>
//                           <td style={{ paddingRight: "10px" }}>{data?._id}</td>
//                           <td style={{ paddingRight: "10px" }}>
//                             <b>Full Legal Name:</b>
//                           </td>
//                           <td>{data?.fullLegalName}</td>
//                         </tr>
//                         <tr>
//                           <td style={{ paddingRight: "10px" }}>
//                             <b>Date of Incorporation:</b>
//                           </td>
//                           <td style={{ paddingRight: "10px" }}>
//                             {moment(
//                               data?.TradeLicenseInfo.incoroprationDate
//                             ).format("DD - MM - yyyy")}
//                           </td>
//                           <td style={{ paddingRight: "10px" }}>
//                             <b>Country of Incorporation:</b>
//                           </td>
//                           <td>
//                             {data?.TradeLicenseInfo.countryofIncorporation}
//                           </td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </Typography>
//                 </Paper>

//                 <Box sx={{ ml: 1 }}>
//                   <Paper
//                     elevation={0}
//                     sx={{
//                       width: "100%",
//                       pr: 8,
//                       color: "#ffc86b",
//                       // background: "lightgrey",
//                     }}
//                   >
//                     <Typography variant="body">
//                       <b>CUSTOMER RISK PROFILE</b>
//                     </Typography>
//                   </Paper>
//                   <Box sx={{ px: 2, py: 0.5, ml: 1 }}>
//                     <Paper
//                       elevation={0}
//                       sx={{
//                         mb: 1,
//                         width: "100%",
//                         pr: 8,
//                         color: "#ffc86b",
//                         // background: "#ffc86b",
//                       }}
//                     >
//                       <Typography>
//                         {/* Legal Person {data.fullLegalName} */}
//                         <b>1. Legal Person</b>
//                       </Typography>
//                     </Paper>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
//                       >
//                         <TableHead>
//                           <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
//                             <TableCell>RBA TYPE</TableCell>
//                             <TableCell>RBA FIELD</TableCell>
//                             <TableCell align="right">RISK</TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           <TableRow key={0}>
//                             <TableCell>{"Full Legal Name"}</TableCell>
//                             <TableCell>{data.fullLegalName}</TableCell>
//                             <TableCell align="right">
//                               {data.riskScores[4]}
//                             </TableCell>
//                           </TableRow>
//                           <TableRow key={1}>
//                             <TableCell>{"Country of Incorporation"}</TableCell>
//                             <TableCell>
//                               {data.TradeLicenseInfo.countryofIncorporation}
//                             </TableCell>
//                             <TableCell align="right">
//                               {data.riskScores[0]}
//                             </TableCell>
//                           </TableRow>
//                           <TableRow key={2}>
//                             <TableCell>{"Jurisdiction"}</TableCell>
//                             <TableCell>{data.jurisdiction}</TableCell>
//                             <TableCell align="right">
//                               {data.riskScores[1]}
//                             </TableCell>
//                           </TableRow>
//                           <TableRow key={3}>
//                             <TableCell>{"Source of Funds"}</TableCell>
//                             <TableCell>{data.sourceOfFunds}</TableCell>
//                             <TableCell align="right">
//                               {data.riskScores[4]}
//                             </TableCell>
//                           </TableRow>
//                           <TableRow key={4}>
//                             <TableCell>{"Business Activity"}</TableCell>
//                             <TableCell>
//                               {data.TradeLicenseInfo.businessActivity}
//                             </TableCell>
//                             <TableCell align="right">
//                               {data.riskScores[3]}
//                             </TableCell>
//                           </TableRow>
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                     <div>
//                       <Paper
//                         elevation={10}
//                         sx={{
//                           my: 1,
//                           width: "80%",
//                           pr: 8,
//                           color: "darkblue",
//                           background: "lightgrey",
//                         }}
//                       >
//                         <Typography variant="body2">
//                           <i>
//                             SHAREHOLDER [NATURAL PERSONS] --{">"}
//                             {data.highestShareHolderRisk.fullLegalName} -
//                             (SHAREHOLDER [NATURAL PERSONS])
//                           </i>
//                         </Typography>
//                       </Paper>
//                       <TableContainer>
//                         <Table
//                           size="small"
//                           sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
//                         >
//                           <TableHead>
//                             <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
//                               <TableCell>RBA TYPE</TableCell>
//                               <TableCell>RBA FIELD</TableCell>
//                               <TableCell align="right">RISK</TableCell>
//                             </TableRow>
//                             <TableRow key={0}>
//                               <TableCell>{"Full Legal Name"}</TableCell>
//                               <TableCell>
//                                 {data.highestShareHolderRisk.fullLegalName}
//                               </TableCell>
//                               <TableCell align="right">
//                                 {data.shareHolderRiskScores[0]}
//                               </TableCell>
//                             </TableRow>
//                             <TableRow key={1}>
//                               <TableCell>{"ID Document"}</TableCell>
//                               <TableCell>
//                                 {data.highestShareHolderRisk.idDoc.type}
//                               </TableCell>
//                               <TableCell align="right">
//                                 {data.shareHolderRiskScores[1]}
//                               </TableCell>
//                             </TableRow>
//                             <TableRow key={2}>
//                               <TableCell>{"Nationality"}</TableCell>
//                               <TableCell>
//                                 {data.highestShareHolderRisk.nationality}
//                               </TableCell>
//                               <TableCell align="right">
//                                 {data.shareHolderRiskScores[2]}
//                               </TableCell>
//                             </TableRow>
//                             <TableRow key={4}>
//                               <TableCell>{"Dual Nationality"}</TableCell>
//                               <TableCell>
//                                 {data.highestShareHolderRisk.dualNationality}
//                               </TableCell>
//                               <TableCell align="right">
//                                 {data.shareHolderRiskScores[3]}
//                               </TableCell>
//                             </TableRow>
//                             <TableRow key={3}>
//                               <TableCell>{"Occupation"}</TableCell>
//                               <TableCell>
//                                 {data.highestShareHolderRisk.occupation}
//                               </TableCell>
//                               <TableCell align="right">
//                                 {data.shareHolderRiskScores[4]}
//                               </TableCell>
//                             </TableRow>
//                           </TableHead>
//                           <TableBody></TableBody>
//                         </Table>
//                       </TableContainer>
//                     </div>

//                     <Box
//                       display="flex"
//                       flexDirection="row"
//                       justifyContent="flex-end"
//                       sx={{ width: "100%" }}
//                     >
//                       <Table
//                         size="small"
//                         sx={{
//                           [`& .${tableCellClasses.root}`]: {
//                             borderBottom: "none",
//                             p: 0,
//                           },
//                         }}
//                       >
//                         <TableRow sx={{ border: "none" }}>
//                           <TableCell>
//                             <Typography textAlign="right">
//                               (Total Sum of Risk (Customer + Relation) / Total
//                               No. of Risk) <b>ONBOARDING RISK :&nbsp;</b>
//                             </Typography>
//                           </TableCell>
//                           <TableCell>
//                             <Divider
//                               sx={{
//                                 mt: 1,
//                                 border: "1px solid",
//                                 borderColor: "blue",
//                                 width: "100%",
//                               }}
//                             />
//                             <Typography textAlign="right">
//                               <b>{totalSum}</b>
//                             </Typography>
//                             <Divider
//                               sx={{
//                                 mt: 0.5,
//                                 border: "1px solid",
//                                 borderColor: "blue",
//                                 width: "100%",
//                               }}
//                             />
//                             <Divider
//                               sx={{
//                                 mt: 0.5,
//                                 border: "1px solid",
//                                 borderColor: "blue",
//                                 width: "100%",
//                               }}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       </Table>
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box sx={{ mt: 1, ml: 1 }}>
//                   <Paper
//                     elevation={10}
//                     sx={{
//                       mb: 1,
//                       width: "60%",
//                       pr: 8,
//                       color: "darkblue",
//                       background: "lightgrey",
//                     }}
//                   >
//                     <Typography variant="body2">
//                       <b>
//                         <i>RISK DISTRIBUTION</i>
//                       </b>
//                     </Typography>
//                   </Paper>
//                   <Box sx={{ px: 2, ml: 1 }}>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{ [`& .${tableCellClasses.root}`]: { p: 0 } }}
//                       >
//                         <TableHead>
//                           <TableRow sx={{ background: "rgb(240, 240, 240)" }}>
//                             <TableCell>RBA TYPE</TableCell>
//                             <TableCell>RBA SCORE</TableCell>
//                             {/* <TableCell>(%)</TableCell> */}
//                             <TableCell align="right">RISK</TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {/* {info.map((row, index) => ( */}
//                           <TableRow key={"index"}>
//                             <TableCell>{"Customer Risk Profile"}</TableCell>
//                             <TableCell>{totalSum}</TableCell>
//                             {/* <TableCell>{row.percentage}</TableCell> */}
//                             <TableCell align="right">{totalSum}</TableCell>
//                           </TableRow>
//                           {/* ))} */}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                     <Box
//                       display="flex"
//                       flexDirection="row"
//                       justifyContent="flex-end"
//                       sx={{ width: "100%" }}
//                     >
//                       <Table
//                         size="small"
//                         sx={{
//                           [`& .${tableCellClasses.root}`]: {
//                             borderBottom: "none",
//                             p: 0,
//                           },
//                         }}
//                       >
//                         <TableRow sx={{ border: "none" }}>
//                           <TableCell>
//                             <Typography textAlign="right">
//                               (Sum of Distributed Risk){" "}
//                               <b>OVERALL RISK :&nbsp;</b>
//                             </Typography>
//                           </TableCell>
//                           <TableCell>
//                             <Divider
//                               sx={{
//                                 mt: 1,
//                                 border: "1px solid",
//                                 borderColor: "blue",
//                                 width: "100%",
//                               }}
//                             />
//                             <Typography textAlign="right">
//                               <b>{totalSum}</b>
//                             </Typography>
//                             <Divider
//                               sx={{
//                                 border: "1px solid",
//                                 borderColor: "blue",
//                                 width: "100%",
//                               }}
//                             />
//                             <Divider
//                               sx={{
//                                 mt: 0.5,
//                                 border: "1px solid",
//                                 borderColor: "blue",
//                                 width: "100%",
//                               }}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       </Table>
//                     </Box>
//                   </Box>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         mt: 1,
//                         background: "lightgrey",
//                         width: "100%",
//                         px: 0.5,
//                       }}
//                     >
//                       <Typography variant="body2">
//                         <b>RISK RANGE</b>
//                       </Typography>
//                       <Typography
//                         variant="body2"
//                         sx={{ color: "white", background: "red" }}
//                       >
//                         High Risk | MORE THAN 3.1
//                       </Typography>
//                       <Typography
//                         variant="body2"
//                         sx={{ color: "white", background: "darkviolet" }}
//                       >
//                         Medium High Risk | 2.1 {"<"}= 3.1
//                       </Typography>
//                       <Typography
//                         variant="body2"
//                         sx={{ color: "white", background: "olive" }}
//                       >
//                         Medium Risk | 1.1 {"<"}= 2.1
//                       </Typography>
//                       <Typography
//                         variant="body2"
//                         sx={{ color: "white", background: "darkgreen" }}
//                       >
//                         Low Risk | {"<"}= 1.1
//                       </Typography>
//                     </Box>
//                     <Box
//                       sx={{
//                         mt: 1,
//                         ml: 4,
//                         background: "lightgrey",
//                         display: "flex",
//                         flexDirection: "row",
//                       }}
//                     >
//                       <Typography variant="body2" sx={{ textAlign: "left" }}>
//                         <i>
//                           <span style={{ color: "maroon" }}>
//                             <b>*Note :-&nbsp;</b>
//                           </span>{" "}
//                           For legal customers, the system includes a profile
//                           with the shareholder who has the highest level of
//                           risk.
//                           <span style={{ color: "maroon" }}>
//                             <b>**</b>
//                           </span>
//                           If De-Risked, Then De-Risk Parameter will be Club Risk
//                           Rating, Else If one or more individual risk scores is
//                           4(Override) and Not Mitigated, the Club Risk Rating
//                           will be overridden to High Risk, Else Club Risk Rating
//                           is Calculated Based on Risk Score as per the set score
//                           range.
//                         </i>
//                       </Typography>
//                       <Box sx={{ color: "darkblue", width: "300%", mt: 2 }}>
//                         <Typography
//                           variant="body2"
//                           sx={{ mt: 1, textAlign: "right" }}
//                         >
//                           CUSTOMER PROFILE RISK: <b>{totalSum}</b>
//                         </Typography>
//                         <Typography
//                           variant="body2"
//                           sx={{ mt: 1, textAlign: "right" }}
//                           color={getRiskLevel(totalSum).color}
//                         >
//                           CLUB RISK RATING:{" "}
//                           <b>{getRiskLevel(totalSum).level}</b>
//                         </Typography>
//                       </Box>
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Divider>&nbsp; ***** END OF CUSTOMER ***** &nbsp;</Divider>
//               </Box>
//               <div className="footer">
//                 <div className="footer-text">
//                   <span className="pageNumber"></span>
//                   <span className="totalPages"></span>
//                 </div>
//               </div>
//             </Container>
//           </DialogContent>
//         ) : (
//           <DialogContent>
//             {/* <Typography variant="h4" alignItems={"center"}>
//               Name screening is mandatory for CRP.
//             </Typography> */}
//             <Typography>
//               Please screen name: <b>{data.fullLegalName}</b> in Screening and
//               Logs --{">"} Instant Screen{" "}
//             </Typography>
//           </DialogContent>
//         )}
//         <DialogActions>
//           <Button onClick={onClose} color="secondary" variant="contained">
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     );
//   }
// };

// export default Crp;

// const ccc = {
//   TradeLicenseInfo: {
//     businessActivity: "Credit Cards and Related Services",
//     tradeLicenseNo: "654654654",
//     countryofIncorporation: "Afghanistan",
//     licensingAuthority: "ddd",
//     incoroprationDate: "2024-12-31T00:00:00.000Z",
//     licenseExpiry: "2024-12-31T00:00:00.000Z",
//   },
//   contactInfo: {
//     contactType: "Mobile",
//     contactCountry: "Afghanistan",
//     contactNumber: "5646546",
//     emailAddress: "email@gmail.com",
//     POBox: "654",
//     website: "http://www.abc.co",
//   },
//   financialInfo: {
//     bankName: "hjhkh",
//     accountNo: "5546464",
//     accountName: "account Name",
//     iban: "KJKJH",
//     bicSwift: "kjjk",
//     currency: "kjkjhkjh",
//     yearOfRelationship: "kjhkjh",
//     branch: "kjh",
//     anualBusiness: "jkh",
//     totalShareCapital: "kjh",
//     totalShareHolderEquity: "kjh",
//     totalAssets: "jkh",
//     totalAnualSales: "jk",
//   },
//   _id: "669447729b9c399aa7a19163",
//   fullLegalName: "Abbas Muhammad",
//   riskProfile: "CRP Pending",
//   registeredAddress: "wdww",
//   businessAddress: "wewe",
//   taxNo: "ewe",
//   jurisdiction: "OUTSIDE UAE ",
//   sourceOfFunds: "LOANS",
//   addedDate: "1901-01-01T00:00:00.000Z",
//   lastModified: "1901-01-01T00:00:00.000Z",
//   company: "649d3759f26ef2e8cb64e94c",
//   createdAt: "2024-07-14T21:47:30.265Z",
//   updatedAt: "2024-07-14T21:47:30.265Z",
//   __v: 0,
//   entityRiskScore: [4, 3, 1, 1, 1],
//   sh: [1, 2, 4, 2, 2],
//   highestRiskShareholder: {
//     idDoc: {
//       type: "Passport",
//       issueCountry: "Afghanistan",
//       number: "1231313",
//       issueDate: "2024-07-15T00:00:00.000Z",
//       expiryDate: "2024-12-29T00:00:00.000Z",
//     },
//     contactInfo: {
//       contactType: "Mobile",
//       contactCountry: "Afghanistan",
//       contactNumber: "135465",
//     },
//     fullLegalName: "Muhammad Abbas",
//     doB: "2024-12-31T00:00:00.000Z",
//     nationality: "Afghanistan",
//     residentialStatus: "Non Resident",
//     occupation: "BANK",
//     dualNationality: "Yes",
//     percentShares: "25",
//     ubo: false,
//     uboReason: "",
//     uboDate: null,
//     isAuthorizedSignatory: false,
//     _id: "669447729b9c399aa7a19164",
//   },
// };

// {
//   /* <TableRow key={0}>
//                         <TableCell>{"Full Legal Name"}</TableCell>
//                         <TableCell>{crpData.fullLegalName}</TableCell>
//                         <TableCell align="right">
//                           {crpData.entityRiskScore[4]}
//                         </TableCell>
//                       </TableRow>
//                       <TableRow key={1}>
//                         <TableCell>{"Country of Incorporation"}</TableCell>
//                         <TableCell>
//                           {crpData.TradeLicenseInfo.countryofIncorporation}
//                         </TableCell>
//                         <TableCell align="right">
//                           {crpData.entityRiskScore[0]}
//                         </TableCell>
//                       </TableRow>
//                       <TableRow key={2}>
//                         <TableCell>{"Jurisdiction"}</TableCell>
//                         <TableCell>{crpData.jurisdiction}</TableCell>
//                         <TableCell align="right">
//                           {crpData.entityRiskScore[1]}
//                         </TableCell>
//                       </TableRow>
//                       <TableRow key={4}>
//                         <TableCell>{"Business Activity"}</TableCell>
//                         <TableCell>{crpData.fullLegalName}</TableCell>
//                         <TableCell align="right">
//                           {crpData.entityRiskScore[3]}
//                         </TableCell>
//                       </TableRow>
//                       <TableRow key={3}>
//                         <TableCell>{"Source of Funds"}</TableCell>
//                         <TableCell>{crpData.sourceOfFunds}</TableCell>
//                         <TableCell align="right">
//                           {crpData.entityRiskScore[2]}
//                         </TableCell>
//                       </TableRow> */
// }

// import React from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Container,
//   Grid,
//   Box,
//   Typography,
//   Divider,
//   Paper,
//   Table,
//   TableCell,
//   TableContainer,
//   TableBody,
//   TableRow,
//   TableHead,
// } from "@mui/material";
// import moment from "moment";

// const Crp = ({ open, onClose, data }) => {
//   const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));

//   const totalSum =
//     (data?.riskScores.reduce((sum, score) => sum + score, 0) +
//       data?.shareHolderRiskScores.reduce((sum, score) => sum + score, 0)) /
//     10;

//   const getRiskLevel = (score) => {
//     if (score > 3.1) {
//       return { level: "High Risk", color: "red" };
//     } else if (score > 2.1) {
//       return { level: "Medium High Risk", color: "darkviolet" };
//     } else if (score > 1.1) {
//       return { level: "Moderate Risk", color: "olive" };
//     } else {
//       return { level: "Low Risk", color: "darkgreen" };
//     }
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       fullWidth
//       maxWidth="md"
//       scroll="paper"
//     >
//       <DialogTitle>
//         <Typography variant="h5" color="#ffc76b" fontWeight="bold">
//           Customer Risk Profile
//         </Typography>
//       </DialogTitle>
//       {data?.riskScores[4] !== 20 ? (
//         <DialogContent>
//           <Container>
//             <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} md={6}>
//                   <Typography variant="h6" fontWeight="bold">
//                     {storedValue.name}
//                   </Typography>
//                   <Typography variant="body2" color="textSecondary">
//                     {storedValue.address}
//                   </Typography>
//                   <Typography variant="body2" color="textSecondary">
//                     {storedValue.email}
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Typography variant="body2">
//                     <b>Entity ID:</b> {data?._id}
//                   </Typography>
//                   <Typography variant="body2">
//                     <b>Full Legal Name:</b> {data?.fullLegalName}
//                   </Typography>
//                   <Typography variant="body2">
//                     <b>Date of Incorporation:</b>{" "}
//                     {moment(data?.TradeLicenseInfo.incoroprationDate).format(
//                       "DD MMM, YYYY"
//                     )}
//                   </Typography>
//                   <Typography variant="body2">
//                     <b>Country of Incorporation:</b>{" "}
//                     {data?.TradeLicenseInfo.countryofIncorporation}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Paper>

//             <Paper elevation={0} sx={{ p: 0, mb: 3 }}>
//               <Typography
//                 variant="h6"
//                 fontWeight="bold"
//                 color="#ffc76b"
//                 gutterBottom
//               >
//                 Legal Person Risk Assessment
//               </Typography>
//               <TableContainer
//                 component={Paper}
//                 elevation={0}
//                 sx={{ border: "1px solid #ccc" }}
//               >
//                 <Table size="small">
//                   <TableHead>
//                     <TableRow sx={{ background: "#f5f5f5" }}>
//                       <TableCell>
//                         <b>Attribute Name</b>
//                       </TableCell>
//                       <TableCell>
//                         <b>Value</b>
//                       </TableCell>
//                       <TableCell align="right">
//                         <b>Risk Score</b>
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>Full Legal Name</TableCell>
//                       <TableCell>{data.fullLegalName}</TableCell>
//                       <TableCell align="right">{data.riskScores[4]}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Country of Incorporation</TableCell>
//                       <TableCell>
//                         {data.TradeLicenseInfo.countryofIncorporation}
//                       </TableCell>
//                       <TableCell align="right">{data.riskScores[0]}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Jurisdiction</TableCell>
//                       <TableCell>{data.jurisdiction}</TableCell>
//                       <TableCell align="right">{data.riskScores[1]}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Source of Funds</TableCell>
//                       <TableCell>{data.sourceOfFunds}</TableCell>
//                       <TableCell align="right">{data.riskScores[4]}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Business Activity</TableCell>
//                       <TableCell>
//                         {data.TradeLicenseInfo.businessActivity}
//                       </TableCell>
//                       <TableCell align="right">{data.riskScores[3]}</TableCell>
//                     </TableRow>
//                     <TableRow sx={{ background: "#f5f5f5" }}>
//                       <TableCell colSpan={2} align="right">
//                         <b>Average Risk Score</b>
//                       </TableCell>
//                       <TableCell align="right">
//                         {/* <b>{averageRiskScore.toFixed(2)}</b> */}
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Paper>

//             <Paper elevation={0} sx={{ p: 0, mb: 3 }}>
//               <Typography
//                 variant="h6"
//                 fontWeight="bold"
//                 color="#ffc76b"
//                 gutterBottom
//               >
//                 Shareholder Risk Assessment
//               </Typography>
//               <TableContainer
//                 component={Paper}
//                 elevation={0}
//                 sx={{ border: "1px solid #ccc" }}
//               >
//                 <Table size="small">
//                   <TableHead>
//                     <TableRow sx={{ background: "#f5f5f5" }}>
//                       <TableCell>
//                         <b>Shareholder Detail</b>
//                       </TableCell>
//                       <TableCell>
//                         <b>Information</b>
//                       </TableCell>
//                       <TableCell align="right">
//                         <b>Risk Score</b>
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>Full Legal Name</TableCell>
//                       <TableCell>
//                         {data.highestShareHolderRisk.fullLegalName}
//                       </TableCell>
//                       <TableCell align="right">
//                         {data.shareHolderRiskScores[0]}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>ID Document</TableCell>
//                       <TableCell>
//                         {data.highestShareHolderRisk.idDoc.type}
//                       </TableCell>
//                       <TableCell align="right">
//                         {data.shareHolderRiskScores[1]}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Nationality</TableCell>
//                       <TableCell>
//                         {data.highestShareHolderRisk.nationality}
//                       </TableCell>
//                       <TableCell align="right">
//                         {data.shareHolderRiskScores[2]}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Dual Nationality</TableCell>
//                       <TableCell>
//                         {data.highestShareHolderRisk.dualNationality}
//                       </TableCell>
//                       <TableCell align="right">
//                         {data.shareHolderRiskScores[3]}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Occupation</TableCell>
//                       <TableCell>
//                         {data.highestShareHolderRisk.occupation}
//                       </TableCell>
//                       <TableCell align="right">
//                         {data.shareHolderRiskScores[4]}
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Paper>

//             <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
//               <Box display="flex" justifyContent="space-between">
//                 <Box>
//                   <Typography variant="h6" fontWeight="bold" gutterBottom>
//                     Overall Risk Score
//                   </Typography>
//                   <Typography variant="body2">
//                     (Sum of Customer & Shareholder Risk Scores) / Total Number
//                     of Assessments
//                   </Typography>
//                 </Box>
//                 <Box textAlign="right">
//                   <Typography
//                     variant="h6"
//                     fontWeight="bold"
//                     color={getRiskLevel(totalSum).color}
//                   >
//                     {totalSum.toFixed(2)} ({getRiskLevel(totalSum).level})
//                   </Typography>
//                 </Box>
//               </Box>
//             </Paper>

//             <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
//               <Typography variant="h6" fontWeight="bold" gutterBottom>
//                 Risk Level Explanation
//               </Typography>
//               <Box display="flex" justifyContent="space-between">
//                 <Box sx={{ width: "48%" }}>
//                   <Typography
//                     variant="body2"
//                     fontWeight="bold"
//                     color="red"
//                     gutterBottom
//                   >
//                     High Risk (Above 3.1)
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     fontWeight="bold"
//                     color="darkviolet"
//                     gutterBottom
//                   >
//                     Medium High Risk (2.1 to 3.1)
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     fontWeight="bold"
//                     color="olive"
//                     gutterBottom
//                   >
//                     Moderate Risk (1.1 to 2.1)
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     fontWeight="bold"
//                     color="darkgreen"
//                     gutterBottom
//                   >
//                     Low Risk (Below 1.1)
//                   </Typography>
//                 </Box>
//                 <Box sx={{ width: "48%" }}>
//                   <Typography variant="body2">
//                     <i>Note:</i> For legal entities, the system evaluates the
//                     risk based on the shareholder with the highest risk score.
//                     If any individual risk scores are flagged as critical (score
//                     of 4), the overall risk is automatically elevated unless
//                     mitigated.
//                   </Typography>
//                 </Box>
//               </Box>
//             </Paper>

//             <Divider sx={{ my: 3 }} />

//             <Box textAlign="center">
//               <Typography variant="body2" color="textSecondary">
//                 ***** End of Report *****
//               </Typography>
//             </Box>
//           </Container>
//         </DialogContent>
//       ) : (
//         <DialogContent>
//           <Typography variant="h6" align="center">
//             No Risk Profile Available
//           </Typography>
//         </DialogContent>
//       )}
//       <DialogActions>
//         <Button onClick={onClose} color="secondary" variant="contained">
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default Crp;

import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Container,
  Grid,
  Box,
  Typography,
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
} from "@mui/material";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const Crp = ({ open, onClose, data }, ref) => {
  const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));

  // Reference to the report content
  const reportRef = useRef();

  // Print handler
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
  });
  // Calculate the average risk score for the "Legal Person Risk Assessment"
  const legalPersonRiskScores = data?.riskScores;
  const legalPersonAverage =
    legalPersonRiskScores?.reduce((sum, score) => sum + score, 0) /
    legalPersonRiskScores?.length;

  // Calculate the average risk score for the "Shareholder Risk Assessment"
  const shareholderRiskScores = data?.shareHolderRiskScores;
  const shareholderAverage =
    shareholderRiskScores?.reduce((sum, score) => sum + score, 0) /
    shareholderRiskScores?.length;

  // Calculate the overall sum
  const totalSum =
    (legalPersonRiskScores?.reduce((sum, score) => sum + score, 0) +
      shareholderRiskScores?.reduce((sum, score) => sum + score, 0)) /
    10;

  const getRiskLevel = (score) => {
    if (score > 3.1) {
      return { level: "High Risk", color: "red" };
    } else if (score > 2.1) {
      return { level: "Medium High Risk", color: "darkviolet" };
    } else if (score > 1.1) {
      return { level: "Medium Risk", color: "olive" };
    } else {
      return { level: "Low Risk", color: "darkgreen" };
    }
  };

  if (data != null) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h5" color="#ffc76b" fontWeight="bold">
            Customer Risk Profile
          </Typography>
        </DialogTitle>
        {data?.riskScores[4] !== 20 ? (
          <DialogContent>
            <Container>
              <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      {storedValue.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {storedValue.address}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {storedValue.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2">
                      <b>Entity ID:</b> {data?._id}
                    </Typography>
                    <Typography variant="body2">
                      <b>Full Legal Name:</b> {data?.fullLegalName}
                    </Typography>
                    <Typography variant="body2">
                      <b>Date of Incorporation:</b>{" "}
                      {moment(data?.TradeLicenseInfo.incoroprationDate).format(
                        "DD MMM, YYYY"
                      )}
                    </Typography>
                    <Typography variant="body2">
                      <b>Country of Incorporation:</b>{" "}
                      {data?.TradeLicenseInfo.countryofIncorporation}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              <Paper elevation={0} sx={{ p: 0, mb: 3 }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="#ffc76b"
                  gutterBottom
                >
                  Legal Person
                </Typography>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ border: "1px solid #ccc" }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{ background: "#f5f5f5" }}>
                        <TableCell>
                          <b>Attribute Name</b>
                        </TableCell>
                        <TableCell>
                          <b>Value</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Risk Score</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Full Legal Name</TableCell>
                        <TableCell>{data.fullLegalName}</TableCell>
                        <TableCell align="right">
                          {data.riskScores[4]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Country of Incorporation</TableCell>
                        <TableCell>
                          {data.TradeLicenseInfo.countryofIncorporation}
                        </TableCell>
                        <TableCell align="right">
                          {data.riskScores[0]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Jurisdiction</TableCell>
                        <TableCell>{data.jurisdiction}</TableCell>
                        <TableCell align="right">
                          {data.riskScores[1]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Source of Funds</TableCell>
                        <TableCell>{data.sourceOfFunds}</TableCell>
                        <TableCell align="right">
                          {data.riskScores[4]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Business Activity</TableCell>
                        <TableCell>
                          {data.TradeLicenseInfo.businessActivity}
                        </TableCell>
                        <TableCell align="right">
                          {data.riskScores[3]}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ background: "#f5f5f5" }}>
                        <TableCell colSpan={2} align="right">
                          <b>Legal Person Average</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{legalPersonAverage.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              <Paper elevation={0} sx={{ p: 0, mb: 3 }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="#ffc76b"
                  gutterBottom
                >
                  Shareholder
                </Typography>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ border: "1px solid #ccc" }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{ background: "#f5f5f5" }}>
                        <TableCell>
                          <b>Attribute</b>
                        </TableCell>
                        <TableCell>
                          <b>Value</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Risk Score</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Full Legal Name</TableCell>
                        <TableCell>
                          {data.highestShareHolderRisk.fullLegalName}
                        </TableCell>
                        <TableCell align="right">
                          {data.shareHolderRiskScores[0]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ID Document</TableCell>
                        <TableCell>
                          {data.highestShareHolderRisk.idDoc.type}
                        </TableCell>
                        <TableCell align="right">
                          {data.shareHolderRiskScores[1]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Nationality</TableCell>
                        <TableCell>
                          {data.highestShareHolderRisk.nationality}
                        </TableCell>
                        <TableCell align="right">
                          {data.shareHolderRiskScores[2]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Dual Nationality</TableCell>
                        <TableCell>
                          {data.highestShareHolderRisk.dualNationality}
                        </TableCell>
                        <TableCell align="right">
                          {data.shareHolderRiskScores[3]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Occupation</TableCell>
                        <TableCell>
                          {data.highestShareHolderRisk.occupation}
                        </TableCell>
                        <TableCell align="right">
                          {data.shareHolderRiskScores[4]}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ background: "#f5f5f5" }}>
                        <TableCell colSpan={2} align="right">
                          <b> Shareholder Average</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{shareholderAverage.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Overall Risk Score
                    </Typography>
                    <Typography variant="body2">
                      (Sum of Customer & Shareholder Risk Scores) / Total Number
                      of Assessments
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={getRiskLevel(totalSum).color}
                    >
                      {totalSum.toFixed(2)} ({getRiskLevel(totalSum).level})
                    </Typography>
                  </Box>
                </Box>
              </Paper>

              <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Risk Level Explanation
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Box sx={{ width: "48%" }}>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="red"
                      gutterBottom
                    >
                      High Risk (Above 3.1)
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="darkviolet"
                      gutterBottom
                    >
                      Medium High Risk (2.1 to 3.1)
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="olive"
                      gutterBottom
                    >
                      Medium Risk (1.1 to 2.1)
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="darkgreen"
                      gutterBottom
                    >
                      Low Risk (Below 1.1)
                    </Typography>
                  </Box>
                  <Box sx={{ width: "48%" }}>
                    <Typography variant="body2">
                      <i>
                        Note: For legal entities, the system evaluates the risk
                        based on the shareholder with the highest risk score. If
                        any individual risk scores are flagged as critical
                        (score of 4), the overall risk is automatically elevated
                        unless mitigated.
                      </i>
                    </Typography>
                  </Box>
                </Box>
              </Paper>

              <Divider sx={{ my: 3 }} />

              <Box textAlign="center">
                <Typography variant="body2" color="textSecondary">
                  ***** End of Report *****
                </Typography>
              </Box>
            </Container>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography variant="h6" align="center">
              No Risk Profile Available
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          {/* <Button onClick={handlePrint} color="primary" variant="contained">
            Print to PDF
          </Button> */}
          <Button onClick={onClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};

export default Crp;
