import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  FormControlLabel,
  Box,
} from "@mui/material";
import { Padding } from "@mui/icons-material";

const PrintTable = React.forwardRef(
  (
    {
      data,
      clientName,
      responses,
      riskProfile,
      dueDiligence,
      statusResult,
      type,
      user,
    },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        className="a"
        sx={{
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          transform: "scale(1.05)",
          paddingTop: "20px",
        }}
      >
        <Box
          className="div"
          sx={{
            backgroundColor: "#ffffff",
            border: "1px none",
            height: "842px",
            position: "relative",
            width: "595px",
          }}
        >
          <Box
            className="group"
            sx={{
              height: "55px",
              left: "60px",
              position: "absolute",
              top: "83px",
              width: "499px",
            }}
          >
            <Box
              className="overlap-group"
              sx={{
                backgroundColor: "#fffcfc",
                border: "1px solid",
                borderColor: "#000000",
                height: "57px",
                left: "-1px",
                position: "relative",
                top: "-1px",
              }}
            >
              <Box
                className="text-wrapper"
                sx={{
                  color: "#000000",
                  fontFamily: '"Inter-ExtraBold", Helvetica',
                  fontSize: "20px",
                  fontWeight: "800",
                  left: "124px",
                  letterSpacing: "0",
                  lineHeight: "normal",
                  position: "absolute",
                  top: "15px",
                  width: "247px",
                }}
              >
                Customer Risk Profile
              </Box>
            </Box>
          </Box>
          {/* <img
            className="rectangle"
            alt="Rectangle"
            src="rectangle-2.png"
            style={{
              height: "321px",
              left: "60px",
              objectFit: "cover",
              position: "absolute",
              top: "184px",
              width: "497px",
            }}
          /> */}
          <Box
            sx={{
              height: "321px",
              left: "60px",
              objectFit: "cover",
              position: "absolute",
              top: "184px",
              width: "497px",
            }}
          >
            <TableContainer>
              <Table>
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <th style={{ padding: "2px" }}>S#</th>
                    <th style={{ padding: "2px" }}>Question</th>
                    <th style={{ padding: "2px", textAlign: "center" }}>
                      Yes/No
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td style={{ padding: "2px", fontSize: "10px" }}>
                        {index + 1}
                      </td>
                      <td style={{ padding: "2px", fontSize: "10px" }}>
                        {row.q}
                      </td>
                      <td
                        style={{
                          padding: "2px",
                          textAlign: "center",
                          fontSize: "10px",
                        }}
                      >
                        <span>
                          {responses[index] === "Yes" ||
                          responses[index] === true
                            ? "Yes"
                            : "No"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            className="name-fakhar-hussain"
            sx={{
              color: "#000000",
              fontFamily: '"Inter-Bold", Helvetica',
              fontSize: "12px",
              fontWeight: "400",
              left: "67px",
              letterSpacing: "0",
              lineHeight: "normal",
              position: "absolute",
              top: "153px",
              width: "638px",
            }}
          >
            <span className="span" style={{ fontWeight: "700" }}>
              Client Name
            </span>
            <span
              className="text-wrapper-2"
              style={{ fontFamily: '"Inter-Regular", Helvetica' }}
            >
              : {clientName}
            </span>
          </Box>

          <Box
            className="rectangle-2"
            sx={{
              height: "75px",
              left: "60px",
              position: "absolute",
              top: "450px",
              width: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              padding: "10px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Risk Profile:{" "}
              <span style={{ color: riskProfile === "Low" ? "green" : "red" }}>
                {riskProfile}
              </span>
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Acceptance/Rejection report:{" "}
              <span
                style={{ color: statusResult === "Accept" ? "green" : "red" }}
              >
                {statusResult}
              </span>
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Due Diligence:{" "}
              <span
                style={{
                  color: dueDiligence === "Enhanced" ? "red" : "green",
                }}
              >
                {dueDiligence}
              </span>
            </Typography>
          </Box>

          <Box
            className="rectangle-3"
            sx={{
              height: "63px",
              left: "380px",
              position: "absolute",
              top: "11px",
              width: "280px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              padding: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                Company Name:
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontSize: "9px" }}
              >
                {/* {"ARA JEWELLEY LLC"} */}
                {user.name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "", gap: "6px" }}>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                Address:
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontSize: "9px" }}
              >
                {/* {"Office # 402, Dubai"}
                {"Diamond Plaza, Gold Souk, Deira, Dubai "} */}
                {user.address}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                FIU Org ID:
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ fontSize: "12px" }}
              >
                {user.fiuOrgID}
              </Typography>
            </Box>
          </Box>

          <Box
            className="rectangle-4"
            sx={{
              //backgroundColor: "#ff1809",
              height: "55px",
              left: "232px",
              position: "absolute",
              top: "11px",
              width: "152px",
            }}
          >
            <img
              className="logo"
              alt="Logo"
              src={`https://amldiligence.com/${user.logo || "logo.png"}`}
              style={{
                height: "100px",
                left: "0",
                objectFit: "contain",
                position: "absolute",
                top: "0px",
                width: "100px",
              }}
            />
          </Box>
          <Box
            className="overlap-2"
            sx={{
              // backgroundColor: "#f7f7f7",
              height: "63px",
              left: "28px",
              position: "absolute",
              top: "11px",
              width: "182px",
            }}
          >
            <Box
              className="text-wrapper-3"
              sx={{
                color: "#000000",
                fontFamily: '"Inter-Thin", Helvetica',
                fontSize: "9px",
                fontWeight: "100",
                left: "3px",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "32px",
              }}
            >
              www.amldiligence.com
            </Box>
            <p
              className="p"
              style={{
                color: "#000000",
                fontFamily: '"Inter-Regular", Helvetica',
                fontSize: "9px",
                fontWeight: "400",
                left: "0",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "35px",
              }}
            >
              {/* Consulted by MHR Chartered Acoountants */}
            </p>
            <img
              className="logo"
              alt="Logo"
              src="logo.png"
              style={{
                height: "24px",
                left: "0",
                objectFit: "cover",
                position: "absolute",
                top: "8px",
                width: "116px",
              }}
            />
          </Box>
          <Box
            className="overlap-3"
            sx={{
              //backgroundImage: "url(./rectangle-5.svg)",
              backgroundSize: "100% 100%",
              //backgroundColor: "grey",
              height: "196px",
              width: "100vw",
              left: "60px",
              position: "absolute",
              top: "609px",
              width: "497px",
            }}
          >
            {/* <Box
              className="text-wrapper-4"
              sx={{
                color: "#000000",
                fontFamily: '"Inter-Regular", Helvetica',
                fontSize: "12px",
                fontWeight: "400",
                left: "11px",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "159px",
                width: "466px",
              }}
            >
              Designation: ___________________________
            </Box> */}
            {/* <p
              className="name-date-signature"
              style={{
                color: "#000000",
                fontFamily: '"Inter-Regular", Helvetica',
                fontSize: "12px",
                fontWeight: "400",
                left: "11px",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "114px",
                width: "466px",
              }}
            >
              <span
                className="text-wrapper-5"
                style={{
                  color: "#000000",
                  fontFamily: '"Inter-Regular", Helvetica',
                  fontSize: "12px",
                  fontWeight: "400",
                  letterSpacing: "0",
                }}
              >
                Name: ______________&nbsp;&nbsp;&nbsp;&nbsp; Date:
                _______________&nbsp;&nbsp;&nbsp;&nbsp; Signature: ___________{" "}
              </span>
              <span className="text-wrapper-6" style={{ fontSize: "20px" }}>
                &nbsp;
              </span>
              <span
                className="text-wrapper-7"
                style={{
                  fontFamily: '"Inter-ExtraBold", Helvetica',
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </p>
            <p
              className="name-date-signature-2"
              style={{
                color: "#000000",
                fontFamily: '"Inter-Regular", Helvetica',
                fontSize: "12px",
                fontWeight: "400",
                left: "11px",
                letterSpacing: "0",
                lineHeight: "normal",
                position: "absolute",
                top: "41px",
                width: "466px",
              }}
            >
              <span
                className="text-wrapper-5"
                style={{
                  color: "#000000",
                  fontFamily: '"Inter-Regular", Helvetica',
                  fontSize: "12px",
                  fontWeight: "400",
                  letterSpacing: "0",
                }}
              >
                Name: ______________&nbsp;&nbsp;&nbsp;&nbsp; Date:
                _______________&nbsp;&nbsp;&nbsp;&nbsp; Signature: ___________{" "}
              </span>
              <span className="text-wrapper-6" style={{ fontSize: "20px" }}>
                &nbsp;
              </span>
              <span
                className="text-wrapper-7"
                style={{
                  fontFamily: '"Inter-ExtraBold", Helvetica',
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </p> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                width: "100%",
                marginBottom: "50px",
              }}
            >
              <Box sx={{ width: "20%", textAlign: "center" }}>
                <Box
                  sx={{ borderBottom: "1px solid #000", marginBottom: "10px" }}
                />
                <Typography variant="body1">Name</Typography>
              </Box>
              <Box sx={{ width: "20%", textAlign: "center" }}>
                <Box
                  sx={{ borderBottom: "1px solid #000", marginBottom: "10px" }}
                />
                <Typography variant="body1">Designation</Typography>
              </Box>
              <Box sx={{ width: "20%", textAlign: "center" }}>
                <Box
                  sx={{ borderBottom: "1px solid #000", marginBottom: "10px" }}
                />
                <Typography variant="body1">Signature</Typography>
              </Box>
              <Box sx={{ width: "20%", textAlign: "center" }}>
                <Box
                  sx={{ borderBottom: "1px solid #000", marginBottom: "10px" }}
                />
                <Typography variant="body1">Date</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className="text-wrapper-8"
            sx={{
              color: "#000000",
              fontFamily: '"Inter-Regular", Helvetica',
              fontSize: "12px",
              fontWeight: "400",
              left: "40vw",
              letterSpacing: "0",
              lineHeight: "normal",
              position: "fixed", // Change this line
              bottom: "20px",
              top: "95vh",
              width: "341px",
            }}
          >
            Printed on: {new Date().toLocaleString()}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default PrintTable;
