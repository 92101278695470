import { React, useMemo, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  DownloadOutlined,
  Email,
  BusinessOutlined,
  PersonAdd,
  Traffic,
  Groups2Outlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Icon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { MaterialReactTable } from "material-react-table";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { useGetDashboardQuery, useGetLogsQuery } from "state/api";
import StatBox from "components/StatBox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ScreenLog from "scenes/ScreenLog";
import GridDash from "components/Dashboard/Grid";
import VerticalMarquee from "components/VerticalMarquee";
document.title = "AMLdiligence.com";

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const { data, isLoading } = useGetDashboardQuery();

  console.log(data);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const nav = () => {
    navigate(`/product`);
  };

  const handleInstantScreeningClick = () => {
    navigate("/search");
  };

  const handleLegalPersonClick = () => {
    navigate("/legalPerson");
  };

  const handleNaturalPersonClick = () => {
    navigate("/naturalPerson");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        {" "}
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Button
          variant="contained"
          color="success"
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={handleInstantScreeningClick}
        >
          Instant Screening ({data?.screened})
        </Button>
        <Button color="success">New Updates</Button>
        {/* <Box width={"200px"}></Box> */}
        {/* <Button
          variant="outlined"
          color="success"
          sx={{
            // backgroundColor: theme.palette.secondary.light,
            // color: theme.palette.secondary.light,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Cutomer Risk Profile
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleLegalPersonClick}>Legal Person </MenuItem>
          <MenuItem onClick={handleNaturalPersonClick}>Natural Person</MenuItem>
        </Menu> */}
        {/* </Box> */}
      </FlexBetween>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Customers"
          value={data && data.individuals}
          increase={data && data.expiredIndividuals}
          description="Expired ID Docs"
          icon={
            <Groups2Outlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Entities"
          value={data && data.entities}
          increase={data && data.expiredEntities}
          description="Expired Licenses"
          icon={
            <BusinessOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <OverviewChart view="sales" isDashboard={true} />
        </Box>
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography
            variant="h4"
            paddingBottom={2}
            style={{ color: theme.palette.secondary[300] }}
            fontWeight="bold"
          >
            Screening lists:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              sx={{ flex: 1 }}
              style={{ color: theme.palette.secondary[300] }}
            >
              United nation consolidated list
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              sx={{ flex: 1 }}
              style={{ color: theme.palette.secondary[300] }}
            >
              UAE Local terrorist List
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              sx={{ flex: 1 }}
              style={{ color: theme.palette.secondary[300] }}
            >
              OFAC List
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              sx={{ flex: 1 }}
              style={{ color: theme.palette.secondary[300] }}
            >
              UKHMT List
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              sx={{ flex: 1 }}
              style={{ color: theme.palette.secondary[300] }}
            >
              EU Sanction list
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              sx={{ flex: 1 }}
              style={{ color: theme.palette.secondary[300] }}
            >
              Wikipedia for Potential PEPs
            </Typography>
          </Box>
        </Box> */}

        <VerticalMarquee />
        <Box
          gridColumn="span 2"
          gridRow="span 1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p="1.25rem 1rem"
          flex="1 1 100%"
          backgroundColor={theme.palette.background.alt}
          borderRadius="0.55rem"
        >
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
              fontWeight={"bold"}
            >
              {" "}
              Case Management{" "}
            </Typography>
          </FlexBetween>
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
            >
              {" "}
              Identified
            </Typography>
            {data?.identified}
          </FlexBetween>
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
            >
              {" "}
              Cleared
            </Typography>
            {data?.cleared}
          </FlexBetween>
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
            >
              {" "}
              Pending
            </Typography>
            {data?.pending}
          </FlexBetween>
        </Box>
        {/* 
        Case Management
Cases Identified   105. (Cleared by reason + PNMR + FFR)
Cases Cleared      100 (Cleared by reason)
Cases Pending.       3

        
        
        
        
        <StatBox
          title="Cases Identified"
          // value={data && data.thisMonthStats.totalSales}
          value={data?.casesIdentified}
          increase="+5%"
          description="Since last month"
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        /> */}
        {/* <StatBox
          title="Name Screened"
          //value={data && data.yearlySalesTotal}
          value={data?.casesIdentified + "  Total Screened"}
          increase="10"
          description="Cleared"
          icon={
            <Typography variant="bold"> {data?.casesIdentified}</Typography>
            // <Traffic
            //   sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
          }
        /> */}
        <Box
          gridColumn="span 2"
          gridRow="span 1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p="1.25rem 1rem"
          flex="1 1 100%"
          backgroundColor={theme.palette.background.alt}
          borderRadius="0.55rem"
        >
          {" "}
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
              fontWeight={"bold"}
            >
              {" "}
              Screening{" "}
            </Typography>
            {data?.screened}
          </FlexBetween>
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
            >
              {" "}
              PEP Status{" "}
            </Typography>
            {data?.pep}{" "}
          </FlexBetween>
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
            >
              {" "}
              PNMR
            </Typography>
            {data?.pnmr}
          </FlexBetween>
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
            >
              {" "}
              FFR
            </Typography>
            {data?.ffr}
          </FlexBetween>
          <FlexBetween>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.secondary[100] }}
            >
              {" "}
              System cleared
            </Typography>
            {data?.systemClear + data?.cleared}
          </FlexBetween>
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <GridDash />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color={theme.palette.secondary[300]}
          >
            {" "}
            Useful Links
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          ></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
